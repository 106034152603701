import styled from "styled-components"

import LinkCollection from "./LinkCollection"

const SubNav = styled(LinkCollection)`
  width: 100%;
  position: sticky;
  top: 0;
  height: 32px;
  display: flex;
  align-content: center;
  justify-content: start;
  margin-bottom: var(--spacing-x2);
  z-index: ${({ subNavZIndex }) => subNavZIndex || "var(--zindex-subNav)"};
  box-shadow: var(--shadow-sm);
  .nav-link {
    padding: var(--spacing-base);
    margin: 0 var(--spacing-base);
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 0px;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: height 0.2s;
      background-color: var(--color-ebatePrimary);
      z-index: -1;
    }
    &:hover {
      :before {
        height: 5px;
      }
    }
    &.active {
      :before {
        height: 100%;
      }
      color: var(--color-white);
    }
  }
`

export default SubNav
