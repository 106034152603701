import { FormValue } from "components/models"

export interface RebateRatesModel {
  fromDate: FormValue
  ratePercentage?: FormValue
  rateValue?: FormValue
  unitsOfMeasurementId?: FormValue
  rebateRateTiers?: FormValue
  productExtensionId?: FormValue
  purchaseQty?: FormValue
  cheapestFOCQty?: FormValue
  sellPrice?: FormValue
  estimatedVolume?: FormValue
  dateTo?: FormValue
  productCode?: FormValue
  rebateRateProducts?: FormValue
  freeOfChargeQty?: FormValue
  focProductExtensionId?: FormValue
  fOCProductDescription?: FormValue
}

export interface RebateRequestTierModel {
  rebateRateId: number
  rebateId: number
  scale: number
  value: number
}

export interface RebateRateInsertRequest {
  rebateId: number
  calculationTypeId: number
  rebateRates: RebateRatesRequest[]
}

export interface RebateRateTier {
  id: number
  rebateRateId: number
  scale: number
  value: number
}

export interface RebateRatesRequest {
  rebateId: number
  fromDate: Date
  ratePercentage?: FormValue
  rateValue?: number
  unitsOfMeasurementId?: FormValue
  productExtensionId?: FormValue
  rebateRateTiers?: RebateRateTier[]
  sellPrice?: FormValue
  purchaseQty?: FormValue
  estimatedVolume?: FormValue
  cheapestFOCQty?: FormValue
  rebateRateProducts?: RebateRateProduct[]
  freeOfChargeQty?: FormValue
  fOCProductDescription?: FormValue
  focProductExtensionId?: FormValue
}

export interface RebateRateProduct {
  rebateRateId: number
  productExtensionId: number
}

export interface RebateRatesGridModel {
  id: number
  fromDate: Date
  ratePercentage: number
  rateValue: number
  unitsOfMeasurementId: number
  rebateRateTiers: any[]
  transactionCurrencyDescription: string
  statusId: number
  statusDescription: string
  unitsOfMeasurementDescription: string
  productCode?: string
  productDescription: string
  productExtensionId: number
  purchaseQty: number
  cheapestFOCQty: number
  sellPrice: number
  estimatedVolume: number
  currentRate?: number
  dateTo: Date
  approvalWorkflowEnabled: boolean
  rebateRateProducts: RebateRateProductDetail[]
  focProductDescription?: string
  freeOfChargeQty?: number
  focProductExtensionId?: number
}

export interface RateGridResponse {
  rateGridItems: RebateRatesGridModel[]
  numberOfPages: number
  numberOfItems: number
}

export interface RebateRateProductDetail {
  productExtensionId: number
  productCode: string
  productDescription: string
}

export interface RebateDeleteRateRequest {
  rebateIds: number[]
}

export interface RebateRateUpdateRequest {
  id: number
  rebateId: number
  fromDate: Date
  ratePercentage?: FormValue
  rateValue?: FormValue
  productExtensionId?: FormValue
  unitsOfMeasurementId?: FormValue
  rebateRateTiers?: RebateRateTier[]
  purchaseQty: number
  cheapestFOCQty?: number
  sellPrice: number
  estimatedVolume: number
  dateTo?: Date
  rebateRateProducts?: RebateRateProduct[]
  freeOfChargeQty?: number
  fOCProductExtensionId?: FormValue
}

export interface RebateRatesApprovalRequest {
  rebateRateIds: number[]
  description: string
}

export interface RateGridFilterRequest {
  parentId: number
  showExpired: boolean
  currentPage: number
  recordsPerPage: number
  orderBy: string
  isAscending: boolean
  isAllData: boolean
  search: string
}

export enum CalculationTypes {
  StandardValuePerUnit = 1,
  StandardPercentageOfTurnover = 2,
  TieredPercentageOfTurnoverRetro = 3,
  TieredValuePerUnitRetro = 4,
  GrowthPercentageOfTurnover = 5,
  GrowthValuePerUnit = 6,
  ProductLevelPercentage = 7,
  TieredPercentageOfTurnoverNonRetro = 8,
  TieredValuePerUnitNonRetro = 9,
  ProductLevelFoc = 10,
  ProductLevelValue = 11,
  ProductLevelFocTiered = 12,
  BuyGetCheapestFree = 13,
  BuyXGetYFree = 14,
  Fee = 15,
  TieredOneOffRebate = 16,
  TotalSalesTieredRebateRetro = 19,
  QuantityTierFixedRateRetro = 20,
  QuantityTierPercentageRateRetro = 21,
  ValueGrowthPercentageRatePaidOnGrowth = 22,
  GrowthLumpSum = 23,
  ValueGrowthPercentageRatePaidOnTurnover = 24,
  TotalSalesTieredRebateNonRetro = 25,
  ExternallyCalculated = 26,
}

export const createEmptyRateGridFilterRequest = (
  rebateId: number,
  calculationTypeId: CalculationTypes
): RateGridFilterRequest => ({
  parentId: rebateId,
  showExpired: !(
    calculationTypeId === CalculationTypes.ProductLevelValue ||
    calculationTypeId === CalculationTypes.ProductLevelPercentage
  ),
  currentPage: 1,
  recordsPerPage: 50,
  orderBy: "id",
  isAscending: true,
  isAllData: false,
  search: "",
})
