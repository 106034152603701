export interface UserInfoModel {
  email: string
  userName: string
  companyName?: string
  role: string
  initials: string
  lastActivity?: string
  tenantIdentifier: string
  isEbateUser: boolean
}

export enum RoleType {
  Normal = 0,
  Internal = 1,
  Portal = 2,
}
