import React from "react"
import { StyledHorizontalLine } from "./styled"

interface Props {
  margin?: string
  coordinates?: any
}
const HorizontalLine: React.FunctionComponent<Props> = ({ ...props }) => (
  <StyledHorizontalLine margin={props.margin} />
)

export default HorizontalLine
