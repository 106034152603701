import {
  AccrualsGridData,
  AccrualsGridRequest,
} from "components/pages/finance/accruals/accrualsList/accrual.models"
import { getAccrualGridData } from "queries"
import { ebateQueryKeys, useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useAccrualsGrid = (accrualFilter: AccrualsGridRequest) => {
  const getUniqueValues = (data): AccrualsGridData[] => {
    const flattened = data.pages.flatMap((page) => page.accrualGridItems)
    return flattened
  }

  const getCompanyType = (data): string => {
    const { companyType } = data.pages[0]
    return companyType
  }
  const fetchData = async () => {
    const response = await getAccrualGridData(accrualFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_ACCRUALS_GRID_DATA,
      accrualFilter.orderBy,
      accrualFilter.isAscending ? "true" : "false",
      accrualFilter.textFilter ?? "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= accrualFilter.currentPage
          ? accrualFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, accrualFilter])

  const accruals = data ? getUniqueValues(data) : []
  const companyType = data ? getCompanyType(data) : undefined
  const hasMore = data?.pages[0]?.numberOfPages > accrualFilter.currentPage
  return { accruals, companyType, hasMore, isLoading, isError }
}
