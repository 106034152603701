import { QueryClient } from "@tanstack/react-query"
import { CacheTime } from "./ebateReactQuery.models"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: CacheTime.medium,
      retryDelay: 1000,
      retry: 3,
    },
    mutations: {
      retryDelay: 1000,
      retry: 3,
    },
  },
})

export default queryClient
