import styled from "styled-components"

const TableHeadItem = styled.div`
  width: ${({ selectable }) => (selectable ? null : "100%")};
  position: sticky;
  top: 0;
  background-color: ${({ nested }) =>
    nested ? "var(--color-nestedGridHeader)" : "var(--color-ebatePrimary)"};
  color: ${({ nested }) =>
    nested ? "var(--color-nestedGridheaderColor)" : "var(--color-white)"};
  padding: var(--spacing-base);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: ${({ index }) => `${index} / span 1`};
  overflow: auto;
  height: 50px;
  min-width: ${({ selectable, nested }) => {
    if (nested && selectable) return `80px`
    if (selectable) return `100px`
    return null
  }};
  padding-right: ${({ selectable }) => (selectable ? "10px" : null)};
  padding-bottom: ${({ selectable }) =>
    selectable ? "var(--spacing-x2)" : null};
  ${({ sort, allowSort }) => {
    if (sort === "desc") {
      return `
      cursor: pointer;
          :after {
          content: "↓";
          padding-left: var(--spacing-half);
          font-size: var(--font-size-lg);
        }
      `
    }
    if (sort === "asc") {
      return `
        cursor: pointer;
        :after {
          content: "↑";
          padding-left: var(--spacing-half);
          font-size: var(--font-size-lg);
        }
      `
    }
    if (allowSort) {
      return `
        cursor: pointer;
        :after {
          content: "ᛨ";
          padding-left: var(--spacing-half);
          font-size: var(--font-size-lg);
          opacity: 0.2;
          transition: opacity 0.5s;
        }
        :hover:before {
          opacity: 0.8;
        }
      `
    }
    return null
  }};
`
export default TableHeadItem
