import React from "react"
import { StyledNewsWidget } from "./styled"
import BaseWidget from "./BaseWidget"
import { NewsFeedWidgetModel } from "./newsFeedWidget.models"
import { NewsFeedCardWidget } from "./NewsFeedCardWidget"

export interface Props {
  stories: NewsFeedWidgetModel[]
  children: any
}
const NewsWidget = ({ stories, children }) => (
  <BaseWidget heading="News Feed">
    <StyledNewsWidget>
      <div className="snw-stories">
        {stories.map((story) => (
          <NewsFeedCardWidget newsFeed={story} key={story.id} />
        ))}
      </div>
      {children}
    </StyledNewsWidget>
  </BaseWidget>
)

export default NewsWidget
