import React, { useContext } from "react"
import {
  RouteManager,
  Header,
  LinkCollection,
  SignOutMenu,
} from "components/library"
import { AuthenticationContext, BEConfigurationContext } from "stores"
import { useRoutesTranslations } from "components/hooks"
import UserProvider from "stores/user/UserContext"

export const RouteApp = () => {
  const { getPermission, getTokenValue } = useContext(AuthenticationContext)
  const { translateTopLevelRoutes, translatePortalTopLevelRoutes } =
    useRoutesTranslations()
  const { tenantConfiguration } = useContext(BEConfigurationContext)

  const hasPermissions = (route, anyPermission) => {
    const hideOptionInMenu = route.hideIfWorkflowEnable
      ? tenantConfiguration.agreementApprovalEnabled ||
        tenantConfiguration.rebateApprovalWorkflowEnabled
      : false
    if (hideOptionInMenu) {
      return false
    }
    if (route.requiredPermissions === undefined) {
      return true
    }
    if (anyPermission) {
      return route.requiredPermissions.some((x) => {
        const permission = getPermission(x) !== undefined
        return permission
      })
    }

    const isOK = route.requiredPermissions.every((x) => {
      const permission = getPermission(x) !== undefined
      return permission
    })
    return isOK
  }

  const filterTopLevelRouteByPermissions = (translateRoutes) => {
    const topRoutesFilterByPermissions = translateRoutes.filter((route) =>
      hasPermissions(route, true)
    )
    return topRoutesFilterByPermissions.map((topLevelRoute) => {
      if (topLevelRoute.dropdownRoutes === undefined) {
        return topLevelRoute
      }
      const dropdownRoutes = topLevelRoute.dropdownRoutes.filter((route) =>
        hasPermissions(route, false)
      )

      return {
        ...topLevelRoute,
        dropdownRoutes,
      }
    })
  }
  const topLevelRoutes = () => {
    const companyId = getTokenValue("company_id")
    if (companyId) {
      const topRoutes = translatePortalTopLevelRoutes()
      return filterTopLevelRouteByPermissions(topRoutes)
    }
    const translateRoutes = translateTopLevelRoutes()
    return filterTopLevelRouteByPermissions(translateRoutes)
  }
  return (
    <RouteManager views={topLevelRoutes()}>
      <UserProvider>
        <Header>
          <SignOutMenu />
          <div className="pendo-help" />
          <LinkCollection links={topLevelRoutes()} />
        </Header>
      </UserProvider>
    </RouteManager>
  )
}
