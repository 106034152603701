import { dropdownUtils } from "components/helpers"
import {
  ebateQueryKeys,
  useEbateQuery,
  getWorkflowById,
  getWorkflowLevelById,
  getWorkflowTypes,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useWorkflowTypes = () => {
  const onCompleted = (res) => {
    const dataMapped = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...dataMapped]
  }
  const fetch = async () => {
    const response = await getWorkflowTypes()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const workflowTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_WORKFLOW_TYPES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return workflowTypes
}

export const useWorkflowById = (id: number) => {
  const fetch = async () => {
    const response = await getWorkflowById(id)

    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const workflow = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_WORKFLOW_BY_ID, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )
  return workflow
}

export const useWorkflowLevelById = (id: number) => {
  const fetch = async () => {
    const response = await getWorkflowLevelById(id)

    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const workflow = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_WORKFLOW_BY_LEVEL_ID, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )
  return workflow
}
