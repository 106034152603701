import React from "react"
import { StyledIcon } from "./styled"

interface Props {
  icon
  color: any
  onClick?
  size?: any
}
const Icon: React.FC<Props> = ({ icon, color, onClick, size }) => (
  <StyledIcon className={icon} color={color} onClick={onClick} size={size} />
)

export default Icon
