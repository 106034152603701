import styled from "styled-components"

const StyledWidget = styled.div`
  background: var(--color-background);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-wrap: nowrap;
  margin: var(--spacing-base);
`

export default StyledWidget
