const getErrors = (ae) => {
  if (ae.length >= 1) {
    return ae.graphQLErrors?.map((e) => ({
      code: e.extensions?.code,
      message: e.message,
    }))
  }
  return [ae]
}

export default { getErrors }
