import {
  ebateQueryKeys,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getAccountingPeriod, getAccountingPeriodGridData } from "queries"
import {
  AccountingPeriodGridFilterRequest,
  AccountingPeriodGridItem,
} from "components/pages/maintanance/accountingPeriod/accountingPeriodList/accountingPeriodList.models"
import { useEffect } from "react"

export const useAccountingPeriodById = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getAccountingPeriod(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const accountingPeriod = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ACCOUNTING_PERIOD_BY_ID, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return accountingPeriod
}

export const useAccountingPeriodGridData = (
  accountingPeriodGridRequest: AccountingPeriodGridFilterRequest
) => {
  const getUniqueValues = (data): AccountingPeriodGridItem[] => {
    const flattened = data.pages.flatMap(
      (page) => page.accountingPeriodGridItems
    )
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as AccountingPeriodGridItem[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getAccountingPeriodGridData(
      accountingPeriodGridRequest
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_ACCOUNTING_PERIOD_GRID_DATA,
      accountingPeriodGridRequest.orderBy,
      accountingPeriodGridRequest.isAscending ? "true" : "false",
      accountingPeriodGridRequest.search,
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= accountingPeriodGridRequest.currentPage
          ? accountingPeriodGridRequest.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, accountingPeriodGridRequest])

  const accountingPeriods = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > accountingPeriodGridRequest.currentPage

  return { accountingPeriods, hasMore, isLoading, isError }
}
