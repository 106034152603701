import { OptionApi } from "components/helpers"

import EbateAxios from "queries/ebateAxios"

const masterDataUrl = "/api/MasterData"

export const getDashboards = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${masterDataUrl}/GetDashboards`)
}

export const getCurrencies = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any[]>(`${masterDataUrl}/GetCurrencies`)
}

export const getCompanyTypesComboValuesByUser = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any[]>(`${masterDataUrl}/GetCompanyTypesComboValuesByUser`)
}

export const getCompanies = async (queryString: string) => {
  const { AxiosGet } = EbateAxios()
  const url = `${masterDataUrl}/GetCompanies`

  return AxiosGet<OptionApi[]>(url, { queryString })
}

export const getPriceTypes = async () => {
  const { AxiosGet } = EbateAxios()
  const url = `${masterDataUrl}/GetPriceTypes`

  return AxiosGet<any[]>(url)
}

export const getPayToTypes = async () => {
  const { AxiosGet } = EbateAxios()
  const url = `${masterDataUrl}/GetPayToTypes`

  return AxiosGet<any[]>(url)
}

export const getAccountTypes = async () => {
  const { AxiosGet } = EbateAxios()
  const url = `${masterDataUrl}/GetAccountTypes`

  return AxiosGet<any[]>(url)
}

export const getAccrualTypes = async () => {
  const { AxiosGet } = EbateAxios()
  const url = `${masterDataUrl}/GetAccrualTypes`

  return AxiosGet<any[]>(url)
}

export const getDocumentSizeLimit = async () => {
  const url = `${masterDataUrl}/GetDocumentSizeLimit`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any>(url)
}

export const getDocumentTypes = async () => {
  const url = `${masterDataUrl}/GetDocumentTypes`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any[]>(url)
}
