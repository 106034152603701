import styled from "styled-components"
import { InputText } from "primereact/inputtext"

const StyledInputText = styled(InputText)`
  width: ${({ width }) => width || null};
  &:enabled:focus {
    border: ${({ $transparent }) =>
      $transparent ? "none" : "2px solid var(--color-ebateControlBorderHover)"};
    box-shadow: ${({ $transparent }) =>
      $transparent
        ? "none"
        : "inset 0 0 0 0px var(--color-ebateControlBorderHover), " +
          "inset 0 0 0 0px var(--color-ebateControlBorderHover)," +
          " inset 0 0 0 0px var(--color-ebateControlBorderHover), " +
          "inset 0 0 0 0px var(--color-ebateControlBorderHover)"};
  }

  padding-left: var(--spacing-base) !important;

  border: ${({ $transparent }) =>
    $transparent ? "none" : "2px solid var(--color-ebateControlBackground)"};
  background: ${({ $transparent }) =>
    $transparent ? "transparent" : "var(--color-ebateControlBackground)"};
`

export default StyledInputText
