import React, { useContext } from "react"
import { ThemeContext } from "theme"

import { Button } from "components/library"

const ThemeModeToggle = () => {
  const { themeDispatch } = useContext(ThemeContext)
  const setTheme = (color) => {
    themeDispatch({ type: "UPDATE_MODE", payload: color })
  }
  return (
    <>
      <Button onClick={() => setTheme("light")} text="light" />
      <Button onClick={() => setTheme("dark")} text="dark" />
    </>
  )
}

export default ThemeModeToggle
