import { dropdownUtils } from "components/helpers"
import {
  ebateQueryKeys,
  getAdjustmentTypes,
  getTransactionTypes,
  useEbateQuery,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useTransactionTypes = () => {
  const onCompleted = (data: any) => {
    const transactionTypesMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...transactionTypesMapped]
  }

  const fetch = async () => {
    const response = await getTransactionTypes()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const transactionsTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_TRANSACTION_TYPES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return transactionsTypes
}

export const useAdjustmentTypes = () => {
  const onCompleted = (data: any) => {
    const transactionTypesMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...transactionTypesMapped]
  }

  const fetch = async () => {
    const response = await getAdjustmentTypes()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const adjustmentTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ADJUSTMENT_TYPES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return adjustmentTypes
}
