import * as useProductDropdown from "components/pages/maintanance/product/productDropdown/useProductDropdown"
import {
  createProductListRequestWithFilter,
  ProductGridRequest,
} from "components/pages/maintanance/product/productList/product.models"
import { Dropdown, ValidationMessage } from "components/library"
import React, { useState } from "react"

interface Props {
  productSelected: number
  productOnChange: (e: any) => void
  errorMessage: string
  displayValidations: boolean
  fieldName: string
  heading: string
  required?: boolean
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProductDropdown: React.FC<Props> = ({
  productSelected,
  productOnChange,
  errorMessage,
  displayValidations,
  fieldName,
  heading,
  required,
  setLoading,
}) => {
  const [productRequest, setProductsRequest] = useState<ProductGridRequest>(
    createProductListRequestWithFilter(productSelected?.toString())
  )
  const { products, showSpinner } =
    useProductDropdown.useProductDropdown(productRequest)

  const [, setFilterText] = useState<string>("")
  const [searchTimeout, setSearchTimeout] = useState<any>()
  const [productCode, setProductCode] = useState<string | undefined>(undefined)

  React.useEffect(() => {
    setProductsRequest(
      createProductListRequestWithFilter(productSelected?.toString())
    )
  }, [productSelected])

  React.useEffect(() => {
    if (!showSpinner && setLoading) {
      setLoading(false)
    }
  }, [showSpinner])

  const filterByText = (textToFilter: string) => {
    setProductsRequest((previousProducts: ProductGridRequest) => ({
      ...previousProducts,
      currentPage: 1,
      search: textToFilter,
    }))
  }

  const textChanged = (event: any) => {
    setFilterText(event.filter)
    if (event.filter !== "" || productCode === "") {
      clearTimeout(searchTimeout)
      setSearchTimeout(
        setTimeout(() => {
          filterByText(event.filter)
        }, 200)
      )
    }
  }

  const onHideSearchPanel = () => {
    if (productCode && productCode !== "") {
      filterByText(productCode)
    }
  }

  const getSelectedProductCode = (id: number) => {
    const productSelected = products.find((p) => p.value === id)?.title

    const productCode = productSelected
      ? productSelected
          .substring(productSelected.indexOf("(") + 1)
          .substring(0, productSelected.indexOf(")") - 1)
      : ""
    return productCode
  }
  return (
    <>
      <Dropdown
        heading={heading}
        options={products}
        id="product"
        name={fieldName}
        value={productSelected}
        required={required}
        placeholder="Select option..."
        onChange={(e) => {
          productOnChange(e)
          setProductCode(getSelectedProductCode(e.value))
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
        canSearch
        onHide={() => onHideSearchPanel()}
      />
      <ValidationMessage
        messageError={errorMessage}
        showMessage={
          displayValidations &&
          errorMessage !== undefined &&
          errorMessage !== null &&
          errorMessage.length > 0
        }
      />
    </>
  )
}
