import React from "react"
import { StyledFormInput, StyledFormLabel } from "./styled"

const FormInput = (props) => {
  const {
    children,
    heading,
    width,
    flat,
    border,
    required,
    floatHeadRight,
    id,
  } = props
  if (floatHeadRight) {
    return (
      <StyledFormInput
        heading={heading}
        width={width}
        flat={flat}
        border={border}
        required={required}
      >
        {children}
        {heading && required && (
          <StyledFormLabel className="form-label" flat floatHeadRight>
            {heading}
            <span className="p-error"> *</span>
          </StyledFormLabel>
        )}
        {heading && !required && (
          <StyledFormLabel className="form-label" flat floatHeadRight>
            {heading}
          </StyledFormLabel>
        )}
      </StyledFormInput>
    )
  }
  return (
    <StyledFormInput
      heading={heading}
      width={width}
      flat={flat}
      border={border}
      required={required}
      id={id}
    >
      {heading && required && (
        <StyledFormLabel className="form-label" flat={flat}>
          {heading}
          <span className="p-error"> *</span>
        </StyledFormLabel>
      )}
      {heading && !required && (
        <StyledFormLabel className="form-label" flat={flat}>
          {heading}
        </StyledFormLabel>
      )}
      {children}
    </StyledFormInput>
  )
}

export default FormInput
