import React from "react"
import { StyledBaseWidget } from "./styled"

const BaseWidget = ({ heading, children }) => (
  <StyledBaseWidget>
    <div className="wdg-header">
      <span className="wdg-heading">{heading}</span>
    </div>
    {children}
  </StyledBaseWidget>
)

export default BaseWidget
