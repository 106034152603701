import {
  ClaimGridItemSelected,
  ClaimsGridFilterRequest,
} from "components/pages/finance/approveFocClaims/approveFocClaims.models"
import { getClaimsGridData } from "queries"
import { ebateQueryKeys, useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useApproveFocClaimGrid = (
  claimsFilter: ClaimsGridFilterRequest
) => {
  const getUniqueValues = (data): ClaimGridItemSelected[] => {
    const flattened = data.pages.flatMap((page) => page.claimGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as ClaimGridItemSelected[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getClaimsGridData(claimsFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const isNotNullOrUndefined = (value: any) =>
    value !== undefined && value !== null

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_APPROVE_FOC_CLAIMS_GRID_DATA,
      claimsFilter.orderBy,
      claimsFilter.isAscending ? "true" : "false",
      claimsFilter.search ?? "",
      claimsFilter.exchangeMappingId?.toString() ?? "",
      claimsFilter.accountingPeriodId?.toString() ?? "",
      claimsFilter.claimGridViewType?.toString() ?? "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= claimsFilter.currentPage
          ? claimsFilter.currentPage + 1
          : undefined,
      enabled:
        isNotNullOrUndefined(claimsFilter.accountingPeriodId) &&
        isNotNullOrUndefined(claimsFilter.exchangeMappingId) &&
        isNotNullOrUndefined(claimsFilter.claimGridViewType),
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, claimsFilter])

  const claims = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > claimsFilter.currentPage

  return { claims, hasMore, isLoading, isError }
}
