import React from "react"

const DefaultRoutes = [
  {
    location: "/login",
    name: "Login",
    Component: React.lazy(() => import("views/auth/Login")),
  },
  {
    location: "/forgottenPassword",
    name: "Forgotten Password",
    Component: React.lazy(() => import("views/auth/ForgottenPassword")),
  },
  {
    location: "/createPassword/:resetToken/:mail",
    name: "Create Password",
    Component: React.lazy(() => import("views/auth/CreatePassword")),
  },
  {
    location: "/confirmEmail/:resetToken/:mail",
    name: "Create Password",
    Component: React.lazy(() => import("views/auth/CreatePassword")),
  },
  {
    location: "/resetPasswordConfirmation/:mail",
    name: "Reset Password Confirm",
    Component: React.lazy(() => import("views/auth/ResetPasswordConfirmation")),
  },
  {
    location: "/passwordCreatedConfirmation",
    name: "Password Created Confirm",
    Component: React.lazy(
      () => import("views/auth/PasswordCreatedConfirmation")
    ),
  },
  {
    location: "/login",
    fallbackLocation: "/login",
    Component: React.lazy(() => import("views/auth/Login")),
  },
]

export default DefaultRoutes
