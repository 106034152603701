import styled from "styled-components"
import { device } from "theme/helpers"

const StyledLinkedCollection = styled.ul`
  display: flex;
  flex-direction: row-reverse;
  list-style: none;
  text-align: center;
  height: 100%;
  width: 100%;

  .active-nav-link {
    color: var(--color-ebateActiveNavLinkFontColor);
    background-color: var(--color-ebateActiveNavLinkBackground);
    padding: 0 var(--spacing-base);
  }
  .active-nav-link svg {
    fill: var(--color-black);
  }
  .main-nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    padding: 0 var(--spacing-base);

    @media ${device.mobileS} {
      font-size: var(--font-size-sm);
      padding: 0 var(--spacing-x2);
    }

    @media ${device.tablet} {
      font-size: var(--font-size-sm);
      padding: 0 var(--spacing-x2);
    }

    @media ${device.laptop} {
      font-size: var(--font-size-md);
      padding: 0 var(--spacing-x2);
    }

    @media ${device.laptopL} {
      font-size: var(--font-size-lg);
      padding: 0 var(--spacing-x4);
    }
  }
`

export default StyledLinkedCollection
