import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getSeasonalitiesByPackage } from "../seasonality.query"

export const useSeasonalityByPackage = (packageId: number) => {
  const fetchSeasonalitiesByPackage = async () => {
    try {
      const response = await getSeasonalitiesByPackage(packageId)

      if (response) {
        if (response.status === 200) {
          return response.data
        }
        return null
      }
      return null
    } catch (exception) {
      return null
    }
  }

  const seasonalitiesByPackage = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_SEASONALITIES_BY_PACKAGE,
      packageId.toString(),
    ],
    fetchSeasonalitiesByPackage,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      enabled: packageId !== undefined && packageId !== null,
      refetchOnMount: "always",
    }
  )

  return seasonalitiesByPackage
}
