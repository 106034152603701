const colors = {
  ebateYellow: "#FDC249",
  ebatePrimary: "#008486",
  ebatePrimaryMid: "#E4F1F1",
  ebatePrimaryLight: "#EBFBFB",
  ebateSecondaryLight: "#FFF7F4",
  ebateSecondary: "#FFE8DF",
  ebateThird: "#99FFFF",
  eBateThirdMid: "#D8FFFF",
  ebateThirdLight: "#F3FFFF",
  ebateBurgundy: "#A02563",
  ebateGreen: "#168A17",
  ebateLightGreen: "#B3D649",
  ebateRed: "#FF0102",
  ebateLightRed: "#e91e63",
  ebateError: "#b00020",
  black: "#090909",
  white: "#fcfcfc",
}

const globalColors = {
  ebateYellow: colors.ebateYellow,
  ebatePrimary: colors.ebatePrimary,
  ebatePrimaryMid: colors.ebatePrimaryMid,
  ebatePrimaryLight: colors.ebatePrimaryLight,
  ebateSecondaryLight: colors.ebateSecondaryLight,
  ebateSecondary: colors.ebateSecondary,
  ebateError: colors.ebateError,
  ebateThird: colors.ebateThird,
  ebateThirdMid: colors.ebatePrimaryMid,
  ebateThirdLight: colors.ebateThirdLight,
  ebateActionButton: colors.ebatePrimary,
  ebateActionButtonBorder: colors.eBateThirdMid,
  ebateActionButtonBorderHover: colors.ebateThirdLight,
  ebatePrimaryButton: colors.ebatePrimary,
  ebatePrimaryButtonBorder: colors.eBateThirdMid,
  ebatePrimaryButtonBorderHover: colors.ebateThirdLight,
  ebateSecondaryButton: colors.ebatePrimary,
  ebateSecondaryButtonHover: colors.ebatePrimaryLight,
  ebateIconButton: colors.ebatePrimary,
  ebateIconButtonBorderHover: colors.ebateThird,
  ebateIconButtonHover: colors.ebatePrimary,
  ebateControlBackground: colors.ebateSecondaryLight,
  ebateControlBorderHover: colors.ebateSecondary,
  ebateCheckboxActive: colors.ebatePrimary,
  ebateInputNumberButton: colors.eBateThirdMid,
  ebateInputNumberButtonHover: colors.ebateThird,
  ebateFileUploadHighlight: colors.ebateThirdMid,
  ebateDataTableRowHover: colors.ebateSecondaryLight,
  ebateDataTableRowSelected: colors.ebatePrimaryMid,
  ebateActiveNavLinkFontColor: colors.black,
  ebateActiveNavLinkBackground: colors.ebateSecondary,
  ebateActive: colors.ebateThird,
  ebateInactive: colors.white,
  ebateStatusFieldColorFont: colors.black,
  ebateWidgetBackground: colors.ebatePrimaryMid,
  ebateWidgetFontColor: colors.ebatePrimary,
  ebatePending: colors.ebatePrimary,
  ebateGraphPrimaryColor: colors.ebatePrimary,
  ebateGraphSecondaryColor: colors.ebateThird,
  ebateGraphTertiaryColor: colors.ebateSecondary,
  ebateGraphQuaternaryColor: colors.ebatePrimaryMid,
  ebateBurgundy: colors.ebateBurgundy,
  approve: colors.ebateGreen,
  approveHover: colors.ebateLightGreen,
  reject: colors.ebateError,
  rejectHover: colors.ebateLightRed,
  grey50: "#555",
  grey80: "#888",
  black: colors.black,
  white: colors.white,
}

export default globalColors
