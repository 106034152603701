import React from "react"

export interface Action {
  type: string
  data: any
}

export interface Field<T> {
  value: T | null
  error: string
  hasError: boolean
  touched: boolean
}

const UPDATE_FORM = "UPDATE_FORM"
const RESET_FORM = "RESET_FORM"
const useReducerForm = (validateInput?: any) => {
  const formsReducer = <T extends object>(state: T, action: Action) => {
    switch (action.type) {
      case UPDATE_FORM: {
        const { name, value, hasError, error, touched, isFormValid } =
          action.data
        return {
          ...state,
          [name]: { ...state[name], value, hasError, error, touched },
          isFormValid,
        }
      }
      case RESET_FORM: {
        return {
          ...action.data,
        }
      }
      default:
        return state
    }
  }
  const resetForm = <T extends object>(
    formState: T,
    dispatch: React.Dispatch<Action>,
    value: T
  ) => {
    dispatch({
      type: RESET_FORM,
      data: {
        ...value,
      },
    })
  }

  const validateForm = <T extends object>(
    formState: T,
    dispatch: React.Dispatch<Action>
  ) => {
    let isFormValid = true
    if (validateInput) {
      Object.keys(formState).forEach((key) => {
        const item = formState[key]
        const { value } = item
        const { hasError, error } = validateInput
          ? validateInput(key, value)
          : { hasError: false, error: "" }

        if (hasError) {
          isFormValid = false
        }
        if (key) {
          dispatch({
            type: UPDATE_FORM,
            data: {
              name: key,
              value,
              hasError,
              error,
              touched: true,
              isFormValid,
            },
          })
        }
      })
    }
    return isFormValid
  }
  const onInputChange = <T extends object>(
    name: string,
    value: any,
    dispatch: React.Dispatch<Action>,
    formState: T
  ) => {
    const { hasError, error } = validateInput
      ? validateInput(name, value)
      : { hasError: false, error: "" }

    let isFormValid = true
    Object.keys(formState).forEach((key) => {
      const item = formState[key]
      if (key === name && hasError) {
        isFormValid = false
      } else if (key !== name && item.hasError) {
        isFormValid = false
      }
    })
    dispatch({
      type: UPDATE_FORM,
      data: {
        name,
        value,
        hasError,
        error,
        touched: false,
        isFormValid,
      },
    })
  }
  const onFocusOut = <T extends object>(
    name: string,
    value: any,
    dispatch: React.Dispatch<Action>,
    formState: T
  ) => {
    const { hasError, error } = validateInput
      ? validateInput(name, value)
      : { hasError: false, error: "" }

    let isFormValid = true
    Object.keys(formState).forEach((key) => {
      const item = formState[key]
      if (key === name && hasError) {
        isFormValid = false
      } else if (key !== name && item.hasError) {
        isFormValid = false
      }
    })

    dispatch({
      type: UPDATE_FORM,
      data: {
        name,
        value,
        hasError,
        error,
        touched: true,
        isFormValid,
      },
    })
  }

  const cleanError = (
    name: string,
    value: any,
    dispatch: React.Dispatch<Action>
  ) => {
    dispatch({
      type: UPDATE_FORM,
      data: {
        name,
        value,
        hasError: false,
        error: "",
        touched: false,
        isFormValid: true,
      },
    })
  }

  return {
    formsReducer,
    onInputChange,
    onFocusOut,
    validateForm,
    cleanError,
    resetForm,
  }
}

export default useReducerForm
