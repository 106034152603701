import styled from "styled-components"

interface Props {
  gutter?: string
  rowGutter?: string
  columnGutter?: string
  columns?: any
  rows?: any
  rowHeight?: string
}
const StyledGrid = styled.div<Props>`
  display: grid;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  grid-gap: ${({ gutter }) => gutter || "var(--spacing-base)"};
  grid-row-gap: ${({ rowGutter }) => rowGutter || null};
  grid-column-gap: ${({ columnGutter }) => columnGutter || null};
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, minmax(50%, 50%))` : "minmax(50%, 50%)"};
  grid-template-rows: ${({ rows, rowHeight }) =>
    rows
      ? `repeat(${rows},  ${rowHeight || "1fr"})`
      : `${rowHeight || "minmax(auto, 1fr)"}`};
`

export default StyledGrid
