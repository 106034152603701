import { ToastComponent } from "components/library"
import React, { createContext, useState } from "react"
import { ToastPositionType, ToastSeverityType } from "primereact/toast"

export interface ToastProperties {
  showToast: boolean
  setShowToast?: any
  severity: ToastSeverityType
  summary: string
  detail?: string
  life: number
  position: ToastPositionType
  sticky?: boolean
  onHide?: () => void
}

export const ToastContext = createContext<((toast: any) => void) | null>(null)
export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProperties[]>([])
  const [, setShowToast] = useState<boolean>(false)

  const addToast = (toast) => {
    const toastToShow = { ...toast, showToast: true }
    setToasts((toasts) => [...toasts, toastToShow])
  }

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {toasts.map((toast, index) => (
        <ToastComponent
          key={index}
          showToast={toast.showToast}
          setShowToast={setShowToast}
          severity={toast.severity}
          summary={toast.summary}
          detail={toast.detail}
          life={toast.life}
          position={toast.position}
          sticky={toast.sticky}
          onHide={toast.onHide}
        />
      ))}
    </ToastContext.Provider>
  )
}
