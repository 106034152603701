import React from "react"
import { AxisLinearOptions, AxisOptions, Chart } from "react-charts"
import { numberFormatter } from "components/helpers"
import { Series, TwelveMonthOverviewModel } from "./graph.models"

export interface Props {
  data: Series[]
  colorPalette: any
}
export const BarGrapheBate: React.FC<Props> = ({ data, colorPalette }) => {
  const primaryAxis = React.useMemo(
    (): AxisOptions<TwelveMonthOverviewModel> => ({
      getValue: (datum) => datum.month,
    }),
    []
  )
  const isEmptyData = () => {
    if (data && data.length > 0) {
      const noData = data.every((item) =>
        item.data.every(
          (monthData) =>
            monthData.data === null ||
            monthData.data === undefined ||
            monthData.data === 0
        )
      )
      return noData
    }
    return true
  }

  const secondaryAxes = React.useMemo(
    (): AxisLinearOptions<TwelveMonthOverviewModel>[] => [
      {
        getValue: (datum) => datum.data ?? 0,
        scaleType: "linear",
        formatters: {
          tooltip: (value: number | undefined) => {
            if (value !== undefined) {
              return numberFormatter.numberFormatter.format(value)
            }
            return 0
          },
        },
        hardMin: isEmptyData() ? 0 : undefined,
        hardMax: isEmptyData() ? 100 : undefined,
      },
    ],
    []
  )

  const getSeriesStyle = React.useCallback(
    (series) => ({
      fill: colorPalette[series.label],
    }),
    []
  )

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        getSeriesStyle,
      }}
    />
  )
}
