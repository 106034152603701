import React from "react"

import { numberFormatter } from "components/helpers"
import { FormInput } from "../formInput"
import { StyledInputNumber } from "./styled"

interface Props {
  heading?: string
  required?: boolean
  label?: string
  decimalnumberlabel?: number
  id?: string
  name?: string
  value?: any
  onValueChange?: (e: any) => void
  onChange?: (e: any) => void
  componenttype?: number
  placeholder?: string
  suffix?: string
  max?: number
  min?: number
  hidden?: boolean
  minFractionDigits?: number
  maxFractionDigits?: number
  step?: number
  disabled?: boolean
  showButtons?: boolean
  width?: string
  onKeyDown?: (e: any) => void
}
const decimalSeparator = numberFormatter.getDecimalSeparator(
  localStorage.getItem("i18nextLng") ?? window.navigator.language
)
const addDecimal = (e) => {
  if (e.key === decimalSeparator && e.target.value === "") {
    e.target.value = `0${decimalSeparator}`
  }
}

const onValueChangeRound = (e, onValueChange) => {
  if (onValueChange) {
    if (e && e.value != null) {
      e.value = parseFloat(e?.value?.toFixed(10))
    }
    if (e.target && e.target.value != null) {
      e.target.value = parseFloat(e?.target?.value?.toFixed(10))
    }

    onValueChange(e)
  }
}

const InputNumber: React.FC<Props> = ({
  heading,
  required,
  width,
  ...props
}) => (
  <FormInput heading={heading} required={required} width={width}>
    <StyledInputNumber
      minFractionDigits={
        props.minFractionDigits !== null &&
        props.minFractionDigits !== undefined
          ? props.minFractionDigits
          : 2
      }
      maxFractionDigits={props.maxFractionDigits ? props.maxFractionDigits : 2}
      step={props.step ? props.step : 0.05}
      showButtons
      {...props}
      onValueChange={(e) => onValueChangeRound(e, props.onValueChange)}
      onKeyDown={(e) => {
        addDecimal(e)
        if (props.onKeyDown) {
          props.onKeyDown(e)
        }
      }}
      id={props.id ?? props.name}
      data-testid={props.name}
    />
  </FormInput>
)

export default InputNumber
