import React from "react"
import { FormInput } from "../formInput"
import { StyledFormLabel } from "../formInput/styled"
import { StyledSwitch } from "./styled"

const Switch = (props) => {
  const {
    checked,
    name,
    onChange,
    disabled,
    optionLeft,
    optionRight,
    className,
    heading,
    flat,
    required,
  } = props

  return (
    <FormInput heading={heading} flat={flat} required={required}>
      <div className={`p-switch-container ${className}`}>
        <StyledFormLabel>{optionLeft}</StyledFormLabel>
        <StyledSwitch
          checked={checked}
          name={name}
          onChange={onChange}
          disabled={disabled}
          data-testid={name}
        />
        <StyledFormLabel>{optionRight}</StyledFormLabel>
      </div>
    </FormInput>
  )
}

export default Switch
