import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getCalculationTypesExportable } from "queries/common/calculationType.query"
import { OptionModel } from "components/helpers"

export const useCalculationTypeExportables = () => {
  const mapCalculationType = (data: any): OptionModel[] =>
    data &&
    data.data.map((d) => ({
      title: d.description,
      value: d.id,
    }))

  const calculationTypesExportables = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_CALCULATION_TYPES_EXPORTABLES,
    getCalculationTypesExportable,
    {
      select: (data) => mapCalculationType(data),
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { calculationTypesExportables }
}
