import styled from "styled-components"
import StyledWidget from "./StyledWidget"

interface Props {
  bgcolor: string
}
const StyledNumberWidget = styled(StyledWidget)<Props>`
  width: 100%;
  background-color: ${({ bgcolor }) => `var(${bgcolor})`};
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: var(--color-ebateWidgetFontColor);
  height: 120px;

  .nw-body {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: var(--spacing-x2);
  }

  .nw-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nw-icon {
    font-size: 6em;
    color: var(--color-ebateWidgetFontColor);
    opacity: 30%;
  }

  .nw-number {
    font-size: var(--font-size-modalClose);
    line-height: var(--font-size-modalClose);
  }

  .nw-text {
    font-size: var(--font-size-xs);
    line-height: var(--font-size-xl);
  }

  .nw-more {
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
    padding: var(--spacing-half);
  }
`

export default StyledNumberWidget
