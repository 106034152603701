import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"
import React from "react"
import styled from "styled-components"
import { RebateRatesGridModel } from "components/pages/pricemanagement/rebates/rebateRates/rebateRates.model"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const dateEndTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.dateTo)

export const StyledProductDescriptionBody = styled.div`
  background-color: #f0f0f0;
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-half);
  padding: var(--spacing-base) var(--spacing-x2);
`

const productDescriptionBody = (rowData: RebateRatesGridModel): any => (
  <>
    {rowData.rebateRateProducts.map((rrp) => (
      <StyledProductDescriptionBody style={{ marginBottom: 12 }}>
        ({rrp.productCode}) {rrp.productDescription}
      </StyledProductDescriptionBody>
    ))}
  </>
)

const BuyGetCheapestFreeColumnColumns = (
  isApprovalEnabled: boolean
): ColumnModel[] => {
  const columns = [
    {
      field: "id",
      header: "ID",
      sortable: true,
    },
    {
      field: "productDescription",
      header: "Product Description",
      sortable: true,
      body: productDescriptionBody,
    },
    {
      field: "fromDate",
      header: "Effective From",
      sortable: true,
      body: dateStartTemplateBody,
    },
    {
      field: "dateTo",
      header: "Effective To",
      sortable: true,
      body: dateEndTemplateBody,
    },
    {
      field: "purchaseQty",
      header: "Purchase Qty",
      sortable: true,
    },
    {
      field: "cheapestFOCQty",
      header: "FOC Qty",
      sortable: true,
    },
  ]
  if (isApprovalEnabled) {
    return [
      ...columns,
      {
        field: "statusDescription",
        header: "Status",
        sortable: true,
      },
    ]
  }
  return columns
}

export default BuyGetCheapestFreeColumnColumns
