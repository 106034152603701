import { CalculationTypeResponse } from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"
import EbateAxios from "queries/ebateAxios"

const calculationTypeUrl = "/api/CalculationType"

export const getCalculationTypesExportable = async () => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<CalculationTypeResponse[]>(
    `${calculationTypeUrl}/GetExportableRateCalculationTypeComboValues`
  )
}
