const getClientName = () => {
  if (
    window.location.href.includes(".viz.") ||
    window.location.href.includes(".viz-")
  ) {
    return "vizient"
  }
  return ""
}

// This functionality should be moved to the Tenant Configuration call in the backend
const isClientBuyingGroup = (): boolean => getClientName() === "vizient"

export { getClientName, isClientBuyingGroup }
