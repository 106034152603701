import React from "react"
import { StyledIconButton } from "./styled"

export default function IconButton(props) {
  const {
    icon,
    onClick,
    isDisabled,
    isSubmit,
    size,
    bgcolor,
    bgcoloronhover,
    className,
    id,
    name,
  } = props

  return (
    <StyledIconButton
      id={id}
      icon={icon}
      type={isSubmit ? "submit" : "button"}
      className={`p-button-raised p-button-rounded ${className}`}
      onClick={onClick || null}
      disabled={isDisabled}
      size={size}
      name={name}
      bgcolor={bgcolor}
      bgcoloronhover={bgcoloronhover}
      data-testid={name}
    />
  )
}
