import React from "react"
import { dateUtils } from "components/helpers"
import { FormInput } from "../formInput"
import { StyledDatePicker } from "./styled"

const DatePicker = (props) => {
  const {
    heading,
    value,
    name,
    onChange,
    flat,
    required,
    disabled,
    width,
    onClearButtonClick,
  } = props
  return (
    <FormInput heading={heading} flat={flat} required={required} width={width}>
      <StyledDatePicker
        disabled={disabled}
        placeholder={
          value ? dateUtils.formatDateToLocale(value) : "Select date"
        }
        dateFormat={dateUtils.getLocaleFormatDateString()}
        name={name}
        viewDate={value ? new Date(value) : new Date()}
        value={value}
        onChange={onChange}
        minDate={props.minDate ?? new Date("2000-01-01T00:00:00")}
        maxDate={props.maxDate ?? new Date("2500-12-31T00:00:00")}
        onSelect={props.onSelect}
        showButtonBar
        clearButtonClassName="date-picker-secondary-button"
        todayButtonClassName="date-picker-secondary-button"
        onClearButtonClick={onClearButtonClick}
        data-testid={name}
      />
    </FormInput>
  )
}

export default DatePicker
