import {
  CompanyGridData,
  CompanyGridRequest,
} from "components/pages/maintanance/company/companyList/company.models"
import {
  getCompanyWithDynamicFields,
  useEbateQuery,
  ebateQueryKeys,
  getEmptyCustomer,
  getCompaniesNotTrading,
  getCompanyGridData,
} from "queries"
import { useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useCompanyWithExtensionField = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getCompanyWithDynamicFields(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const company = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANY_WITH_DYNAMIC_FIELDS, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return company
}

export const useCompanyComboValueWithExtensionField = (id: number) => {
  const onCompleted = (data) => {
    const companyComboValue = {
      value: data.id,
      title: data.accountName,
      companyTypeId: data.companyType,
    }
    return [companyComboValue]
  }
  const fetch = async () => {
    try {
      const response = await getCompanyWithDynamicFields(id)
      if (response?.status === 200) {
        return onCompleted(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const company = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_COMPANY_COMBO_VALUE_WITH_DYNAMIC_FIELDS,
      id.toString(),
    ],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return company
}
export const useEmptyCustomer = () => {
  const fetch = async () => {
    try {
      const response = await getEmptyCustomer()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const emptyCustomer = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANY_EMPTY_CUSTOMER],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return emptyCustomer
}

export const useCompaniesNoTrading = () => {
  const fetch = async () => {
    try {
      const response = await getCompaniesNotTrading()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const emptyCustomer = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANIES_NO_TRADING],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return emptyCustomer
}

export const useCompanyGridData = (companyFilter: CompanyGridRequest) => {
  const getUniqueValues = (data): CompanyGridData[] => {
    const flattened = data.pages.flatMap((page) => page.companyGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as CompanyGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getCompanyGridData(companyFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_COMPANY_GRID_DATA,
      companyFilter.orderBy,
      companyFilter.isAscending ? "true" : "false",
      companyFilter.accountTypeId?.toString() || "",
      companyFilter.companyType?.toString() || "",
      companyFilter.search,
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= companyFilter.currentPage
          ? companyFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, companyFilter])

  const companies = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > companyFilter.currentPage

  return { companies, hasMore, isLoading, isError }
}
