import {
  AgreementDetailsAmendValueResponse,
  ChangesOnAgreementRequest,
} from "components/pages/pricemanagement/approvals/approvalAmend/approvalAmend.model"

import EbateAxios from "queries/ebateAxios"

const auditAgreementUrl = "/api/AuditAgreement"

export const getChangesOnAgreement = async (
  data: ChangesOnAgreementRequest
) => {
  const url = `${auditAgreementUrl}/GetChangesOnAgreement`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    ChangesOnAgreementRequest,
    AgreementDetailsAmendValueResponse[]
  >(url, data)
}
export const getPendingChangesOnAgreement = async (agreementId: number) => {
  const url = `${auditAgreementUrl}/GetPendingChangesOnAgreement/${agreementId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<AgreementDetailsAmendValueResponse[]>(url)
}
