import {
  ebateQueryKeys,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getEmptyProduct,
  getProductGridData,
  getProductWithDynamicFields,
} from "queries"
import {
  ProductGridRequest,
  ProductGridData,
} from "components/pages/maintanance/product/productList/product.models"
import { useEffect } from "react"

export const useProductEmpty = () => {
  const fetch = async () => {
    try {
      const response = await getEmptyProduct()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const emptyCustomer = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PRODUCT_EMPTY],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return emptyCustomer
}

export const useProductWithExtensionField = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getProductWithDynamicFields(id.toString())
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const product = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PRODUCT_WITH_DYNAMIC_FIELDS, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return product
}

export const useProductGridData = (productFilter: ProductGridRequest) => {
  const getUniqueValues = (data): ProductGridData[] => {
    const flattened = data.pages.flatMap((page) => page.productGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as ProductGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getProductGridData(productFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PRODUCT_GRID_DATA,
      productFilter.orderBy,
      productFilter.isAscending ? "true" : "false",
      productFilter.search,
      productFilter.statusId?.toString() || "",
      productFilter.uOM?.toString() || "",
      productFilter.filter || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= productFilter.currentPage
          ? productFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, productFilter])

  const products = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > productFilter.currentPage

  return { products, hasMore, isLoading, isError }
}
