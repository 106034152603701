import { Action } from "components/hooks"

export const ADD_FILTER = "ADD_FILTER"
export const RESET_FILTER = "RESET_FILTER"

const useReducerFilter = () => {
  const filterReducer = <T extends object>(state: T, action: Action) => {
    switch (action.type) {
      case ADD_FILTER: {
        const { filter, value, description, tooltipDescription, id } =
          action.data
        const tempFilterValues = {
          ...state,
          [filter]: {
            ...state[filter],
            value,
            description,
            tooltipDescription,
            id,
          },
        }
        return tempFilterValues
      }
      case RESET_FILTER: {
        return {
          ...action.data,
        }
      }

      default:
        return state
    }
  }
  const resetFilter = <T extends object>(
    value: T,
    dispatch: React.Dispatch<Action>
  ) => {
    dispatch({
      type: RESET_FILTER,
      data: {
        ...value,
      },
    })
  }

  const onInputChange = (
    filter: string,
    value: any,
    description: string,
    tooltipDescription: string,
    id: number,
    dispatch: React.Dispatch<Action>
  ) => {
    dispatch({
      type: ADD_FILTER,
      data: {
        filter,
        value,
        description,
        tooltipDescription,
        id,
      },
    })
  }

  return {
    filterReducer,
    onInputChange,
    resetFilter,
  }
}

export default useReducerFilter
