import React from "react"
import StyledTooltip from "./styled/StyledTooltip"

const Tooltip = (props) => {
  const { position, target, content, className } = props

  return (
    <StyledTooltip
      position={position}
      target={target}
      content={content}
      className={className}
    />
  )
}

export default Tooltip
