import React, { useEffect, useRef, useState } from "react"
import { StyledFade } from "./styled"

interface Props {
  collapse?: boolean | null
  hover?: boolean | null
  maxHeight?: number | null
  children?: any
}
const Fade: React.FunctionComponent<Props> = ({ children, ...props }) => {
  const [maxHeight, setMaxHeight] = useState(0)
  const ref = useRef<any>(null)

  useEffect(() => {
    if (props.maxHeight !== null && props.maxHeight !== undefined) {
      setMaxHeight(props.maxHeight)
    } else {
      setMaxHeight(ref?.current.scrollHeight)
    }
  }, [ref?.current?.scrollHeight, props.maxHeight])

  return (
    <StyledFade
      ref={ref}
      heightCalc={maxHeight}
      collapse={props.collapse}
      hover={props.hover}
    >
      {children}
    </StyledFade>
  )
}
export default Fade
