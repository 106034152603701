import { ValidationResultModel } from "components/models"
import { DocumentTagEditRequest } from "components/pages/maintanance/documentTag/documentTagForm/documentTagForm.models"
import {
  DocumentTagRequest,
  TagGridResponse,
} from "components/pages/maintanance/documentTag/documentTagList/documentTagList.models"
import EbateAxios from "queries/ebateAxios"

const documentTagUrl = "/api/DocumentTag"

export const getTagGridData = async (
  data: DocumentTagRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${documentTagUrl}/GetTagGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<DocumentTagRequest, TagGridResponse>(url, data, abortSignal)
}

export const addDocumentTag = async (text: string) => {
  const url = `${documentTagUrl}/Add`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, ValidationResultModel>(url, text)
}

export const updateDocumentTag = async (data: DocumentTagEditRequest) => {
  const url = `${documentTagUrl}/Update`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<DocumentTagEditRequest, ValidationResultModel>(url, data)
}

export const deleteDocumentTag = async (id: number) => {
  const url = `${documentTagUrl}/Delete/${id}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}
