import { ebateQueryKeys, useEbateQuery } from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getAllExchangeObject } from "../exchangeFormat.query"

export const useExchangeObject = () => {
  const fetch = async () => {
    const response = await getAllExchangeObject()

    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const exchangeObjects = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_EXCHANGE_OBJECTS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return exchangeObjects
}
