import React from "react"
import { StyledLoadingComponent } from "./styled/styledLoadingComponent"

interface Props {
  height: string
}
export const LoadingComponent: React.FC<Props> = ({ height }) => (
  <StyledLoadingComponent height={height}>
    <div className="spinner">
      <span className="loader" />
    </div>
  </StyledLoadingComponent>
)
