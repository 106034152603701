import { useAuthentication } from "components/hooks"
import React, { createContext } from "react"

export const AuthenticationContext = createContext()

const AuthenticationProvider = ({ children }) => {
  const {
    storeAuthToken,
    getToken,
    isAuthorized,
    signOut,
    secondsLeft,
    setSecondsLeft,
    getTokenValue,
    getPermission,
  } = useAuthentication()

  return (
    <AuthenticationContext.Provider
      value={{
        storeAuthToken,
        getToken,
        isAuthorized,
        signOut,
        secondsLeft,
        setSecondsLeft,
        getTokenValue,
        getPermission,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
