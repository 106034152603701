import {
  ProductGridDataResponse,
  ProductGridRequest,
} from "components/pages/maintanance/product/productList/product.models"
import {
  ProductFormRequest,
  ProductModelApi,
} from "components/pages/maintanance/product/productForm/productForm.models"
import EbateAxios from "queries/ebateAxios"
import { ValidationResultModel } from "components/models"

const productUrl = "/api/Product"

export const getProductGridData = async (
  data: ProductGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${productUrl}/GetProductGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProductGridRequest, ProductGridDataResponse>(
    url,
    data,
    abortSignal
  )
}

export const getEmptyProduct = async () => {
  const url = `${productUrl}/GetEmptyProduct`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ProductModelApi>(url)
}
export const addProduct = async (data: ProductFormRequest) => {
  const url = `${productUrl}/AddProduct`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProductFormRequest, ValidationResultModel>(url, data)
}

export const getProductWithDynamicFields = async (id: string) => {
  const url = `${productUrl}/GetProductWithDynamicFields/${id}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ProductModelApi>(url)
}

export const updateProduct = async (data: ProductFormRequest) => {
  const url = `${productUrl}/UpdateProduct`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProductFormRequest, ValidationResultModel>(url, data)
}
