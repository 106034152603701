import styled from "styled-components"

const StyledErrorMessage = styled.div`
  color: var(--error-color);
  background-color: var(--error-background);
  border-color: var(--error-boder-color);
  padding: 0.5rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin: 1rem 0;

  li:not(:first-child) {
    padding-top: var(--spacing-half);
  }
`

export default StyledErrorMessage
