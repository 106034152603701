import styled from "styled-components"

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) =>
    margin || "0 var(--spacing-x2) var(--spacing-x2) 0"};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
`

export default StyledIconContainer
