import { useContext, useEffect, useState } from "react"
import { BEConfigurationContext } from "stores"
import { numberFormatter } from "components/helpers"

const useCurrencyFormatter = (
  data: number,
  optionalResultWhenDataIs0?: string
) => {
  const [numberWithCurrency, setNumberWithCurrency] = useState<string>("")

  const { tenantConfiguration } = useContext(BEConfigurationContext)

  const format = async () => {
    const { defaultCurrency } = tenantConfiguration

    if (defaultCurrency) {
      let result
      if (defaultCurrency.symbolPlacementDescription === "After") {
        result =
          numberFormatter.numberFormatter.format(data) + defaultCurrency.symbol
      } else if (data < 0) {
        result = `-${
          defaultCurrency.symbol
        }${numberFormatter.numberFormatter.format(Math.abs(Number(data)))}`
      } else {
        result =
          defaultCurrency.symbol + numberFormatter.numberFormatter.format(data)
      }
      setNumberWithCurrency(result)
    } else {
      setNumberWithCurrency(
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
          minimumFractionDigits: 2,
        }).format(data)
      )
    }
  }

  useEffect(() => {
    format()
  }, [data, optionalResultWhenDataIs0])

  return data === 0 && optionalResultWhenDataIs0 !== undefined
    ? optionalResultWhenDataIs0
    : numberWithCurrency
}

export default useCurrencyFormatter
