import styled from "styled-components"

export const StyledMenuCard = styled.div`
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: var(--shadow-sm);
  position: absolute;
  right: 0;
  margin-top: var(--spacing-half);
  z-index: 200;

  .menu-option {
    display: flex;
    direction: row;
    align-items: "flex-end";
    cursor: pointer;
    padding: var(--spacing-base);
    font-size: var(--font-size-md);
    color: var(--color-ebateSecondaryButton);
  }
  .icon-button {
    padding: 0 var(--spacing-half);
  }
  .text-link {
    margin-left: var(--spacing-half);
  }
  .menu-option:hover {
    background-color: var(--color-ebateSecondaryButtonHover);
    font-family: var(--font-bold);
  }
`
