import React, { useContext, useState, useEffect, Suspense } from "react"
import { RouteManager, RouteApp } from "components/library"
import {
  AuthenticationContext,
  AzureProviderContext,
  BEConfigurationProvider,
} from "stores"
import { LogoutUtils } from "components/helpers"
import { DefaultRoutes } from "routes"
import Axios from "axios"

import { refreshToken, signOutApi } from "queries"
import { LoadingSpinner } from "./components/library/loading"

const EBApp = () => {
  const { isAuthorized, storeAuthToken, signOut, getPermission } = useContext(
    AuthenticationContext
  )
  const [refreshed, setRefreshed] = useState(false)
  const azureContext = useContext(AzureProviderContext)

  const doRefresh = async (abortSignal) => {
    const response = await refreshToken(abortSignal)

    if (response && response.status === 200) {
      if (response.data.valid) {
        storeAuthToken(response.data.data.idToken, response.data.data.expiresIn)
      } else {
        if (azureContext && azureContext.authProvider)
          azureContext.authProvider.logout()
        signOut()
      }
    }
  }

  Axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401) {
        await signOutApi()
        if (azureContext && azureContext.authProvider)
          azureContext.authProvider.logout()
        signOut()
      }
      return error.response
    }
  )

  useEffect(() => {
    const abortController = new AbortController()
    let mounted = true
    const trySilentRefresh = () => {
      if (mounted) {
        storeAuthToken({})
        return doRefresh(abortController.signal)
      }
      return new Promise()
    }

    trySilentRefresh().then(() => {
      if (mounted) setRefreshed(true)
    })

    return () => {
      mounted = false
      storeAuthToken({})
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    const logoutUtil = new LogoutUtils({ onLogout: signOut })

    return () => logoutUtil && logoutUtil.cleanUp()
  }, [signOut])

  if (isAuthorized) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <BEConfigurationProvider>
          <RouteApp getPermission={getPermission} />
        </BEConfigurationProvider>
      </Suspense>
    )
  }

  if (!refreshed) {
    return <LoadingSpinner />
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <RouteManager views={DefaultRoutes} />
    </Suspense>
  )
}

export default EBApp
