import styled from "styled-components"

const TableItem = styled.div`
  width: ${({ selectable }) => (selectable ? null : "100%")};
  background-color: var(--color-background);
  color: var(--color-text);
  border-bottom: 1px solid var(--color-lineColor);
  padding-bottom: var(--spacing-x2);
  padding-top: ${({ selectable }) =>
    selectable ? "12px" : "var(--spacing-x2)"};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || "flex-start"};
  min-width: ${({ selectable }) => (selectable ? "100px" : null)};
  .icon-item {
    width: 100%;
    display: flex;
    justify-content: ${({ justify }) => justify || "flex-start"};
  }
  padding-left: ${({ nested, column, selectable }) => {
    if (selectable && nested && column === 0) return `6px`
    if (selectable && column === 0) return `var(--spacing-x4)`
    if (nested && column === 0) return `0`
    return `var(--spacing-x2)`
  }};
  padding-right: ${({ nested, column, selectable }) => {
    if (selectable && column === 0) return `var(--spacing-x4)`
    if (nested && column === 0) return `var(--spacing-x4)`
    return `var(--spacing-x2)`
  }};
`

export default TableItem
