/* 
  Prevent outputting a value until the debounce delay is over
  Useful for preventing events from occuring en-masse
  e.g: Prevent a resize listener from firing until the user stops
  resizing the screen

  Set the 'immediate' argument to true to override the debounce
*/
const debounce = (func, wait, immediate) => {
  let timeout
  // eslint-disable-next-line func-names
  return function (...args) {
    const context = this
    // eslint-disable-next-line func-names
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default debounce
