import EbateAxios from "queries/ebateAxios"
import {
  AgreementDetailsApi,
  RequestAgreementRequest,
  UpdateAgreementDetailsRequest,
  ResetAgreementRequest,
} from "components/pages/pricemanagement/agreements/agreementDetails/agreementDetails.models"
import {
  DocumentTabGridResponse,
  DocumentTabGridFilterRequest,
} from "components/library/documentTab/documentTab.models"
import {
  TradeAgreementsTabGridFilterRequest,
  TradeAgreementsTabResponse,
} from "components/library/tradeAgreementsTab/tradeAgreementsTab.models"
import { AgreementCopyRebateGridItemResponse } from "components/pages/pricemanagement/rebates/rebateCopy/rebateCopy.models"
import {
  GenericApiResponseModel,
  ValidationResultModel,
} from "components/models"
import {
  AgreementGridDetailResponse,
  AgreementGridRequest,
  AgreementGridResponse,
} from "components/pages/pricemanagement/agreements/agreementList/agreements.model"
import { AgreementRequestAdd } from "components/pages/pricemanagement/agreements/agreementDetails/agreementAddModels"
import {
  SendEmailRequestModel,
  TradeAgreementCustomFileRequest,
  TradeAgreementGenerateRequestModel,
} from "components/library/tooltips/tradeAgreementForm.model"

const agreementDataUrl = "/api/Agreement"

export const getGlobalAgreement = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AgreementDetailsApi>(
    `${agreementDataUrl}/GetGlobalAgreement/${id}`
  )
}

export const getGlobalAgreementFileGridData = async (
  data: DocumentTabGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${agreementDataUrl}/GetGlobalAgreementFileGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<DocumentTabGridFilterRequest, DocumentTabGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const getAgreementFileGridData = async (
  data: DocumentTabGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${agreementDataUrl}/GetAgreementFileGridData`
  return AxiosPost<DocumentTabGridFilterRequest, DocumentTabGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const getGlobalTradeAgreementFileGridData = async (
  data: TradeAgreementsTabGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${agreementDataUrl}/GetGlobalTradeAgreementFileGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    TradeAgreementsTabGridFilterRequest,
    TradeAgreementsTabResponse
  >(url, data, abortSignal)
}

export const getAgreementsForCopyRebateByPackage = async (
  packageId: number
) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AgreementCopyRebateGridItemResponse[]>(
    `${agreementDataUrl}/GetAgreementsForCopyRebateByPackage/${packageId}`
  )
}

export const requestAgreementRequest = async (
  data: RequestAgreementRequest
) => {
  const url = `${agreementDataUrl}/RequestAgreementRequest`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RequestAgreementRequest, GenericApiResponseModel<any>>(
    url,
    data
  )
}

export const getAgreementGridDetailData = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AgreementGridDetailResponse>(
    `${agreementDataUrl}/GetAgreementGridDetailData/${id}`
  )
}

export const getGlobalAgreementGridDetailData = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AgreementGridDetailResponse>(
    `${agreementDataUrl}/GetGlobalAgreementGridDetailData/${id}`
  )
}

export const getAgreementGridData = async (
  data: AgreementGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${agreementDataUrl}/GetAgreementGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AgreementGridRequest, AgreementGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const getAgreementDetails = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AgreementDetailsApi>(
    `${agreementDataUrl}/GetAgreementDetails/${id}`
  )
}

export const getTradeAgreementFileGridData = async (
  data: TradeAgreementsTabGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${agreementDataUrl}/GetTradeAgreementFileGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    TradeAgreementsTabGridFilterRequest,
    TradeAgreementsTabResponse
  >(url, data, abortSignal)
}

export const deleteAgreement = async (id: number) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(
    `${agreementDataUrl}/DeleteAgreement/${id}`,
    {}
  )
}

export const updateAgreement = async (data: UpdateAgreementDetailsRequest) => {
  const url = `${agreementDataUrl}/UpdateAgreement`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<UpdateAgreementDetailsRequest, ValidationResultModel>(
    url,
    data
  )
}

export const addAgreement = async (
  data: AgreementRequestAdd,
  checkWarning: boolean
) => {
  const url = `${agreementDataUrl}/AddAgreement/${checkWarning}`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AgreementRequestAdd, ValidationResultModel>(url, data)
}

export const addCustomTradeAgreementFile = async (
  data: TradeAgreementCustomFileRequest
) => {
  const url = `${agreementDataUrl}/AddCustomTradeAgreementFile`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<TradeAgreementCustomFileRequest, ValidationResultModel>(
    url,
    data
  )
}

export const generateNewAgreementFile = async (
  data: TradeAgreementGenerateRequestModel
) => {
  const url = `${agreementDataUrl}/GenerateNewAgreementFile`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    TradeAgreementGenerateRequestModel,
    GenericApiResponseModel<any>
  >(url, data)
}

export const sendFileToMailAddress = async (data: SendEmailRequestModel) => {
  const url = `${agreementDataUrl}/SendFileToMailAddress`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<SendEmailRequestModel, ValidationResultModel>(url, data)
}
export const resetRequest = async (data: ResetAgreementRequest) => {
  const url = `${agreementDataUrl}/ResetRequest`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<ResetAgreementRequest, ValidationResultModel>(url, data)
}
