import { ebateQueryKeys, getTenantConfiguration, useEbateQuery } from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useTenantConfiguration = () => {
  const fetch = async () => {
    try {
      const response = await getTenantConfiguration()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const tenantConfiguration = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_TENANT_CONFIGURATION],
    fetch,
    {
      cacheTime: CacheTime.infinity,
      staleTime: StaleTime.long,
    }
  )

  return tenantConfiguration
}
