import { ValidationResultModel } from "components/models"
import {
  CompanyFormRequest,
  CompanyModelApi,
} from "components/pages/maintanance/company/companyForm/companyForm.models"
import {
  CompanyGridData,
  CompanyGridRequest,
} from "components/pages/maintanance/company/companyList/company.models"
import { CompanyResponse } from "components/pages/maintanance/tradingGroup/linkedAccountForm/linkedAccountForm.models"
import EbateAxios from "queries/ebateAxios"

const companyUrl = "/api/Company"

export const getCompaniesNotTrading = async () => {
  const url = `${companyUrl}/getCompaniesNotTrading`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CompanyResponse[]>(url)
}

export const getCompanyGridData = async (
  data: CompanyGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${companyUrl}/GetCompanyGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CompanyGridRequest, CompanyGridData>(url, data, abortSignal)
}

export const getEmptyCustomer = async () => {
  const url = `${companyUrl}/GetEmptyCustomer`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CompanyModelApi>(url)
}

export const addCompany = async (data: CompanyFormRequest) => {
  const url = `${companyUrl}/AddCompany`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CompanyFormRequest, ValidationResultModel>(url, data)
}

export const getCompanyWithDynamicFields = async (id: number) => {
  const url = `${companyUrl}/GetCompanyWithDynamicFields/${id}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CompanyModelApi>(url)
}

export const updateCompany = async (data: CompanyFormRequest) => {
  const url = `${companyUrl}/UpdateCompany`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<CompanyFormRequest, ValidationResultModel>(url, data)
}
