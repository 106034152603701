import { OptionModel } from "components/helpers"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getAgreementsByCalculationType } from "queries"
import { AgreementsByCalcTypeRequest } from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"

export const useGetAgreementsByCalculationType = (
  calculationTypeId: number,
  query: string
) => {
  const fetchAgreementsByCalculationType = async (): Promise<OptionModel[]> => {
    const request: AgreementsByCalcTypeRequest = {
      calculationTypeId,
      filter: query,
    }
    const response = await getAgreementsByCalculationType(request)
    if (response?.status === 200) {
      return response.data?.map((d) => ({
        value: d.id,
        title: `${d.id} - ${d.name}`,
      }))
    }
    return []
  }
  const agreementsByCalculationType = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_AGREEMENTS_BY_CALCULATION_TYPE,
      calculationTypeId.toString(),
      query,
    ],
    fetchAgreementsByCalculationType,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return { agreementsByCalculationType }
}
