import styled from "styled-components"

const StyledModalYesNoContent = styled.div`
  min-width: 420px;
  width: 100%;
  .footer {
    border-top: solid 1px var(--color-lineColor);
    padding-top: 15px;
    text-align: right;
  }
`

export default StyledModalYesNoContent
