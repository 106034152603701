import styled from "styled-components"

const StyledLogo = styled.img`
  height: 100%;
  padding-top: var(--spacing-base);
  padding-bottom: var(--spacing-base);
  filter: ${({ dark }) => (dark ? "saturate(1.5)" : "none")};
  transition: all 0.5s;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1);
  }
`

export default StyledLogo
