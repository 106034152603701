import { AxiosResponse } from "axios"
import {
  UploadFileToSFTPRequest,
  ValidationResultModel,
} from "components/models"
import EbateAxios from "queries/ebateAxios"

const importDataUrl = "/api/ImportData"

export const getImportFileTemplate = async (
  exchangeObjectId: number,
  exchangeMappingId: number
) => {
  const url = `${importDataUrl}/GetImportFileTemplate/${exchangeObjectId}/${exchangeMappingId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<Blob>(url)
}

export const getSeasonalityImportFileTemplate = async () => {
  const url = `${importDataUrl}/GetSeasonalityImportFileTemplate`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<Blob>(url)
}

export const uploadFileToSFTP = async (
  request: UploadFileToSFTPRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${importDataUrl}/uploadFileToSFTP`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<UploadFileToSFTPRequest, ValidationResultModel>(url, request)
}
