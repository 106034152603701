import React, { useState, useEffect } from "react"
import { urlUtils } from "components/helpers"
import { Route, useNavigate, Routes } from "react-router-dom"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security, LoginCallback } from "@okta/okta-react"
import { OktaProvider, AzureProvider } from "stores"
import { AzureAD, AuthenticationState } from "react-aad-msal"
import { ssoInfo } from "queries"
import { LoadingSpinner } from "components/library/loading"
import { useAzureAuth } from "./authProvider"

const SSOADInterceptor = ({ children }) => {
  const [adProvider, setADProvider] = useState()
  const [oktaAuth, setOktaAuth] = useState()

  const { setConfiguration, authProvider } = useAzureAuth()

  const navigate = useNavigate()

  const restoreOriginalUri = React.useCallback((_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin))
  }, [])

  const triggerLogin = async () => {
    if (oktaAuth) await oktaAuth.signInWithRedirect()
  }
  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      await triggerLogin()
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    const getSSOProviders = async () => {
      const tenant = urlUtils.extractTenantName(window.location.hostname)

      let provider = process.env[`REACT_APP_${tenant}_ADProvider`] ?? "Ebate"

      if (provider === "Ebate") {
        const response = await ssoInfo(abortController.signal)
        if (response.status === 200) {
          if (response.data.instance) {
            provider = "Azure"

            setConfiguration({
              auth: {
                authority: `${response.data.instance}${response.data.tenantId}`,
                clientId: response.data.clientId,
                redirectUri:
                  window.location.origin + response.data.callbackPath,
                tenantId: response.data.tenantId,
              },
              cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
              },
            })
          }
        }
      }

      setADProvider(provider)

      if (provider === "Okta") {
        const auth = new OktaAuth({
          clientId: process.env[`REACT_APP_${tenant}_CLIENT_ID`],
          issuer: process.env[`REACT_APP_${tenant}_ISSUER`],
          redirectUri: `${window.location.origin}/login/callback`,
          pkce: true,
          onAuthRequired: customAuthHandler,
        })

        setOktaAuth(auth)
      }
      return () => {
        if (provider === "Okta") {
          oktaAuth.options.restoreOriginalUri = undefined
        }
        abortController.abort()
      }
    }
    getSSOProviders()
  }, [])

  switch (adProvider) {
    case "Okta":
      return (
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <Routes>
            <Route path="/login/callback" element={<LoginCallback />} />
          </Routes>
          <OktaProvider>{children}</OktaProvider>
        </Security>
      )
    case "Azure":
      return (
        <>
          {authProvider ? (
            <AzureAD provider={authProvider} forceLogin>
              {({ authenticationState, accountInfo }) => {
                if (authenticationState === AuthenticationState.Authenticated) {
                  return (
                    <AzureProvider
                      authProvider={authProvider}
                      token={accountInfo.jwtIdToken}
                    >
                      {children}
                    </AzureProvider>
                  )
                }
                return <></>
              }}
            </AzureAD>
          ) : (
            <></>
          )}
        </>
      )

    case "Ebate":
      return <>{children}</>
    default:
      return (
        <>
          <LoadingSpinner />
        </>
      )
  }
}

export default SSOADInterceptor
