import React, { useState } from "react"
import { CheckBox, Table } from "components/library"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import useCurrencyFormatter from "components/helpers/currencyFormatter"
import { TableItem, TableRow } from "./styled"
import { FlexContainer } from "../containers"

function TableColumnFormat(format, value) {
  switch (format) {
    case "currency":
      return useCurrencyFormatter(value)
    default:
      return value
  }
}

const RowItem = ({
  row,
  rowParent,
  head,
  nested,
  selectable,
  currentRow,
  setSelection,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const checkBox = (nested, row, setSelection) => {
    if (nested)
      return (
        <CheckBox
          checked={row.selected}
          onChange={() => {
            setSelection(
              {
                id: rowParent.id,
              },
              !row.selected,
              row.id
            )
          }}
        />
      )
    return (
      <CheckBox
        checked={row.selected}
        indeterminate={
          row.details !== undefined &&
          row.details.filter((key) => key.selected).length <
            row.details.length &&
          row.details.filter((key) => key.selected).length > 0
            ? "indeterminate"
            : undefined
        }
        onChange={() => {
          setSelection(
            {
              id: row.id,
            },
            !row.selected
          )
        }}
      />
    )
  }

  const generateExpandIcons = (row, currentColumn, html) => {
    if (
      currentColumn === 0 &&
      row.details !== undefined &&
      row.details.length > 1 &&
      !isExpanded
    )
      return (
        <FlexContainer>
          <FaChevronRight onClick={() => setIsExpanded(!isExpanded)} />
          <div className="icon-item">{html}</div>
        </FlexContainer>
      )
    if (
      currentColumn === 0 &&
      row.details !== undefined &&
      row.details.length > 1 &&
      isExpanded
    )
      return (
        <FlexContainer className="row-icon">
          <FaChevronDown onClick={() => setIsExpanded(!isExpanded)} />
          <div className="icon-item">{html}</div>
        </FlexContainer>
      )
    return <>{html}</>
  }

  return (
    <>
      <TableRow
        key={row.id + (nested ? row.parentId : null)}
        nested={row.details !== undefined || nested}
      >
        {selectable && (
          <TableItem
            className={
              nested
                ? `nestedRow`
                : `row ${
                    row.details !== undefined && row.details.length > 1
                      ? null
                      : `checkbox`
                  }`
            }
            nested={row.details !== undefined || nested}
            selectable={selectable}
            justify
            column={0}
          >
            {generateExpandIcons(row, 0, checkBox(nested, row, setSelection))}
          </TableItem>
        )}
        {Object.keys(head)
          .filter((key) => key !== "details")
          .map(
            (key, currentColumn) =>
              head[key] &&
              !head[key].hidden && (
                <TableItem
                  className={nested ? "nestedRow" : "row"}
                  column={selectable ? currentColumn + 1 : currentColumn}
                  row={currentRow}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${row.id}-${Object.keys(head)[currentColumn]}`}
                  justify={head[key].justify}
                  nested={nested || row.details !== undefined}
                >
                  {selectable ? (
                    <div className="icon-item">
                      {TableColumnFormat(head[key].format, row[key])}
                    </div>
                  ) : (
                    generateExpandIcons(
                      row,
                      currentColumn,
                      TableColumnFormat(head[key].format, row[key])
                    )
                  )}
                </TableItem>
              )
          )}
      </TableRow>
      {isExpanded && row.details !== undefined && (
        <TableRow>
          <Table
            striped
            paginated={10}
            data={{ head: head.details, rows: row.details }}
            nested
            selectable={selectable}
            rowParent={row}
            setSelection={setSelection}
          />
        </TableRow>
      )}
    </>
  )
}

export default RowItem
