const locale = localStorage.getItem("i18nextLng") ?? "en-GB"

function isNullOrWhitespace(input): boolean {
  return !input || !input.trim()
}

const plainHtmlText = (input: string): string =>
  input.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, "")

const numberToString = (input: number): string =>
  input.toLocaleString(locale, { minimumFractionDigits: 2 })

export default { isNullOrWhitespace, plainHtmlText, numberToString }
