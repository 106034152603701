import React, { useContext } from "react"
import {
  Grid,
  SecondaryButton,
  FlexContainer,
  PrimaryButton,
} from "components/library"
import { useTranslation } from "react-i18next"
import { ModalContext } from "stores"
import { LoadingSpinner } from "components/library/loading"
import { StyledModalYesNoContent } from "./styled"

interface Props {
  message: string
  onConfirm?: () => void
  className?: string
  hideConfirm?: boolean
  isLoading?: boolean
}
const ModalYesNoContent: React.FC<Props> = ({
  message,
  onConfirm,
  className,
  hideConfirm = false,
  isLoading = false,
}) => {
  const { handleModal } = useContext(ModalContext)
  const { t } = useTranslation()

  const multilineText = (text: string) =>
    text.split("<br/>").map((t, index) => <p key={index}>{t}</p>)

  return (
    <StyledModalYesNoContent className={className}>
      {isLoading && <LoadingSpinner />}
      <Grid>
        <div data-testid="message" coordinates={[1, 1, 12, 11]}>
          {multilineText(message)}
        </div>
        <div coordinates={[1, 12, 12, 1]}>
          <FlexContainer className="footer" justify="flex-end">
            {!hideConfirm && (
              <SecondaryButton
                onClick={() => handleModal()}
                text={hideConfirm ? t("close") : t("popup-cancel")}
                fontSize="var(--font-size-sm)"
                width="100px"
                id="popup-cancel"
              />
            )}
            <PrimaryButton
              text={t("confirm")}
              id="confirm"
              onClick={() => {
                if (onConfirm) onConfirm()
              }}
              width="100px"
            />
          </FlexContainer>
        </div>
      </Grid>
    </StyledModalYesNoContent>
  )
}

export default ModalYesNoContent
