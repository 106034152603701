const borderRadius = {
  sm: "5px",
  md: "10px",
  lg: "20px",
  circle: "50%",
  base: "0px",
  x2: "0px",
  x3: "0px",
  half: "0px",
  quarter: "0px",
}

export default borderRadius
