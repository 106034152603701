import { RolesUserVm } from "components/pages/maintanance/workflow/workflowLevel/workflowLevelForm/WorkflowLevelForm.model"
import {
  ebateQueryKeys,
  useEbateQuery,
  getNormalRoles,
  getRolesGridData,
  getRoleClaims,
  getRoleDetails,
  getRolesAndUsers,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useRoles = (isPortalRole: boolean) => {
  const fetch = async () => {
    try {
      const response = await getNormalRoles(isPortalRole)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const roles = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLES, isPortalRole.toString()],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return roles
}

export const useRolesGridData = (isPortalRole: boolean) => {
  const fetch = async () => {
    try {
      const response = await getRolesGridData(isPortalRole)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const roles = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLES_GRID_DATA, isPortalRole.toString()],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return roles
}

export const useRoleClaims = (isPortalRole: boolean) => {
  const fetch = async () => {
    try {
      const response = await getRoleClaims(isPortalRole)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const roleClaims = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLE_CLAIMS, isPortalRole.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return roleClaims
}

export const useRoleById = (id: string) => {
  const fetch = async () => {
    try {
      const response = await getRoleDetails(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const role = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLE_BY_ID, id],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return role
}

export const useRolesAndUsers = (isPortal: boolean) => {
  const onCompleted = (data): RolesUserVm[] => {
    const dataMapped = data.map((val) => {
      if (val.userIdGUID) {
        return {
          value: val.userIdGUID,
          title: val.description,
          isUser: true,
        }
      }
      return {
        value: val.roleIdGUID,
        title: val.description,
        isUser: false,
      }
    })
    return dataMapped
  }
  const fetch = async () => {
    try {
      const response = await getRolesAndUsers(isPortal)
      if (response?.status === 200) {
        return onCompleted(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const role = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLES_AND_USERS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return role
}
