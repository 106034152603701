import styled from "styled-components"
import { DataTable } from "primereact/datatable"
import { device } from "theme/helpers"

const StyledCardGrid = styled(DataTable)`
  .p-sortable-column-icon {
    color: var(--color-white) !important;
    margin-left: 0.5rem;
  }

  th {
    background-color: var(--color-ebatePrimary) !important;
    color: var(--color-white) !important;
    padding: var(--spacing-x2) !important;
  }

  td {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-sm);
    font-family: var(--font-primary);
    padding: var(--spacing-base) !important;
  }

  .p-paginator-bottom {
    padding: var(--spacing-half) !important;
  }
  @media ${device.mobileS} {
    .p-paginator-page {
      font-size: var(--font-size-sm);
      min-width: 20px !important;
      height: 20px !important;
    }

    .p-paginator-next,
    .p-paginator-last,
    .p-paginator-first,
    .p-paginator-prev {
      min-width: 20px !important;
      height: 16px !important;
      margin: var(--spacing-base);
    }
  }
  @media ${device.tablet} {
    .p-paginator-page {
      font-size: var(--font-size-sm);
      min-width: 30px !important;
      height: 30px !important;
    }
    .p-paginator-next,
    .p-paginator-last,
    .p-paginator-first,
    .p-paginator-prev {
      min-width: 30px !important;
      height: 16px !important;
      margin: var(--spacing-x2);
    }
  }
  @media ${device.laptop} {
    .p-paginator-page {
      font-size: var(--font-size-sm);
      min-width: 20px !important;
      height: 20px !important;
    }
    .p-paginator-next,
    .p-paginator-last,
    .p-paginator-first,
    .p-paginator-prev {
      min-width: 20px !important;
      height: 16px !important;
      margin: var(--spacing-x2);
    }
  }
`

export default StyledCardGrid
