import { useEffect, useState } from "react"
import { getCriteriaCompanyTypes } from "queries"
import { dropdownUtils, OptionModel } from "components/helpers"

export const useCriteriaCompanyTypes = () => {
  const [criteriaCompanyTypes, setCriteriaCompanyTypes] = useState<
    OptionModel[]
  >([])
  const onCompletedCompanyTypes = (res) => {
    const companiesFromApi = dropdownUtils.mapToDropDownOptionFromApi(res)
    setCriteriaCompanyTypes([...companiesFromApi])
  }

  const getCompanyTypes = async () => {
    const response = await getCriteriaCompanyTypes()
    if (response?.status === 200) {
      onCompletedCompanyTypes(response.data)
    }
  }

  useEffect(() => {
    getCompanyTypes()
  }, [])

  return criteriaCompanyTypes
}
