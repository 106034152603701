import { createGlobalStyle } from "styled-components"

/* 
  -------------------------------------------------------------------
  Generate variables from a passed theme.
  -------------------------------------------------------------------
  Loops over every theme property and recursively heads down the tree
  to create a variable for each theme property, based on the key name
*/

const generateVariables = (property, name) => {
  // Get the keys of the current property
  const variables = Object.keys(property).map((key) => {
    // If the current key is an object, we know it contains more properties
    if (typeof property[key] === "object") {
      // Recurse using current key, add a dash to name if we're on a sub-property
      return generateVariables(property[key], `${name ? `${name}-` : ""}${key}`)
    }
    // otherwise it's a string, so must be a css property. Generate the variable
    return `--${name}-${key}: ${property[key]};`
  })
  // Return the variables as a minified string
  return variables.join("")
}

const GlobalStyle = createGlobalStyle`

  // ---------------------------------
  // GLOBAL THEME VARIABLES
  // ---------------------------------
  :root {
    // Global variables are generatede from the active theme
    ${({ theme }) => generateVariables(theme)}
  }

  // ---------------------------------
  // GLOBAL TRANSITIONS
  // ---------------------------------

  * {
    transition: background 0.5s, color 0.5s, box-shadow 0.5s, text-shadow 0.5s, padding 0.5s, margin 0.5s;
    box-sizing: border-box;
  }
  .page-enter, .page-appear {
    opacity: 0;
    transform: scale(1.1);
  }

  .page-enter-active, .page-appear-done, .page-enter-done {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  .page-exit {
    opacity: 1;
    transform: scale(1);
  }

  .page-exit-active, .page-exit-done {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }


  // ---------------------------------
  // GLOBAL STYLING
  // ---------------------------------

  body {
    background-color: var(--color-body);
    color: var(--color-text);
    font-family: var(--font-primary);
    font-size: var(--font-size-sm);
    line-height: var(--lineHeight-sm);
    margin: 0;

    // Clear the header
    #root {
      padding-top: 62px;
    }

    // ---------------------------------
    // HEADERS
    // ---------------------------------

    h1, h2, h3, h4, h5, h6 {
      font-family: var(--font-header);
    }
    h1 {
      font-size: var(--font-size-xl);
      line-height: var(--lineHeight-header);
      font-weight: bold;
    }
    h2 {
      font-size: var(--font-size-lg);
      line-height: var(--lineHeight-xl);
      font-weight: bold;
    }
    h3 {
      font-size: var(--font-size-md);
      line-height: var(--lineHeight-lg);
      font-weight: bold;
    }
    h4 {
      font-size: var(--font-size-sm);
      line-height: var(--lineHeight-md);
      font-weight: bold;
    }
    h5 {
      font-size: var(--font-size-sm);
      line-height: var(--lineHeight-sm);
    }
    h6 {
      font-size: var(--font-size-xs);
      line-height: var(--lineHeight-xs);
    }
    
    // ---------------------------------
    // INPUTS
    // ---------------------------------

    input, textarea { 
      background-color: var(--color-body);
      color: var(--color-text);
       border: 1px solid var(--color-ebatePrimary);
      border-radius: var(--border-radius-sm); 
      padding: var(--spacing-base);
      font-family: var(--font-primary);
      :active, :focus {
        outline: none;
        box-shadow: none;
      }
    }

    input[type=text],input[type=date] {
      border: 2px solid var(--color-ebateControlBackground);
    }

    // ---------------------------------
    // TEXT
    // ---------------------------------

    p, span, button {
      font-family: var(--font-primary);
    }
    p {
      margin: var(--spacing-base) 0;
    }
    code {
      font-family: var(--font-code);
      background-color: var(--color-text);
      color: var(--color-background);
      padding: 0 var(--spacing-half);
      margin: 0 var(--spacing-half);
      line-height: var(--lineHeight-md);
      display: inline-block;
    }
    a {
      text-decoration: none;
      cursor: pointer;
      color: var(--color-text);
    }
    hr {
      background-color: var(--color-text);
    }

    .input-icon-left {
      position: relative;
      display: flex;
    }

    // Override font sizes so graphs may determine their own axis
    .recharts-surface {
      font-size: unset;
    }

    // ---------------------------------
    // LISTS
    // ---------------------------------
    ul {
      list-style: disc;
    }
    ul, ol {
      padding-left: var(--spacing-x2);
      margin: var(--spacing-base) 0;
    }

    // ---------------------------------
    // LOADING SPINNER
    // ---------------------------------

    #loader {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: var(--color-background);
      z-index: var(--zindex-page);
    }
  }

  // ---------------------------------
  // LOADING SPINNER
  // ---------------------------------

svg {
  font-size: 30px;
  cursor: pointer;
  fill: var(--color-transparentButtonColor);
}

.headeritem {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-ebatePrimary);
  color: var(--color-white);
  padding: var(--spacing-base);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: ${({ index }) => `${index} / span 1`};
  overflow: auto;
  height: 50px;
}

.rowitem {
  width: 100%;
  background-color: var(--color-background);
  color: var(--color-text);
  border-bottom: 1px solid var(--color-lineColor);
  padding: var(--spacing-x2);
  height: 50px;
  display: flex;
  align-items: center;
}

.error-msg {
    color: var(--color-ebateError);
    font-size: var(--font-size-sm);
  }


  .p-fileupload-highlight {
    background-color: var(--color-ebateFileUploadHighlight);
  }

  .p-fileupload-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.38) !important;
  }

  // ---------------------------------
// SCROLL
// ---------------------------------

 *::-webkit-scrollbar {
    border-radius: 10px;
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
    border-radius: 10px;
  }
  *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-button:increment,
  *::-webkit-scrollbar-button {
    display: none;
  }

  .pendo-help {
    width: 3rem;
  }

.transition-hidden {
    opacity: 0;
    height: 0;
    transition: visibility 1s, opacity 1s linear ;
  }

  .transition-visible {
    opacity: 1;
    height: auto;
    transition: visibility 1s, opacity 1s linear ;
  }

  // INPUT NUMBER
  .p-inputnumber-buttons-stacked .p-inputnumber-input {
    width: 100%;
  }  
`

export default GlobalStyle
