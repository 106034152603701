import React from "react"
import { useForm } from "components/hooks"
import { InputText } from "components/library"
import { StyledFilterInput } from "./styled"

const FilterInput = ({ placeholder, caption, setFilter, full }) => {
  const formState = useForm({
    filter: "",
  })

  return (
    <StyledFilterInput full={full}>
      <div className="filterText">
        <div className="input-icon">
          <InputText
            transparent
            icon="pi pi-search"
            name="filter"
            value={formState.formValues.filter}
            placeholder={placeholder}
            onChange={(e) => {
              formState.update(e.target.name, e.target.value)
              setFilter(e.target.value)
            }}
          />
        </div>
      </div>
      <div className="caption">
        <span>{caption}</span>
      </div>
    </StyledFilterInput>
  )
}
export default FilterInput
