const checkPasswordStrength = (password: string) => {
  // Following are the conditions for validating password :
  // a) At least one digit ('0'-'9')
  // b) At least one lowercase ('a'-'z')
  // c) At least one uppercase ('A'-'Z')
  // d) At least one special character ('!','$','@','*',etc.)
  // e) At least 8 characters. - Length of password should be between range 8 to 14

  const reg = {
    digitsRegExp: /(?=.*?[0-9])/,
    lowercaseRegExp: /(?=.*?[a-z])/,
    uppercaseRegExp: /(?=.*?[A-Z])/,
    specialCharRegExp: /(?=.*?[#?!@$%^&*-])/,
    minLengthRegExp: /.{8,}/,
  }
  return (
    reg.digitsRegExp.test(password) &&
    reg.lowercaseRegExp.test(password) &&
    reg.uppercaseRegExp.test(password) &&
    reg.specialCharRegExp.test(password) &&
    reg.minLengthRegExp.test(password)
  )
}

export default { checkPasswordStrength }
