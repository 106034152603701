import EbateAxios from "queries/ebateAxios"
import { ValidationResultModel } from "components/models"
import { AxiosResponse } from "axios"
import {
  ConditionApiModel,
  MarketShareRequest,
  MinimumSpendRequest,
} from "components/pages/pricemanagement/rebates/rebateConditions/rebateConditionList/rebateConditionList.models"
import { OptionApi } from "components/helpers"
import { AddRebateConditionApiModel } from "components/pages/pricemanagement/rebates/rebateConditions/rebateConditionForm/rebateConditionForm.models"

const rebateConditionApiUrl = "/api/RebateCondition"

export const getGlobalAllRebateConditions = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<ConditionApiModel[]>(
    `${rebateConditionApiUrl}/GetGlobalAllRebateConditions/${id}`
  )
}

export const deleteRebateCondition = async (
  id?: number
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${rebateConditionApiUrl}/DeleteRebateCondition/${id}`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(url, undefined)
}

export const getAllRebateConditions = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<ConditionApiModel[]>(
    `${rebateConditionApiUrl}/GetAllRebateConditions/${id}`
  )
}

export const getRebateConditionTypes = async () => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<OptionApi[]>(
    `${rebateConditionApiUrl}/GetRebateConditionTypes`
  )
}

export const addRebateCondition = async (data: AddRebateConditionApiModel) => {
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AddRebateConditionApiModel, ValidationResultModel>(
    `${rebateConditionApiUrl}/AddRebateCondition`,
    data
  )
}

export const updatePurchaseAllFromRange = async (data) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(
    `${rebateConditionApiUrl}/UpdatePurchaseAllFromRange`,
    data
  )
}

export const updatePurchaseQTYFromRange = async (data) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(
    `${rebateConditionApiUrl}/UpdatePurchaseQTYFromRange`,
    data
  )
}

export const updateMarketShare = async (data: MarketShareRequest) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<MarketShareRequest, ValidationResultModel>(
    `${rebateConditionApiUrl}/UpdateMarketShare`,
    data
  )
}

export const updateMinimumSpend = async (data: MinimumSpendRequest) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<MinimumSpendRequest, ValidationResultModel>(
    `${rebateConditionApiUrl}/UpdateMinimumSpend`,
    data
  )
}
