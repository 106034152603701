import { useConfiguration } from "components/hooks"
import { TenantSpecOptions } from "models"
import { TenantConfiguration } from "models/beconfiguration/beconfiguration.models"
import React, { createContext } from "react"

interface BEConfigurationModel {
  configuration: TenantSpecOptions
  configLoaded: boolean
  is10DecimalsActive: boolean
  tenantConfiguration: TenantConfiguration
}
export const BEConfigurationContext = createContext<BEConfigurationModel>(
  {} as BEConfigurationModel
)

const BEConfigurationProvider = ({ children }) => {
  const [configuration, configLoaded, is10DecimalsActive, tenantConfiguration] =
    useConfiguration()

  if (configLoaded !== true) return null

  return (
    <BEConfigurationContext.Provider
      value={{
        configuration,
        configLoaded,
        is10DecimalsActive,
        tenantConfiguration,
      }}
    >
      {children}
    </BEConfigurationContext.Provider>
  )
}

export default BEConfigurationProvider
