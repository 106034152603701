import { AdditionalInfoLevels } from "components/library/additionalInfo/additionalInfo.model"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getAdditionalInfo,
  getGlobalAdditionalInfo,
} from "../additionalInfo.query"

export const useAdditionalInfo = (
  id: number,
  type: AdditionalInfoLevels,
  isGlobal?: boolean
) => {
  const fetchAdditionalInfo = async () => {
    try {
      const response = isGlobal
        ? await getGlobalAdditionalInfo(type, id)
        : await getAdditionalInfo(type, id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const additionalInfo = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_ADDITIONAL_INFO,
      id.toString(),
      type.toString(),
    ],
    fetchAdditionalInfo,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return additionalInfo
}
