import { OptionApi } from "components/helpers"
import EbateAxios from "queries/ebateAxios"

const exchangeFormatUrl = "/api/ExchangeMapping"

export const getAllData = async () => {
  const url = `${exchangeFormatUrl}/GetAllData`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}
