import { OptionModel } from "components/helpers"
import { MultiSelect, ValidationMessage } from "components/library"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetRebatesByCalculationType } from "./hook/useGetRebatesByCalculationType"

interface Props {
  calculationTypeId: number
  agreementIds: number[]
  errorMessage?: string
  displayValidations?: boolean
  fieldName: string
  heading: string
  required?: boolean
  onChange: (e: any) => void
  selectedItems: any[]
  disabled?: boolean
}

export const RebateByCalculationTypeMultiselect: React.FC<Props> = ({
  calculationTypeId,
  agreementIds,
  errorMessage,
  displayValidations,
  fieldName,
  heading,
  required,
  onChange,
  selectedItems,
  disabled,
}) => {
  const { t } = useTranslation()
  const [searchTimeout, setSearchTimeout] = useState<any>()
  const [query, setQuery] = useState<string>("")
  const { rebatesByCalculationType } = useGetRebatesByCalculationType(
    calculationTypeId,
    agreementIds,
    query
  )
  const [rebateList, setRebateList] = useState<OptionModel[]>([])

  const [rebatesSelected, setRebatesSelected] = useState<OptionModel[]>(
    selectedItems
      ? selectedItems.map((item) => ({
          value: item,
          title: item,
          hidden: true,
        }))
      : []
  )
  const textChanged = (event: any) => {
    clearTimeout(searchTimeout)
    setSearchTimeout(
      setTimeout(() => {
        setQuery(event.filter)
      }, 500)
    )
  }

  const getSelectedRebateValue = (ids: string[]) => {
    const rebateSelectedButNotInList = ids
      .filter((p) => !rebateList.map((c) => c.value).includes(p))
      ?.map((c) => ({
        value: c,
        title: rebatesSelected.find((psi) => psi.value === c)?.title ?? c,
        hidden: true,
      }))
    const options = rebateList.concat(rebateSelectedButNotInList)
    setRebateList(options)

    const selectedItems = ids.map((c) => ({
      value: c,
      title:
        rebateList?.find((aps) => aps.value === c)?.title ??
        rebatesSelected.find((psi) => psi.value === c)?.title ??
        c,
      hidden: true,
    }))
    return selectedItems
  }

  React.useEffect(() => {
    if (
      rebatesByCalculationType.data &&
      rebatesByCalculationType.data.length > 0
    ) {
      const rebateSelectedButNotInList =
        selectedItems.length > 0
          ? rebatesSelected
              .filter(
                (us) =>
                  !rebatesByCalculationType.data
                    .map((u) => u.value)
                    .includes(us.value)
              )
              ?.map((us) => ({
                value: us.value,
                title: us.title,
                hidden: true,
              }))
          : []

      const rebatesTemp = rebatesByCalculationType.data.concat(
        rebateSelectedButNotInList
      )
      setRebateList(rebatesTemp)
    }
    // } else {
    //   setRebateList([])
    // }
  }, [rebatesByCalculationType.data])
  return (
    <>
      <MultiSelect
        options={rebateList}
        required={required}
        optionLabel="title"
        optionValue="value"
        name={fieldName}
        heading={heading}
        value={rebateList?.length > 0 ? selectedItems : []}
        onChange={(e) => {
          onChange(e)
          setRebatesSelected(getSelectedRebateValue(e.value))
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
        placeholder={
          rebatesByCalculationType.data &&
          rebatesByCalculationType.data?.length < 1
            ? t("no-records-found")
            : t("select-options")
        }
        disabled={disabled}
      />
      {errorMessage && displayValidations && (
        <ValidationMessage
          messageError={errorMessage}
          showMessage={
            displayValidations &&
            errorMessage !== undefined &&
            errorMessage !== null &&
            errorMessage.length > 0
          }
        />
      )}
    </>
  )
}
