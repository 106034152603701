import families from "./families"
import sizes from "./sizes"
import weights from "./weights"

const fonts = {
  ...families,
  weight: weights,
  size: sizes,
}

export default fonts
