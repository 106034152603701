import styled from "styled-components"

const StyledNewsWidget = styled.div`
  .snw-stories {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .full-description {
    padding: 0 var(--spacing-x2) var(--spacing-half) var(--spacing-x3);
    word-break: break-all;
  }

  .spinner {
    height: 5%;
    width: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`
export default StyledNewsWidget
