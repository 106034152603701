import styled from "styled-components"

export const StyledFilterModal = styled.div`
  width: 60vw;
  max-width: 800px;
  padding: 0;
  padding-bottom: 56px;

  .filter-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: unset;
    padding: var(--spacing-base);
  }

  .p-col {
    width: 50%;
  }
`
