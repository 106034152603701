import styled from "styled-components"

interface Props {
  height: string
}
export const StyledLoadingComponent = styled.div<Props>`
  .loader {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    animation: shadowExpandX 2s linear infinite alternate;
  }

  @keyframes shadowExpandX {
    0% {
      box-shadow: 0 0, 0 0;
      color: rgba(0, 132, 134, 0.2);
    }
    100% {
      box-shadow: -24px 0, 24px 0;
      color: rgba(0, 132, 134, 0.8);
    }
  }
  .spinner {
    height: ${({ height }) => height || "100%"};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
