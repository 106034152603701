import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const dateEndTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.dateTo)

const BuyXGetYFreeColumns = (isApprovalEnabled: boolean, t) => {
  const rebateLang = "rebates"
  const columns: ColumnModel[] = [
    {
      field: "id",
      header: t("id"),
      sortable: true,
    },
    {
      field: "productCode",
      header: "Product Code",
      sortable: true,
    },
    {
      field: "productDescription",
      header: "Product Description",
      sortable: true,
    },
    {
      field: "fromDate",
      header: t("effective-from"),
      sortable: true,
      body: dateStartTemplateBody,
    },
    {
      field: "dateTo",
      header: t("effective-to"),
      sortable: true,
      body: dateEndTemplateBody,
    },
    {
      field: "purchaseQty",
      header: t(`${rebateLang}:purchaseQty`),
      sortable: true,
    },
    {
      field: "focProductDescription",
      header: t(`${rebateLang}:focProduct`),
      sortable: true,
    },
    {
      field: "freeOfChargeQty",
      header: t(`${rebateLang}:cheapestFOCQty`),
      sortable: true,
    },
  ]
  if (isApprovalEnabled) {
    return [
      ...columns,
      {
        field: "statusDescription",
        header: t("status"),
        sortable: true,
      },
    ]
  }
  return columns
}

export default BuyXGetYFreeColumns
