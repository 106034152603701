import { ErrorMessage, Grid, Button } from "components/library"
import React, { useContext } from "react"
import { ModalContext } from "stores"
import { useTranslation } from "react-i18next"
import { StyledModalYesNoContent } from "./styled"

interface Props {
  messages: string[]
  onConfirm?: () => void
}
export const ModalError: React.FC<Props> = ({ messages, onConfirm }) => {
  const { handleModal } = useContext(ModalContext)
  const { t } = useTranslation()

  return (
    <StyledModalYesNoContent>
      <Grid>
        <div coordinates={[1, 1, 12, 11]}>
          <ErrorMessage messages={messages} />
        </div>
        <div coordinates={[1, 12, 12, 1]}>
          <Grid>
            <div coordinates={[1, 1, 2, 1]} className="footer">
              <div className="button-container">
                <Button
                  square
                  bgcolor="var(--color-ebateOrange)"
                  color="var(--color-white)"
                  onClick={() => {
                    if (onConfirm) {
                      onConfirm()
                      handleModal()
                    } else {
                      handleModal()
                    }
                  }}
                >
                  {t("ok")}
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </Grid>
    </StyledModalYesNoContent>
  )
}
