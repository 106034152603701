import React from "react"
import permissionRoute from "routes/permissionRoute"
import Permissions from "components/helpers/permissions"

const PortalDocumentRoutes = [
  {
    location: "/documents/documents",
    name: "collaboration-documents",
    requiredPermissions: [Permissions.CollaborationDocumentManagement],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/Document")),
      Permissions.CollaborationDocumentManagement
    ),
  },
]

export default PortalDocumentRoutes
