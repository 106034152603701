import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const percentageColumnTemplateBody = (rowData): string =>
  `${rowData.currentRate}`

const totalSalesTieredRebateColumns = (t) => {
  const rebateLang = "rebates"
  const columns: ColumnModel[] = [
    {
      field: "id",
      header: t("id"),
      sortable: true,
    },
    {
      field: "fromDate",
      header: t("startDate"),
      sortable: true,
      body: dateStartTemplateBody,
    },
    {
      field: "transactionCurrencyDescription",
      header: t(`${rebateLang}:currency`),
      sortable: true,
    },
    {
      field: "statusDescription",
      header: t("status"),
      sortable: true,
    },
    {
      field: "currentRate",
      header: t(`${rebateLang}:header-current-rate`),
      sortable: true,
      body: percentageColumnTemplateBody,
    },
  ]
  return columns
}
export default totalSalesTieredRebateColumns
