import styled from "styled-components"
import { Card } from "components/library"

const StyledModal = styled(Card)`
  position: fixed;
  left: 50%;
  top: 55%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: var(--zindex-modal);
  border-radius: 0;
  #headerGradient {
    height: 4px;
    background-color: var(--color-ebatePrimary);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .modal-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  #headerSeparator {
    border-bottom: solid 1px var(--color-lineColor);
    margin-left: -16px;
    margin-right: -16px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }
`

export default StyledModal
