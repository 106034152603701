import styled from "styled-components"

interface Props {
  lines: number
}
export const StyledTruncateText = styled.div<Props>`
  position: "relative";
  width: "100%";

  ${({ lines }) => `
   .truncated-text-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  `}
`
