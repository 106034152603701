const LightTheme = {
  body: "#fafafa",
  text: "#090909",
  background: "#fcfcfc",
  foreground: "#f0f0f0",
  menuUser: "#ECF0F6",
  lineColor: "#edeff2",
  transparentButtonColor: "var(--color-ebatePrimary)",
}

export default LightTheme
