import { useOktaAuthentication } from "components/hooks/auth/useOktaAuthentication"
import React, { createContext } from "react"

export const OktaProviderContext = createContext()

export const OktaProvider = ({ children }) => {
  const { oktaEmail, oktaToken } = useOktaAuthentication()

  return (
    <OktaProviderContext.Provider
      value={{
        oktaEmail,
        oktaToken,
      }}
    >
      {children}
    </OktaProviderContext.Provider>
  )
}
