import * as model from "components/models"

const format = (d: Date) => d.toISOString().split("T")[0]
const dateFormat =
  localStorage.getItem("i18nextLng") ?? window.navigator.language

const getDateAddSeconds = (seconds: number) => {
  const d = new Date()
  d.setSeconds(d.getSeconds() + seconds)
  return d
}

const formatDateTimeToLocale = (date: Date) =>
  new Date(date)
    .toLocaleString(dateFormat, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(",", "")

const formatDateTimeToLocaleRemoveSeconds = (date: Date) =>
  new Date(date)
    .toLocaleString(dateFormat, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(",", "")

const formatDateToLocale = (date: any) =>
  new Date(date).toLocaleDateString(dateFormat)

const formatToUtc = (date) => {
  const dateTime = new Date(date)
  return new Date(
    Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())
  )
}
const formatToUTCFromFilterDate = (
  date: model.FilterModel<Date>
): model.FilterModel<Date> => {
  if (date.value) {
    const dateTime = new Date(date.value)
    return {
      value: new Date(
        Date.UTC(
          dateTime.getFullYear(),
          dateTime.getMonth(),
          dateTime.getDate()
        )
      ),
      error: date.error,
    }
  }
  return date
}

function getLocaleFormatDateString() {
  const formats = {
    "en-GB": "dd/mm/yy",
    "en-US": "m/d/yy",
    "en-US-Vizient": "mm/dd/yy",
    "es-ES": "dd/mm/yy",
  }

  return formats[dateFormat] || "dd/mm/yy"
}

export const getStartDateOfYear = () => `${new Date().getFullYear()}-01-01`
export const getLastDateOfYear = () => `${new Date().getFullYear()}-12-31`

export default {
  format,
  getDateAddSeconds,
  formatToUtc,
  formatToUTCFromFilterDate,
  formatDateToLocale,
  formatDateTimeToLocale,
  getLocaleFormatDateString,
  formatDateTimeToLocaleRemoveSeconds,
  getStartDateOfYear,
  getLastDateOfYear,
}
