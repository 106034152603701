import styled from "styled-components"

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  flex-wrap: ${({ wrap }) => wrap || "nowrap"};
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: ${({ align }) => align || "stretch"};
  width: 100%;
  height: 100%;
  ${({ align }) => {
    if (align === "stretch" || align === undefined) {
      return "> *{height: unset !important};"
    }
    return null
  }}
  ${({ direction, gutter }) => {
    if (
      direction === "row" ||
      direction === "row-reverse" ||
      direction === undefined
    ) {
      return `
      > :not(:first-child) {
        margin-left: ${gutter || "var(--spacing-half)"};
      }
      > :not(:last-child) {
        margin-right: ${gutter || "var(--spacing-half)"};
      }`
    }
    return `
      > :not(:first-child) {
        margin-top: ${gutter || "var(--spacing-half)"};
      }
      > :not(:last-child) {
        margin-bottom: ${gutter || "var(--spacing-half)"};
      }`
  }}
`

export default StyledFlexContainer
