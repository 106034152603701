import { AxiosResponse } from "axios"
import { OptionApi } from "components/helpers"
import { PaymentAdjustmentRequest } from "components/pages/finance/ClaimsPayments/claimPaymentAdjustment/claimPaymentAdjustment.models"
import EbateAxios from "queries/ebateAxios"
import { ValidationResultModel } from "components/models/validationResultModel"

const paymentAdjustmentUrl = "/api/PaymentAdjustment"

export const getPaymentAdjustmentReasons = async () => {
  const url = `${paymentAdjustmentUrl}/GetPaymentAdjustmentReasons`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const createPaymentAdjustment = async (
  request: PaymentAdjustmentRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${paymentAdjustmentUrl}/Create`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<PaymentAdjustmentRequest, ValidationResultModel>(
    url,
    request
  )
}
