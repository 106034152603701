import { Toast, ToastPositionType, ToastSeverityType } from "primereact/toast"
import React, { useEffect, useRef } from "react"

interface Props {
  showToast: boolean
  setShowToast: any
  severity: ToastSeverityType | undefined
  summary: string
  detail?: string
  life: number
  position: ToastPositionType
  sticky?: boolean
  onHide?: () => void
}
export const ToastComponent: React.FC<Props> = ({
  showToast,
  setShowToast,
  severity,
  summary,
  detail,
  life,
  position,
  sticky,
  onHide,
}) => {
  const toast = useRef<Toast | null>(null)
  useEffect(() => {
    if (showToast) {
      switch (severity) {
        case "error":
          toast.current?.show({
            severity,
            summary,
            detail,
            life: life ?? null,
            contentClassName: "",
            sticky: sticky ?? false,
            content: (
              <>
                <span className="p-toast-message-icon pi pi-times-circle" />
                <div className="p-toast-message-text">
                  <span className="p-toast-summary">Error</span>
                  <div className="p-toast-detail">{detail}</div>
                </div>
              </>
            ),
          })
          break
        case "warn":
          toast.current?.show({
            severity,
            summary,
            detail,
            life: life ?? null,
            contentClassName: "",
            sticky: sticky ?? false,
            content: (
              <>
                <span className="p-toast-message-icon pi pi-exclamation-triangle" />
                <div className="p-toast-message-text">
                  <span className="p-toast-summary">Warning</span>
                  <div className="p-toast-detail">{detail}</div>
                </div>
              </>
            ),
          })
          break
        case "success":
          toast.current?.show({
            severity,
            summary,
            detail,
            life: life ?? null,
            contentClassName: "",
            sticky: sticky ?? false,
            content: (
              <>
                <span className="p-toast-message-icon pi pi-check-circle" />
                <div className="p-toast-message-text">
                  <span className="p-toast-summary">Success</span>
                  <div className="p-toast-detail">{detail}</div>
                </div>
              </>
            ),
          })
          break
        default:
          toast.current?.show({
            severity,
            summary,
            detail,
            life: life ?? null,
            contentClassName: "",
            sticky: sticky ?? false,
          })
          break
      }
      setShowToast(false)
    }
  }, [showToast])
  return (
    <Toast ref={toast} position={position ?? "top-center"} onHide={onHide} />
  )
}
