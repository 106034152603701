import { SSOInfoModel } from "components/library/auth/sso.models"
import EbateAxios from "queries/ebateAxios"

const ssoUrl = "/api/SSO"

export const silentAuth = async (token, abortSignal?: AbortSignal) => {
  const url = `${ssoUrl}/SSOSilentLogin`
  const { AxiosPostSilentLogin } = EbateAxios()

  return AxiosPostSilentLogin<any, any>(url, token, abortSignal)
}

export const ssoInfo = async (abortSignal?: AbortSignal) => {
  const url = `${ssoUrl}/SSOInfo`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<SSOInfoModel>(url, undefined, undefined, abortSignal)
}
