import { ExchangeObjectResponse } from "components/pages/maintanance/crossReference/crossReferenceList/crossReference.models"
import EbateAxios from "queries/ebateAxios"

const exchangeFormatUrl = "/api/ExchangeFormat"

export const getExchangeFormatByMappingId = async (id: number) => {
  const url = `${exchangeFormatUrl}/GetExchangeFormatByMappingId/${id}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ExchangeObjectResponse[]>(url)
}

export const getAllExchangeObject = async () => {
  const url = `${exchangeFormatUrl}/GetAllExchangeObject`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ExchangeObjectResponse[]>(url)
}

export const getExchangeFormatsByExchangeObjectId = async (
  exchangeObjectId: number
) => {
  const url = `${exchangeFormatUrl}/GetExchangeFormatsByExchangeObjectId/${exchangeObjectId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ExchangeObjectResponse[]>(url)
}
