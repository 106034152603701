import React from "react"

import { usePagination } from "components/hooks"

import { Button, FlexContainer } from "components/library"

import { StyledTable } from "./styled"

import Header from "./Header"
import Rows from "./Rows"

const Paginated = (props) => {
  const {
    data,
    paginated,
    sortFunc,
    currentSort,
    nested,
    selectable,
    setSelection,
    rowParent,
    resetSelection,
    setAllSelection,
  } = props

  const pageData = usePagination(data.rows, paginated)

  return (
    <FlexContainer direction="column">
      {/* Only show paginator if there is multiple pages of data */}
      {pageData.maxPage !== 1 ? (
        <FlexContainer justify="flex-end">
          <Button
            onClick={pageData.prev}
            bgcolor="var(--color-ebatePrimary)"
            color="var(--color-white)"
            disabled={pageData.currentPage === 1}
          >
            Previous
          </Button>
          <p>
            Page {pageData.currentPage} of {pageData.maxPage}
          </p>
          <Button
            onClick={pageData.next}
            bgcolor="var(--color-ebatePrimary)"
            color="var(--color-white)"
            disabled={pageData.currentPage === pageData.maxPage}
          >
            Next
          </Button>
        </FlexContainer>
      ) : null}
      <StyledTable {...props}>
        <Header
          data={data.head}
          sortFunc={sortFunc}
          currentSort={currentSort}
          nested={nested}
          selectable={selectable}
          checked={
            data.rows.filter((key) => key.selected).length ===
              data.rows.length && data.rows.length > 0
          }
          indeterminate={
            data.rows.filter((key) => key.indeterminate === "indeterminate")
              .length > 0 ||
            (data.rows.filter((key) => key.selected).length !==
              data.rows.length &&
              data.rows.filter((key) => key.selected).length > 0)
          }
          resetSelection={resetSelection}
          setAllSelection={setAllSelection}
        />
        <Rows
          data={{ ...data, rows: pageData.currentData() }}
          nested={nested}
          selectable={selectable}
          setSelection={setSelection}
          rowParent={rowParent}
        />
      </StyledTable>
    </FlexContainer>
  )
}

export default Paginated
