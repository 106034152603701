import styled from "styled-components"

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  opacity: 0.8;
  z-index: vaR(--zindex-modal);
`

export default Scrim
