const compact = {
  base: "4px",
  x2: "8px",
  x3: "12px",
  x4: "18px",
  x5: "22px",
  half: "2px",
  quarter: "1px",
}

export default compact
