import { ValidationResultModel } from "components/models"
import {
  RebateGridRequest,
  RebateGridResponse,
} from "components/pages/pricemanagement/rebates/rebateList/rebateList.models"
import { RebateIncentivesGridRequest } from "components/pages/pricemanagement/rebateIncentives/rebateIncentiveList/rebateIncentiveList.models"
import {
  AddRebateIncentiveApiModel,
  RebateIncentiveDetailsApi,
  RebateIncentiveDetailsRequestApi,
} from "components/pages/pricemanagement/rebateIncentives/rebateIncentiveDetails/rebateIncentiveDetails.models"
import {
  DocumentTabGridFilterRequest,
  DocumentTabGridResponse,
} from "components/library/documentTab/documentTab.models"

import { NoteModel } from "components/library/note/note.models"

import EbateAxios from "queries/ebateAxios"
import {
  AddRebateApiModel,
  RebateDetailsApi,
  RebateDetailsRequestApi,
} from "components/pages/pricemanagement/rebates/rebateDetails/rebateDetails.models"
import { CalculatedInfoResponse } from "components/pages/pricemanagement/rebates/rebateOverview/rebateOverviewModel"
import {
  RebateDoCopyRequest,
  RebateIdResponse,
} from "components/pages/pricemanagement/rebates/rebateCopy/rebateCopy.models"
import { GenericApiResponseModel } from "components/models/common.model"

import {
  RateGridFilterRequest,
  RateGridResponse,
  RebateRateInsertRequest,
  RebateRateUpdateRequest,
  RebateRatesApprovalRequest,
} from "components/pages/pricemanagement/rebates/rebateRates/rebateRates.model"
import {
  AuditLogGridFilterRequest,
  AuditLogGridFilterResponse,
} from "components/pages/pricemanagement/rebates/rebateAuditLog/rebateAuditLog.models"
import { RebateDeleteFormRequest } from "components/pages/pricemanagement/rebates/rebateDelete/rebateDelete.models"

const rebateApiUrl = "/api/rebate"

export const getProductLevelRateFileTemplate = async () => {
  const url = `${rebateApiUrl}/GetRatesFileTemplate`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<Blob>(url)
}

export const uploadProductRateFile = async (data: any) => {
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, ValidationResultModel>(
    `${rebateApiUrl}/AddRatesFromFile`,
    data
  )
}

export const getGlobalRebatesGridData = async (
  data: RebateGridRequest | RebateIncentivesGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateApiUrl}/GetGlobalRebatesGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    RebateGridRequest | RebateIncentivesGridRequest,
    RebateGridResponse
  >(url, data, abortSignal)
}

export const getRebateIncentiveGlobalDetails = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateIncentiveDetailsApi>(
    `${rebateApiUrl}/RebateGlobalDetails/${id}`
  )
}

export const getRebateGlobalDetails = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateDetailsApi>(`${rebateApiUrl}/RebateGlobalDetails/${id}`)
}

export const getGlobalRebateFileGridData = async (
  data: DocumentTabGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateApiUrl}/GetGlobalRebateFileGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<DocumentTabGridFilterRequest, DocumentTabGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const getGlobalRebateComments = async (id: number | undefined) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<NoteModel[]>(`${rebateApiUrl}/GetGlobalRebateComments/${id}`)
}

export const copyRebate = async (data: RebateDoCopyRequest) => {
  const url = `${rebateApiUrl}/CopyRebate`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    RebateDoCopyRequest,
    GenericApiResponseModel<RebateIdResponse>
  >(url, data)
}

export const getGlobalCalculatedInfo = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<CalculatedInfoResponse>(
    `${rebateApiUrl}/GetGlobalCalculatedInfo/${id}`
  )
}

export const getGlobalRatesGridData = async (
  data: RateGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateApiUrl}/GetGlobalRatesGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RateGridFilterRequest, RateGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const getGlobalAllConditionsMustBeMetValue = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<Boolean>(
    `${rebateApiUrl}/GetGlobalAllConditionsMustBeMetValue/${id}`
  )
}
export const getAuditLogRebateGridData = async (
  data: AuditLogGridFilterRequest
) => {
  const url = `${rebateApiUrl}/GetAuditLogRebateGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AuditLogGridFilterRequest, AuditLogGridFilterResponse>(
    url,
    data
  )
}

export const getRebateDetails = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateDetailsApi>(`${rebateApiUrl}/GetRebateDetails/${id}`)
}

export const getRebateIncentiveDetails = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateIncentiveDetailsApi>(
    `${rebateApiUrl}/GetRebateDetails/${id}`
  )
}

export const getRatesGridData = async (
  data: RateGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateApiUrl}/GetRatesGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RateGridFilterRequest, RateGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const addRates = async (data: RebateRateInsertRequest) => {
  const url = `${rebateApiUrl}/AddRates`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RebateRateInsertRequest, ValidationResultModel>(url, data)
}

export const deleteRates = async (rebateRateIds: number[]) => {
  const url = `${rebateApiUrl}/DeleteRates`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<number[], ValidationResultModel>(url, rebateRateIds)
}

export const updateRate = async (data: RebateRateUpdateRequest) => {
  const url = `${rebateApiUrl}/UpdateRate`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<RebateRateUpdateRequest, ValidationResultModel>(url, data)
}

export const requestRebateRates = async (data: RebateRatesApprovalRequest) => {
  const url = `${rebateApiUrl}/RequestRebateRates`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RebateRatesApprovalRequest, ValidationResultModel>(url, data)
}

export const getRebatesGridData = async (
  data: RebateGridRequest | RebateIncentivesGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateApiUrl}/GetRebatesGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    RebateGridRequest | RebateIncentivesGridRequest,
    RebateGridResponse
  >(url, data, abortSignal)
}

export const getCalculatedInfo = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<CalculatedInfoResponse>(
    `${rebateApiUrl}/GetCalculatedInfo/${id}`
  )
}

export const getRebateFileGridData = async (
  data: DocumentTabGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${rebateApiUrl}/GetRebateFileGridData`
  return AxiosPost<DocumentTabGridFilterRequest, DocumentTabGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const addRebate = async (
  data: AddRebateIncentiveApiModel | AddRebateApiModel
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${rebateApiUrl}/AddRebate`
  return AxiosPost<
    AddRebateIncentiveApiModel | AddRebateApiModel,
    GenericApiResponseModel<any>
  >(url, data)
}

export const deleteRebate = async (data: RebateDeleteFormRequest) => {
  const { AxiosPut } = EbateAxios()

  const url = `${rebateApiUrl}/DeleteRebate`
  return AxiosPut<RebateDeleteFormRequest, ValidationResultModel>(url, data)
}

export const updateRebate = async (
  data: RebateDetailsRequestApi | RebateIncentiveDetailsRequestApi,
  checkwarning: boolean
) => {
  const url = `${rebateApiUrl}/UpdateRebate/${checkwarning}`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<
    RebateDetailsRequestApi | RebateIncentiveDetailsRequestApi,
    GenericApiResponseModel<any>
  >(url, data)
}

export const getExistCalculations = async (data: number[]) => {
  const { AxiosPost } = EbateAxios()

  const url = `${rebateApiUrl}/ExistCalculations`
  return AxiosPost<number[], Boolean>(url, data)
}

export const getAllConditionsMustBeMetValue = async (id: number) => {
  const { AxiosGet } = EbateAxios()

  const url = `${rebateApiUrl}/GetAllConditionsMustBeMetValue/${id}`
  return AxiosGet<Boolean>(url)
}

export const editAllConditionsMustBeMetValue = async (
  id: number,
  value: boolean
) => {
  const { AxiosPut } = EbateAxios()

  const url = `${rebateApiUrl}/EditAllConditionsMustBeMetValue/${id}`
  return AxiosPut<Boolean, ValidationResultModel>(url, value)
}
