import styled from "styled-components"

export const StyledFilterValues = styled.div`
  display: flex;

  .chipFilterValue,
  .chipFilter {
    height: 24px;
  }
  .chipFilterValue > span.p-chip-text {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }
`
