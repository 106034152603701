import {
  ebateQueryKeys,
  useEbateQuery,
  getNormalRoles,
  getPortalRolesGridData,
  getPortalRoleDetails,
  getPortalRoleClaims,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useRoles = (isPortalRole: boolean) => {
  const fetch = async () => {
    try {
      const response = await getNormalRoles(isPortalRole)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const roles = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLES, isPortalRole.toString()],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return roles
}

export const usePortalRolesGridData = () => {
  const fetch = async () => {
    try {
      const response = await getPortalRolesGridData()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const roles = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PORTAL_ROLES_GRID_DATA],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return roles
}

export const usePortalRoleClaims = () => {
  const fetch = async () => {
    try {
      const response = await getPortalRoleClaims()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const roleClaims = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PORTAL_ROLE_CLAIMS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return roleClaims
}

export const usePortalRoleById = (id: string) => {
  const fetch = async () => {
    try {
      const response = await getPortalRoleDetails(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const role = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PORTAL_ROLE_BY_ID, id],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return role
}
