import { AxiosResponse } from "axios"
import { ValidationResultModel } from "components/models"
import {
  AccountingPeriodAddRequest,
  AccountingPeriodData,
  AccountingPeriodUpdateRequest,
} from "components/pages/maintanance/accountingPeriod/accountingPeriodForm/accountingPeriodForm.models"
import {
  AccountingPeriodGridFilterRequest,
  AccountingPeriodGridResponse,
} from "components/pages/maintanance/accountingPeriod/accountingPeriodList/accountingPeriodList.models"
import EbateAxios from "queries/ebateAxios"

const accountingPeriodUrl = "/api/AccountingPeriod"

export const getAccountingPeriodGridData = async (
  data: AccountingPeriodGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${accountingPeriodUrl}/GetAccountingPeriodGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<
    AccountingPeriodGridFilterRequest,
    AccountingPeriodGridResponse
  >(url, data, abortSignal)
}

export const addAccountingPeriod = async (data: AccountingPeriodAddRequest) => {
  const url = `${accountingPeriodUrl}/AddAccountingPeriod`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<AccountingPeriodAddRequest, ValidationResultModel>(url, data)
}

export const getAccountingPeriod = async (accountingPeriodId: number) => {
  const url = `${accountingPeriodUrl}/GetAccountingPeriod/${accountingPeriodId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<AccountingPeriodData>(url)
}

export const updateAccountingPeriod = async (
  request: AccountingPeriodUpdateRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${accountingPeriodUrl}/UpdateAccountingPeriod`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<AccountingPeriodUpdateRequest, ValidationResultModel>(
    url,
    request
  )
}

export const deleteAccountingPeriod = async (
  id: number
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${accountingPeriodUrl}/DeleteAccountingPeriod/${id}`
  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}
