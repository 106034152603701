import {
  DocumentGridData,
  DocumentGridRequest,
} from "components/pages/collaboration/document/documentList/document.models"
import {
  ebateQueryKeys,
  getDocumentById,
  getDocumentGridData,
  useEbateQuery,
} from "queries"
import { useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useDocumentById = (id: string) => {
  const fetch = async () => {
    try {
      const response = await getDocumentById(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const document = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DOCUMENT_BY_ID, id],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return document
}

export const useDocumentGrid = (documentFilter: DocumentGridRequest) => {
  const getUniqueValues = (data): DocumentGridData[] => {
    const flattened = data.pages.flatMap((page) => page.documents)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as DocumentGridData[]
    return arrayUniqueByKey
  }
  const fetchDocumentGridData = async () => {
    const response = await getDocumentGridData(documentFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_DOCUMENT_GRID_DATA,
      documentFilter.orderBy,
      documentFilter.isAscending ? "true" : "false",
      documentFilter.search ?? "",
      documentFilter.companyId?.toString() ?? "",
      documentFilter.companyTypeId?.toString() ?? "",
      documentFilter.tagId?.toString() ?? "",
      documentFilter.categoryId?.toString() ?? "",
    ],
    fetchDocumentGridData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= documentFilter.currentPage
          ? documentFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, documentFilter])

  const documents = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > documentFilter.currentPage

  return { documents, hasMore, isLoading, isError }
}
