import { CurrencyApiModel } from "components/pages/maintanance/currency/currencyList/currencyList.models"
import { CurrencyRequest } from "components/pages/maintanance/currency/currencyForm/currencyForm.models"
import EbateAxios from "queries/ebateAxios"

const currencyUrl = "/api/Currency"

export const getCurrencyGridData = async () => {
  const url = `${currencyUrl}/GetCurrencyGridData`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CurrencyApiModel[]>(url)
}

export const addCurrency = async (data: CurrencyRequest) => {
  const url = `${currencyUrl}/AddCurrency`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CurrencyRequest, any>(url, data)
}

export const getDefaultCurrency = async () => {
  const url = `${currencyUrl}/GetDefaultCurrency`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CurrencyApiModel>(url)
}

export const getTenantCurrencies = async () => {
  const url = `${currencyUrl}/GetTenantCurrencies`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CurrencyApiModel[]>(url)
}
