import { useContext, useEffect } from "react"
import { AuthenticationContext, AzureProviderContext } from "stores"
import { IdleTimer } from "components/helpers"
import { signOutApi, useRefreshToken } from "queries"

const timeoutMinutes = 10

const AuthBlock = () => {
  const { isAuthorized, storeAuthToken, secondsLeft, setSecondsLeft, signOut } =
    useContext(AuthenticationContext)
  const azureContext = useContext(AzureProviderContext)

  const token = useRefreshToken(isAuthorized)

  useEffect(() => {
    if (isAuthorized) {
      if (!token.isLoading) {
        if (
          token.data &&
          !token.isError &&
          token.data.validationResult.isValid
        ) {
          storeAuthToken(token.data.data.idToken, token.data.data.expiresIn)
        } else {
          if (azureContext && azureContext.authProvider)
            azureContext.authProvider.logout()
          signOut()
        }
      }
    }
  }, [token.data, token.isLoading])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSecondsLeft(secondsLeft - 60)
      if (isAuthorized) {
        if (secondsLeft < 61) {
          token.refetch()
        }
      }
    }, 60000)

    return () => {
      clearTimeout(timerId)
    }
  }, [isAuthorized, secondsLeft, setSecondsLeft, storeAuthToken])

  useEffect(() => {
    let timer
    if (isAuthorized) {
      timer = new IdleTimer({
        timeout: timeoutMinutes * 60,
        onTimeout: async () => {
          await signOutApi()
          if (azureContext && azureContext.authProvider)
            azureContext.authProvider.logout()
          signOut()
        },
      })
    }

    return () => timer && timer.cleanUp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized])

  return null
}

export default AuthBlock
