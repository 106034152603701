import { useState } from "react"
import { dateUtils } from "components/helpers"
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode"
import Axios from "axios"
import { SessionStorageKeys } from "components/models/sessionStorageKeys"
import queryClient from "queries/ebateReactQuery/ebateQueryClient"

const useAuthentication = () => {
  const [secondsLeft, setSecondsLeft] = useState()
  const [decoded, setDecoded] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(false)

  const signOut = () => {
    localStorage.setItem("logout", Date.now().toString())
    sessionStorage.removeItem(SessionStorageKeys.linkDate)
    sessionStorage.removeItem(SessionStorageKeys.unlinkDate)
    queryClient.removeQueries()
    setIsAuthorized(false)
    setDecoded(null)
    Axios.defaults.headers.common.Authorization = false
  }

  const storeAuthToken = (jwt, expiresIn) => {
    const token = { token: jwt, expiry: dateUtils.getDateAddSeconds(expiresIn) }
    const authorized = getAuthorization(token)
    setIsAuthorized(authorized)
    setSecondsLeft(expiresIn)
    Axios.defaults.headers.common.Authorization = authorized
      ? `Bearer ${token.token}`
      : ""
    if (expiresIn) setDecoded(jwt_decode(jwt))
  }

  const getToken = (token) => token.token

  const getAuthorization = (token) =>
    getToken(token) !== undefined &&
    getToken(token).length > 0 &&
    token.expiry !== null

  const getTokenValue = (key) => {
    if (decoded) return decoded[key]
    return null
  }

  const getPermission = (permission) =>
    decoded !== null ? decoded[permission] : undefined

  return {
    storeAuthToken,
    getToken,
    isAuthorized,
    signOut,
    secondsLeft,
    setSecondsLeft,
    getTokenValue,
    getPermission,
  }
}

export default useAuthentication
