import React, { createContext } from "react"
import { useModal } from "components/hooks"
import { Modal } from "components/library"

export const ModalContext = createContext()

const ModalProvider = ({ children }) => {
  const {
    modal,
    handleModal,
    modalContent,
    modalTitle,
    setModalContent,
    setModalTitle,
    closeModal,
  } = useModal()
  return (
    <ModalContext.Provider
      value={{
        modal,
        handleModal,
        modalContent,
        modalTitle,
        setModalContent,
        setModalTitle,
        closeModal,
      }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
