export default {
  ReportingReportServer: "Reporting_ReportServer",
  ReportingReportGeneration: "Reporting_ReportGeneration",

  // In user claims as per excel sheet
  MaintenanceCrossReference: "Maintenance_CrossReference",
  MaintenanceClientConfiguration: "Maintenance_ClientConfiguration",
  MaintenanceCurrency: "Maintenance_Currency",
  MaintenanceDocumentCategory: "Maintenance_DocumentCategory",
  MaintenanceDocumentTag: "Maintenance_DocumentTag",
  MaintenanceTradingGroup: "Maintenance_TradingGroup",
  MaintenanceUsers: "Maintenance_Users",
  MaintenanceCompanyMaintenance: "Maintenance_CompanyMaintenance",
  MaintenanceProductMaintenance: "Maintenance_ProductMaintenance",
  MaintenanceProjectMaintenance: "Maintenance_ProjectMaintenance",

  // Not in user claims as per excel sheet
  MaintenanceDimensionManager: "Maintenance_DimensionManager",
  MaintenanceHierarchies: "Maintenance_Hierarchies",
  MaintenanceHierarchyManualMaintenance:
    "Maintenance_HierarchyManualMaintenance",
  MaintenanceUnitsOfMeasure: "Maintenance_UnitsOfMeasure",
  MaintenanceCustomerMaintenance: "Maintenance_CustomerMaintenance",
  MaintenanceRoles: "Maintenance_Roles",
  MaintenanceWorkflow: "Maintenance_Workflow",
  MaintenanceWholeSalerDiscount: "Maintenance_WholeSalerDiscount",
  MaintenanceAccountingPeriod: "Maintenance_AccountingPeriod",
  MaintenanceWorkflowAudit: "Maintenance_WorkflowAudit",
  MaintenancePortalRoles: "Maintenance_PortalRoles",

  // In user claims as per excel sheet
  PricingManagementPackage: "PricingManagement_Package",
  PricingManagementAgreement: "PricingManagement_Agreement",
  PricingManagementApprovals: "PricingManagement_Approvals",
  // Not in user claims as per excel sheet
  PricingManagementRebate: "PricingManagement_Rebate",
  PricingManagementCalcEngineStatus: "PricingManagement_CalcEngineStatus",
  PricingManagementEventBaseIncentive: "PricingManagement_EventBaseIncentive",
  PricingManagementExportAndImportRates:
    "PricingManagement_ExportAndImportRates",

  // In user claims as per excel sheet
  FinanceAccruals: "Finance_Accruals",
  FinancePayments: "Finance_Payments",
  FinanceImportData: "Finance_ImportData",
  FinanceClaimPaymentStatus: "Finance_ClaimPaymentStatus",

  // Not in user claims as per excel sheet
  FinanceApproveFOCClaims: "Finance_ApproveFOCClaims",
  FinancePaymentAdjustments: "Finance_PaymentAdjustments",

  // In user claims as per excel sheet
  CollaborationContracts: "Collaboration_ContractsNegotiation",
  CollaborationDocumentLibrary: "Collaboration_DocumentLibrary",
  CollaborationProduct: "Collaboration_Product",
  CollaborationMemberDashboard: "Collaboration_MemberDashboard",
  CollaborationReports: "Collaboration_Reports",
  CollaborationDocumentManagement: "Collaboration_DocumentManagement",
  CollaborationImportTransactions: "Collaboration_ImportTransactions",
  CollaboratationPortalUserManagment: "Collaboratation_PortalUserManagment",
  CollaboratationTradeAgreements: "Collaboratation_TradeAgreements",

  // Not in user claims as per excel sheet
  CollaborationNewsFeed: "Collaboration_NewsFeed",
}
