import {
  ClaimPaymentApprovalGridData,
  UserRequestsGridItemType,
} from "./approvalClaimPayments.model"

export const mapClaimPaymentApprovalFromApiToVm = (
  response: UserRequestsGridItemType
): ClaimPaymentApprovalGridData => ({
  id: response.requestId,
  requestDate: response.workflowLevelSubRequestCreatingDate,
  requestDescription: response.description,
  requestType: response.typeDescription,
  status: response.requestStatusDescription,
  value: response.impactWithCurrency,
  workflowLevelSubRequestId: response.workflowLevelSubRequestId,
})

export const mapFrontFieldToBackField = (frontField: string): string => {
  switch (frontField) {
    case "requestDate":
      return "date"
    case "requestDescription":
      return "description"
    case "requestType":
      return "type"
    case "value":
      return "impact"
    default:
      return frontField
  }
}

export const mapBackFieldToFrontField = (backField: string): string => {
  switch (backField) {
    case "date":
      return "requestDate"
    case "description":
      return "requestDescription"
    case "type":
      return "requestType"
    case "impact":
      return "value"
    default:
      return backField
  }
}
