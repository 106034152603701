import { ValidationResultModel } from "components/models"
import {
  AddCrossReferenceRequest,
  CrossReferenceExchangeObjectResponse,
  CrossReferenceGridRequest,
  CrossReferenceGridResponse,
  CrossReferencedValueRequest,
} from "components/pages/maintanance/crossReference/crossReferenceList/crossReference.models"
import EbateAxios from "queries/ebateAxios"

const crossReferenceUrl = "/api/CrossReference"

export const getCrossReferenceGrid = async (
  data: CrossReferenceGridRequest
) => {
  const url = `${crossReferenceUrl}/GetCrossReferenceGrid`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CrossReferenceGridRequest, CrossReferenceGridResponse>(
    url,
    data
  )
}

export const getCrossReferencedValues = async (
  data: CrossReferencedValueRequest
) => {
  const url = `${crossReferenceUrl}/GetCrossReferencedValues`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<
    CrossReferencedValueRequest,
    CrossReferenceExchangeObjectResponse
  >(url, data)
}

export const addCrossReference = async (data: AddCrossReferenceRequest) => {
  const url = `${crossReferenceUrl}/AddCrossReference`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<AddCrossReferenceRequest, ValidationResultModel>(url, data)
}

export const deleteCrossReference = async (id: number) => {
  const url = `${crossReferenceUrl}/DeleteCrossReference/${id}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}
