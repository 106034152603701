import React from "react"
import { CheckBox } from "components/library"

import { TableHeader, TableHeadItem } from "./styled"

const Header = ({
  data,
  currentSort,
  sortFunc,
  nested,
  selectable,
  indeterminate,
  checked,
  resetSelection,
  setAllSelection,
}) => {
  const setChecked = (checked) => {
    if (checked) {
      setAllSelection()
    } else {
      resetSelection()
    }
  }

  const selectionColumn = () => {
    if (selectable && !nested)
      return (
        <TableHeadItem
          index="0"
          key={`${nested ? "n_" : "p_"}select`}
          nested={nested}
          selectable={selectable}
        >
          <CheckBox
            onChange={(e) => setChecked(e.target.checked)}
            checked={checked}
            indeterminate={
              indeterminate && !checked ? "indeterminate" : undefined
            }
          />
        </TableHeadItem>
      )

    return null
  }

  const generateHeader = () =>
    Object.keys(data)
      .filter((key) => key !== "details")
      .map(
        (key, index) =>
          !data[key].hidden && (
            <TableHeadItem
              index={index + 1}
              key={(nested ? "n_" : "p_") + data[key].title}
              resize={data[key].resize}
              allowSort={data[key].sort}
              sort={key === currentSort.key ? currentSort.order : false}
              onClick={data[key].sort ? () => sortFunc(key) : null}
              nested={nested}
            >
              {data[key].title}
            </TableHeadItem>
          )
      )
  return (
    <TableHeader>
      {selectionColumn()}
      {generateHeader()}
    </TableHeader>
  )
}

export default Header
