import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const dateEndTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.dateTo)

const percentageColumnTemplateBody = (rowData): string =>
  `${rowData.ratePercentage}%`

const ProductLevelPerecentageColumns = (
  isApprovalEnabled: boolean
): ColumnModel[] => {
  const columns = [
    {
      field: "id",
      header: "ID",
      sortable: true,
    },
    {
      field: "productCode",
      header: "Product Code",
      sortable: true,
    },
    {
      field: "productDescription",
      header: "Product Description",
      sortable: true,
    },
    {
      field: "fromDate",
      header: "Effective From",
      sortable: true,
      body: dateStartTemplateBody,
    },
    {
      field: "dateTo",
      header: "Effective To",
      sortable: true,
      body: dateEndTemplateBody,
    },
    {
      field: "ratePercentage",
      header: "Current Rate",
      sortable: true,
      style: { textAlign: "right" },
      body: percentageColumnTemplateBody,
    },
  ]
  if (isApprovalEnabled) {
    return [
      ...columns,
      {
        field: "statusDescription",
        header: "Status",
        sortable: true,
      },
    ]
  }
  return columns
}

export default ProductLevelPerecentageColumns
