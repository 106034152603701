import styled from "styled-components"

const StyledPageContainer = styled.div`
  padding: ${({ wide }) => (wide ? "" : "var(--spacing-x2)")};
  width: 100%;
  overflow: visible;
  margin: 0 auto;
  ${({ narrow }) => (narrow ? "max-width: 1200px" : "")};
`

export default StyledPageContainer
