import styled from "styled-components"

const TableRow = styled.div`
  display: flex;
  .nestedRow {
    min-width: 80px;
    padding-left: 22px;
  }
  &:hover {
    .row {
      background-color: var(--color-rowSelected);
    }
  }
  &:hover > .nestedRow {
    background-color: var(--color-rowSelected);
  }
  .checkbox {
    padding-left: var(--spacing-x4);
  }
`

export default TableRow
