import { useEffect, useState } from "react"
import { getCompanies } from "queries"
import { dropdownUtils, OptionApi, OptionModel } from "components/helpers"

const useCompanies = (query: string = "", isAllCompaniesActive = false) => {
  const [companies, setCompanies] = useState<OptionModel[]>([])
  const [loadedData, setLoadedData] = useState<boolean>(false)

  const onCompletedCompanies = (data: OptionApi[]) => {
    let companiesFromApi = dropdownUtils.mapToDropDownOptionFromApi(data)

    if (isAllCompaniesActive) {
      companiesFromApi = [
        {
          value: 0,
          title: "All Companies",
        },
        ...companiesFromApi,
      ]
    }
    setCompanies([...companiesFromApi])
  }

  const fetchCompanies = async () => {
    setLoadedData(false)
    try {
      const response = await getCompanies(query)

      setLoadedData(true)

      if (response?.status === 200) {
        onCompletedCompanies(response.data)
      }
    } catch (e) {
      setLoadedData(true)
    }
  }

  useEffect(() => {
    fetchCompanies()
  }, [query])

  return { companies, loadedData }
}

export default useCompanies
