import React, { useContext } from "react"
import { ThemeContext } from "theme"
import { Link } from "react-router-dom"

import { useLogo } from "components/hooks"
import { StyledHeader, StyledLogo } from "./styled"

const Header = ({ children }) => {
  const { themeState } = useContext(ThemeContext)
  const { logo } = useLogo()

  return (
    <StyledHeader>
      <Link to="/dashboard" className="header-logo">
        <StyledLogo
          dark={themeState.themeMode === "dark"}
          src={logo}
          alt="E-Bate Logo"
        />
      </Link>
      <div className="left-links">{children}</div>
    </StyledHeader>
  )
}

export default Header
