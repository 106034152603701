import { NoteModel, NoteModelRequest } from "components/library"
import { NoteModelUpdateRequest } from "components/library/note/note.models"
import { ValidationResultModel } from "components/models"
import EbateAxios from "queries/ebateAxios"

const CommentUrl = "/api/Comment"

export const getComments = async (dealId, dealType) => {
  const url = `${CommentUrl}/GetComments/${dealId}/${dealType}`

  const { AxiosGet } = EbateAxios()

  return AxiosGet<NoteModel[]>(url)
}

export const getGlobalComments = async (dealId, dealType) => {
  const url = `${CommentUrl}/GetGlobalComments/${dealId}/${dealType}`

  const { AxiosGet } = EbateAxios()

  return AxiosGet<NoteModel[]>(url)
}

export const deleteComment = async (commentId) => {
  const url = `${CommentUrl}/DeleteComment/${commentId}`

  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}

export const addComment = async (request: NoteModelRequest) => {
  const url = `${CommentUrl}/AddComment`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<NoteModelRequest, ValidationResultModel>(url, request)
}

export const updateComment = async (request: NoteModelUpdateRequest) => {
  const url = `${CommentUrl}/UpdateComment`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<NoteModelUpdateRequest, ValidationResultModel>(url, request)
}
