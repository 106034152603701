import React from "react"
import { StyledWizardSummary } from "./styled"

const WizardSummary = ({ content }) => (
  <StyledWizardSummary>
    {content.map((section) => (
      <div key={section.name} className="summary-box">
        <span className="summary-name">{section.name}</span>

        {section.items.map((item) => (
          <div key={item.name} className="summary-item">
            {item.title && (
              <span className="summary-item-title">{item.title}: </span>
            )}
            <span className="summary-item-value">{item.value}</span>
          </div>
        ))}
      </div>
    ))}
  </StyledWizardSummary>
)

export default WizardSummary
