import styled from "styled-components"

const StyledModalMessage = styled.div`
  min-width: 420px;
  height: 100px;

  .footer {
    border-top: solid 1px var(--color-lineColor);
    padding-top: 15px;
    text-align: right;
  }

  .button-container {
    display: inline-block;
    margin-left: 14px;
  }

  .button-container > button {
    width: 64px;
  }

  .message {
    text-align: center;
  }
`

export default StyledModalMessage
