import styled from "styled-components"

const ModalClose = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: end;
  background-color: transparent;
  width: 40px;
  height: 40px;
  color: var(--color-text);
  font-size: var(--font-size-modalClose);
  cursor: pointer;
`
export default ModalClose
