import styled from "styled-components"

export const StyledDropdownEbate = styled.div`
  background-color: var(--color-ebateControlBackground);
  width: 100%;
  position: relative;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--font-size-md);
  border-radius: 4px;

  .dropdown-box {
    padding: 1.5px 0px 1.5px 12px;
    display: flex;
    border: 2px solid var(--color-ebateControlBackground);
    border-radius: 4px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .dropdown-box-text {
      width: 100%;
      height: 100%;
      color: rgba(0, 0, 0, 0.87);
    }

    .dropdown-box-text:empty:before {
      content: attr(data-placeholder);
      color: rgba(0, 0, 0, 0.6);
    }

    .dropdown-icons {
      display: flex;

      .dropdown-icon {
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &:hover {
      border: 2px solid var(--color-ebateControlBorderHover);
    }
  }

  .dropdown-panel {
    position: absolute;
    background-color: #ffffff;
    z-index: 9999999;
    width: 100%;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border: 0 none;
    border-radius: 4px;
    padding: 12px;

    cursor: default;

    .dropdown-item-panel {
      max-height: 200px;
      margin-top: 12px;

      overflow-y: auto;
      .dropdown-item {
        cursor: pointer;

        width: 100%;
        padding: 0.75rem 0.75rem;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
        font-weight: normal;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        margin: 0;
      }

      .dropdown-item:hover {
        background: rgba(240, 240, 240);
      }

      .dropdown-item-selected {
        background: rgba(240, 240, 240);
        color: var(--color-ebatePrimary);
      }

      .not-results-found {
        padding: 0.75rem 0.75rem;
      }
    }
  }

  .dropdown-searchbox {
    width: 100%;
    background-color: var(--color-ebateControlBackground);
    border: 2px solid var(--color-ebateControlBackground);

    display: flex;
    justify-content: space-evenly;
    align-items: baseline;

    input {
      width: 95%;
      background-color: var(--color-ebateControlBackground);
      border: none;
      font-family: var(--font-primary);
      font-size: var(--font-size-md);
    }
    i {
      color: rgba(0, 0, 0, 0.6);
    }

    &:hover,
    &:focus {
      border: 2px solid var(--color-ebateControlBorderHover);
    }
  }

  .spinner {
    height: 5%;
    width: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`
