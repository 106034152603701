import styled from "styled-components"
import { device } from "theme/helpers"

const StyledHeader = styled.div`
  background-color: var(--color-background);
  color: var(--color-text);
  height: 62px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: var(--shadow-md);
  padding-left: var(--spacing-x2);
  padding-right: var(--spacing-x2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: var(--zindex-header);

  .header-logo {
    height: 100%;
    position: relative;
  }
  .left-links {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;

    .signout-link {
      margin-top: var(--spacing-base);
      display: flex;
      min-width: 0px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: var(--color-ebatePrimary);
      color: var(--color-white);
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-xl);
      font-family: var(--font-bold);

      @media ${device.mobileS} {
        font-size: var(--font-size-sm);
      }

      @media ${device.tablet} {
        font-size: var(--font-size-sm);
      }

      @media ${device.laptop} {
        font-size: var(--font-size-md);
      }

      @media ${device.laptopL} {
        font-size: var(--font-size-lg);
      }
    }

    .hide-on-tablet {
      @media ${device.mobileS} {
        display: none;
      }

      @media ${device.tablet} {
        display: none;
      }

      @media ${device.laptop} {
        display: flex;
      }

      @media ${device.laptopL} {
        display: flex;
      }
    }

    .nav-link {
      height: 100%;
      background: var(--color-background);
      position: relative;

      &:after {
        content: "";
        height: 0px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        transition: height 0.2s, background-color 0.2s;
      }

      &:hover,
      &.active {
        &:after {
          content: "";
          height: 5px;
          background-color: var(--color-ebateSecondary);
        }
      }
    }
  }
`

export default StyledHeader
