import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

// const percentageColumnTemplateBody = (rowData): string =>
//   `${rowData.currentRate}`

const GrowthValueperUnitColumn: ColumnModel[] = [
  {
    field: "id",
    header: "ID",
    sortable: true,
  },
  {
    field: "fromDate",
    header: "Start Date",
    sortable: true,
    body: dateStartTemplateBody,
  },
  {
    field: "transactionCurrencyDescription",
    header: "Currency",
    sortable: true,
  },
  {
    field: "statusDescription",
    header: "Status",
    sortable: true,
  },
  // Will uncomment this column after the rate value issur solved
  // {
  //   field: "currentRate",
  //   header: "Current Rate",
  //   sortable: true,
  //   body: percentageColumnTemplateBody,
  // },
]

export default GrowthValueperUnitColumn
