import styled from "styled-components"
import { MultiSelect } from "primereact/multiselect"

const StyledMultiSelect = styled(MultiSelect)`
  .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-base) 0;
  }

  .p-multiselect-label-container {
    max-height: 124px;
    overflow-y: auto;
  }
  .p-multiselect-label-container::-webkit-scrollbar {
    border-radius: 10px;
    width: 10px;
  }

  .p-multiselect-label-container::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
    border-radius: 10px;
  }
  .p-multiselect-label-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .p-multiselect-label-container::-webkit-scrollbar-button:increment,
  .p-multiselect-label-container::-webkit-scrollbar-button {
    display: none;
  }
  width: ${({ width }) => width || "100%"};
`

export default StyledMultiSelect
