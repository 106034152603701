import Permissions from "components/helpers/permissions"

const ReportingRoutes = [
  {
    location: "reportServerUrl",
    name: "reporting-report",
    redirectToExternalUrl: true,
    requiredPermissions: [Permissions.ReportingReportServer],
  },
]

export default ReportingRoutes
