import styled from "styled-components"

const Codeblock = styled.code`
  display: block;
  width: 100%;
  padding: var(--spacing-x2);
  background-color: var(--color-grey50);
  color: var(--color-white);
  white-space: pre;
`

export default Codeblock
