import { ValidationResultModel } from "components/models"
import {
  AddIncentiveApiModel,
  EditIncentiveApiModel,
} from "components/pages/pricemanagement/rebateIncentives/incentives/incentiveForm/incentiveForm.models"
import {
  IncentiveGridFilterRequest,
  IncentiveTabGridResponse,
} from "components/pages/pricemanagement/rebateIncentives/incentives/incentiveList/incentiveList.models"

import EbateAxios from "queries/ebateAxios"

const rebateIncentiveApiUrl = "/api/RebateIncentive"

export const getRebateGlobalIncentiveGridData = async (
  data: IncentiveGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateIncentiveApiUrl}/GetRebateGlobalIncentiveGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<IncentiveGridFilterRequest, IncentiveTabGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const getRebateIncentiveGridData = async (
  data: IncentiveGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${rebateIncentiveApiUrl}/GetRebateIncentiveGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<IncentiveGridFilterRequest, IncentiveTabGridResponse>(
    url,
    data,
    abortSignal
  )
}

export const insertRebateIncentives = async (data: AddIncentiveApiModel) => {
  const url = `${rebateIncentiveApiUrl}/InsertRebateIncentives`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AddIncentiveApiModel, ValidationResultModel>(url, data)
}

export const updateRebateIncentive = async (data: EditIncentiveApiModel) => {
  const url = `${rebateIncentiveApiUrl}/UpdateRebateIncentive`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<EditIncentiveApiModel, ValidationResultModel>(url, data)
}

export const deleteIncentive = async (id: number) => {
  const url = `${rebateIncentiveApiUrl}/DeleteIncentive/${id}`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(url, {})
}
