import { OptionModel } from "components/helpers"
import { ebateQueryKeys, getUserStructureValues, useEbateQuery } from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { UserStructureFilter } from "../userStructureSelect.models"

export const useUserStructure = (
  userStructureId: number,
  query: string = ""
) => {
  const onCompletedUserStructures = (data: UserStructureFilter) => {
    const userStructuresMapped = data.options?.map((o) => ({
      value: o.name,
      title: o.name,
    }))
    return [...userStructuresMapped]
  }
  const fetchUserStructure = async (): Promise<OptionModel[]> => {
    const response = await getUserStructureValues(userStructureId, query)
    if (response?.status === 200) {
      return onCompletedUserStructures(response.data)
    }
    return []
  }

  const userStructuresQuery = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_USER_STRUCTURES_VALUES,
      userStructureId.toString(),
      query,
    ],
    fetchUserStructure,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return userStructuresQuery
}
