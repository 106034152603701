const QUERY_KEY_ACCOUNT_TYPES = ["accountTypes"]
const QUERY_KEY_ACCOUNTING_PERIOD_BY_ID = ["getAccountingPeriod"]
const QUERY_KEY_ACCOUNTING_PERIOD_GRID_DATA = ["getAccountingPeriodGridData"]
const QUERY_KEY_ACCRUALS_GRID_DATA = ["getAccrualGridData"]
const QUERY_KEY_ACCRUAL_TYPES = ["accrualTypes"]
const QUERY_KEY_ACTIVE_REBATES_COUNT = ["getActiveRebatesCount"]
const QUERY_KEY_ADDITIONAL_INFO = ["additionalInfo"]
const QUERY_KEY_ADJUSTMENT_TYPES = ["getAdjustmentTypes"]
const QUERY_KEY_AGGREGATION_LEVELS = ["aggregationLevels"]
const QUERY_KEY_AGREEMENT_AGGREGATION = ["getAgreementAggregation"]
const QUERY_KEY_AGREEMENT_APPROVAL_GRID_DATA = [
  "getUserAgreementRequestGridData",
]
const QUERY_KEY_AGREEMENT_DETAIL_GET = ["getAgreementDetails"]
const QUERY_KEY_AGREEMENT_DOCUMENT_GRID_DATA = ["getAgreementFileGridData"]
const QUERY_KEY_AGREEMENTS_BY_CALCULATION_TYPE = ["agreementsByCalculationType"]
const QUERY_AGREEMENTS_BY_WORKFLOW_TYPE = ["agreementsByWorkflowType"]
const QUERY_KEY_AGREEMENTS_FOR_COPY_REBATE_BY_PACKAGE = [
  "getAgreementsForCopyRebateByPackage",
]
const QUERY_KEY_AGREEMENTS_OF_COMPANY = ["getAgreementsOfCompany"]
const QUERY_KEY_AGREEMENT_STATUS_VALUES = ["agreementStatus"]
const QUERY_KEY_AGREEMENT_TRADE_AGREEMENT_GRID_DATA = [
  "getTradeAgreementFileGridData",
]
const QUERY_KEY_ALL_FINANCIAL_ACCOUNTS = ["getAllFinancialAccounts"]
const QUERY_KEY_ALL_USER_STRUCTURE_VALUES = ["getAllUserStructureValues"]
const QUERY_KEY_ALL_USERS_COMBO = ["getAllUsersCombo"]
const QUERY_KEY_AMEND_APPROVAL_GRID_DATA = [
  "getUserAmendAgreementRequestGridData",
]
const QUERY_KEY_APPROVE_FOC_CLAIMS_GRID_DATA = ["getClaimsGridData"]
const QUERY_KEY_CALCULATION_ENGINE_STATUS = ["getCalculationEngineStatus"]
const QUERY_KEY_CALCULATION_TYPE = ["getCalculationType"]
const QUERY_KEY_CALCULATION_TYPE_GRID_DATA = ["getCalculationTypeGridData"]
const QUERY_KEY_CALCULATION_TYPES_COMBO_VALUES = ["calculationTypesComboValues"]
const QUERY_KEY_CALCULATION_TYPES_EXPORTABLES = ["calculationTypesExportables"]
const QUERY_KEY_CHANGES_ON_AGREEMENT = ["getChangesOnAgreement"]
const QUERY_KEY_CLAIM_PAYMENT_APPROVAL_GRID_DATA = [
  "getPaymentsClaimsRequestsGridData",
]
const QUERY_KEY_CLAIMS_PAYMENTS_STATUS_GRID_DATA = [
  "getClaimsPaymentsStatusGridData",
]
const QUERY_KEY_CLAIM_TYPES = ["claimTypes"]
const QUERY_KEY_COMMENTS_GET = ["getComments"]
const QUERY_KEY_COMMENTS_GLOBAL_GET = ["getGlobalComments"]
const QUERY_KEY_COMPANIES_COMBO_VALUE = ["getCompaniesComboValue"]
const QUERY_KEY_COMPANIES_DROPDOWN = ["companiesDropdown"]
const QUERY_KEY_COMPANIES_NO_TRADING = ["getCompaniesNotTrading"]
const QUERY_KEY_COMPANY_COMBO_VALUE_WITH_DYNAMIC_FIELDS = [
  "getCompanyComboValueWithDynamicFields",
]
const QUERY_KEY_COMPANY_GRID_DATA = ["getCompanyGridData"]
const QUERY_KEY_COMPANY_EMPTY_CUSTOMER = ["getEmptyCustomer"]
const QUERY_KEY_COMPANY_TYPES = ["companyTypes"]
const QUERY_KEY_COMPANY_TYPE_COMBO_VALUES_BY_USER = [
  "companyTypeComboValuesByUser",
]
const QUERY_KEY_COMPANY_STRUCTURE_VALUES = ["getCompanyUserStructureValues"]
const QUERY_KEY_COMPANY_WITH_DYNAMIC_FIELDS = ["getCompanyWithDynamicFields"]
const QUERY_KEY_CRITERIA_GET = ["getCriteria"]
const QUERY_KEY_CRITERIA_GLOBAL_GET = ["getGlobalCriteria"]
const QUERY_KEY_CROSS_REFERENCE_GRID_DATA = ["getCrossReferenceGrid"]
const QUERY_KEY_CROSS_REFERENCE_VALUES = ["getCrossReferenceValues"]
const QUERY_KEY_CURRENCIES = ["getCurrencies"]
const QUERY_KEY_CURRENCY_GRID_DATA = ["getCurrencyGridData"]
const QUERY_KEY_CURRENT_YEAR_REBATE_BY_MONTH = [
  "getCurrentYearRebateByMonthReport",
]
const QUERY_KEY_CURRENT_YEAR_REBATE_BY_MONTH_AND_COMPANY = [
  "getCurrentYearRebateByMonthAndCompanyReport",
]
const QUERY_KEY_CUSTOMER_INFO_GRID_DATA = [
  "getAdditionalCompanyClaimsAndPaymentsByFilters",
]
const QUERY_KEY_DASHBOARDS = ["getDashboards"]
const QUERY_KEY_DATA_TYPES = ["dataTypes"]
const QUERY_KEY_DEFAULT_CURRENCY = ["getDefaultCurrency"]
const QUERY_KEY_DOCUMENT_BY_ID = ["getDocumentById"]
const QUERY_KEY_DOCUMENT_CATEGORIES = ["Document Categories"]
const QUERY_KEY_DOCUMENT_GRID_DATA = ["getDocumentGridData"]
const QUERY_KEY_DOCUMENT_SIZE_LIMIT = ["documentSizeLimit"]
const QUERY_KEY_DOCUMENT_TYPES = ["documentTypes"]
const QUERY_KEY_DOCUMENT_TAGS = ["Document Tags"]
const QUERY_KEY_EXCHANGE_OBJECTS = ["getAllExchangeObject"]
const QUERY_KEY_EXTENSION_FIELD_VALUES = ["extensionFieldValues"]
const QUERY_KEY_IMPORT_NAMES = ["importNames"]
const QUERY_KEY_INCENTIVE_GRID_DATA = ["getIncentiveGridData"]
const QUERY_KEY_LINKED_ACCOUNT_GRID_DATA = ["getLinkedAccountsGridData"]
const QUERY_KEY_IMPORT_STATUS = ["getEbateImportStatus"]
const QUERY_KEY_NEWS_FEED_BY_COMPANY_LIST = ["getNewsFeedsByCompanyId"]
const QUERY_KEY_NEWS_FEED_GRID_DATA = ["getNewsFeedGridData"]
const QUERY_KEY_PAY_TO_TYPES = ["payToTypes"]
const QUERY_KEY_PACKAGE_DETAILS = ["packageDetails"]
const QUERY_KEY_PACKAGE_DOCUMENT_GRID_DATA = ["getPackageFileGridData"]
const QUERY_KEY_PACKAGE_GRID_DATA = ["getPackageGridData"]
const QUERY_KEY_PACKAGE_STATUS_VALUES = ["packageStatus"]
const QUERY_KEY_PACKAGES_BY_COMPANY_SELECTION = [
  "getPackagesByCompanySelection",
]
const QUERY_KEY_PAYMENT_ADJUSTMENT_REASONS = ["paymentAdjustmentReasons"]
const QUERY_KEY_PAYMENT_ADJUSTMENT_TYPES = ["getPaymentAdjustmentTypes"]
const QUERY_KEY_PAYMENT_STATUS = ["getPaymentStatus"]
const QUERY_KEY_PAYMENT_USER_STRUCTURES_FILTER = [
  "getPaymentUserStructureOptions",
]
const QUERY_KEY_PERIODS = ["periods"]
const QUERY_KEY_PORTAL_IMPORT_STATUS = ["getPortalImportStatus"]
const QUERY_KEY_PORTAL_ROLE_BY_ID = ["getPortalRoleDetails"]
const QUERY_KEY_PORTAL_ROLE_CLAIMS = ["getPortalRoleClaims"]
const QUERY_KEY_PORTAL_ROLES_GRID_DATA = ["getPortalRolesGridData"]
const QUERY_KEY_PORTAL_USER_BY_ID = ["getPortalUserById"]
const QUERY_KEY_PORTAL_USER_GRID_DATA = ["getPortalUsersGridData"]
const QUERY_KEY_PRICE_TYPES = ["priceTypes"]
const QUERY_KEY_PRODUCT_CODES_BY_CALCULATION_TYPE = [
  "productCodesByCalculationType",
]
const QUERY_KEY_PRODUCT_GRID_DATA = ["getProductGridData"]
const QUERY_KEY_PRODUCT_EMPTY = ["getEmptyProduct"]
const QUERY_KEY_PRODUCT_WITH_DYNAMIC_FIELDS = ["getProductWithDynamicFields"]
const QUERY_KEY_PROJECT_GRID_DATA = ["getProjectGridData"]
const QUERY_KEY_PROJECT_EMPTY = ["getEmptyProject"]
const QUERY_KEY_PROJECT_WITH_DYNAMIC_FIELDS = ["getProjectWithDynamicFields"]
const QUERY_KEY_RATE_APPROVAL_GRID_DATA = ["getRequestsRebateRateGridData"]
const QUERY_KEY_RATES_BY_CALCULATION_TYPE = ["ratesByCalculationType"]
const QUERY_KEY_REBATE_ACCRUAL_YTD = ["getRebateAccrualYTD"]
const QUERY_KEY_REBATE_ALLOCATION = ["getRebateAllocations"]
const QUERY_KEY_REBATE_ALLOCATION_SUM_PERCENTAGE = [
  "getRebateAllocationSumPercentage",
]
const QUERY_KEY_REBATE_CALCULATION_INFO = ["getRebateCalculationInfo"]
const QUERY_KEY_REBATE_CATEGORIES = ["rebateCategories"]
const QUERY_KEY_REBATE_CONDITIONS = ["getRebateConditions"]
const QUERY_KEY_REBATE_CONDITIONS_MUST_BE_MET = [
  "getAllConditionsMustBeMetValue",
]
const QUERY_KEY_REBATE_CONDITION_TYPES = ["getRebateConditionTypes"]
const QUERY_KEY_REBATE_DETAILS = ["rebateDetails"]
const QUERY_KEY_REBATE_DOCUMENT_GRID_DATA = ["getRebateFileGridData"]
const QUERY_KEY_REBATE_GRID_DATA = ["getRebateGridData"]
const QUERY_KEY_REBATE_INCENTIVE_DETAILS = ["rebateIncentiveDetails"]
const QUERY_KEY_REBATE_INCENTIVE_GRID_DATA = ["getRebateIncentiveGridData"]
const QUERY_KEY_REBATE_PAY_TO_COMPANIES = ["rebatePayToCompanies"]
const QUERY_KEY_REBATE_PAYMENT_GRID_DATA = ["getRebatePayments"]
const QUERY_KEY_REBATE_PAYMENTS_SUMMARY = ["getPaymentsSummaryByRebate"]
const QUERY_KEY_REBATES_BY_CALCULATION_TYPE = ["rebatesByCalculationType"]
const QUERY_KEY_REBATE_RATE_GRID_DATA = ["getRatesGridData"]
const QUERY_KEY_REBATE_RATES_BY_REQUEST = ["getRebateRatesByRequest"]
const QUERY_KEY_REBATE_VS_BUDGET_PERCENTAGE = ["getRebateVSBudgetPercentage"]
const QUERY_KEY_REBATE_VS_TARGET_PERCENTAGE = ["getRebateVSTargetPercentage"]
const QUERY_KEY_REFRESH_TOKEN = ["refreshToken"]
const QUERY_KEY_REPORT_TYPES_GET = ["getReportTypes"]
const QUERY_KEY_REQUEST_STATUSES = ["requestStatuses"]
const QUERY_KEY_ROLE_BY_ID = ["getRoleDetails"]
const QUERY_KEY_ROLE_CLAIMS = ["getRoleClaims"]
const QUERY_KEY_ROLES = ["getNormalRoles"]
const QUERY_KEY_ROLES_GRID_DATA = ["getRolesGridData"]
const QUERY_KEY_ROLES_AND_USERS = ["getRolesAndUsers"]
const QUERY_KEY_ROLLOVER_STATUS = ["getRollOverStatus"]
const QUERY_KEY_SEASONALITIES_BY_PACKAGE = ["seasonalitiesByPackage"]
const QUERY_KEY_TENANT = ["getTenant"]
const QUERY_KEY_TENANT_CONFIGURATION = ["getTenantConfiguration"]
const QUERY_KEY_TENANT_CURRENCIES = ["tenantCurrenciesGet"]
const QUERY_KEY_TRADE_AGREEMENT_GRID_DATA = [
  "getTradeAgreementByCompanyGridData",
]
const QUERY_KEY_TRADING_GROUP_BY_ID = ["getTradingGroupById"]
const QUERY_KEY_TRADING_GROUP_GRID_DATA = ["getTradingGroupGridData"]
const QUERY_KEY_TRANSACTION_TYPES = ["getTransactionTypes"]
const QUERY_KEY_UNIT_OF_MEASUREMENTS = ["unitOfMeasurements"]
const QUERY_KEY_USER_BY_ID = ["getUserById"]
const QUERY_KEY_USER_GRID_DATA = ["getNormalUsersGridData"]
const QUERY_KEY_USER_PERMISSION_OPTIONS = ["getUserPermissionOptions"]
const QUERY_KEY_USER_STRUCTURES_VALUES = ["userStructuresValues"]
const QUERY_KEY_WHOLESALERDISCOUNT_BY_ID = ["getWholesalerDiscount"]
const QUERY_KEY_WHOLESALER_DISCOUNT_GRID_DATA = [
  "getWholeSalerDiscountGridData",
]
const QUERY_KEY_WORKFLOW_BY_ID = ["getWorkflowById"]
const QUERY_KEY_WORKFLOW_BY_LEVEL_ID = ["getWorkflowLevelById"]
const QUERY_KEY_WORKFLOW_TYPES = ["getWorkflowTypes"]
const QUERY_KEY_WORKFLOW_TYPES_FOR_AUDIT = ["workflowTypesForAudit"]

export default {
  QUERY_KEY_ACCOUNT_TYPES,
  QUERY_KEY_ACCOUNTING_PERIOD_BY_ID,
  QUERY_KEY_ACCOUNTING_PERIOD_GRID_DATA,
  QUERY_KEY_ACCRUALS_GRID_DATA,
  QUERY_KEY_ACCRUAL_TYPES,
  QUERY_KEY_ACTIVE_REBATES_COUNT,
  QUERY_KEY_ADDITIONAL_INFO,
  QUERY_KEY_ADJUSTMENT_TYPES,
  QUERY_KEY_AGREEMENT_APPROVAL_GRID_DATA,
  QUERY_KEY_AGREEMENT_DETAIL_GET,
  QUERY_KEY_AGREEMENT_DOCUMENT_GRID_DATA,
  QUERY_KEY_AGREEMENTS_BY_CALCULATION_TYPE,
  QUERY_KEY_AGREEMENTS_FOR_COPY_REBATE_BY_PACKAGE,
  QUERY_KEY_AGREEMENTS_OF_COMPANY,
  QUERY_KEY_AGGREGATION_LEVELS,
  QUERY_KEY_AGREEMENT_AGGREGATION,
  QUERY_AGREEMENTS_BY_WORKFLOW_TYPE,
  QUERY_KEY_AGREEMENT_STATUS_VALUES,
  QUERY_KEY_AGREEMENT_TRADE_AGREEMENT_GRID_DATA,
  QUERY_KEY_ALL_FINANCIAL_ACCOUNTS,
  QUERY_KEY_ALL_USER_STRUCTURE_VALUES,
  QUERY_KEY_ALL_USERS_COMBO,
  QUERY_KEY_AMEND_APPROVAL_GRID_DATA,
  QUERY_KEY_APPROVE_FOC_CLAIMS_GRID_DATA,
  QUERY_KEY_CALCULATION_ENGINE_STATUS,
  QUERY_KEY_CALCULATION_TYPE,
  QUERY_KEY_CALCULATION_TYPE_GRID_DATA,
  QUERY_KEY_CALCULATION_TYPES_COMBO_VALUES,
  QUERY_KEY_CALCULATION_TYPES_EXPORTABLES,
  QUERY_KEY_CHANGES_ON_AGREEMENT,
  QUERY_KEY_CLAIM_PAYMENT_APPROVAL_GRID_DATA,
  QUERY_KEY_CLAIMS_PAYMENTS_STATUS_GRID_DATA,
  QUERY_KEY_CLAIM_TYPES,
  QUERY_KEY_COMMENTS_GET,
  QUERY_KEY_COMMENTS_GLOBAL_GET,
  QUERY_KEY_COMPANIES_COMBO_VALUE,
  QUERY_KEY_COMPANIES_DROPDOWN,
  QUERY_KEY_COMPANIES_NO_TRADING,
  QUERY_KEY_COMPANY_COMBO_VALUE_WITH_DYNAMIC_FIELDS,
  QUERY_KEY_COMPANY_GRID_DATA,
  QUERY_KEY_COMPANY_EMPTY_CUSTOMER,
  QUERY_KEY_COMPANY_TYPES,
  QUERY_KEY_COMPANY_TYPE_COMBO_VALUES_BY_USER,
  QUERY_KEY_COMPANY_STRUCTURE_VALUES,
  QUERY_KEY_COMPANY_WITH_DYNAMIC_FIELDS,
  QUERY_KEY_CRITERIA_GET,
  QUERY_KEY_CRITERIA_GLOBAL_GET,
  QUERY_KEY_CROSS_REFERENCE_GRID_DATA,
  QUERY_KEY_CROSS_REFERENCE_VALUES,
  QUERY_KEY_CURRENCIES,
  QUERY_KEY_CURRENCY_GRID_DATA,
  QUERY_KEY_CURRENT_YEAR_REBATE_BY_MONTH,
  QUERY_KEY_CURRENT_YEAR_REBATE_BY_MONTH_AND_COMPANY,
  QUERY_KEY_CUSTOMER_INFO_GRID_DATA,
  QUERY_KEY_DASHBOARDS,
  QUERY_KEY_DATA_TYPES,
  QUERY_KEY_DEFAULT_CURRENCY,
  QUERY_KEY_DOCUMENT_BY_ID,
  QUERY_KEY_DOCUMENT_CATEGORIES,
  QUERY_KEY_DOCUMENT_GRID_DATA,
  QUERY_KEY_DOCUMENT_SIZE_LIMIT,
  QUERY_KEY_DOCUMENT_TYPES,
  QUERY_KEY_DOCUMENT_TAGS,
  QUERY_KEY_EXCHANGE_OBJECTS,
  QUERY_KEY_EXTENSION_FIELD_VALUES,
  QUERY_KEY_IMPORT_NAMES,
  QUERY_KEY_IMPORT_STATUS,
  QUERY_KEY_INCENTIVE_GRID_DATA,
  QUERY_KEY_LINKED_ACCOUNT_GRID_DATA,
  QUERY_KEY_NEWS_FEED_BY_COMPANY_LIST,
  QUERY_KEY_NEWS_FEED_GRID_DATA,
  QUERY_KEY_PACKAGE_DETAILS,
  QUERY_KEY_PACKAGE_DOCUMENT_GRID_DATA,
  QUERY_KEY_PACKAGE_GRID_DATA,
  QUERY_KEY_PACKAGE_STATUS_VALUES,
  QUERY_KEY_PACKAGES_BY_COMPANY_SELECTION,
  QUERY_KEY_PAY_TO_TYPES,
  QUERY_KEY_PAYMENT_ADJUSTMENT_REASONS,
  QUERY_KEY_PAYMENT_ADJUSTMENT_TYPES,
  QUERY_KEY_PAYMENT_STATUS,
  QUERY_KEY_PAYMENT_USER_STRUCTURES_FILTER,
  QUERY_KEY_PERIODS,
  QUERY_KEY_PORTAL_IMPORT_STATUS,
  QUERY_KEY_PORTAL_ROLE_BY_ID,
  QUERY_KEY_PORTAL_ROLE_CLAIMS,
  QUERY_KEY_PORTAL_ROLES_GRID_DATA,
  QUERY_KEY_PORTAL_USER_BY_ID,
  QUERY_KEY_PORTAL_USER_GRID_DATA,
  QUERY_KEY_PRICE_TYPES,
  QUERY_KEY_PRODUCT_CODES_BY_CALCULATION_TYPE,
  QUERY_KEY_PRODUCT_GRID_DATA,
  QUERY_KEY_PRODUCT_EMPTY,
  QUERY_KEY_PRODUCT_WITH_DYNAMIC_FIELDS,
  QUERY_KEY_PROJECT_GRID_DATA,
  QUERY_KEY_PROJECT_EMPTY,
  QUERY_KEY_PROJECT_WITH_DYNAMIC_FIELDS,
  QUERY_KEY_RATE_APPROVAL_GRID_DATA,
  QUERY_KEY_RATES_BY_CALCULATION_TYPE,
  QUERY_KEY_REBATE_ACCRUAL_YTD,
  QUERY_KEY_REBATE_ALLOCATION,
  QUERY_KEY_REBATE_ALLOCATION_SUM_PERCENTAGE,
  QUERY_KEY_REBATE_CALCULATION_INFO,
  QUERY_KEY_REBATE_CATEGORIES,
  QUERY_KEY_REBATE_CONDITIONS,
  QUERY_KEY_REBATE_CONDITIONS_MUST_BE_MET,
  QUERY_KEY_REBATE_CONDITION_TYPES,
  QUERY_KEY_REBATE_DETAILS,
  QUERY_KEY_REBATE_DOCUMENT_GRID_DATA,
  QUERY_KEY_REBATE_GRID_DATA,
  QUERY_KEY_REBATE_INCENTIVE_DETAILS,
  QUERY_KEY_REBATE_INCENTIVE_GRID_DATA,
  QUERY_KEY_REBATE_PAY_TO_COMPANIES,
  QUERY_KEY_REBATE_PAYMENT_GRID_DATA,
  QUERY_KEY_REBATE_PAYMENTS_SUMMARY,
  QUERY_KEY_REBATE_RATE_GRID_DATA,
  QUERY_KEY_REBATE_RATES_BY_REQUEST,
  QUERY_KEY_REBATES_BY_CALCULATION_TYPE,
  QUERY_KEY_REBATE_VS_BUDGET_PERCENTAGE,
  QUERY_KEY_REBATE_VS_TARGET_PERCENTAGE,
  QUERY_KEY_REFRESH_TOKEN,
  QUERY_KEY_REPORT_TYPES_GET,
  QUERY_KEY_REQUEST_STATUSES,
  QUERY_KEY_ROLE_BY_ID,
  QUERY_KEY_ROLE_CLAIMS,
  QUERY_KEY_ROLES,
  QUERY_KEY_ROLES_GRID_DATA,
  QUERY_KEY_ROLES_AND_USERS,
  QUERY_KEY_ROLLOVER_STATUS,
  QUERY_KEY_SEASONALITIES_BY_PACKAGE,
  QUERY_KEY_TENANT,
  QUERY_KEY_TENANT_CONFIGURATION,
  QUERY_KEY_TENANT_CURRENCIES,
  QUERY_KEY_TRADE_AGREEMENT_GRID_DATA,
  QUERY_KEY_TRADING_GROUP_BY_ID,
  QUERY_KEY_TRADING_GROUP_GRID_DATA,
  QUERY_KEY_TRANSACTION_TYPES,
  QUERY_KEY_UNIT_OF_MEASUREMENTS,
  QUERY_KEY_USER_BY_ID,
  QUERY_KEY_USER_GRID_DATA,
  QUERY_KEY_USER_PERMISSION_OPTIONS,
  QUERY_KEY_USER_STRUCTURES_VALUES,
  QUERY_KEY_WHOLESALERDISCOUNT_BY_ID,
  QUERY_KEY_WHOLESALER_DISCOUNT_GRID_DATA,
  QUERY_KEY_WORKFLOW_BY_ID,
  QUERY_KEY_WORKFLOW_BY_LEVEL_ID,
  QUERY_KEY_WORKFLOW_TYPES,
  QUERY_KEY_WORKFLOW_TYPES_FOR_AUDIT,
}
