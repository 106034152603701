import {
  RebateRateOverviewGridItem,
  RebateRateOverviewGridResponse,
} from "./approvalRateList.models"

export const mapRateApprovalFromApiToVm = (
  response: RebateRateOverviewGridResponse
): RebateRateOverviewGridItem => ({
  rebateId: response.rebateId,
  company: response.company,
  date: response.creatingDate,
  id: response.requestId,
  rebateDescription: response.rebateDescription,
  status: response.requestStatusDescription,
  requestStatus: response.requestStatus,
  value: response.impactWithCurrency,
  workflowLevelSubRequestId: response.workflowLevelSubRequestId,
})

export const mapFrontFieldToBackField = (frontField: string): string => {
  switch (frontField) {
    case "value":
      return "impact"
    case "date":
      return "creatingDate"
    case "status":
      return "requestStatus"
    default:
      return frontField
  }
}

export const mapBackFieldToFrontField = (backField: string): string => {
  switch (backField) {
    case "impact":
      return "value"
    case "creatingDate":
      return "date"
    case "requestStatus":
      return "status"
    default:
      return backField
  }
}
