import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import { OptionApi } from "components/helpers"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getCurrencyGridData,
  getDefaultCurrency,
  getTenantCurrencies,
} from "queries"

export const useTenantCurrencies = () => {
  const onCompleted = (data: any) => {
    const tenantCurrenciesMapped = data.map(
      (d) => ({ id: d.id, name: d.currencyName } as OptionApi)
    )
    return [...tenantCurrenciesMapped]
  }

  const fetchTenantCurrencies = async () => {
    const response = await getTenantCurrencies()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const tenantCurrencies = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_TENANT_CURRENCIES],
    fetchTenantCurrencies,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return tenantCurrencies
}
export const useDefaultCurrency = () => {
  const fetch = async () => {
    const response = await getDefaultCurrency()

    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const defaultCurrency = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DEFAULT_CURRENCY],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return defaultCurrency
}

export const useCurrencyGridData = () => {
  const fetch = async () => {
    const response = await getCurrencyGridData()

    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const defaultCurrency = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CURRENCY_GRID_DATA],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return defaultCurrency
}
