import { GenericApiResponseModel } from "components/models"
import {
  ExportRebateRatesFilterRequest,
  ExportRebateRatesResponse,
} from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"
import EbateAxios from "queries/ebateAxios"

const exportApiUrl = "/api/exportRate"

export const getRebateRatesCount = async (
  data: ExportRebateRatesFilterRequest
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${exportApiUrl}/GetRebateRatesCount`
  return AxiosPost<
    ExportRebateRatesFilterRequest,
    GenericApiResponseModel<ExportRebateRatesResponse>
  >(url, data)
}

export const getRebateRatesCsvFileByFilters = async (
  data: ExportRebateRatesFilterRequest
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${exportApiUrl}/GetRebateRatesCsvFileByFilters`
  return AxiosPost<ExportRebateRatesFilterRequest, Blob>(url, data)
}

export const getRebateRatesImportFileTemplate = async (
  calculationTypeId: number
) => {
  const { AxiosGet } = EbateAxios()

  const url = `${exportApiUrl}/getRebateRatesImportFileTemplate/${calculationTypeId}`
  return AxiosGet<Blob>(url)
}
