import { OptionApi, OptionModel } from "components/helpers"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getAgreementsInWorkflowAuditTrails,
  getWorkflowTypesForAudit,
} from "queries/maintenance/workflowAudit.query"

export const useWorkflowTypeForAudit = () => {
  const mapApiData = (data: any): OptionModel[] =>
    data &&
    data.data.map((d: OptionApi) => ({
      title: d.name,
      value: d.id,
    }))

  const workflowTypes = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_WORKFLOW_TYPES_FOR_AUDIT,
    getWorkflowTypesForAudit,
    {
      select: (data) => mapApiData(data),
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { workflowTypes }
}

export const useAgreementsByWorkflowType = (workflowType: number) => {
  const fetchAgreementsInWorkflowAuditTrails = async (): Promise<
    OptionModel[]
  > => {
    const response = await getAgreementsInWorkflowAuditTrails(workflowType)
    if (response?.status === 200) {
      return response.data?.map((d) => ({
        value: d.id,
        title: `${d.id} - ${d.name}`,
      }))
    }
    return []
  }

  const agreementsByWorkflowType = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_AGREEMENTS_BY_WORKFLOW_TYPE,
      workflowType?.toString(),
    ],
    fetchAgreementsInWorkflowAuditTrails,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
      enabled: !!workflowType,
    }
  )

  return { agreementsByWorkflowType }
}
