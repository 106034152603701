import React from "react"
import { DropdownChangeParams, DropdownFilterParams } from "primereact/dropdown"
import { StyledDropdown } from "./styled"
import { FormInput } from "../formInput"
import { FlexContainer } from "../containers"
import { LoadingComponent } from "../loading"

interface Props {
  heading?: string
  label?: string
  transparent?: boolean
  value?: any
  name?: string
  options?: any[]
  onChange?: (e: DropdownChangeParams) => void
  placeholder?: string
  width?: string
  canSearch?: boolean
  border?: string
  required?: boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  componenttype?: number
  showClear?: boolean
  id?: string
  disabled?: boolean
  hidden?: boolean
  onFilter?: (e: DropdownFilterParams) => void
  noResetFilterOnHide?: boolean
  onHide?: () => void
  onShow?: () => void
  filterPlaceholder?: string
  showLoadingTemplate?: boolean
}
const Dropdown: React.FC<Props> = ({ ...props }) => {
  const loadingTemplate = () => (
    <FlexContainer style={{ cursor: "wait" }} justify="center">
      <LoadingComponent height="24px" />
    </FlexContainer>
  )
  return (
    <FormInput
      heading={props.heading}
      width={props.width}
      border={props.border}
      required={props.required}
    >
      <StyledDropdown
        $transparent={props.transparent}
        width={props.width}
        value={props.value}
        name={props.name}
        options={props.options}
        onChange={props.onChange}
        onFilter={props.onFilter}
        placeholder={props.placeholder}
        optionLabel="title"
        optionValue="value"
        filter={props.canSearch && props.options && props.options.length > 0}
        showClear={
          (props.showClear || props.canSearch) &&
          props.options &&
          props.options.length > 0
        }
        filterBy="title"
        dropdownIcon="pi pi-chevron-down"
        onBlur={props.onBlur}
        id={props.id ?? props.name}
        disabled={props.disabled}
        resetFilterOnHide={!props.noResetFilterOnHide}
        onHide={props.onHide}
        onShow={props.onShow}
        data-testid={props.name}
        filterPlaceholder={props.filterPlaceholder}
        itemTemplate={props.showLoadingTemplate ? loadingTemplate : null}
      />
    </FormInput>
  )
}

export default Dropdown
