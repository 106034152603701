import React from "react"
import { createPortal } from "react-dom"
import { StyledBar } from "./styled"

const Bar = () => {
  const loadRoot = document.getElementById("loading-bar-root")
  return createPortal(
    <StyledBar>
      <div id="bar-segment-1" />
      <div id="bar-segment-2" />
      <div id="bar-segment-3" />
      <div id="bar-segment-4" />
    </StyledBar>,
    loadRoot
  )
}

export default Bar
