import React from "react"
import { StyledChip } from "./styled"

interface Props {
  id?: string
  text
  icon?
  className?
  removable?: boolean
  onRemove?: (event: any) => void
  props?
}

const Chip: React.FC<Props> = ({ text, icon, className, ...props }) => (
  <StyledChip
    className={className}
    label={text}
    icon={icon}
    data-testid={props.id}
    {...props}
  />
)

export default Chip
