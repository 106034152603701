import React from "react"
import permissionRoute from "routes/permissionRoute"
import Permissions from "components/helpers/permissions"

const PortalMaintenanceRoutes = [
  {
    location: "/maintenance/NewsFeed",
    name: "portal-maintenance-news-feed-maintenance",
    requiredPermissions: [Permissions.CollaborationNewsFeed],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/NewsFeed")),
      Permissions.CollaborationNewsFeed
    ),
  },
  {
    location: "/maintenance/importTransactions",
    name: "portal-maintenance-import-transactions",
    requiredPermissions: [Permissions.CollaborationImportTransactions],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/ImportTransactions")),
      Permissions.CollaborationImportTransactions
    ),
  },
  {
    location: "/maintenance/portalUserManagement",
    name: "portal-maintenance-user-management",
    requiredPermissions: [Permissions.CollaboratationPortalUserManagment],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/PortalUserManagement")),
      Permissions.CollaboratationPortalUserManagment
    ),
  },
]

export default PortalMaintenanceRoutes
