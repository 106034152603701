import React, { useContext, useEffect, useState } from "react"
import {
  FlexContainer,
  Button,
  DatePicker,
  Dropdown,
  Cbx,
  Switch,
  MultiSelect,
  InputText,
  SecondaryButton,
} from "components/library"
import { useTranslation } from "react-i18next"
import { useForm } from "components/hooks"
import { ComponentTypes, OptionModel } from "components/helpers"
import { ModalContext } from "stores"
import { StyledFilterDialog } from "./styled"

interface FieldRow {
  heading?: string
  name: string
  type: number
  options?: OptionModel[]
  textRightSwitch?: string
  textLeftSwitch?: string
  searchable?: boolean
  clearable?: boolean
  disabled?: boolean
}

export interface FormRow {
  id: number
  fields: FieldRow[]
}

interface Props {
  onApply: (values: any) => void
  formRows: any[]
  filtersInitialValues: any
  clearValues: any
  name: string
}
const FilterDialog: React.FC<Props> = (props) => {
  const { onApply, formRows, filtersInitialValues, name, clearValues } = props
  const { t } = useTranslation(["translation"])
  const [initialised, setInitialised] = useState(false)
  const { handleModal } = useContext(ModalContext)

  const formState = useForm(filtersInitialValues)
  const applyFilters = () => {
    localStorage.setItem(name, JSON.stringify(formState.formValues))
    onApply(formState.formValues)
  }

  const clearFilters = () => {
    localStorage.removeItem(name)
    formState.reset(filtersInitialValues)
    onApply(clearValues)
  }

  useEffect(() => {
    if (initialised) {
      return
    }

    const filterString = localStorage.getItem(name)

    if (filterString) {
      formState.reset(JSON.parse(filterString))
    }

    setInitialised(true)
  }, [])

  const renderCbx = (ff: FieldRow) => (
    <div key={ff.name} className="p-col">
      <Cbx
        heading={ff.heading}
        name={ff.name}
        checked={formState.formValues[ff.name].value}
        onChange={(e) => formState.updateCheck(e)}
      />
    </div>
  )
  const renderDropdown = (ff: FieldRow) => (
    <div key={ff.name} className="p-col">
      <Dropdown
        heading={ff.heading}
        name={ff.name}
        value={formState.formValues[ff.name].value}
        options={ff.options}
        onChange={(e) => formState.updateInt(e)}
        canSearch={ff.searchable}
        showClear={ff.clearable}
        disabled={ff.disabled}
      />
    </div>
  )
  const renderMultiselect = (ff: FieldRow) => (
    <div key={ff.name} className="p-col">
      <MultiSelect heading={ff.heading} name={ff.name} />
    </div>
  )
  const renderDatePicker = (ff) => (
    <div key={ff.name} className="p-col">
      <DatePicker
        heading={ff.heading}
        name={ff.name}
        value={formState.formValues[ff.name].value}
        onChange={(e) => {
          formState.updateDate(e)
        }}
      />
    </div>
  )

  const renderSwitch = (ff: FieldRow) => (
    <div key={ff.name} className="p-col">
      <Switch
        heading={ff.heading}
        checked={formState.formValues[ff.name].value}
        name={ff.name}
        optionRight={ff.textRightSwitch}
        optionLeft={ff.textLeftSwitch}
        onChange={(e) => {
          formState.updateValue(e)
        }}
      />
    </div>
  )

  const renderInputText = (ff: FieldRow) => (
    <div key={ff.name} className="p-col">
      <InputText
        heading={ff.heading}
        value={formState.formValues[ff.name].value}
        name={ff.name}
        onChange={(e) => {
          formState.updateValue(e)
        }}
        width="100%"
      />
    </div>
  )

  return (
    <StyledFilterDialog>
      {formRows.map((rw: FormRow) => (
        <div key={rw.id} className="p-grid">
          {rw.fields.map((ff) => {
            switch (ff.type) {
              case ComponentTypes.InputText:
                return renderInputText(ff)

              case ComponentTypes.Checkbox:
                return renderCbx(ff)

              case ComponentTypes.Dropdown:
                return renderDropdown(ff)

              case ComponentTypes.MultiSelect:
                return renderMultiselect(ff)

              case ComponentTypes.DatePicker:
                return renderDatePicker(ff)

              case ComponentTypes.Switch:
                return renderSwitch(ff)

              default:
                return null
            }
          })}
        </div>
      ))}
      <FlexContainer className="filter-footer" justify="flex-end">
        <SecondaryButton
          onClick={() => clearFilters()}
          text={t("popup-clear")}
          icon="pi pi-filter-slash"
        />
        <FlexContainer justify="flex-end">
          <Button
            square
            bgcolor="var(--color-ebateSecondary)"
            color="var(--color-white)"
            onClick={() => applyFilters()}
            width="100px"
          >
            {t("popup-apply")}
          </Button>
          <Button
            square
            bgcolor="var(--color-grey50)"
            color="var(--color-white)"
            onClick={() => handleModal()}
            width="100px"
          >
            {t("popup-cancel")}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </StyledFilterDialog>
  )
}

export default FilterDialog
