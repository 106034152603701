const sum = (arr, key) => arr.reduce((a, b) => a + (b[key] || 0), 0)

const sortFunc = (a, b, field, asc = true) => {
  const aField = a[field]
  const bField = b[field]
  if (typeof aField === "string") {
    const sAField = aField.toUpperCase()
    const sBField = bField.toUpperCase()
    if (sAField < sBField) {
      return asc ? -1 : 1
    }
    if (sAField > sBField) {
      return asc ? 1 : -1
    }

    return 0
  }

  if (typeof aField === "number") {
    return asc ? aField - bField : bField - aField
  }

  if (typeof aField?.getMonth === "function") {
    const dAField = Date.parse(aField)
    const dBField = Date.parse(bField)
    if (dAField < dBField) return asc ? -1 : 1

    return 0
  }

  return 0
}
const groupBy = (arr, key) => {
  const initialValue = {}
  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key]
    acc[myAttribute] = [...(acc[myAttribute] || []), cval]
    return acc
  }, initialValue)
}

export default { sum, sortFunc, groupBy }
