import styled from "styled-components"

export const FileUploadComponent = styled.section`
  border: 2px solid var(--color-ebateControlBackground);
  padding: var(--spacing-x2);
  border-radius: var(--border-radius-sm);
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-ebateControlBackground);

  .p-2rem {
    font-size: var(--font-size-header);
  }

  .p-file-icon {
    width: 50px;
  }

  .p-file-name {
    width: 50%;
  }

  .p-file-size {
    text-align: right;
  }

  .p-file-remove-button {
    width: 50px;
  }

  .p-fileupload-files {
    padding: var(--spacing-half);
  }
`

export const FileUploadContainer = styled.section`
  position: relative;
  margin: var(--spacing-x3) 0 var(--spacing-x2);
  border: 2px dotted var(--color-ebatePrimary);
  padding: var(--spacing-x3);
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DragDropText = styled.p`
  font-weight: bold;
  margin-top: 0;
  text-align: center;
`
