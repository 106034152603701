import React from "react"
import InputText from "../inputText"
import { StyledSearchTextbox } from "./styled"

interface Props {
  id?: string
  name?: string
  value?: string
  onChange?: (e: any) => void
  onKeyUp?: (e: any) => void
  placeholder?: string
  caption?: string
}
export const SearchTextBox: React.FC<Props> = ({
  id,
  name,
  value,
  onChange,
  onKeyUp,
  placeholder,
  caption,
}) => (
  <StyledSearchTextbox>
    <div className="filterText">
      <div className="input-icon">
        <InputText
          transparent
          icon="pi pi-search"
          className="input-search-box"
          id={id}
          name={name ?? "quickSearchBox"}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          flat
        />
      </div>
    </div>
    {caption && (
      <div className="caption">
        <span>{caption}</span>
      </div>
    )}
  </StyledSearchTextbox>
)
