import { MultiSelect, ValidationMessage } from "components/library"
import React, { useState } from "react"
import { OptionModel } from "components/helpers"
import { useCompanies } from "components/hooks"
import { useTranslation } from "react-i18next"

interface Props {
  companiesSelected: any[]
  companiesOnChange: (e: any) => void
  errorMessage: string
  displayValidations: boolean
  fieldName: string
  heading: string
  required?: boolean
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CompanyMultiSelect: React.FC<Props> = ({
  companiesSelected,
  companiesOnChange,
  errorMessage,
  displayValidations,
  fieldName,
  heading,
  required,
  setLoading,
}) => {
  const { t } = useTranslation(["translation"])

  const [query, setQuery] = useState<string | undefined>("")
  const { companies } = useCompanies(query)

  const [companyList, setCompanyList] = useState<OptionModel[]>([])

  const [companiesSelectedInfo, setCompaniesSelectedInfo] = useState<
    OptionModel[]
  >([])

  const [searchTimeout, setSearchTimeout] = useState<any>()

  const filterByText = (textToFilter: string) => {
    setQuery(textToFilter)
  }

  const textChanged = (event: any) => {
    clearTimeout(searchTimeout)
    setSearchTimeout(
      setTimeout(() => {
        filterByText(event.filter)
      }, 500)
    )
  }
  React.useEffect(() => {
    if (companies && companies.length > 0) {
      const companiesSelectedButNotInList = companiesSelectedInfo
        .filter((ps) => !companies.map((c) => c.value).includes(ps.value))
        ?.map((ps) => ({
          value: ps.value,
          title: ps.title,
          hidden: true,
        }))

      const productNew = companies.concat(companiesSelectedButNotInList)

      setCompanyList(productNew)
    }
  }, [companies])

  const getSelectedCompaniesValue = (ids: string[]) => {
    const companiesSelectedButNotInList = ids
      .filter((p) => !companyList.map((c) => c.value).includes(p))
      ?.map((c) => ({
        value: c,
        title: companiesSelectedInfo.find((psi) => psi.value === c)?.title ?? c,
        hidden: true,
      }))

    const options = companyList.concat(companiesSelectedButNotInList)
    setCompanyList(options)

    return ids.map((c) => ({
      value: c,
      title:
        companyList?.find((aps) => aps.value === c)?.title ??
        companiesSelectedInfo.find((psi) => psi.value === c)?.title ??
        c,
      hidden: true,
    }))
  }
  return (
    <>
      <MultiSelect
        options={companyList}
        placeholder="Select options..."
        required={required}
        optionLabel="title"
        optionValue="value"
        name={fieldName}
        heading={heading}
        value={companyList?.length > 0 ? companiesSelected : []}
        onChange={(e) => {
          companiesOnChange(e)
          setCompaniesSelectedInfo(getSelectedCompaniesValue(e.value))
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
        filterPlaceholder={t("searchForMoreCompanies")}
      />
      <ValidationMessage
        messageError={errorMessage}
        showMessage={
          displayValidations &&
          errorMessage !== undefined &&
          errorMessage !== null &&
          errorMessage.length > 0
        }
      />
    </>
  )
}
