import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const StandardValuePerUnitColumns: ColumnModel[] = [
  {
    field: "id",
    header: "ID",
    sortable: true,
  },
  {
    field: "fromDate",
    header: "Start Date",
    sortable: true,
    body: dateStartTemplateBody,
  },
  {
    field: "statusDescription",
    header: "Status",
    sortable: true,
  },
  {
    field: "rateValue",
    header: "Current Rate",
    sortable: true,
  },
  {
    field: "unitsOfMeasurementDescription",
    header: "UOM",
    sortable: true,
  },
]

export default StandardValuePerUnitColumns
