import React from "react"
import StyledPageHeader from "./styled"

const PageHeader = ({ title }) => (
  <StyledPageHeader>
    <h1>{title}</h1>
  </StyledPageHeader>
)

export default PageHeader
