import React from "react"
import { StyledLineGraph } from "./styled"

const LineGraph = () => {
  const basicData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "First Dataset",
        data: [35, 50, 50, 50, 30, 20, 10],
        fill: true,
        borderColor: "#42A5F5",
        tension: 0.4,
        backgroundColor: "#42A5F5",
      },
      {
        label: "Second Dataset",
        data: [28, 48, 40, 19, 30, 27, 80],
        fill: true,
        borderColor: "#FFA726",
        tension: 0.4,
        backgroundColor: "#FFA726",
      },
    ],
  }

  return <StyledLineGraph type="line" data={basicData} />
}

export default LineGraph
