export interface OptionApi {
  id: number | string
  name: string
}

export interface OptionModel {
  value: number | string
  title: string
}

const mapToDropDownOptionFromApi = (options: OptionApi[]): OptionModel[] =>
  options.map((option) => ({
    value: option.id,
    title: option.name,
  }))

export default {
  mapToDropDownOptionFromApi,
}
