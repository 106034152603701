import React, { useState } from "react"
import { Editor } from "primereact/editor"
import { Card, IconButton, IconContainer } from "components/library"
import { StyledEditorCard } from "./styled"

const EditorCard = (props) => {
  const { note, onModeChange, editorOpen, cancel, onTextChange, onSave } = props
  const [text, setText] = useState(note.text)
  const [editMode, setEditMode] = useState(note.isNew)

  const onCancel = () => {
    setEditMode(false)
    setText(note.text)
    onModeChange()
    cancel(note)
  }

  const card = () =>
    editorOpen ? null : (
      <Card>
        <Card.Header>
          <div className="editor-header">
            <span className="user-header">{note.creatorIdFullName}</span>
          </div>
          <div className="last-updated">
            {`${new Date(note.dateCreated).toLocaleDateString()} ${new Date(
              note.dateCreated
            ).toLocaleTimeString()}`}
          </div>
        </Card.Header>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Card>
    )

  const header = (
    <div className="editing-button-header">
      <span className="ql-formats">
        <button className="ql-bold" type="button" aria-label="Bold" />
        <button className="ql-italic" type="button" aria-label="Italic" />
        <button className="ql-underline" type="button" aria-label="Underline" />
      </span>
    </div>
  )

  const editor = () => (
    <span>
      <IconContainer>
        <IconButton icon="pi pi-save" onClick={() => onSave(text)} />
        <IconButton icon="pi pi-times" onClick={() => onCancel()} />
      </IconContainer>
      <Editor
        style={{ height: "320px" }}
        value={note.text}
        onTextChange={(e) => {
          setText(e.htmlValue)
          onTextChange(e.htmlValue)
        }}
        headerTemplate={header}
      />
    </span>
  )

  return (
    <StyledEditorCard>
      <div>{editMode ? editor() : card()}</div>
    </StyledEditorCard>
  )
}

export default EditorCard
