import React, { useContext, useReducer } from "react"
import { FlexContainer } from "components/library/containers"
import { ModalContext } from "stores"
import { Action } from "components/hooks"
import { useTranslation } from "react-i18next"
import {
  CommandBarFilterType,
  ComponentFilterModal,
  QuickSearchModel,
  SearchTextBox,
  SecondaryButton,
} from "components/library"
import { FilterValues } from "./filterValues/FilterValues"
import { FilterModal } from "./filterModal"
import useReducerFilter from "./useReducerFilter"

interface Props {
  filterType: CommandBarFilterType
  componentQuickSearch?: QuickSearchModel
  componentFilterModal?: ComponentFilterModal
}
export const FilterBlock: React.FC<Props> = ({
  filterType,
  componentFilterModal,
  componentQuickSearch,
}) => {
  const { handleModal } = useContext(ModalContext)
  const { t } = useTranslation()

  const filterReducer = useReducerFilter()
  const [filterInfo, dispatch] = useReducer<React.Reducer<any, Action>>(
    filterReducer.filterReducer,
    componentFilterModal?.filtersInitialValues
  )
  const onApply = (filterValues) => {
    filterReducer.resetFilter(filterValues, dispatch)
    componentFilterModal?.onApply(filterValues)
  }

  const onClear = () => {
    filterReducer.resetFilter(
      componentFilterModal?.filtersInitialValues,
      dispatch
    )
    componentFilterModal?.onApply(componentFilterModal?.filtersInitialValues)
  }
  const onFilterClick = () => {
    handleModal(
      componentFilterModal?.title,
      <FilterModal
        onApply={onApply}
        filterRows={componentFilterModal ? componentFilterModal.formRows : []}
        filterInfo={filterInfo}
        onClear={onClear}
      />
    )
  }

  const renderFilter = () => {
    switch (filterType) {
      case CommandBarFilterType.FilterSearchAndValues:
        return (
          <FlexContainer direction="column">
            <FlexContainer direction="row" justify="flex-start">
              <SearchTextBox
                onChange={componentQuickSearch?.onChange}
                onKeyUp={componentQuickSearch?.onKeyUp}
                placeholder={componentQuickSearch?.searchPlaceholder}
                name={componentQuickSearch?.searchName}
                value={componentQuickSearch?.searchValue}
                caption={componentQuickSearch?.searchCaption}
                id="command-filter"
              />
              <SecondaryButton
                onClick={() => onFilterClick()}
                text={t("filters")}
                icon="pi pi-filter"
                iconToRightText
                fontSize="1rem"
                id="filter-button"
              />
            </FlexContainer>
            <FlexContainer justify="flex-start">
              <FilterValues
                filterInfo={filterInfo}
                onApply={onApply}
                onClear={onClear}
              />
            </FlexContainer>
          </FlexContainer>
        )

      case CommandBarFilterType.OnlyQuickSearch:
        return (
          <FlexContainer direction="row" justify="flex-start">
            <SearchTextBox
              onChange={componentQuickSearch?.onChange}
              onKeyUp={componentQuickSearch?.onKeyUp}
              placeholder={componentQuickSearch?.searchPlaceholder}
              name={componentQuickSearch?.searchName}
              value={componentQuickSearch?.searchValue}
              caption={componentQuickSearch?.searchCaption}
              id="command-filter"
            />
          </FlexContainer>
        )
      default:
        return <></>
    }
  }
  return <>{renderFilter()}</>
}
