import { useEffect } from "react"
import {
  PackageGridData,
  PackageGridRequest,
} from "components/pages/pricemanagement/packages/packageList/package.models"
import {
  ebateQueryKeys,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { OptionApi, OptionModel, dropdownUtils } from "components/helpers"
import { DealsByCompaniesSelectionRequest } from "components/pages/pricemanagement/rebates/rebateCopy/rebateCopy.models"
import {
  getPackageDetails,
  getPackageFileGridData,
  getPackageGridData,
  getPackageStatusCombo,
  getPackagesByCompanySelection,
} from "queries"
import { DocumentTabGridFilterRequest } from "components/library/documentTab"
import { DocumentTabGridData } from "components/library/documentTab/documentTab.models"

export const usePackageGrid = (initialPackageFilter: PackageGridRequest) => {
  const getUniqueValues = (data): PackageGridData[] => {
    const flattened = data.pages.flatMap((page) => page.packageGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as PackageGridData[]
    return arrayUniqueByKey
  }
  const fetchPackageGridData = async () => {
    const response = await getPackageGridData(initialPackageFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PACKAGE_GRID_DATA,
      initialPackageFilter.orderBy,
      initialPackageFilter.isAscending ? "true" : "false",
      initialPackageFilter.search ?? "",
      initialPackageFilter.periodStart?.toDateString() ?? "",
      initialPackageFilter.periodEnd?.toDateString() ?? "",
      initialPackageFilter.companyId?.toString() ?? "",
      initialPackageFilter.statusId?.toString() ?? "",
      initialPackageFilter.isForecast?.toString() ?? "",
      initialPackageFilter.type?.toString() ?? "",
    ],
    fetchPackageGridData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= initialPackageFilter.currentPage
          ? initialPackageFilter.currentPage + 1
          : undefined,
    }
  )

  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, initialPackageFilter])

  const packages = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > initialPackageFilter.currentPage

  return { packages, hasMore, isLoading, isError }
}

export const usePackageStatus = () => {
  const onCompletedPackageStatus = (res: OptionApi[]) => {
    const packageStatusesFromApi = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...packageStatusesFromApi]
  }

  const fetchPackageStatus = async (): Promise<OptionModel[]> => {
    const response = await getPackageStatusCombo()

    if (response?.status === 200) {
      return onCompletedPackageStatus(response.data)
    }
    return []
  }

  const packageStatuses = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PACKAGE_STATUS_VALUES],
    fetchPackageStatus,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return packageStatuses
}

export const usePackageDetails = (id: Number) => {
  const fetchPackage = async () => {
    try {
      const response = await getPackageDetails(Number(id))
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const packageDetails = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PACKAGE_DETAILS, id.toString()],
    fetchPackage,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return packageDetails
}

export const usePackagesByCompanySelection = (
  data: DealsByCompaniesSelectionRequest
) => {
  const fetch = async () => {
    try {
      const response = await getPackagesByCompanySelection(data)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const packages = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PACKAGES_BY_COMPANY_SELECTION,
      data.companyIds.join(","),
    ],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.zero,
    }
  )

  return packages
}

export const usePackageDocumentGridData = (
  documentFilter: DocumentTabGridFilterRequest
) => {
  const getUniqueValues = (data): DocumentTabGridData[] => {
    const flattened = data.pages.flatMap((page) => page.files)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as DocumentTabGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getPackageFileGridData(documentFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PACKAGE_DOCUMENT_GRID_DATA,
      documentFilter.id.toString(),
      documentFilter.orderBy,
      documentFilter.isAscending ? "true" : "false",
      documentFilter.fileLinkTypeId?.toString() || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= documentFilter.currentPage
          ? documentFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, documentFilter])

  const documents = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > documentFilter.currentPage

  return { documents, hasMore, isLoading, isError }
}
