import { ChangesOnAgreementRequest } from "components/pages/pricemanagement/approvals/approvalAmend/approvalAmend.model"
import { useEbateQuery, ebateQueryKeys } from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getChangesOnAgreement } from "../auditAgreement.query"

export const useChangesOnAgreement = (request: ChangesOnAgreementRequest) => {
  const fetch = async () => {
    try {
      const response = await getChangesOnAgreement(request)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const changesOnAgreement = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CHANGES_ON_AGREEMENT,
      request.agreementId.toString(),
      request.requestDate.toString(),
      request.status.toString(),
    ],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return changesOnAgreement
}
