import React from "react"

import { useWindowSize } from "components/hooks"
import { getCurrentBreakpoint } from "components/helpers"

import { StyledGrid, Item } from "./styled"

// const handleDrag = (event) => {
//   console.log("PickedUp")
// }

// const handleDrop = (event) => {
//   console.log("Dropped")
// }

interface Props {
  children?: any
  rows?: number
  columns?: number
  gutter?: string
  columnGutter?: string
  rowGutter?: string
  rowHeight?: string
  className?: string
  rearrange?: boolean
}

const Grid: React.FC<Props> & { Item?: any } = ({
  children,
  rows,
  columns,
  gutter,
  columnGutter,
  rowGutter,
  rowHeight,
  className,
  rearrange = false,
}) => {
  // Get the currently active breakpoint for use in later calculations
  const currentBreakpoint = getCurrentBreakpoint(useWindowSize().width)

  const checkGridSizes = () => {
    React.Children.forEach(children, (child, index) => {
      /*
        FullGrid component accepts rows and columns as optional props
        These override the grid's children in defining the amount of columns and rows
        If we exceed these boundaries, throw an error as we will end up with broken display elements
      */
      const childcoordinates = child.props.coordinates || false
      // First check for a breakpoint specifc grid alignment, else use default grid coordinates, else use the coord prop (for single coord props)
      if (
        childcoordinates[currentBreakpoint] ||
        childcoordinates.default ||
        childcoordinates
      ) {
        if (columns) {
          /*
            Child coordinates array is as follows:
            [x,y,w,h]
            [0,1,2,3]
            Subtract 1 as index of FullGrid starts at 1, but is actually position 0
          */
          const childHeight = childcoordinates[1] + childcoordinates[3] - 1
          if (childHeight > Number(columns)) {
            throw new Error(
              `The Grid Item at position ${index} is too tall. Allowed rows: ${columns}, used ${childHeight}`
            )
          }
        }
        if (rows) {
          const childWidth = childcoordinates[0] + childcoordinates[2] - 1
          if (childWidth > Number(rows)) {
            throw new Error(
              `The Grid Item at position ${index} is too wide. Allowed columns: ${rows}, used ${childWidth}`
            )
          }
        }
      }
    })
  }

  const generateGridItems = () => {
    // First check if grid is correctly sized for breakpoint
    checkGridSizes()
    // If so, return the grid children
    return React.Children.map(children, (child) => {
      // Get the property if it exists
      // return false if not
      const coords =
        child.props.coordinates[currentBreakpoint] ||
        child.props.coordinates.default ||
        child.props.coordinates ||
        false
      if (coords) {
        // Don't render if hidden at current size
        if (coords === "hidden") {
          return null
        }
        // If child is a grid item. just return it with breakpoint info
        if (child.type.displayName === "Item") {
          return React.cloneElement(child, { breakpoint: currentBreakpoint })
        }
        // Else, wrap it in a FullGrid.Item
        return (
          <Grid.Item
            breakpoint={currentBreakpoint}
            coordinates={coords}
            // If we're in rearangeable grid and the item isn't locked, make the item draggable
            draggable={!!(rearrange && !child.props.locked)}
            // onDragStart={rearrange ? handleDrag : null}
          >
            {child}
          </Grid.Item>
        )
      }
      // Throw error if configuration is wrong
      throw new Error(
        `A grid child of type <${child.type}> has no coordinate property. Did you mean to place it outside the grid?`
      )
    })
  }

  return (
    <StyledGrid
      rows={rows}
      columns={columns}
      gutter={gutter}
      rowGutter={rowGutter}
      columnGutter={columnGutter}
      rowHeight={rowHeight}
      className={className}
      role="grid"
    >
      {generateGridItems()}
    </StyledGrid>
  )
}

Grid.Item = Item

export default Grid
