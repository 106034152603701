import React from "react"
import { useTranslation } from "react-i18next"
import { CardIconModel } from "components/models"
import { StyledMenuCard } from "./styled"

interface Props {
  cardIcon?: CardIconModel
}

export const MenuCard: React.FC<Props> = ({ cardIcon }) => {
  const { t } = useTranslation()
  return (
    <StyledMenuCard>
      {cardIcon && cardIcon.onApprove && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onApprove?.()}
          role="generic"
          data-testid={cardIcon.name ? `approve-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-check icon-button" />
          <div className="text-link">{t("approve-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onReject && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onReject?.()}
          role="generic"
          data-testid={cardIcon.name ? `reject-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-times icon-button" />
          <div className="text-link">{t("reject-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onView && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onView?.()}
          role="generic"
          data-testid={cardIcon.name ? `view-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-eye icon-button" />
          <div className="text-link">{t("view-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onEdit && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onEdit?.()}
          role="generic"
          data-testid={cardIcon.name ? `edit-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-pencil icon-button" />
          <div className="text-link">{t("edit-menu")}</div>
        </div>
      )}

      {cardIcon && cardIcon.onAdjustment && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onAdjustment?.()}
          role="generic"
          data-testid={
            cardIcon.name ? `adjustment-${cardIcon.name}` : undefined
          }
        >
          <i className="pi pi-sliders-h icon-button" />
          <div className="text-link">{t("adjustment-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onCopy && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onCopy?.()}
          role="generic"
          data-testid={cardIcon.name ? `copy-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-copy icon-button" />
          <div className="text-link">{t("copy-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onSendLink && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onSendLink?.()}
          role="generic"
          data-testid={cardIcon.name ? `sendLink-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-send icon-button" />
          <div className="text-link">{t("send-link-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onSubscribe && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onSubscribe?.()}
          role="generic"
          data-testid={cardIcon.name ? `subscribe-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-link icon-button" />
          <div className="text-link">{t("subscribe-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onDelete && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onDelete?.()}
          role="generic"
          data-testid={cardIcon.name ? `delete-${cardIcon.name}` : undefined}
        >
          <i className="pi pi-trash icon-button" />
          <div className="text-link">{t("delete-menu")}</div>
        </div>
      )}
      {cardIcon && cardIcon.onOpenCustomerInfo && (
        <div
          className="menu-option"
          onClick={() => cardIcon.onOpenCustomerInfo?.()}
          role="generic"
          data-testid={
            cardIcon.name ? `customerInfo-${cardIcon.name}` : undefined
          }
        >
          <i className="pi pi-info icon-button" />
          <div className="text-link">{t("info-menu")}</div>
        </div>
      )}
    </StyledMenuCard>
  )
}
