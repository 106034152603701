import React from "react"
import { Column } from "primereact/column"
import { StyledCardGrid } from "./styled"

const CardGrid = (props) => {
  const { columns, data, paginator, rows, paginatorTemplate } = props

  return (
    <StyledCardGrid
      value={data}
      dataKey="id"
      stripedRows
      paginator={paginator}
      rows={rows}
      paginatorTemplate={paginatorTemplate}
    >
      {columns.map((col) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={col.body}
          sortable
          style={{ width: `${col.width ? col.width : null}` }}
        />
      ))}
    </StyledCardGrid>
  )
}

export default CardGrid
