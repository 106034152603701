import styled from "styled-components"

export const StyledStatusField = styled.span`
  .active {
    border: 1px solid var(--color-ebateActive);
    background-color: var(--color-ebateActive);
    color: var(--color-ebateStatusFieldColorFont);
    padding: var(--spacing-half);
    border-radius: 4px;
  }

  .no-active {
    border: 1px solid var(--color-ebateInactive);
    background-color: var(--color-ebateInactive);
    color: var(--color-ebateStatusFieldColorFont);
    border-radius: 4px;
  }
`
