import styled from "styled-components"

const StyledWizard = styled.div`
  width: 800px;

  .wizard-content {
    padding: var(--spacing-x2) 0;
  }
`
export default StyledWizard
