import compact from "./compact"
import normal from "./normal"
import touch from "./touch"

const spacing = {
  compact,
  normal,
  touch,
}

export default spacing
