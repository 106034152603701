const light = {
  light:
    "linear-gradient(120deg, rgba(216,116,81,1) 0%,  rgba(253,194,73,1) 100%);",
  medium:
    "linear-gradient(120deg, rgba(160,37,99,1) 0%, rgba(253,194,73,1) 100%);",
  dark: "linear-gradient(120deg, rgba(160,37,99,1) 0%, rgba(216,116,81,1) 100%);",
  ebateYellow:
    "linear-gradient(45deg, rgb(206,139,2) 0%,  rgb(253,187,54) 100%);",
  ebatePrimary:
    "linear-gradient(45deg, rgb(125,45,159) 0%,  rgb(175,92,209) 100%);",
  ebateBurgundy:
    "linear-gradient(45deg, rgb(160,37,99) 0%,  rgb(215,80,148) 100%);",
  ebateSecondary:
    "linear-gradient(45deg, rgb(163,69,36) 0%,  rgb(216,116,81) 100%);",
  ebateSpectrum:
    "linear-gradient(45deg, rgb(253,187,54) 0%,  rgb(175,92,209) 100%);",
}

export default light
