import { ColumnModel } from "components/library"
import { TFunction } from "react-i18next"
import {
  GrowthPercentOfTurnoverColumns,
  GrowthValueperUnitColumn,
  ProductLevelPerecentageColumns,
  ProductLevelValueColumns,
  QuantityTierFixedRateRetroColumns,
  StandardPercentOfTurnoverColumns,
  StandardValuePerUnitColumns,
  TieredOneOffColumns,
  TieredStandardPercentOfTurnoverColumns,
  TieredValuePerColumns,
  totalSalesTieredRebateColumns,
  GrowthLumpSumColumns,
  ProductLevelFOCColumns,
  ValueGrowthPercentageRatePaidOnTurnoverColumns,
} from "./columns"
import BuyGetCheapestFreeColumnColumns from "./columns/buyGetCheapestFreeColumns"
import BuyXGetYFreeColumns from "./columns/buyXGetYFreeColumns"
import { CalculationTypes } from "./rebateRates.model"
import ValueGrowthPercentageRatePaidOnGrowthColumns from "./columns/valueGrowthPercentageRatePaidOnGrowthColumns"
import FeeColumns from "./columns/feeColumns"

export const getColumns = (
  calculationTypeId: number,
  approvalEnabled: boolean,
  t: TFunction<(any | "translation")[]>
): ColumnModel[] => {
  switch (calculationTypeId) {
    case CalculationTypes.StandardValuePerUnit:
      return StandardValuePerUnitColumns

    case CalculationTypes.TieredValuePerUnitRetro:
    case CalculationTypes.TieredValuePerUnitNonRetro:
      return TieredValuePerColumns

    case CalculationTypes.StandardPercentageOfTurnover:
      return StandardPercentOfTurnoverColumns

    case CalculationTypes.Fee:
      return FeeColumns

    case CalculationTypes.TieredPercentageOfTurnoverRetro:
    case CalculationTypes.TieredPercentageOfTurnoverNonRetro:
    case CalculationTypes.QuantityTierPercentageRateRetro:
      return TieredStandardPercentOfTurnoverColumns

    case CalculationTypes.GrowthValuePerUnit:
      return GrowthValueperUnitColumn

    case CalculationTypes.ProductLevelPercentage:
      return ProductLevelPerecentageColumns(approvalEnabled)

    case CalculationTypes.ProductLevelValue:
      return ProductLevelValueColumns(approvalEnabled)

    case CalculationTypes.ValueGrowthPercentageRatePaidOnGrowth:
      return ValueGrowthPercentageRatePaidOnGrowthColumns

    case CalculationTypes.ValueGrowthPercentageRatePaidOnTurnover:
      return ValueGrowthPercentageRatePaidOnTurnoverColumns

    case CalculationTypes.GrowthPercentageOfTurnover:
      return GrowthPercentOfTurnoverColumns

    case CalculationTypes.TieredOneOffRebate:
      return TieredOneOffColumns

    case CalculationTypes.GrowthLumpSum:
      return GrowthLumpSumColumns

    case CalculationTypes.BuyGetCheapestFree:
      return BuyGetCheapestFreeColumnColumns(approvalEnabled)

    case CalculationTypes.BuyXGetYFree:
      return BuyXGetYFreeColumns(approvalEnabled, t)

    case CalculationTypes.QuantityTierFixedRateRetro:
      return QuantityTierFixedRateRetroColumns

    case CalculationTypes.TotalSalesTieredRebateRetro:
      return totalSalesTieredRebateColumns(t)
    case CalculationTypes.TotalSalesTieredRebateNonRetro:
      return totalSalesTieredRebateColumns(t)
    case CalculationTypes.ProductLevelFoc:
      return ProductLevelFOCColumns(approvalEnabled, t)
    default:
      return []
  }
}
