import React, { useContext, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalContext } from "stores"
import { StyledModal, Scrim, ModalClose } from "./styled"

interface Props {
  modalTitle: string
  handleModal: any
  separator?: any
}
const ModalHead: React.FC<Props> = ({ modalTitle, handleModal, separator }) => (
  <StyledModal.Header
    style={{
      marginBottom: separator ? "10px" : null,
    }}
  >
    <div className="modal-header">
      <div className="modal-title">{modalTitle}</div>
      <ModalClose onClick={() => handleModal()}>&times;</ModalClose>
    </div>
  </StyledModal.Header>
)

const Modal = () => {
  const { modalTitle, modalContent, handleModal, modal } =
    useContext(ModalContext)
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [modal])

  if (modal) {
    return createPortal(
      <>
        <Scrim />
        <StyledModal>
          <div id="headerGradient" />
          {modalTitle && typeof modalTitle !== "string" ? (
            <div id="headerSeparator">
              <ModalHead
                modalTitle={modalTitle}
                handleModal={handleModal}
                separator
              />
            </div>
          ) : (
            <ModalHead modalTitle={modalTitle} handleModal={handleModal} />
          )}

          <StyledModal.Body>{modalContent}</StyledModal.Body>
        </StyledModal>
      </>,
      document.querySelector("#modal-root")!
    )
  }
  return null
}

export default Modal
