import React from "react"
import Button from "components/library/button/baseButton"
import { FlexContainer } from "components/library/containers"
import { StyledSecondaryButton } from "./styled/styledSecondaryButton"

interface Props {
  onClick: () => any
  color?: string
  backgroundColorHover?: string
  icon?: string
  text: string
  fontSize?: string
  isLink?: boolean
  id?: string
  className?: string
  iconToRightText?: boolean
  width?: string
  disabled?: boolean
}
export const SecondaryButton: React.FC<Props> = ({
  onClick,
  color,
  backgroundColorHover,
  icon,
  text,
  fontSize,
  isLink,
  className,
  iconToRightText,
  width,
  disabled,
  ...props
}) => (
  <StyledSecondaryButton
    className={className}
    fontSize={fontSize}
    backgroundColorHover={backgroundColorHover}
    width={width}
    disabled={disabled}
  >
    <Button
      onClick={onClick}
      transparent
      color={color ?? "var(--color-ebateSecondaryButton)"}
      fontSize={fontSize}
      isLink={isLink}
      width={width}
      disabled={disabled}
      data-testid={props.id}
      {...props}
    >
      {icon ? (
        <FlexContainer
          align="flex-end"
          direction={iconToRightText ? "row-reverse" : "row"}
        >
          {icon && <i className={`${icon} icon-button`} />}
          <div className="text-button">{text}</div>
        </FlexContainer>
      ) : (
        <FlexContainer align="center" justify="center">
          <div className="text-button">{text}</div>
        </FlexContainer>
      )}
    </Button>
  </StyledSecondaryButton>
)
