import { numberFormatter } from "components/helpers"
import { IconButton } from "components/library/iconButton"
import ErrorMessage from "components/library/message"
import React, { Dispatch, SetStateAction, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormInput } from "../formInput"
import {
  DragDropText,
  FileUploadComponent,
  FileUploadContainer,
} from "./styled"

const KILO_BYTES_PER_BYTE = 1000
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 100000

interface Props {
  id?: string
  heading?: string
  required?: boolean
  maxFileSizeInBytes?: number
  allowedTypes?: string[]
  multiple?: boolean
  files: File[]
  disabled?: boolean
  setFiles: Dispatch<SetStateAction<File[]>>
  triggerCleanError?: number
}
const FileUpload: React.FC<Props> = ({
  id,
  heading,
  required,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  allowedTypes,
  multiple,
  files,
  disabled = false,
  setFiles,
  triggerCleanError,
}) => {
  const fileInputField = useRef<HTMLInputElement | null>(null)
  const [drop, setDrop] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const { t } = useTranslation()

  React.useEffect(() => {
    if (triggerCleanError) {
      setErrorMessages([])
    }
  }, [triggerCleanError])

  const accept = () => {
    if (allowedTypes) {
      const listTypes = allowedTypes?.map((type) => `.${type}`)
      return listTypes.toString()
    }

    return undefined
  }
  const addNewFiles = (newFiles: FileList) => {
    const files: any[] = []
    for (let i = 0; i < newFiles.length; i += 1) {
      const file = newFiles[i]
      if (allowedTypes && allowedTypes.length > 0) {
        const splittedFileName = file.name.toLowerCase().split(".")

        if (splittedFileName.length > 2) {
          setErrorMessages([t("v-invalid-file-name")])
          return files
        }

        if (
          !allowedTypes.some(
            (t) => t === splittedFileName[splittedFileName.length - 1]
          )
        ) {
          setErrorMessages([
            t("v-invalid-file-type"),
            `${t("v-allowed-file-type")} ${allowedTypes.toString()}`,
          ])
          return files
        }
      }
      if (file.size <= maxFileSizeInBytes) {
        if (!multiple) {
          files[i] = file
          return files
        }
        files[i] = file
      } else {
        setErrorMessages([
          t("v-invalid-file-size"),
          `${t("v-the-maximum-size-is", {
            size: numberFormatter.numberFormatter.format(
              maxFileSizeInBytes / 1024 / 1024
            ),
          })}`,
        ])
      }
    }
    return files
  }

  const onDragEnter = (e) => {
    if (!disabled) {
      e.dataTransfer.dropEffect = "copy"
      setDrop(true)
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onDragOver = (e) => {
    if (!disabled) {
      e.dataTransfer.dropEffect = "copy"
      setDrop(true)
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onDragLeave = (e) => {
    e.dataTransfer.dropEffect = "copy"
    setDrop(false)
  }
  const onDrop = (event) => {
    if (!disabled) {
      event.stopPropagation()
      event.preventDefault()

      const files = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files
      const allowDrop = multiple || (files && files.length === 1)

      if (allowDrop) {
        handleNewFileUpload(event)
      }
      setDrop(false)
    }
  }

  const handleNewFileUpload = (e) => {
    setErrorMessages([])
    const newFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files

    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles)
      setFiles(updatedFiles)
    }
  }

  const choose = () => {
    if (fileInputField?.current) {
      fileInputField.current.click()
    }
  }

  const removeFile = (index) => {
    files.splice(index, 1)
    if (files.length === 0) {
      setFiles([])
    } else {
      setFiles({ ...files })
    }
  }

  const formatSize = (bytes) => {
    if (bytes === 0) {
      return "0 B"
    }
    const k = KILO_BYTES_PER_BYTE
    const dm = 3
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
  }

  const renderFile = (file, index) => {
    const preview = (
      <div className="p-file-icon">
        <i className="pi pi-file p-2rem" role="presentation" />
      </div>
    )
    const fileName = (
      <div className="p-fileupload-filename p-file-name">{file.name}</div>
    )
    const size = <div className="p-file-size">{formatSize(file.size)}</div>
    const removeButton = (
      <div className="p-file-remove-button">
        <IconButton
          type="button"
          icon="pi pi-times"
          onClick={(e) => removeFile(index)}
        />
      </div>
    )
    const content = (
      <>
        {preview}
        {fileName}
        {size}
        {removeButton}
      </>
    )

    return (
      <div className="p-fileupload-row" key={file.name + file.type + file.size}>
        {content}
      </div>
    )
  }

  const renderFiles = () => (
    <div className="p-fileupload-files">
      {files.map((file, index) => renderFile(file, index))}
    </div>
  )

  return (
    <FormInput id={id} heading={heading} required={required}>
      <FileUploadComponent data-testid="fileUploadComponent">
        <label onChange={handleNewFileUpload} htmlFor="formId">
          <IconButton
            isDisabled={disabled || (!multiple && files.length > 0)}
            onClick={choose}
            htmlFor="formId"
            icon="pi pi-upload"
          />
          <input
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            title=""
            value=""
            className="custom-choose-btn p-button-rounded"
            hidden
            accept={accept()}
            disabled={!multiple && files.length > 0}
          />
        </label>
        {errorMessages && errorMessages.length > 0 ? (
          <ErrorMessage messages={errorMessages} />
        ) : (
          <span />
        )}
        {!multiple && files.length === 0 && (
          <FileUploadContainer
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            className={`${drop ? "p-fileupload-highlight" : ""} ${
              disabled ? "p-fileupload-disabled" : ""
            }`}
          >
            <DragDropText>{t(`dragAndDrop`)}</DragDropText>
          </FileUploadContainer>
        )}

        {renderFiles()}
      </FileUploadComponent>
    </FormInput>
  )
}

export default FileUpload
