// Ref.: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
import { useEffect, useState } from "react"

/*
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(signOutMenuRef) {
  const [isComponentVisible, setIsComponentVisible] = useState(false)

  const handleClickOutside = (event) => {
    if (
      signOutMenuRef.current &&
      !signOutMenuRef.current.contains(event.target)
    ) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return { isComponentVisible, setIsComponentVisible }
}

export default useOutsideAlerter
