import React from "react"
import { CheckboxChangeParams } from "primereact/checkbox"
import { StyledCheckbox } from "./styled"
import { FormInput } from "../formInput"

interface Props {
  heading?: string
  checked: boolean
  name: string
  disabled?: boolean
  required?: boolean
  label?: string
  componenttype?: number
  id?: string
  flat?: boolean
  width?: string
  onChange?: (e: CheckboxChangeParams) => void
  onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void
  floatHeadRight?: boolean
  value?: any
  hidden?: boolean
}

const Checkbox: React.FC<Props> = ({
  heading,
  checked,
  name,
  onChange,
  disabled,
  required,
  ...props
}) => (
  <FormInput
    heading={heading}
    required={required}
    flat={props.flat}
    width={props.width}
    floatHeadRight={props.floatHeadRight}
  >
    <StyledCheckbox
      checked={checked}
      name={name}
      onChange={onChange}
      onMouseDown={props.onMouseDown}
      disabled={disabled}
      value={props.value}
      data-testid={name}
    />
  </FormInput>
)

export default Checkbox
