import styled from "styled-components"

const StyledTable = styled.div`
  display: grid;
  width: 100%;
  position: relative;
  padding: ${({ nested }) => (nested ? "0 20px 20px 20px" : null)};
  border-bottom: ${({ nested }) =>
    nested ? "solid 1px var(--color-lineColor)" : null};
  ${({ striped }) => {
    if (striped) {
      return `
        > :nth-child(2n) *{
          background-color: ${({ headerBg }) =>
            headerBg || "var(--color-body)"};
        }
      `
    }
    return null
  }};
`

export default StyledTable
