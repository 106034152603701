import React from "react"
import logo from "assets/ebate-loader.gif"
import { StyledLoadingForm } from "./styled"

export const LoadingForm = () => (
  <StyledLoadingForm>
    <div className="overlay">
      <div className="overlay-content">
        <img src={logo} alt="Loading..." className="logo" />
      </div>
    </div>
  </StyledLoadingForm>
)
