import styled from "styled-components"

const StyledBar = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  div {
    height: 100%;
    position: absolute;
    animation-name: loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform: translate(-50%, 0);
    border-radius: var(--border-radius-sm);
    &#bar-segment-1 {
      animation-delay: 0s;
    }
    &#bar-segment-2 {
      animation-delay: 0.5s;
    }
    &#bar-segment-3 {
      animation-delay: 1s;
    }
    &#bar-segment-4 {
      animation-delay: 1.5s;
    }
  }

  @keyframes loader {
    0% {
      left: 0;
      background-color: var(--color-ebatePrimary);
      width: 0;
    }
    20% {
      left: 25%;
      background-color: var(--color-ebateBurgundy);
      width: 15%;
    }
    50% {
      left: 50%;
      width: 20%;
    }
    80% {
      left: 75%;
      background-color: var(--color-ebateSecondary);
      width: 15%;
    }
    100% {
      left: 100%;
      background-color: var(--color-ebateYellow);
      width: 0;
    }
  }
`
export default StyledBar
