import styled from "styled-components"

export const StyledNotesComponent = styled.div`
  .add-note {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: var(--spacing-x2);
    margin-right: var(--spacing-x2);
  }

  #loader {
    height: initial;
    top: 0;
    position: relative;
  }

  .row-note {
    overflow: hidden;
    display: -webkit-box;
    width: inherit;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: inherit;
    word-break: break-all;
  }
  .row-note:hover {
    display: block;
  }
`
