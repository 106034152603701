import React from "react"
import { StyledStatusField } from "./styled/styledStatusField"

interface Props {
  status: string
  activeField: string
}

export const StatusField: React.FunctionComponent<Props> = ({
  status,
  activeField,
}) => {
  const className =
    status?.toLowerCase() === activeField.toLowerCase() ? "active" : "no-active"

  return (
    <StyledStatusField>
      <span className={className}>{status}</span>
    </StyledStatusField>
  )
}
