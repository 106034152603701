import Chip from "components/library/chip"
import { Tooltip } from "components/library/tooltips"
import React from "react"
import { useTranslation } from "react-i18next"
import { FilterModel, FilterValueModel } from "components/library"
import { StyledFilterValues } from "./styled"

interface Props {
  filterInfo: any
  onApply: (filterValues: any) => void
  onClear: () => void
}

export const FilterValues: React.FC<Props> = ({
  filterInfo,
  onApply,
  onClear,
}) => {
  const maxNumberOfFilterValuesShowed = 3

  const { t } = useTranslation()
  const removeFilter = (idFilter: string) => {
    const filterName = idFilter.split("-")[0]
    const valueToFilter = idFilter.split("-")[1]
    const filterInfoFilteredValues = {
      ...filterInfo,
      [filterName]: {
        ...filterInfo[filterName],
        filterValue: filterInfo[filterName].filterValue.filter(
          (f) => String(f.id) !== valueToFilter
        ),
      },
    }
    onApply(filterInfoFilteredValues)
  }

  const renderFilterValues = () => {
    const filterValues = Object.keys(filterInfo)
      .map(
        (k) =>
          ({
            filterName: k,
            filterValue: filterInfo[k].filterValue?.map(
              (fv) =>
                ({
                  title: fv.title,
                  value: fv.value,
                  tooltipDescription: fv.tooltipDescription,
                  id: fv.id,
                } as FilterValueModel<any>)
            ),
          } as FilterModel<any>)
      )
      .filter(
        (fi) =>
          fi.filterValue.length > 0 &&
          fi.filterValue.some((fv) => fv.title && fv.title !== "")
      ) as FilterModel<any>[]

    const numberOfFilterValues = filterValues.length

    const chipsToRender = (values: FilterModel<any>[]) =>
      values.map((f) =>
        f.filterValue.map((fv) => (
          <div key={`${f.filterName}-${fv.id}`}>
            <Chip
              id={`${f.filterName}-${fv.id}`}
              key={`${f.filterName}-${fv.id}`}
              text={fv.title}
              className="chipFilterValue"
              removable
              onRemove={(e) => removeFilter(e.target.parentElement.id)}
            />
            <Tooltip
              position="right"
              target={`#${f.filterName}-${fv.id}`}
              content={fv.tooltipDescription}
            />
          </div>
        ))
      )

    const chipWithMoreFilters = (moreFilters: number) => (
      <Chip
        id="chipWithMoreFilters"
        key="chipWithMoreFilters"
        text={`${moreFilters} ${t("more")}`}
        className="chipFilter"
      />
    )
    return (
      <>
        {numberOfFilterValues > maxNumberOfFilterValuesShowed ? (
          <>
            {chipsToRender(
              filterValues.slice(0, maxNumberOfFilterValuesShowed)
            )}
            {chipWithMoreFilters(
              numberOfFilterValues - maxNumberOfFilterValuesShowed
            )}
          </>
        ) : (
          chipsToRender(filterValues)
        )}
        {numberOfFilterValues > 0 && (
          <Chip
            id="clearAll"
            key="clearAll"
            text={t("clearAll")}
            className="chipFilter"
            removable
            onRemove={onClear}
          />
        )}
      </>
    )
  }
  return <StyledFilterValues>{renderFilterValues()}</StyledFilterValues>
}
