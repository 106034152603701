import styled from "styled-components"

interface Props {
  text?: string
  children?: any
  bgcolor?: string
  transparent?: boolean
  lg?: string
  borderColor?: string
  fontSize?: string
  square?: boolean
  bold?: boolean
  wide?: string
  color?: string
  onClick?: () => any
  id?: string
  style?: any
  disabled?: boolean
  padding?: string
  isLink?: boolean
  width?: string
  borderColorHover?: string
}

const StyledButton = styled.button<Props>`
  background-color: ${({ bgcolor, transparent }) =>
    bgcolor || (transparent ? "transparent" : "var(--color-background)")};
  color: ${({ color, transparent }) =>
    color ||
    (transparent
      ? "var(--color-transparentButtonColor)"
      : "var(--color-text)")};
  padding: ${({ lg, padding }) =>
    padding || (lg ? "var(--spacing-x2)" : "var(--spacing-base)")};

  transition: all 0.3s;
  border: ${({ borderColor }) =>
    borderColor ? `solid 3px ${borderColor}` : "none"};
  border-radius: ${({ transparent, square }) =>
    transparent || square ? "none" : "var(--border-radius-lg)"};
  font-size: ${({ fontSize }) => fontSize || null};

  &:focus {
    outline: none;
    box-shadow: none;
  }
  ${({ borderColorHover }) => {
    if (borderColorHover) {
      return `
      &:hover {
          font-family: var(--font-bold);
          border-color: ${borderColorHover}
      }
       &:active {
        filter: none;
        box-shadow: none;
        border-color: ${borderColorHover};
      }
      `
    }
    return ` &:hover {
           font-family: var(--font-bold);
      }
        &:active {
        filter: none;
        box-shadow: none;
      }
      `
  }}

  width: ${({ width }) => width || null};

  ${({ disabled, transparent, color, isLink, borderColorHover }) => {
    if (disabled) {
      if (borderColorHover) {
        return `filter: grayscale(1); cursor: not-allowed;       
       &:active {
          filter: grayscale(1);
          box-shadow: none;
          border-color: ${borderColorHover};
        }`
      }
      return `filter: grayscale(1); cursor: not-allowed;      
       &:active {
          filter: grayscale(1);
          box-shadow: none;
          border: none;
        }`
    }
    if (isLink) {
      return `
      cursor: pointer;      
      &::after {
        content: '';
        width: 0px;
        height: 1px;
        display: block;
        background: ${color};
        transition: 300ms;
      }

      &:hover::after {
        width: 100%;
      }  
    `
    }

    if (transparent) {
      return `
      cursor: pointer;
      &:hover {
          filter: none;
          box-shadow: none;
        }`
    }

    return `
      cursor: pointer;
      &:hover {
        filter: brightness(110%);               
      }
    `
  }};
`

export default StyledButton
