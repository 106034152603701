import React from "react"
import { Steps } from "primereact/steps"
import { IconContainer, IconButton, HorizontalLine } from "components/library"
import { StyledWizard } from "./styled"

const Wizard = ({ pages, incStep, decStep, save, step }) => (
  <StyledWizard>
    <Steps model={pages} activeIndex={step} />
    <HorizontalLine />

    <div className="wizard-content">{pages[step].component}</div>

    <IconContainer>
      {step === pages.length - 1 ? (
        <IconButton
          id="icon-save"
          name="icon-save"
          icon="pi pi-save"
          onClick={() => save()}
        />
      ) : (
        <IconButton
          id="caret-right"
          name="caret-right"
          icon="fas fa-caret-right"
          onClick={() => incStep()}
        />
      )}
      <IconButton
        id="caret-left"
        name="caret-left"
        icon="fas fa-caret-left"
        onClick={() => decStep()}
      />
    </IconContainer>
  </StyledWizard>
)

export default Wizard
