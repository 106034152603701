import { AxiosResponse } from "axios"
import { ValidationResultModel } from "components/models"
import {
  WholesalerDiscountAddRequest,
  WholesalerDiscountDataResponse,
  WholesalerDiscountUpdateRequest,
} from "components/pages/maintanance/wholesalerDiscount/wholeSalerDiscountForm/wholesalerDiscountForm.models"
import {
  WholesalerDiscountGridFilterRequest,
  WholesalerDiscountGridResponse,
} from "components/pages/maintanance/wholesalerDiscount/wholesalerDiscountList/wholesalerDiscountList.models"
import EbateAxios from "queries/ebateAxios"

const wholesalerUrl = "/api/WholeSaler"

export const getWholeSalerDiscountGridData = async (
  data: WholesalerDiscountGridFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${wholesalerUrl}/GetwholeSalerDiscountGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<
    WholesalerDiscountGridFilterRequest,
    WholesalerDiscountGridResponse
  >(url, data, abortSignal)
}

export const addWholesalerDiscount = async (
  data: WholesalerDiscountAddRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${wholesalerUrl}/AddWholesalerDiscount`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<WholesalerDiscountAddRequest, ValidationResultModel>(
    url,
    data
  )
}

export const getWholesalerDiscount = async (wholesalerDiscountId: number) => {
  const url = `${wholesalerUrl}/GetWholesalerDiscount/${wholesalerDiscountId}`

  const { AxiosGet } = EbateAxios()

  return AxiosGet<WholesalerDiscountDataResponse>(url)
}

export const updateWholesalerDiscount = async (
  request: WholesalerDiscountUpdateRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${wholesalerUrl}/UpdateWholesalerDiscount`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<WholesalerDiscountUpdateRequest, ValidationResultModel>(
    url,
    request
  )
}
export const deleteWholesalerDiscount = async (
  id: number
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${wholesalerUrl}/DeleteWholesalerDiscount/${id}`
  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}
