import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const dateEndTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.dateTo)

const ProductLevelValueColumns = (
  isApprovalEnabled: boolean
): ColumnModel[] => {
  const columns = [
    {
      field: "id",
      header: "ID",
      sortable: true,
      style: { textAlign: "right" },
    },
    {
      field: "productCode",
      header: "Product Code",
      sortable: true,
    },
    {
      field: "productDescription",
      header: "Product Description",
      sortable: true,
    },
    {
      field: "fromDate",
      header: "Effective From",
      sortable: true,
      body: dateStartTemplateBody,
    },
    {
      field: "dateTo",
      header: "Effective To",
      sortable: true,
      body: dateEndTemplateBody,
    },
    {
      field: "purchaseQty",
      header: "Min Qty",
      sortable: true,
      style: { textAlign: "right" },
    },
    {
      field: "rateValue",
      header: "Rate Value",
      sortable: true,
      style: { textAlign: "right" },
    },
    {
      field: "sellPrice",
      header: "Sell Price",
      sortable: true,
      style: { textAlign: "right" },
    },
    {
      field: "estimatedVolume",
      header: "Estimated Volume",
      sortable: true,
      style: { textAlign: "right" },
    },
  ]
  if (isApprovalEnabled) {
    return [
      ...columns,
      {
        field: "statusDescription",
        header: "Status",
        sortable: true,
      },
    ]
  }
  return columns
}
export default ProductLevelValueColumns
