import {
  ebateQueryKeys,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries/ebateReactQuery"
import { dropdownUtils } from "components/helpers"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  ClaimsPaymentsStatusGridData,
  ClaimsPaymentsStatusGridRequest,
} from "components/pages/finance/claimsPaymentsStatus/claimsPaymentsStatusList/claimsPaymentsStatus.models"
import { useEffect } from "react"
import { getClaimsPaymentsStatusGridData, getPaymentStatus } from "queries"

export const usePaymentStatus = () => {
  const onCompleted = (res) => {
    const companiesMapped = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...companiesMapped]
  }
  const fetch = async () => {
    const response = await getPaymentStatus()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const paymentStatuses = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PAYMENT_STATUS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return paymentStatuses
}

export const useClaimsPaymentsGrid = (
  claimsPaymentsFilter: ClaimsPaymentsStatusGridRequest
) => {
  const getUniqueValues = (data): ClaimsPaymentsStatusGridData[] => {
    const flattened = data.pages.flatMap(
      (page) => page.claimsPaymentStatusItems
    )
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as ClaimsPaymentsStatusGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getClaimsPaymentsStatusGridData(claimsPaymentsFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CLAIMS_PAYMENTS_STATUS_GRID_DATA,
      claimsPaymentsFilter.orderBy,
      claimsPaymentsFilter.isAscending ? "true" : "false",
      claimsPaymentsFilter.search ?? "",
      claimsPaymentsFilter.paymentDateFrom?.toString() ?? "",
      claimsPaymentsFilter.paymentDateTo?.toString() ?? "",
      claimsPaymentsFilter.companyId?.toString() ?? "",
      claimsPaymentsFilter.statusId?.toString() ?? "",
      claimsPaymentsFilter.paymentRef ?? "",
      claimsPaymentsFilter.tenantRef ?? "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= claimsPaymentsFilter.currentPage
          ? claimsPaymentsFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, claimsPaymentsFilter])

  const claimsPayments = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > claimsPaymentsFilter.currentPage

  return { claimsPayments, hasMore, isLoading, isError }
}
