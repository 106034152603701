function useFilter(data = []) {
  // Filter an Array of Objects by a given string (or number)
  const filterObjArray = (filterString) =>
    data.filter((row) => {
      // Compare stringified version of given row of data against string.
      if (JSON.stringify(Object.values(row)).includes(filterString)) {
        return row
      }
      return false
    })
  return { filterObjArray }
}
export default useFilter
