import React from "react"
import { StyledCheckbox } from "./styled"

const CheckBox = ({ id, onChange, title, checked, indeterminate }) => (
  <>
    <StyledCheckbox>
      <label className="checkbox-label" htmlFor={id}>
        {title}
        <input id={id} type="checkbox" checked={checked} onChange={onChange} />
        <span className={indeterminate ? "indeterminate" : "checkmark"} />
      </label>
    </StyledCheckbox>
  </>
)

export default CheckBox
