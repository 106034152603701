import styled from "styled-components"

const StyledEditorCard = styled.div`
  padding: var(--spacing-x2) 0;

  .editor-header {
    display: flex;
    align-items: center;
    font-family: var(--font-header);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-header);
    height: 20px;
  }

  .user-header {
    width: 100%;
  }

  .editing-button-header {
    display: flex;
    align-items: center;
  }

  .ql-formats {
    width: 100%;
  }

  .last-updated {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-xs);
  }

  .ql-editor strong,
  strong {
    font-weight: bold;
  }

  .ql-editor italic,
  em {
    font-style: italic;
  }
`

export default StyledEditorCard
