import { dropdownUtils } from "components/helpers"
import {
  ebateQueryKeys,
  getTenant,
  getAllUserStructureValues,
  useEbateQuery,
  getCompanyUserStructureValues,
  getRollOverStatus,
  getAgreementAggregation,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useTenant = () => {
  const fetch = async () => {
    try {
      const response = await getTenant()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const tenant = useEbateQuery([...ebateQueryKeys.QUERY_KEY_TENANT], fetch, {
    cacheTime: CacheTime.medium,
    staleTime: StaleTime.medium,
    refetchOnMount: "always",
  })

  return tenant
}

export const useAllUserStructureValues = () => {
  const onCompleted = (data) => dropdownUtils.mapToDropDownOptionFromApi(data)
  const fetch = async () => {
    try {
      const response = await getAllUserStructureValues()
      if (response?.status === 200) {
        return onCompleted(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const allUserStructureValues = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ALL_USER_STRUCTURE_VALUES],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return allUserStructureValues
}

export const useCompanyStructureValues = () => {
  const onCompleted = (data) => dropdownUtils.mapToDropDownOptionFromApi(data)
  const fetch = async () => {
    const response = await getCompanyUserStructureValues()
    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const companyUserStructureValues = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANY_STRUCTURE_VALUES],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return companyUserStructureValues
}

export const useRolloverStatus = () => {
  const onCompleted = (data) => dropdownUtils.mapToDropDownOptionFromApi(data)
  const fetch = async () => {
    try {
      const response = await getRollOverStatus()
      if (response?.status === 200) {
        return onCompleted(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rolloverStatus = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ROLLOVER_STATUS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return rolloverStatus
}

export const useAgreementAggregation = () => {
  const onCompleted = (data) => dropdownUtils.mapToDropDownOptionFromApi(data)
  const fetch = async () => {
    try {
      const response = await getAgreementAggregation()
      if (response?.status === 200) {
        return onCompleted(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rolloverStatus = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGREEMENT_AGGREGATION],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return rolloverStatus
}
