import styled from "styled-components"

interface Props {
  collapse?: boolean | null
  hover?: boolean | null
  heightCalc?: number | null
}

const StyledFade = styled.div<Props>`
  opacity: 0;
  max-height: ${({ heightCalc }) => heightCalc || `1000`}px;

  ${({ collapse }) =>
    collapse !== undefined &&
    (collapse
      ? `
    animation-fill-mode: both;
    animation-duration: 875ms;
    animation-delay: 125ms;
    animation-iteration-count: 1;
animation-name: fade-when-display;

  @keyframes fade-when-display {
      100% {
        opacity: 1;
        transform: none;
      }
    }
  transition: max-height 500ms ease-in-out;
  `
      : `
      animation-fill-mode: both;
     animation-duration: 875ms;
    animation-delay: 125ms;
    animation-iteration-count: 1;
animation-name: fade-when-collapse;

  @keyframes fade-when-collapse {
    
    100% { 
      opacity: 0;
      }
        
     
    }
  transition: max-height 500ms ease-in-out;
    max-height:0px;
  `)};

  ${({ hover }) =>
    hover !== undefined &&
    (hover
      ? `
    animation-fill-mode: both;
    animation-duration: 1000ms;
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-name: fade-when-hover;

    @keyframes fade-when-hover {
   
        
      100% {
        opacity: 1;
        transform: none;
      }
    }
    `
      : `animation-fill-mode: both;
     animation-duration: 500ms;
    animation-delay: 0ms;
    animation-iteration-count: 1;
animation-name: fade-when-not-hover;
  opacity: 0;

  @keyframes fade-when-not-hover {
    
    
    100% {
      opacity: 0;
      }
        
     
    }`)}
`
export default StyledFade
