import { DecimalResponse, StringResponse } from "components/models"
import {
  CalculationJobCurrentStatus,
  ImportOperation,
  RebateTransactionMonthly,
} from "components/pages/dashboard/dashboard.models"
import EbateAxios from "queries/ebateAxios"

const dashboardUrl = "/api/Dashboard"

export const getRebateAccrualYTD = async () => {
  const url = `${dashboardUrl}/GetRebateAccrualYTD`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<StringResponse>(url)
}

export const getRebateVSTargetPercentage = async () => {
  const url = `${dashboardUrl}/getRebateVSTargetPercentage`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<DecimalResponse>(url)
}

export const getRebateVSBudgetPercentage = async () => {
  const url = `${dashboardUrl}/GetRebateVSBudgetPercentage`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<DecimalResponse>(url)
}

export const getActiveRebatesCount = async () => {
  const url = `${dashboardUrl}/GetActiveRebatesCount`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<DecimalResponse>(url)
}

export const getCurrentYearRebateByMonthReport = async () => {
  const url = `${dashboardUrl}/GetCurrentYearRebateByMonthReport`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RebateTransactionMonthly[]>(url)
}

export const getCurrentYearRebateByMonthAndCompanyReport = async (
  companyId: number
) => {
  const url = `${dashboardUrl}/GetCurrentYearRebateByMonthAndCompanyReport/${companyId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RebateTransactionMonthly[]>(url)
}

export const getCalculationEngineStatus = async () => {
  const url = `${dashboardUrl}/GetCalculationEngineStatus`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CalculationJobCurrentStatus[]>(url)
}

export const getPortalImportStatus = async (companyId: number) => {
  const url = `${dashboardUrl}/GetPortalImportStatus/${companyId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ImportOperation[]>(url)
}

export const getEbateImportStatus = async () => {
  const url = `${dashboardUrl}/GetEbateImportStatus`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ImportOperation[]>(url)
}
