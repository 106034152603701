import styled from "styled-components"

const StyledHeaderForm = styled.div`
  .edit-links {
    justify-content: right;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .icon-container {
    margin: 0;
    align-self: center;
  }
`
export default StyledHeaderForm
