import { useEffect, useState } from "react"
import { getClientName } from "components/helpers"

export const useLogo = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>(null)
  const [logo, setLogo] = useState<any>(null)

  useEffect(() => {
    const fetchImage = async () => {
      try {
        let response
        const clientName = getClientName()
        switch (clientName) {
          case "vizient":
            response = await import("assets/vizientlogo.png")
            break
          default:
            response = await import("assets/ebatelogo.png")
            break
        }
        setLogo(response.default)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchImage()
  }, [])

  return {
    loading,
    error,
    logo,
  }
}
