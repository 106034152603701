import styled from "styled-components"
import { MultiStateCheckbox } from "primereact/multistatecheckbox"

const StyledMultiStateCheckbox = styled(MultiStateCheckbox)`
  .p-checkbox-box.p-highlight {
    background-color: var(--color-ebateSecondary);
    border: solid 2px var(--color-ebateSecondary);
  }
  .p-checkbox-box.p-highlight:not(.p-disabled).p-focus {
    background-color: var(--color-ebateSecondary);
    border: solid 2px var(--color-ebateSecondary);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: var(--color-ebateSecondary);
    border: solid 2px var(--color-ebateSecondary);
  }
`
export default StyledMultiStateCheckbox
