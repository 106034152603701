import { breakpoints } from "theme/helpers"

// returns the name of the currently in use breakpoint

const getCurrentBreakpoint = (viewPortWidth: undefined | number) => {
  let breakpoint = ""
  Object.keys(breakpoints).forEach((key) => {
    if (viewPortWidth && viewPortWidth > breakpoints[key]) {
      breakpoint = key
    }
  })
  return breakpoint
}
export default getCurrentBreakpoint
