import styled from "styled-components"

const Header = styled.div`
  font-family: var(--font-header);
  font-size: var(--font-size-header);
  width: 100%;
  margin-bottom: var(--spacing-x2);
  line-height: var(--lineHeight-lg);
`

export default Header
