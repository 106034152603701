import React, { useContext, useRef } from "react"
import { NavLink } from "react-router-dom"
import { FlexContainer, SecondaryButton } from "components/library"
import {
  AuthenticationContext,
  AzureProviderContext,
  UserContext,
} from "stores"
import { useTranslation } from "react-i18next"
import { useOutsideAlerter } from "components/hooks"
import { signOutApi } from "queries"
import { StyledSignOut } from "./styled"
import { UserInfoModel } from "../../models/userInfo.model"
import { PrimaryButton } from "../button/primaryButton"

const SignOutMenu = () => {
  const { signOut } = useContext(AuthenticationContext)
  const loginLang = "login"
  const { t } = useTranslation(["translation", loginLang])

  const url = "https://e-batehelp.zendesk.com/hc/en-us/requests/new"

  const azureContext = useContext(AzureProviderContext)
  const { userInfo } = useContext(UserContext) as { userInfo: UserInfoModel }

  const signOutMenuRef = useRef(null)

  const { isComponentVisible, setIsComponentVisible } =
    useOutsideAlerter(signOutMenuRef)

  const logout = async () => {
    await signOutApi()
    if (azureContext && azureContext.authProvider)
      azureContext.authProvider.logout()
    signOut()
  }

  // Initialize pendo
  ;(window as any).pendo?.initialize({
    visitor: { id: userInfo.email },
    account: { id: "e-bate" },
  })

  return (
    <StyledSignOut>
      <NavLink
        to=""
        key="/"
        style={{ textAlign: "center" }}
        className="signout-link"
        onClick={(e) => {
          e.preventDefault()
          setIsComponentVisible(!isComponentVisible)
        }}
      >
        {userInfo.initials}
      </NavLink>
      {isComponentVisible && (
        <div id="userMenu" ref={signOutMenuRef}>
          <FlexContainer className="account-menu" direction="row">
            <div className="signout-link user-initials">
              {userInfo.initials}
            </div>
            <FlexContainer direction="column" className="user-info">
              <div className="user-info-name">{userInfo.userName}</div>
              <div className="user-info-email">{userInfo.email}</div>
            </FlexContainer>
          </FlexContainer>
          <div className="user-info-menu-others">
            {userInfo.companyName && (
              <FlexContainer direction="row" className="user-info-menu-company">
                <div>Company:</div>
                <div className="user-info-company">{userInfo.companyName}</div>
              </FlexContainer>
            )}
            <FlexContainer direction="row" className="user-info-menu-role">
              <div>Role:</div>
              <div className="user-info-role">{userInfo.role}</div>
            </FlexContainer>
          </div>
          <FlexContainer direction="row" className="support-tenant">
            {userInfo.isEbateUser && (
              <PrimaryButton
                icon="pi pi-comments"
                iconToRightText
                onClick={() => window?.open(url, "_blank")?.focus()}
                text="Support"
                className="btn-support"
                id="supportButton"
              />
            )}
            <FlexContainer direction="column" align="flex-end">
              <div className="user-info-teantIdentifier">
                {userInfo.tenantIdentifier}
              </div>
              <div className="user-info-lastActivity">
                {userInfo.lastActivity}
              </div>
            </FlexContainer>
          </FlexContainer>

          <SecondaryButton
            id="btnSignOut"
            className="btnSignOut"
            onClick={() => logout()}
            text={t(`${loginLang}:signOut`)}
          />
        </div>
      )}
    </StyledSignOut>
  )
}

export default SignOutMenu
