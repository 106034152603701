const zindex = {
  onTop: 2147483647,
  page: 100,
  header: 1000,
  subNav: 1200,
  modal: 400,
  subHeader: 200,
}

export default zindex
