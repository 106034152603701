import { AxiosResponse } from "axios"
import { ValidationResultModel } from "components/models"
import {
  CalculationTypeGridResponse,
  CalculationTypeUpdateRequest,
  CalculationTypeItemResponse,
} from "components/pages/maintanance/clientMaintenance/clientTabs/calculationTypesTab/calculationTypes.models"
import EbateAxios from "queries/ebateAxios"

const calculationTypeUrl = "/api/calculationtype"

export const getCalculationTypeGridData = async () => {
  const url = `${calculationTypeUrl}/GetCalculationTypeGridData`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CalculationTypeGridResponse[]>(url)
}

export const updateCalculationType = async (
  request: CalculationTypeUpdateRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${calculationTypeUrl}/UpdateCalculationType`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<CalculationTypeUpdateRequest, ValidationResultModel>(
    url,
    request
  )
}

export const getCalculationType = async (id) => {
  const url = `${calculationTypeUrl}/GetCalculationType/${id}`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<CalculationTypeItemResponse>(url)
}

export const getCalculationTypeComboValues = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any[]>(`${calculationTypeUrl}/GetCalculationTypeComboValues`)
}
