const lineHeight = {
  xs: "14px",
  sm: "16px",
  md: "20px",
  lg: "28px",
  xl: "36px",
  header: "38px",
  subHeader: "30px",
}

export default lineHeight
