import styled from "styled-components"
import { Button } from "primereact/button"

const StyledIconButton = styled(Button)`
  margin: 0 var(--spacing-half);
  background-color: ${({ bgcolor }) =>
    bgcolor || "var(--color-ebateIconButton)"};

  ${({ bgcoloronhover }) => {
    if (bgcoloronhover) {
      return `
      &:hover {
          background-color:
        ${bgcoloronhover} !important;
       border: 1px solid ${bgcoloronhover} !important;};
       
    }
      `
    }
    return `
    ve&:hor {
      background-color:
        var(--color-ebateIconButtonHover) !important};        
    }`
  }}

  ${({ size }) => {
    if (size === "sm") {
      return `
        height: 20px !important;
        width: 20px !important;
        font-size: var(--font-size-xs);

      .p-button-icon {
          font-size: var(--font-size-xs) !important;
        }
        `
    }

    if (size === "m") {
      return `
        height: 30px !important;
        width: 30px !important;
        font-size: var(--font-size-sm);

      .p-button-icon {
          font-size: var(--font-size-sm) !important;
        }
        `
    }

    return `
        height: 40px !important;
        width: 40px !important;
        font-size: var(--font-size-xl);
        margin-left: var(--spacing-base);

        .p-button-icon {
            font-size: var(--font-size-xl) !important;
        }`
  }}
`

export default StyledIconButton
