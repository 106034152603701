import React from "react"
import { StyledBarGraph } from "./styled"
import LegendItem from "./legendItem"

const BarGraph = (props) => {
  const { data } = props

  const options = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  }

  return (
    <>
      <StyledBarGraph type="bar" data={data} options={options} />

      <div className="bar-legend">
        {data.labels?.map((d, i) => (
          <LegendItem
            key={d}
            color={data.datasets[0]?.backgroundColor[i]}
            text={d}
            value={data.datasets[0]?.data[i]}
          />
        ))}
      </div>
    </>
  )
}

export default BarGraph
