import { useUserInfo } from "components/hooks"
import React, { createContext } from "react"

export const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [userInfo] = useUserInfo()

  return (
    <UserContext.Provider value={{ userInfo }}>{children}</UserContext.Provider>
  )
}

export default UserProvider
