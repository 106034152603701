import styled from "styled-components"

const StyledFormLabel = styled.span`
  font-size: var(--font-size-md);
  font-family: var(--font-bold);
  padding: ${({ flat }) =>
    flat
      ? "0 var(--spacing-half) 0 var(--spacing-half)"
      : "var(--spacing-half) 0"};
  margin-bottom: var(--spacing-half);
  width: ${({ flat }) => (flat ? "auto" : "100%")};
`

export default StyledFormLabel
