import {
  AgreementRequestGridResponse,
  UserAgreementGridFilterRequest,
} from "components/pages/pricemanagement/approvals/approvalAgreement/approvalAgreement.model"
import {
  AmendAgreementRequestGridItems,
  UserAmendGridFilterRequest,
} from "components/pages/pricemanagement/approvals/approvalAmend/approvalAmend.model"
import { mapClaimPaymentApprovalFromApiToVm } from "components/pages/pricemanagement/approvals/approvalClaimPayment/approvalClaimPayment.map"
import {
  ClaimPaymentApprovalGridData,
  ClaimPaymentApprovalRequest,
} from "components/pages/pricemanagement/approvals/approvalClaimPayment/approvalClaimPayments.model"
import { mapRateApprovalFromApiToVm } from "components/pages/pricemanagement/approvals/approvalRate/approvalRateList/approvalRateList.map"
import {
  RateApprovalRequest,
  RebateRateOverviewGridItem,
} from "components/pages/pricemanagement/approvals/approvalRate/approvalRateList/approvalRateList.models"
import {
  ebateQueryKeys,
  getPaymentsClaimsRequestsGridData,
  getRebateRatesByRequest,
  getRequestsRebateRateGridData,
  getUserAgreementRequestGridData,
  getUserAmendAgreementRequestGridData,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useRebateRatesByRequest = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getRebateRatesByRequest(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateRatesByRequest = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_RATES_BY_REQUEST, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return rebateRatesByRequest
}

export const useAgreementApprovalGridData = (
  agreementApprovalFilter: UserAgreementGridFilterRequest
) => {
  const getUniqueValues = (data): AgreementRequestGridResponse[] => {
    const flattened = data.pages.flatMap(
      (page) => page.agreementRequestGridItems
    )
    if (flattened.length > 0 && flattened[0] === null) {
      return []
    }
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.requestId, item])).values(),
    ] as AgreementRequestGridResponse[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getUserAgreementRequestGridData(
      agreementApprovalFilter
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_AGREEMENT_APPROVAL_GRID_DATA,
      agreementApprovalFilter.orderBy,
      agreementApprovalFilter.isAscending ? "true" : "false",
      agreementApprovalFilter.search || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= agreementApprovalFilter.currentPage
          ? agreementApprovalFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, agreementApprovalFilter])

  const approvals = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > agreementApprovalFilter.currentPage

  return { approvals, hasMore, isLoading, isError }
}

export const useAmendApprovalGridData = (
  amendApprovalFilter: UserAmendGridFilterRequest
) => {
  const getUniqueValues = (data): AmendAgreementRequestGridItems[] => {
    const flattened = data.pages.flatMap(
      (page) => page.amendAgreementRequestGridItems
    )
    if (flattened.length > 0 && flattened[0] === null) {
      return []
    }
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.requestId, item])).values(),
    ] as AmendAgreementRequestGridItems[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getUserAmendAgreementRequestGridData(
      amendApprovalFilter
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_AMEND_APPROVAL_GRID_DATA,
      amendApprovalFilter.orderBy,
      amendApprovalFilter.isAscending ? "true" : "false",
      amendApprovalFilter.search || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= amendApprovalFilter.currentPage
          ? amendApprovalFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, amendApprovalFilter])

  const approvals = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > amendApprovalFilter.currentPage

  return { approvals, hasMore, isLoading, isError }
}

export const useClaimPaymentApprovalGridData = (
  paymentApprovalFilter: ClaimPaymentApprovalRequest
) => {
  const getUniqueValues = (data): ClaimPaymentApprovalGridData[] => {
    const flattened = data.pages.flatMap((page) => page.userRequestsGridItem)
    if (flattened.length > 0 && flattened[0] === null) {
      return []
    }
    const mappedFlattened = flattened.map((item) =>
      mapClaimPaymentApprovalFromApiToVm(item)
    )
    const arrayUniqueByKey = [
      ...new Map(mappedFlattened.map((item) => [item.id, item])).values(),
    ] as ClaimPaymentApprovalGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getPaymentsClaimsRequestsGridData(
      paymentApprovalFilter
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CLAIM_PAYMENT_APPROVAL_GRID_DATA,
      paymentApprovalFilter.orderBy,
      paymentApprovalFilter.isAscending ? "true" : "false",
      paymentApprovalFilter.search || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= paymentApprovalFilter.currentPage
          ? paymentApprovalFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, paymentApprovalFilter])

  const approvals = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > paymentApprovalFilter.currentPage

  return { approvals, hasMore, isLoading, isError }
}

export const useRateApprovalGridData = (
  rateApprovalFilter: RateApprovalRequest
) => {
  const getUniqueValues = (data): RebateRateOverviewGridItem[] => {
    const flattened = data.pages.flatMap(
      (page) => page.rebateRateOverviewGridItem
    )
    if (flattened.length > 0 && flattened[0] === null) {
      return []
    }
    const mappedFlattened = flattened.map((item) =>
      mapRateApprovalFromApiToVm(item)
    )
    const arrayUniqueByKey = [
      ...new Map(mappedFlattened.map((item) => [item.id, item])).values(),
    ] as RebateRateOverviewGridItem[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getRequestsRebateRateGridData(rateApprovalFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_RATE_APPROVAL_GRID_DATA,
      rateApprovalFilter.orderBy,
      rateApprovalFilter.isAscending ? "true" : "false",
      rateApprovalFilter.search || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= rateApprovalFilter.currentPage
          ? rateApprovalFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, rateApprovalFilter])
  const approvals = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > rateApprovalFilter.currentPage

  return { approvals, hasMore, isLoading, isError }
}
