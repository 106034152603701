import styled from "styled-components"

export const StyledCommandBar = styled.div`
  width: 100%;
  .wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
  .search {
    width: 70%;
  }

  .sort-icons {
    width: 30%;
  }

  #sortdropdown:not(.p-disabled): hover {
    border: none;
  }

  #sortdropdown:not(.p-disabled).p-focus {
    border: none;
    box-shadow: none;
  }
`
