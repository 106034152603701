import React from "react"

import { StyledButton } from "./styled"

interface Props {
  text?: string
  children?: any
  bgcolor?: string
  transparent?: boolean
  lg?: string
  borderColor?: string
  fontSize?: string
  square?: boolean
  bold?: boolean
  width?: string
  color?: string
  onClick?: () => any
  type?: "button" | "submit" | "reset" | undefined
  id?: string
  style?: any
  disabled?: boolean
  padding?: string
  isLink?: boolean
  borderColorHover?: string
}

const Button: React.FunctionComponent<Props> = ({
  text,
  children,
  ...rest
}) => <StyledButton {...rest}>{text || children}</StyledButton>

Button.defaultProps = {
  text: undefined,
  children: undefined,
  bgcolor: undefined,
  transparent: undefined,
  lg: undefined,
  borderColor: undefined,
  fontSize: undefined,
  square: undefined,
  bold: undefined,
  width: undefined,
  color: undefined,
  onClick: undefined,
  id: undefined,
  style: undefined,
  disabled: undefined,
  type: "button",
  borderColorHover: undefined,
}

export default Button
