const touch = {
  base: "10px",
  x2: "20px",
  x3: "30px",
  x4: "40px",
  x5: "50px",
  half: "5px",
  quarter: "2px",
}

export default touch
