const breakpoints = {
  xs: 500,
  sm: 768,
  md: 1024,
  lg: 1360,
  xl: 1920,
}

const screensizes = {
  smUp: `(min-width: ${breakpoints.sm}px)`,
  mdUp: `(min-width: ${breakpoints.md}px)`,
  lgUp: `(min-width: ${breakpoints.lg}px)`,
  xlUp: `(min-width: ${breakpoints.xl}px)`,
  smDown: `(max-width: ${breakpoints.sm}px)`,
  mdDown: `(max-width: ${breakpoints.md}px)`,
  lgDown: `(max-width: ${breakpoints.lg}px)`,
  xlDown: `(max-width: ${breakpoints.xl}px)`,
}

export default screensizes
export { breakpoints }
