import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import logo from "assets/ebate-loader.gif"
import { UseQueryResult } from "@tanstack/react-query"
import { OptionModel } from "components/helpers"
import { StyledDropdownEbate } from "./styled/styledDropdownEbate"
import { QueryFilterModel } from "./query.model"
import { FormInput } from "../formInput"

interface Props<T extends QueryFilterModel, Y extends OptionModel> {
  data: T
  useDropDownQuery: (data: T) => UseQueryResult<any, unknown>
  onChange: (selection: Y | undefined) => void
  selectedItem: Y
  heading: string
  name: string
  required?: boolean
}

export const DropdownEbate = <
  T extends QueryFilterModel,
  Y extends OptionModel
>({
  data,
  useDropDownQuery,
  onChange,
  selectedItem,
  heading,
  name,
  required,
}: Props<T, Y>): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>("")
  const [searchTimeout, setSearchTimeout] = useState<any>()
  const [dropdownList, setDropdownList] = useState<Y[]>([])
  const [itemSelected, setItemSelected] = useState<Y | undefined>(undefined)
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const [showPanel, setShowPanel] = useState(false)

  const dropdownQuery = useDropDownQuery({ ...data, query })

  const filterByText = (textToFilter: string) => {
    setQuery(textToFilter)
  }

  const textChanged = (event: any) => {
    clearTimeout(searchTimeout)
    setSearchTimeout(
      setTimeout(() => {
        filterByText(event)
      }, 500)
    )
  }

  React.useEffect(() => {
    if (!dropdownQuery.isFetching && dropdownQuery.data) {
      if (
        itemSelected?.value !== undefined &&
        itemSelected?.value !== null &&
        selectedItem?.value !== null &&
        selectedItem?.value !== undefined &&
        !dropdownQuery.data?.map((c) => c.value).includes(itemSelected?.value)
      ) {
        if (query === "") {
          const options = dropdownQuery.data.concat([
            {
              value: itemSelected.value,
              title: itemSelected.title,
            },
          ])

          setDropdownList(options)
        } else {
          setDropdownList(dropdownQuery.data)
        }
      } else if (itemSelected?.value !== selectedItem?.value) {
        // For editing values
        if (
          selectedItem?.value !== null &&
          selectedItem?.value !== undefined &&
          selectedItem?.value !== ""
        ) {
          const itemInList = dropdownQuery?.data?.find(
            (d) => d.value === selectedItem.value
          )
          if (itemInList) {
            setDropdownList(dropdownQuery.data)
            setItemSelected(itemInList)
          } else {
            const options = [...dropdownQuery.data, selectedItem]
            setDropdownList(options)
            setItemSelected(selectedItem)
          }
        } else {
          // For clearing data from parent component
          setItemSelected(selectedItem)
          setDropdownList(dropdownQuery.data)
        }
      } else {
        setDropdownList(dropdownQuery.data)
      }
    } else {
      setDropdownList([])
    }
  }, [dropdownQuery.isFetching, dropdownQuery.data, selectedItem])

  React.useEffect(() => {
    if (dropdownList?.length > 0) {
      if (itemSelected !== undefined) {
        const selectedIndex = dropdownList.indexOf(itemSelected)

        setFocusedIndex(selectedIndex)
      }
    } else {
      setFocusedIndex(-1)
    }
  }, [dropdownList])

  const handleSelection = (selectedIndex: number) => {
    const selectedItem = dropdownList[selectedIndex]

    setItemSelected(selectedItem)
    onChange(selectedItem)
  }

  const resetSearchComplete = useCallback(() => {
    setQuery("")

    setShowPanel(false)
  }, [])

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e
    let nextIndexCount = 0

    // move down
    if (key === "ArrowDown") {
      nextIndexCount = (focusedIndex + 1) % dropdownList.length
      setFocusedIndex(nextIndexCount)

      const element = document.getElementById(`list${nextIndexCount}`)
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        })
      }
    }

    // move up
    if (key === "ArrowUp") {
      nextIndexCount =
        (focusedIndex + dropdownList.length - 1) % dropdownList.length
      setFocusedIndex(nextIndexCount)
      const element = document.getElementById(`list${nextIndexCount}`)
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        })
      }
    }

    // hide search results
    if (key === "Escape") {
      resetSearchComplete()
    }

    // select the current item
    if (key === "Enter") {
      if (focusedIndex !== -1) {
        nextIndexCount = focusedIndex
        e.preventDefault()
        handleSelection(nextIndexCount)

        setFocusedIndex(nextIndexCount)
      }
      setQuery("")

      setShowPanel(false)
    }
  }

  type changeHandler = ChangeEventHandler<HTMLInputElement>
  const handleChange: changeHandler = (e) => {
    textChanged(e.target.value)
  }

  const fieldRef = useRef<HTMLDivElement | null>(null)
  const panelRef = useRef<HTMLDivElement | null>(null)

  const positionPanel = () => {
    if (fieldRef.current && panelRef.current) {
      const fieldRect = fieldRef.current.getBoundingClientRect()

      const viewportHeight = window.innerHeight
      const bottomSpace = viewportHeight - fieldRect.bottom

      const shouldShowAtTop = panelRef.current.offsetHeight > bottomSpace

      if (shouldShowAtTop)
        panelRef.current.style.bottom = `${fieldRef.current.offsetHeight}px`
    }
  }

  React.useEffect(() => {
    positionPanel()
  }, [showPanel])
  const openPanel = () => {
    setShowPanel(true)
    positionPanel()
    if (dropdownList?.length > 0) {
      if (itemSelected !== undefined) {
        const selectedIndex = dropdownList.indexOf(itemSelected)

        setFocusedIndex(selectedIndex)
      }
    } else {
      setFocusedIndex(-1)
    }
  }

  useEffect(() => {
    if (focusedIndex !== -1) handleSelection(focusedIndex)
  }, [focusedIndex])

  const cancelSelection = (event) => {
    event.stopPropagation()

    setItemSelected(undefined)
    onChange(undefined)
    setFocusedIndex(-1)
    setShowPanel(false)
  }

  return (
    <FormInput heading={heading} required={required}>
      <StyledDropdownEbate
        onBlur={resetSearchComplete}
        onClick={() => openPanel()}
      >
        <div className="dropdown-box" ref={fieldRef} data-testid={name}>
          <div
            className="dropdown-box-text"
            data-placeholder="Select Option..."
          >
            {itemSelected?.title}
          </div>

          <div className="dropdown-icons">
            {itemSelected !== undefined && (
              <div
                className="pi pi-times dropdown-icon"
                onClick={(event) => cancelSelection(event)}
                role="generic"
              />
            )}

            <div className="pi pi-chevron-down dropdown-icon" role="generic" />
          </div>
        </div>

        {showPanel && (
          <div className="dropdown-panel">
            <div onKeyDown={handleKeyDown} role="list" className="relative">
              <div className="dropdown-searchbox">
                <input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  onChange={handleChange}
                  type="text"
                  placeholder="Search your query..."
                />
                <i className="pi pi-search dropdown-icon" />
              </div>

              {!dropdownQuery.isFetching ? (
                <div className="dropdown-item-panel">
                  {dropdownList?.length > 0 ? (
                    dropdownList.map((item, index) => (
                      <div
                        key={`list${index}`}
                        id={`list${index}`}
                        onMouseDown={() => {
                          handleSelection(index)
                          resetSearchComplete()
                        }}
                        role="listitem"
                        className={
                          item.value === itemSelected?.value
                            ? "dropdown-item dropdown-item-selected"
                            : "dropdown-item"
                        }
                      >
                        {item.title}
                      </div>
                    ))
                  ) : (
                    <div className="not-results-found">No results found</div>
                  )}
                </div>
              ) : (
                <img
                  className="spinner"
                  src={logo}
                  alt="Loading..."
                  id="imgLoadMore"
                />
              )}
            </div>
          </div>
        )}
      </StyledDropdownEbate>
    </FormInput>
  )
}
