const dateFormat = localStorage.getItem("i18nextLng") ?? "en-GB"

const numberFormatter = new Intl.NumberFormat(dateFormat, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const customNumberFormatter = (decimals) =>
  new Intl.NumberFormat(dateFormat, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

const getDecimalSeparator = (locale: string) => {
  const numberWithDecimalSeparator = 1.1
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find((part) => part.type === "decimal")?.value
}

const formatPercentage = (value) =>
  Intl.NumberFormat(dateFormat, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)

export default {
  numberFormatter,
  customNumberFormatter,
  getDecimalSeparator,
  formatPercentage,
}
