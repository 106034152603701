import styled from "styled-components"

interface Props {
  bgcolor?: string
  padding?: string
  color?: string
}
const StyledCard = styled.div<Props>`
  background: ${({ bgcolor }) => bgcolor || "var(--color-background)"};
  color: ${({ color }) => color || "var(--color-text)"};
  border-radius: var(--border-radius-base);
  padding: ${({ padding }) => padding || "var(--spacing-x2)"};
  box-shadow: var(--shadow-sm);
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  &:hover {
    box-shadow: var(--shadow-md);
  }
`

export default StyledCard
