import React from "react"
import { Icon } from "components/library"
import { FormInput } from "../formInput"
import { StyledInputText } from "./styled"

const InputText = (props) => {
  const {
    transparent,
    icon,
    heading,
    value,
    onChange,
    placeholder,
    name,
    flat,
    onKeyUp,
    width,
    type,
    onBlur,
    maxLength,
    required,
    disabled,
    autoFocus,
    id,
    autoComplete,
  } = props

  return (
    <FormInput heading={heading} flat={flat} width={width} required={required}>
      <span className="input-icon-left" style={{ width }}>
        {icon && <Icon icon={icon} />}
        <StyledInputText
          id={id}
          $transparent={transparent}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          width={width}
          type={type}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          data-testid={name}
        />
      </span>
    </FormInput>
  )
}

export default InputText
