import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getComments, getGlobalComments } from "../comment.query"

export const useComments = (id: number, dealType: number) => {
  const fetchComments = async () => {
    try {
      const response = await getComments(id, dealType)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const comments = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_COMMENTS_GET,
      id.toString(),
      dealType.toString(),
    ],
    fetchComments,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return comments
}

export const useGlobalComments = (id: number, dealType: number) => {
  const fetchComments = async () => {
    try {
      const response = await getGlobalComments(id, dealType)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const comments = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_COMMENTS_GLOBAL_GET,
      id.toString(),
      dealType.toString(),
    ],
    fetchComments,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return comments
}
