import EbateAxios from "queries/ebateAxios"

const extensionFieldUrl = "/api/ExtensionField"

export const getExtensionFieldDistinctValues = async (
  basicDimensionId: number,
  querySearch: string
) => {
  const url =
    querySearch !== undefined && querySearch !== null && querySearch !== ""
      ? `${extensionFieldUrl}/GetExtensionFieldDistinctValues/${basicDimensionId}?searchQuery=${querySearch}`
      : `${extensionFieldUrl}/GetExtensionFieldDistinctValues/${basicDimensionId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<string[]>(url)
}
