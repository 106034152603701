import { dropdownUtils } from "components/helpers"
import {
  UsersGridData,
  UsersGridRequest,
} from "components/pages/maintanance/users/userList/userList.models"
import {
  ebateQueryKeys,
  useEbateQuery,
  getUserPermissionOptions,
  getUserById,
  getAllUsersCombo,
  getPortalUserById,
  getNormalUsersGridData,
  useEbateInfinityQuery,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useUserPermissionOptions = () => {
  const fetch = async () => {
    try {
      const response = await getUserPermissionOptions()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const userPermissionOptions = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_USER_PERMISSION_OPTIONS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return userPermissionOptions
}

export const useUserById = (id: string) => {
  const fetch = async () => {
    try {
      const response = await getUserById(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const user = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_USER_BY_ID, id],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return user
}

export const useAllUsersCombo = () => {
  const onCompleted = (data) => dropdownUtils.mapToDropDownOptionFromApi(data)

  const fetch = async () => {
    try {
      const response = await getAllUsersCombo()
      if (response?.status === 200) {
        return onCompleted(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const allUsers = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ALL_USERS_COMBO],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return allUsers
}

export const usePortalUserById = (id: string) => {
  const fetch = async () => {
    try {
      const response = await getPortalUserById(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const user = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PORTAL_USER_BY_ID, id],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return user
}

export const useUserGridData = (filter: UsersGridRequest) => {
  const getUniqueValues = (data): UsersGridData[] => {
    const flattened = data.pages.flatMap((page) => page.userGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as UsersGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getNormalUsersGridData(filter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_USER_GRID_DATA,
      filter.orderBy,
      filter.isAscending ? "true" : "false",
      filter.search ?? "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= filter.currentPage
          ? filter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, filter])

  const users = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > filter.currentPage

  return { users, hasMore, isLoading, isError }
}
