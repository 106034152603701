import { TenantConfiguration } from "models/beconfiguration/beconfiguration.models"
import EbateAxios from "queries/ebateAxios"

const configurationUrl = "/api/Configuration"

export const getTenantConfiguration = async () => {
  const url = `${configurationUrl}/getTenantConfiguration`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<TenantConfiguration>(url)
}
