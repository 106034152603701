import styled from "styled-components"

export const StyledNoteFormComponent = styled.div`
  width: 60vw;
  max-width: 650px;
  padding: 1rem;
  background-color: var(--gradient-ebateSecondary);
  .footer {
    width: 100%;
    padding-top: var(--spacing-base);
  }
  .styled-icon {
    background-color: var(--color-ebatePrimary);
    color: white;
    border-radius: 50%;
    padding: 0.5rem;
    margin: 0.2rem;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-component {
    padding-bottom: var(--spacing-x5);
  }
`
