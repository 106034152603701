import React from "react"
import { StyledModalCommonHeader } from "./styled"

interface Props {
  title: string
}
const ModalCommonHeader: React.FC<Props> = ({ title }) => (
  <StyledModalCommonHeader>
    <h2>{title}</h2>
  </StyledModalCommonHeader>
)

export default ModalCommonHeader
