import { ComponentTypes, dateUtils } from "components/helpers"
import {
  Cbx,
  DatePicker,
  Dropdown,
  InputText,
  SecondaryButton,
  FilterRow,
  FieldFilterRow,
  FlexContainer,
  PrimaryButton,
} from "components/library"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalContext } from "stores"
import { CompanyDropdown } from "components/library/companySelect"
import { StyledFilterModal } from "./styled"
import { FilterModel, FilterValueModel } from "../filterBlock.models"

interface Props {
  onApply: (filterValues: any) => void
  filterRows: FilterRow[]
  filterInfo: any
  onClear: () => void
}
export const FilterModal: React.FC<Props> = ({
  onApply,
  filterRows,
  filterInfo,
  onClear,
}) => {
  const { t } = useTranslation()
  const { handleModal } = React.useContext(ModalContext)

  const [tempFilterInfo, setTempFilterInfo] = React.useState(filterInfo)

  const updateFilterInfo = (
    fieldName: string,
    fieldValue: string | number | boolean,
    fieldDescription: string,
    fieldTooltipDescription: string,
    fieldId: number
  ) => {
    setTempFilterInfo({
      ...tempFilterInfo,
      [fieldName]: {
        ...tempFilterInfo[fieldName],
        filterValue: [
          {
            value: fieldValue,
            title: fieldDescription,
            tooltipDescription: fieldTooltipDescription,
            id: fieldId,
          } as FilterValueModel<string | number | boolean>,
        ],
      },
    } as FilterModel<string | number | boolean>)
  }

  const renderDropdown = (ff: FieldFilterRow) => (
    <div key={ff.name} className="p-col">
      <Dropdown
        heading={ff.heading}
        name={ff.name}
        value={tempFilterInfo[ff.name].filterValue[0]?.value}
        options={ff.options}
        onChange={(e) => {
          const description = ff.options?.find(
            (o) => o.value === e.target.value
          )?.title

          updateFilterInfo(
            ff.name,
            e.value,
            description ?? "",
            `${ff.heading}: ${description}`,
            0
          )
        }}
        canSearch={ff.searchable}
        showClear={ff.clearable}
        disabled={ff.disabled}
      />
    </div>
  )

  const renderCompanyDropdown = (ff: FieldFilterRow) => (
    <div key={ff.name} className="p-col">
      <CompanyDropdown
        heading={ff.heading}
        name={ff.name}
        companySelected={tempFilterInfo[ff.name].filterValue[0]}
        companyOnChange={(e, description) => {
          updateFilterInfo(
            ff.name,
            e.value,
            description ?? "",
            `${ff.heading}: ${description}`,
            0
          )
        }}
        errorMessage=""
        displayValidations={false}
        allCompaniesActive={ff.allCompaniesActive}
      />
    </div>
  )

  const renderDatePicker = (ff) => (
    <div key={ff.name} className="p-col">
      <DatePicker
        heading={ff.heading}
        name={ff.name}
        value={tempFilterInfo[ff.name].filterValue[0]?.value}
        onChange={(e) => {
          updateFilterInfo(
            ff.name,
            e.value,
            e.value ? dateUtils.formatDateToLocale(e.value) : "",
            e.value
              ? `${ff.heading}: ${dateUtils.formatDateToLocale(e.value)}`
              : "",
            0
          )
        }}
      />
    </div>
  )

  const renderInputText = (ff: FieldFilterRow) => (
    <div key={ff.name} className="p-col">
      <InputText
        heading={ff.heading}
        value={tempFilterInfo[ff.name].filterValue[0]?.value}
        name={ff.name}
        onChange={(e) => {
          updateFilterInfo(
            ff.name,
            e.target.value,
            e.target.value,
            `${ff.heading}: ${e.target.value}`,
            0
          )
        }}
        width="100%"
      />
    </div>
  )
  const renderCbx = (ff: FieldFilterRow) => (
    <div key={ff.name} className="p-col">
      <Cbx
        heading={ff.heading}
        name={ff.name}
        checked={tempFilterInfo[ff.name].filterValue[0]?.value}
        onChange={(e) => {
          updateFilterInfo(
            ff.name,
            e.target.checked,
            ff.heading ?? "",
            ff.heading ?? "",
            0
          )
        }}
      />
    </div>
  )
  return (
    <StyledFilterModal>
      {filterRows.map((rw: FilterRow) => (
        <div key={rw.id} className="p-grid">
          {rw.fields.map((ff) => {
            switch (ff.type) {
              case ComponentTypes.Dropdown:
                return renderDropdown(ff)

              case ComponentTypes.DatePicker:
                return renderDatePicker(ff)

              case ComponentTypes.InputText:
                return renderInputText(ff)

              case ComponentTypes.Checkbox:
                return renderCbx(ff)

              case ComponentTypes.CompanyDropdown:
                return renderCompanyDropdown(ff)

              default:
                return null
            }
          })}
        </div>
      ))}
      <FlexContainer className="filter-footer" justify="flex-end">
        <SecondaryButton
          onClick={() => onClear()}
          text={t("popup-clear")}
          icon="pi pi-filter-slash"
          fontSize="var(--font-size-sm)"
          id="clear-button"
        />
        <FlexContainer justify="flex-end">
          <SecondaryButton
            onClick={() => handleModal()}
            text={t("popup-cancel")}
            fontSize="var(--font-size-sm)"
            width="120px"
            id="cancel-button"
          />
          <PrimaryButton
            text={t("popup-apply")}
            onClick={() => onApply(tempFilterInfo)}
            width="120px"
            id="apply-button"
          />
        </FlexContainer>
      </FlexContainer>
    </StyledFilterModal>
  )
}
