import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getAggregationLevels,
  getAgreementStatusCombo,
  getAgreementsOfCompany,
  getAllFinancialAccounts,
  getClaimTypes,
  getCompaniesComboValue,
  getCompanyTypes,
  getDocumentCategories,
  getDocumentTags,
  getPaymentAdjustmentTypes,
  getPeriods,
  getRebateCategories,
  getReportTypes,
  getRequestStatuses,
  getUnitOfMeasurements,
} from "queries/common/dropdownData.query"
import { OptionApi, OptionModel, dropdownUtils } from "components/helpers"

export const useDocumentTags = () => {
  const onCompleted = (res) => {
    const documentTagsMapped = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...documentTagsMapped]
  }
  const fetch = async () => {
    const response = await getDocumentTags()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const documentTags = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DOCUMENT_TAGS],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )
  return documentTags
}

export const useDocumentCategories = () => {
  const onCompleted = (res) => {
    const documentCategoriesMapped = res.map((dc) => ({
      value: dc.id,
      title: dc.name,
      isDeleted: dc.isDeleted,
    }))
    return [...documentCategoriesMapped]
  }
  const fetch = async () => {
    const response = await getDocumentCategories()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const documentCategories = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DOCUMENT_CATEGORIES],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )
  return documentCategories
}

export const useRequestStatuses = () => {
  const mapOptionData = (data: any): OptionModel[] =>
    data &&
    data.data.map((d: OptionApi) => ({
      title: d.name,
      value: d.id,
    }))

  const requestStatuses = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_REQUEST_STATUSES,
    getRequestStatuses,
    {
      select: (data) => mapOptionData(data),
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { requestStatuses }
}

export const useRebateCategories = () => {
  const onCompletedRebateCategories = (data: any) => {
    const rebateCategoriesMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...rebateCategoriesMapped]
  }

  const fetchRebateCategories = async () => {
    const rebateCategoriesResponse = await getRebateCategories()

    if (rebateCategoriesResponse?.status === 200) {
      return onCompletedRebateCategories(rebateCategoriesResponse.data)
    }
    throw new Error(rebateCategoriesResponse.statusText)
  }

  const rebateCategories = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_CATEGORIES],
    fetchRebateCategories,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return rebateCategories
}

export const useCompanyTypes = () => {
  const onCompletedCompanyTypes = (data: any) => {
    const rebateCategoriesMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...rebateCategoriesMapped]
  }

  const fetchCompanyTypes = async () => {
    const companyTypesResponse = await getCompanyTypes()

    if (companyTypesResponse?.status === 200) {
      return onCompletedCompanyTypes(companyTypesResponse.data)
    }
    throw new Error(companyTypesResponse.statusText)
  }

  const companyTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANY_TYPES],
    fetchCompanyTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return companyTypes
}

export const useUnitOfMeasurements = () => {
  const onCompletedUOM = (data: any) => {
    const uOMMapped = dropdownUtils.mapToDropDownOptionFromApi(
      data.unitOfMeasurements
    )
    return [...uOMMapped]
  }

  const fetchUOM = async () => {
    const uomResponse = await getUnitOfMeasurements()
    if (uomResponse?.status === 200) {
      return onCompletedUOM(uomResponse.data)
    }
    throw new Error(uomResponse.statusText)
  }

  const unitOfMeasurements = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_UNIT_OF_MEASUREMENTS],
    fetchUOM,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return unitOfMeasurements
}

export const usePeriods = () => {
  const onCompletedPeriods = (data: any) => {
    const periodsMapped = dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...periodsMapped]
  }

  const fetchPeriods = async () => {
    const periodsResponse = await getPeriods()
    if (periodsResponse?.status === 200) {
      return onCompletedPeriods(periodsResponse.data)
    }
    throw new Error(periodsResponse.statusText)
  }

  const periods = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PERIODS],
    fetchPeriods,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return periods
}

export const useAgreementStatus = () => {
  const onCompletedAgreementStatus = (res) => {
    const agreementStatusesFromApi =
      dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...agreementStatusesFromApi]
  }

  const fetchAgreementStatus = async (): Promise<OptionModel[]> => {
    const response = await getAgreementStatusCombo()

    if (response?.status === 200) {
      return onCompletedAgreementStatus(response.data)
    }
    return []
  }

  const agreementStatuses = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGREEMENT_STATUS_VALUES],
    fetchAgreementStatus,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return agreementStatuses
}

export const useAggregationLevels = () => {
  const onCompletedAggregationLevel = (res) => {
    const aggregationLevels = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...aggregationLevels]
  }

  const fetchAggregationLevels = async (): Promise<OptionModel[]> => {
    const response = await getAggregationLevels()

    if (response?.status === 200) {
      return onCompletedAggregationLevel(response.data)
    }
    return []
  }

  const aggregationLevels = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGGREGATION_LEVELS],
    fetchAggregationLevels,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return aggregationLevels
}

export const useClaimTypes = (enableQuery: boolean) => {
  const onCompletedClaimTypes = (res) => {
    const claimTypesFromApi = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...claimTypesFromApi]
  }

  const fetchClaimTypes = async (): Promise<OptionModel[]> => {
    const response = await getClaimTypes()

    if (response?.status === 200) {
      return onCompletedClaimTypes(response.data)
    }
    return []
  }

  const claimTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CLAIM_TYPES],
    fetchClaimTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      enabled: enableQuery,
    }
  )

  return claimTypes
}

export const useReportTypes = (reportCategoryId: number) => {
  const onCompletedReportTypes = (res) => {
    const reportTemplates = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...reportTemplates]
  }

  const fetchReportTypes = async (): Promise<OptionModel[]> => {
    const response = await getReportTypes(reportCategoryId)

    if (response?.status === 200) {
      return onCompletedReportTypes(response.data)
    }
    return []
  }

  const reportTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REPORT_TYPES_GET, reportCategoryId.toString()],
    fetchReportTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return reportTypes
}

export const useAllFinancialAccounts = () => {
  const fetch = async () => {
    const response = await getAllFinancialAccounts()

    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const financialAccounts = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ALL_FINANCIAL_ACCOUNTS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return financialAccounts
}

export const useCompanyComboValues = (isEnabled: boolean) => {
  const onCompleted = (res) => {
    const companiesMapped = res.map((c) => ({
      value: c.id,
      title: c.name,
      companyTypeId: c.companyTypeId,
    }))
    return [...companiesMapped]
  }
  const fetch = async () => {
    const response = await getCompaniesComboValue()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const companiesComboValues = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANIES_COMBO_VALUE],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      enabled: isEnabled,
    }
  )
  return companiesComboValues
}

export const usePaymentAdjustmentTypes = () => {
  const onCompleted = (res) => {
    const companiesMapped = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...companiesMapped]
  }
  const fetch = async () => {
    const response = await getPaymentAdjustmentTypes()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const paymentAdjustmentTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PAYMENT_ADJUSTMENT_TYPES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return paymentAdjustmentTypes
}

export const useAgreementsOfCompany = () => {
  const onCompleted = (res) => {
    const agreementsMapped = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...agreementsMapped]
  }
  const fetch = async () => {
    const response = await getAgreementsOfCompany()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const agreements = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGREEMENTS_OF_COMPANY],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      refetchOnMount: "always",
    }
  )
  return agreements
}
