import {
  useEbateQuery,
  getActiveRebatesCount,
  ebateQueryKeys,
  getRebateAccrualYTD,
  getRebateVSBudgetPercentage,
  getRebateVSTargetPercentage,
  getCurrentYearRebateByMonthReport,
  getCurrentYearRebateByMonthAndCompanyReport,
  getCalculationEngineStatus,
  getEbateImportStatus,
  getPortalImportStatus,
} from "queries"
import {
  CacheTime,
  RefetchTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useActiveRebatesCount = () => {
  const fetch = async () => {
    try {
      const response = await getActiveRebatesCount()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const activeRebates = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ACTIVE_REBATES_COUNT],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return activeRebates
}

export const useRebateAccrualYTD = () => {
  const fetch = async () => {
    try {
      const response = await getRebateAccrualYTD()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateAccrualYTD = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_ACCRUAL_YTD],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return rebateAccrualYTD
}

export const useRebateVSBudgetPercentage = () => {
  const fetch = async () => {
    try {
      const response = await getRebateVSBudgetPercentage()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateVsBudget = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_VS_BUDGET_PERCENTAGE],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return rebateVsBudget
}

export const useRebateVSTargetPercentage = () => {
  const fetch = async () => {
    try {
      const response = await getRebateVSTargetPercentage()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateVsTarget = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_VS_TARGET_PERCENTAGE],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return rebateVsTarget
}

export const useCurrentYearRebateByMonthReport = () => {
  const fetch = async () => {
    try {
      const response = await getCurrentYearRebateByMonthReport()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const currentYearRebate = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CURRENT_YEAR_REBATE_BY_MONTH],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return currentYearRebate
}

export const useCurrentYearRebateByMonthAndCompany = (companyId: number) => {
  const fetch = async () => {
    try {
      const response = await getCurrentYearRebateByMonthAndCompanyReport(
        companyId
      )
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const currentYearRebate = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CURRENT_YEAR_REBATE_BY_MONTH_AND_COMPANY,
      companyId.toString(),
    ],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return currentYearRebate
}

export const useCalculationEngineStatus = () => {
  const fetch = async () => {
    const response = await getCalculationEngineStatus()
    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const currentYearRebate = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CALCULATION_ENGINE_STATUS],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
      refetchOnMount: "always",
      refetchInterval: RefetchTime.xShort,
    }
  )

  return currentYearRebate
}

export const useImportStatus = () => {
  const fetch = async () => {
    const response = await getEbateImportStatus()
    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const importStatus = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_IMPORT_STATUS],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
      refetchOnMount: "always",
      refetchInterval: RefetchTime.xShort,
    }
  )

  return importStatus
}

export const usePortalImportStatus = (companyId: number) => {
  const fetch = async () => {
    try {
      const response = await getPortalImportStatus(companyId)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const importStatus = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PORTAL_IMPORT_STATUS, companyId.toString()],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
      refetchOnMount: "always",
      refetchInterval: RefetchTime.xShort,
    }
  )

  return importStatus
}
