import { UserStructures } from "components/pages/finance/ClaimsPayments/claimPaymentFilter/claimPaymentFilter.models"
import {
  CustomerInfoModel,
  PaymentAdditionalCompanyFilterRequest,
} from "components/pages/finance/ClaimsPayments/customerInfo/customerInfo.models"
import {
  RebatePaymentDetailModel,
  RebatePaymentsFilterRequest,
} from "components/pages/pricemanagement/rebates/rebatePayments/rebatePaymentsDetail/rebatePaymentsDetail.models"
import {
  ebateQueryKeys,
  getAdditionalCompanyClaimsAndPaymentsByFilters,
  getPaymentUserStructureOptions,
  getRebatePayments,
  useEbateQuery,
} from "queries"
import { useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const usePaymentUserStructureFilter = () => {
  const onCompleted = (data) => {
    const userPermissions: UserStructures = {
      firstUserStructure: data?.firstUserStructure
        ? {
            id: data.firstUserStructure?.id,
            name: data.firstUserStructure?.name,
            options: data.firstUserStructure?.options.map((x) => ({
              value: x.name,
              title: x.name,
            })),
          }
        : undefined,
      secondUserStructure: data.secondUserStructure
        ? {
            id: data.secondUserStructure?.id,
            name: data.secondUserStructure?.name,
            options: data.secondUserStructure?.options.map((x) => ({
              value: x.name,
              title: x.name,
            })),
          }
        : undefined,
      thirdUserStructure: data.thirdUserStructure
        ? {
            id: data.thirdUserStructure?.id,
            name: data.thirdUserStructure?.name,
            options: data.thirdUserStructure?.options.map((x) => ({
              value: x.name,
              title: x.name,
            })),
          }
        : undefined,
    }
    return userPermissions
  }

  const fetch = async () => {
    const response = await getPaymentUserStructureOptions()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    return []
  }

  const paymentUserStructuresFilter = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PAYMENT_USER_STRUCTURES_FILTER],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return paymentUserStructuresFilter
}

export const useCustomerInfoGridData = (
  customerInfoRequest: PaymentAdditionalCompanyFilterRequest
) => {
  const getUniqueValues = (data): CustomerInfoModel[] => {
    const flattened = data.pages.flatMap(
      (page) => page.paymentAdditionalCompanyGridItems
    )
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.rebateDue, item])).values(),
    ] as CustomerInfoModel[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getAdditionalCompanyClaimsAndPaymentsByFilters(
      customerInfoRequest
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_NEWS_FEED_GRID_DATA,
      customerInfoRequest.orderBy,
      customerInfoRequest.isAscending ? "true" : "false",
      customerInfoRequest.primaryCompanyId?.toString() ?? "",
      customerInfoRequest.rebateId?.toString() ?? "",
      customerInfoRequest.dueFrom?.toString() ?? "",
      customerInfoRequest.dueTo?.toString() ?? "",
      customerInfoRequest.dateFrom?.toString() ?? "",
      customerInfoRequest.dateTo?.toString() ?? "",
      customerInfoRequest.uS1Values?.toString() ?? "",
      customerInfoRequest.uS2Values?.toString() ?? "",
      customerInfoRequest.uS3Values?.toString() ?? "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= customerInfoRequest.currentPage
          ? customerInfoRequest.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, customerInfoRequest])

  const customerInfoList = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > customerInfoRequest.currentPage

  return { customerInfoList, hasMore, isLoading, isError }
}

export const useRebatePaymentGridData = (
  filter: RebatePaymentsFilterRequest
) => {
  const getUniqueValues = (data): RebatePaymentDetailModel[] => {
    const flattened = data.pages.flatMap((page) => page.rebatePaymentsGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.requestId, item])).values(),
    ] as RebatePaymentDetailModel[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getRebatePayments(filter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATE_PAYMENT_GRID_DATA,
      filter.rebateId.toString(),
      filter.orderBy,
      filter.isAscending ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= filter.currentPage
          ? filter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, filter])

  const rebatePaymentsDetails = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > filter.currentPage

  return { rebatePaymentsDetails, hasMore, isLoading, isError }
}
