import { Dropdown } from "primereact/dropdown"
import styled from "styled-components"

interface Props {
  width?: string
  $transparent?: boolean
}
const StyledDropdown = styled(Dropdown)<Props>`
  width: ${({ width }) => width || "100%"};

  &:enabled:focus {
    border: ${({ $transparent }) =>
      $transparent ? "none" : "2px solid var(--color-ebateControlBackground);"};
    box-shadow: ${({ $transparent }) =>
      $transparent
        ? "none"
        : "inset 0 0 0 1px var(--color-ebateControlBorderHover), " +
          "inset 0 0 0 1px var(--color-ebateControlBorderHover)," +
          " inset 0 0 0 1px var(--color-ebateControlBorderHover), " +
          "inset 0 0 0 1px var(--color-ebateControlBorderHover)"};
  }

  &:not(.p-disabled).p-focus {
    box-shadow: inset 0 0 0 1px var(--color-ebateControlBorderHover),
      inset 0 0 0 1px var(--color-ebateControlBorderHover),
      inset 0 0 0 1px var(--color-ebateControlBorderHover),
      inset 0 0 0 1px var(--color-ebateControlBorderHover);
  }

  &:not(.p-disabled):focus-visible {
    outline: none;
    border: none;
  }

  border: ${({ $transparent }) =>
    $transparent ? "none" : "2px solid var(--color-ebateControlBackground);"};
  background: ${({ $transparent }) =>
    $transparent ? "transparent" : "var(--color-white)"};
  background: ${({ $transparent }) =>
    $transparent ? "transparent" : "var(--color-ebateControlBackground)"};

  &:not(.p-disabled):hover {
    border: 2px solid var(--color-ebateControlBorderHover);
  }
`

export default StyledDropdown
