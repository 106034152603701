import { FilterRow } from "components/library"

export interface ComponentFilterModal {
  title: string
  formRows: FilterRow[]
  filtersInitialValues: any
  onApply: (values: any) => void
}

export interface QuickSearchModel {
  searchPlaceholder?: string
  searchName?: string
  searchValue?: string
  searchCaption?: string
  onChange?: (e: any) => void
  onKeyUp?: () => void
}

export enum CommandBarFilterType {
  NoFilter,
  OnlyQuickSearch,
  FilterSearchAndValues,
}
