import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const dateEndTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.dateTo)

const ProductLevelFOCColumns = (isApprovalEnabled: boolean, t) => {
  const columns: ColumnModel[] = [
    {
      field: "id",
      header: "ID",
      sortable: true,
      style: { textAlign: "right" },
    },
    {
      field: "productCode",
      header: "Product Code",
      sortable: true,
    },
    {
      field: "productDescription",
      header: "Product Description",
      sortable: true,
    },
    {
      field: "fromDate",
      header: "Effective From",
      sortable: true,
      body: dateStartTemplateBody,
    },
    {
      field: "dateTo",
      header: "Effective To",
      sortable: true,
      body: dateEndTemplateBody,
    },
    {
      field: "purchaseQty",
      header: "Purchase Qty",
      sortable: true,
    },
    {
      field: "freeOfChargeQty",
      header: "FOC Qty",
      sortable: true,
    },
  ]
  if (isApprovalEnabled) {
    return [
      ...columns,
      {
        field: "statusDescription",
        header: t("status"),
        sortable: true,
      },
    ]
  }
  return columns
}

export default ProductLevelFOCColumns
