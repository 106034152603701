import { RebateRatesGridModel } from "./rebateRates.model"

export const mapRateGridDataFromApiToVm = (
  rateGrid,
  approvalEnabled
): RebateRatesGridModel => ({
  id: rateGrid.id,
  fromDate: rateGrid.fromDate,
  dateTo: rateGrid.dateTo,
  ratePercentage: rateGrid.ratePercentage,
  rateValue: rateGrid.rateValue,
  statusDescription: rateGrid.statusDescription,
  statusId: rateGrid.statusId,
  unitsOfMeasurementId: rateGrid.unitsOfMeasurementId,
  unitsOfMeasurementDescription: rateGrid.unitsOfMeasurementDescription,
  transactionCurrencyDescription: rateGrid.transactionCurrencyDescription,
  estimatedVolume: rateGrid.estimatedVolume,
  sellPrice: rateGrid.sellPrice,
  purchaseQty: rateGrid.purchaseQty,
  currentRate: rateGrid.currentRate,
  rebateRateTiers: rateGrid.details,
  productCode:
    rateGrid.rebateRateProducts.length > 0
      ? rateGrid.rebateRateProducts[0].productCode
      : null,
  productDescription:
    rateGrid.rebateRateProducts.length > 0
      ? rateGrid.rebateRateProducts[0].productDescription
      : null,
  productExtensionId:
    rateGrid.rebateRateProducts.length > 0
      ? rateGrid.rebateRateProducts[0].productExtensionId
      : null,
  approvalWorkflowEnabled: approvalEnabled,
  rebateRateProducts: rateGrid.rebateRateProducts,
  cheapestFOCQty: rateGrid.cheapestFOCQty,
  focProductDescription: rateGrid.focProductDescription,
  freeOfChargeQty: rateGrid.freeOfChargeQty,
  focProductExtensionId: rateGrid.focProductExtensionId,
})
