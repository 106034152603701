import {
  PortalUsersGridData,
  PortalUsersGridRequest,
} from "components/pages/collaboration/portalUserManagement/PortalUserManagementList/PortalUserManagementList.models"
import { getPortalUsersGridData } from "queries"
import { ebateQueryKeys, useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useContext, useEffect, useState } from "react"
import { AuthenticationContext } from "stores"

export const usePortalUserGridData = (
  portalUserFilter: PortalUsersGridRequest
) => {
  const { getTokenValue } = useContext(AuthenticationContext)
  const [authCompanyId] = useState(parseInt(getTokenValue("company_id"), 10))
  const getUniqueValues = (data): PortalUsersGridData[] => {
    const flattened = data.pages.flatMap((page) => page.userGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as PortalUsersGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getPortalUsersGridData(
      portalUserFilter.request,
      authCompanyId && authCompanyId > 0
        ? authCompanyId
        : portalUserFilter.companyId
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PORTAL_USER_GRID_DATA,
      portalUserFilter.request.orderBy,
      portalUserFilter.request.isAscending ? "true" : "false",
      authCompanyId && authCompanyId > 0
        ? authCompanyId.toString()
        : portalUserFilter?.companyId?.toString() || "",
      portalUserFilter.request.search,
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= portalUserFilter.request.currentPage
          ? portalUserFilter.request.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, portalUserFilter])

  const portalUsers = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > portalUserFilter.request.currentPage

  return { portalUsers, hasMore, isLoading, isError }
}
