import { OptionModel, dropdownUtils } from "components/helpers"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getPaymentAdjustmentReasons } from "../paymentAdjustment.query"

export const usePaymentAdjustmentReasons = () => {
  const onCompletedPaymentAdjustmentReasons = (res) => {
    const paymentAdjustmentReasonsFromApi =
      dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...paymentAdjustmentReasonsFromApi]
  }

  const fetchPaymentAdjustmentReasons = async (): Promise<OptionModel[]> => {
    const response = await getPaymentAdjustmentReasons()

    if (response?.status === 200) {
      return onCompletedPaymentAdjustmentReasons(response.data)
    }
    return []
  }

  const paymentAdjustmentReasons = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PAYMENT_ADJUSTMENT_REASONS],
    fetchPaymentAdjustmentReasons,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return paymentAdjustmentReasons
}
