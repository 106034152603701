import styled from "styled-components"

const StyledCardGridLayoutContainer = styled.div`
  .spinnerContainer {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
  }
`

export default StyledCardGridLayoutContainer
