import React from "react"
import RowItem from "./RowItem"

const Rows = ({ data, rowParent, nested, selectable, setSelection }) => {
  const { rows, head } = data

  const generateRows = () => {
    if (selectable) {
      return rows
        .filter((key) => selectable && key.visible)
        .map((row, currentRow) => (
          <RowItem
            row={row}
            key={`p-ri-${row.id}`}
            rowParent={rowParent}
            head={head}
            nested={nested}
            selectable={selectable}
            currentRow={currentRow}
            setSelection={setSelection}
          />
        ))
    }
    return rows.map((row, currentRow) => (
      <RowItem
        row={row}
        key={`n-ri-${row.id}`}
        rowParent={rowParent}
        head={head}
        nested={nested}
        selectable={selectable}
        currentRow={currentRow}
        setSelection={setSelection}
      />
    ))
  }

  return <div>{generateRows()}</div>
}

export default Rows
