import styled from "styled-components"

const StyledLegendItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: var(--spacing-half) 0;

  .legend-block {
    height: 15px;
    width: 30px;
    background-color: ${({ color }) => color};
    margin-right: var(--spacing-base);
  }

  .legend-text,
  .legend-value {
    width: 100%;
    font-size: var(--font-size-sm);
    font-family: var(--font-light);
  }

  .legend-value {
    text-align: right;
  }
`

export default StyledLegendItem
