import {
  ProjectGridDataResponse,
  ProjectGridRequest,
} from "components/pages/maintanance/project/projectList/project.models"
import {
  ProjectFormRequest,
  ProjectModelApi,
} from "components/pages/maintanance/project/projectForm/projectForm.models"
import EbateAxios from "queries/ebateAxios"
import { ValidationResultModel } from "components/models"

const productUrl = "/api/Project"

export const getProjectGridData = async (
  data: ProjectGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${productUrl}/GetProjectGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProjectGridRequest, ProjectGridDataResponse>(
    url,
    data,
    abortSignal
  )
}

export const getEmptyProject = async () => {
  const url = `${productUrl}/GetEmptyProject`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ProjectModelApi>(url)
}
export const addProject = async (data: ProjectFormRequest) => {
  const url = `${productUrl}/AddProject`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProjectFormRequest, ValidationResultModel>(url, data)
}

export const getProjectWithDynamicFields = async (id: string) => {
  const url = `${productUrl}/GetProjectWithDynamicFields/${id}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ProjectModelApi>(url)
}

export const updateProject = async (data: ProjectFormRequest) => {
  const url = `${productUrl}/UpdateProject`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProjectFormRequest, ValidationResultModel>(url, data)
}
