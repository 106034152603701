import styled from "styled-components"

const StyledFormInput = styled.div`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-wrap: ${({ flat }) => (flat ? "no-wrap" : "wrap")};
  padding: ${({ heading }) => (heading ? "var(--spacing-base) 0" : 0)};
  border: ${({ border }) => border || "none"};
`

export default StyledFormInput
