import React from "react"
import logo from "assets/ebate-loader.gif"
import { StyledLoadingSpinner } from "./styled"

const LoadingSpinner = () => (
  <div id="loader" data-testid="loader">
    <StyledLoadingSpinner>
      {" "}
      <img
        className="spinner"
        src={logo}
        alt="Loading..."
        height="100"
        width="100"
      />
    </StyledLoadingSpinner>
  </div>
)

export default LoadingSpinner
