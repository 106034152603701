import EbateAxios from "queries/ebateAxios"
import { AxiosResponse } from "axios"
import {
  AdditionalInfoRequestType,
  AdditionalInfoResponseType,
} from "components/library/additionalInfo/additionalInfo.model"
import { ValidationResultModel } from "components/models"

const additionalInfoDataUrl = "/api/AdditionalInfo"

export const updateAdditionalInfo = async (
  request: AdditionalInfoRequestType
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${additionalInfoDataUrl}/UpdateAdditionalInfo`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AdditionalInfoRequestType, ValidationResultModel>(
    url,
    request
  )
}

export const getAdditionalInfo = async (type: number, parentId: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AdditionalInfoResponseType[]>(
    `${additionalInfoDataUrl}/GetAdditionalInfo/${type}/${parentId}`
  )
}

export const getGlobalAdditionalInfo = async (
  type: number,
  parentId: number
) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AdditionalInfoResponseType[]>(
    `${additionalInfoDataUrl}/GetGlobalAdditionalInfo/${type}/${parentId}`
  )
}
