import styled from "styled-components"

const StyledTabView = styled.div`
  .p-tabview-nav {
    background-color: var(--color-lineColor);
  }

  .p-tabview-selected {
    background-color: var(--color-ebatePrimaryLight);
  }

  .p-tabview-nav-link {
    background: var(--color-lineColor);
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: var(--color-ebatePrimary);
  }
`

export default StyledTabView
