import { TenantSpecOptions } from "models"
import { TenantConfiguration } from "models/beconfiguration/beconfiguration.models"
import { useTenantConfiguration } from "queries"
import { useEffect, useState } from "react"

const useConfiguration = (): [
  TenantSpecOptions,
  boolean,
  boolean,
  TenantConfiguration
] => {
  const [is10DecimalsActive, setIs10DecimalsActive] = useState(false)

  const tenantConfiguration = useTenantConfiguration()

  useEffect(() => {
    calculateDecimalsActive()
  }, [])

  const calculateDecimalsActive = () => {
    if (
      process.env.REACT_APP_DOMAINS_10DECIMALS?.split(";").some((domain) =>
        window.location.href.includes(domain)
      )
    ) {
      setIs10DecimalsActive(true)
    } else {
      setIs10DecimalsActive(false)
    }
  }

  return [
    tenantConfiguration.data?.subscription,
    !tenantConfiguration.isLoading,
    is10DecimalsActive,
    tenantConfiguration.data,
  ]
}

export default useConfiguration
