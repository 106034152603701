import { ebateQueryKeys, refreshToken, useEbateQuery } from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"

export const useRefreshToken = (isAuthorized: boolean) => {
  const fetch = async () => {
    const response = await refreshToken()
    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const token = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REFRESH_TOKEN],
    fetch,
    {
      cacheTime: CacheTime.infinity,
      staleTime: StaleTime.infinity,
      enabled: isAuthorized,
    }
  )
  return token
}
