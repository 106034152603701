import React from "react"
import { StyledIconRounded } from "./styled"

interface Props {
  iconClass: string
  backgroundColor: string
}
export const IconRounded: React.FC<Props> = ({
  iconClass,
  backgroundColor,
}) => (
  <StyledIconRounded backgroundColor={backgroundColor}>
    <div className={iconClass} />
  </StyledIconRounded>
)
