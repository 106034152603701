import { OptionModel } from "components/helpers"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getRebatesOfAgreementsByCalculationType } from "queries"
import { RebatesOfAgreementsRequest } from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"

export const useGetRebatesByCalculationType = (
  calculationTypeId: number,
  agreements: number[],
  query: string
) => {
  const fetchRebatesByCalculationType = async (): Promise<OptionModel[]> => {
    const request: RebatesOfAgreementsRequest = {
      calculationTypeId,
      agreementIds: agreements,
      filter: query,
    }
    const response = await getRebatesOfAgreementsByCalculationType(request)
    if (response?.status === 200) {
      return response.data?.map((d) => ({
        value: d.id,
        title: `${d.id} - ${d.name}`,
      }))
    }
    return []
  }
  const rebatesByCalculationType = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATES_BY_CALCULATION_TYPE,
      calculationTypeId.toString(),
      agreements.join(","),
      query,
    ],
    fetchRebatesByCalculationType,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      enabled: agreements && agreements.length > 0,
    }
  )

  return { rebatesByCalculationType }
}
