import React, { useRef, useEffect } from "react"
import { StyledTruncateText } from "./styled"

interface TruncateTextProps {
  html: string
  lines: number
  setIsClamped: any
}

const TruncateText: React.FC<TruncateTextProps> = ({
  html,
  lines,
  setIsClamped,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const checkClamping = () => {
      const isContentClamped = container.scrollHeight > container.clientHeight
      setIsClamped(isContentClamped)
    }

    checkClamping()

    const resizeObserver = new ResizeObserver(checkClamping)
    resizeObserver.observe(container)

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect()
    }
  }, [html, lines])

  return (
    <StyledTruncateText lines={lines}>
      <div
        ref={containerRef}
        className="truncated-text-container"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </StyledTruncateText>
  )
}

export default TruncateText
