import { useEffect, useState, useContext } from "react"
import { AuthenticationContext } from "stores"
import { RoleType, UserInfoModel } from "components/models/userInfo.model"
import { dateUtils } from "components/helpers"
import { getCompanyWithDynamicFields } from "queries"
import { CompanyModelApi } from "components/pages/maintanance/company/companyForm/companyForm.models"

const useUserInfo = (): [UserInfoModel] => {
  const { getTokenValue } = useContext(AuthenticationContext)

  const getInitials = () => {
    if (!getTokenValue("name")) return null
    const nameArr = getTokenValue("name").split([" "])
    let initials = nameArr[0][0]

    if (nameArr.length === 2) {
      initials += nameArr[1][0]
    }

    return initials
  }

  const lastActivity = () => {
    const lastActivityFromToken = getTokenValue("auth_time")
    if (lastActivityFromToken)
      return dateUtils.formatDateTimeToLocale(
        new Date(lastActivityFromToken * 1000)
      )

    return undefined
  }

  const getTypeOfUser = (): boolean => {
    const roleType = getTokenValue("role_type")
    if (roleType === RoleType.Portal.toString()) return false

    return true
  }

  const [userInfo, setUserInfo] = useState<UserInfoModel>({
    userName: getTokenValue("given_name"),
    lastActivity: lastActivity(),
    initials: getInitials(),
    role: getTokenValue("role"),
    email: getTokenValue("email"),
    tenantIdentifier: getTokenValue("custom:tenant_identifier"),
    isEbateUser: getTypeOfUser(),
  })

  const onCompleted = (res: CompanyModelApi) => {
    setUserInfo({
      ...userInfo,
      companyName: res.accountName,
    })
  }

  const fecthCompany = async (id) => {
    const response = await getCompanyWithDynamicFields(id)

    if (response?.status === 200) {
      onCompleted(response.data)
    }
  }

  useEffect(() => {
    const companyId = getTokenValue("company_id")
    if (companyId) {
      fecthCompany(Number(companyId))
    }
  }, [])

  return [userInfo]
}

export default useUserInfo
