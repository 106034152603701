class LogoutUtils {
  constructor({ onLogout }) {
    this.onLogout = onLogout
    this.syncLogout = this.performSyncLogout.bind(this)
    this.tracker()
  }

  performSyncLogout(event) {
    if (event.key === "logout") {
      if (this.onLogout) {
        this.onLogout()
        this.cleanUp()
      }
    }
  }

  tracker() {
    window.addEventListener("storage", this.syncLogout)
  }

  cleanUp() {
    window.removeEventListener("storage", this.syncLogout)
  }
}

export default LogoutUtils
