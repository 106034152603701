import React, { useState } from "react"
import { StyledNewsFeedCardWidget } from "./styled"
import { NewsFeedWidgetModel } from "./newsFeedWidget.models"
import TruncateText from "../truncateText/TruncateText"

interface Props {
  newsFeed: NewsFeedWidgetModel
}
export const NewsFeedCardWidget: React.FC<Props> = ({ newsFeed }) => {
  const [isClamped, setIsClamped] = useState<boolean>(false)
  const renderDescription = () => (
    <TruncateText
      html={newsFeed.content}
      lines={2}
      setIsClamped={setIsClamped}
    />
  )
  const renderFullDescription = () => (
    <div
      className="full-description"
      dangerouslySetInnerHTML={{ __html: newsFeed.content }}
    />
  )
  return (
    <StyledNewsFeedCardWidget
      height="110px"
      header={newsFeed.title}
      body={renderDescription()}
      numberOfCards={1}
      expandedContent={isClamped && renderFullDescription()}
      hideBodyOnExpandedContent
    />
  )
}
