import {
  WholesalerDiscountGridFilterRequest,
  WholesalerDiscountGridItem,
} from "components/pages/maintanance/wholesalerDiscount/wholesalerDiscountList/wholesalerDiscountList.models"
import {
  ebateQueryKeys,
  getWholeSalerDiscountGridData,
  getWholesalerDiscount,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useWholesalerDiscountById = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getWholesalerDiscount(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const emptyCustomer = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_WHOLESALERDISCOUNT_BY_ID, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return emptyCustomer
}

export const useWholesalerDiscountGridData = (
  wholesalerDiscountGridFilterRequest: WholesalerDiscountGridFilterRequest
) => {
  const getUniqueValues = (data): WholesalerDiscountGridItem[] => {
    const flattened = data.pages.flatMap(
      (page) => page.wholeSalerDiscountGridItems
    )
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as WholesalerDiscountGridItem[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getWholeSalerDiscountGridData(
      wholesalerDiscountGridFilterRequest
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_WHOLESALER_DISCOUNT_GRID_DATA,
      wholesalerDiscountGridFilterRequest.orderBy,
      wholesalerDiscountGridFilterRequest.isAscending ? "true" : "false",
      wholesalerDiscountGridFilterRequest.search,
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >=
        wholesalerDiscountGridFilterRequest.currentPage
          ? wholesalerDiscountGridFilterRequest.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, wholesalerDiscountGridFilterRequest])

  const wholesalerDiscounts = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages >
    wholesalerDiscountGridFilterRequest.currentPage

  return { wholesalerDiscounts, hasMore, isLoading, isError }
}
