const colors = {
  ebateSecondary: "#FF4E00",
  ebateSecondaryMid: "#D87451",
  ebateSecondaryLight: "#FeF7F1",
  ebatePrimary: "#7F5EBA",
  ebatePrimaryLight: "rgb(127, 94, 186, 0.05)",
  ebateThirdMid: "#01ADAB",
  ebateThird: "#028583",
  ebateGrey: "#888",
  ebateGreyLight: "#f0f0f0",
  ebateGreen: "#99CC33",
  ebateLightGreen: "#6ea305",
  ebateRed: "#FF3333",
  ebateLightRed: "#fa0c0c",
  ebateYellow: "#FFC02E",
  ebateError: "#b00020",
  black: "#090909",
  white: "#fcfcfc",
}

const vizientColors = {
  ebateSecondary: colors.ebateSecondary,
  ebatePrimary: colors.ebatePrimary,
  ebatePrimaryLight: colors.ebatePrimaryLight,
  ebateActionButton: colors.ebatePrimary,
  ebateActionButtonBorder: colors.ebatePrimary,
  ebateActionButtonBorderHover: colors.ebatePrimary,
  ebatePrimaryButton: colors.ebateSecondary,
  ebatePrimaryButtonBorder: colors.ebateSecondary,
  ebatePrimaryButtonBorderHover: colors.ebateSecondary,
  ebateSecondaryButton: colors.ebatePrimary,
  ebateSecondaryButtonHover: colors.ebateGreyLight,
  ebateIconButton: colors.ebateThirdMid,
  ebateIconButtonBorderHover: colors.ebateThird,
  ebateIconButtonHover: colors.ebateThird,
  ebateControlBackground: colors.ebateGreyLight,
  ebateControlBorderHover: colors.ebatePrimary,
  ebateCheckboxActive: colors.ebateSecondary,
  ebateInputNumberButton: colors.ebateGreyLight,
  ebateInputNumberButtonHover: colors.ebatePrimary,
  ebateFileUploadHighlight: colors.ebatePrimaryLight,
  ebateDataTableRowHover: colors.ebateGreyLight,
  ebateDataTableRowSelected: colors.ebatePrimaryLight,
  ebateActiveNavLinkFontColor: colors.ebateSecondary,
  ebateActiveNavLinkBackground: colors.ebateSecondaryLight,
  ebateActive: colors.ebatePrimary,
  ebateInactive: colors.ebateSecondary,
  ebateStatusFieldColorFont: colors.white,
  ebateWidgetBackground: colors.ebateSecondaryMid,
  ebateWidgetFontColor: colors.ebateSecondaryLight,
  ebatePending: colors.ebateYellow,
  ebateButtonHover: colors.ebateDarkTeal,
  ebateButtonActive: colors.ebateTeal,
  ebateButtonFocus: colors.ebateDarkTeal,
  ebateGraphPrimaryColor: colors.ebateSecondaryMid,
  ebateGraphSecondaryColor: colors.ebatePrimary,
  ebateGraphTertiaryColor: colors.ebateYellow,
  ebateGraphQuaternaryColor: colors.ebateThirdMid,
  approve: colors.ebateGreen,
  approveHover: colors.ebateLightGreen,
  reject: colors.ebateRed,
  rejectHover: colors.ebateLightRed,
  ebateError: colors.ebateError,
}

export default vizientColors
