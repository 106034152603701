import { permissionsLevelsEnum } from "components/helpers"
import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthenticationContext } from "stores"

export default (
    WrapperComponent: React.LazyExoticComponent<
      ({ isReadOnly }) => JSX.Element
    >,
    permission
  ) =>
  () => {
    const { getPermission } = useContext(AuthenticationContext)
    const permissionValue = getPermission(permission)

    if (permissionValue === undefined) {
      return <Navigate to="/" />
    }

    return (
      <WrapperComponent
        isReadOnly={permissionValue === permissionsLevelsEnum.ReadOnly}
      />
    )
  }
