import { OptionModel } from "components/helpers"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getRatesOfAgreementsByCalculationType } from "queries"
import { RatesOfAgreementsRequest } from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"

export const useGetRatesByCalculationType = (
  calculationTypeId: number,
  agreements: number[],
  query: string,
  rebates: number[],
  products: number[]
) => {
  const fetchRatesByCalculationType = async (): Promise<OptionModel[]> => {
    const request: RatesOfAgreementsRequest = {
      calculationTypeId,
      agreementIds: agreements,
      rebateIds: rebates,
      productExtIds: products,
      filter: query,
    }
    const response = await getRatesOfAgreementsByCalculationType(request)

    if (response?.status === 200) {
      return response.data?.map((d) => ({
        value: d.id,
        title: d.name,
      }))
    }
    return []
  }
  const ratesByCalculationType = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_RATES_BY_CALCULATION_TYPE,
      calculationTypeId.toString(),
      agreements.join(","),
      rebates.join(","),
      products.join(","),
      query,
    ],
    fetchRatesByCalculationType,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      enabled: agreements && agreements.length > 0,
    }
  )

  return { ratesByCalculationType }
}
