import { OptionModel, dropdownUtils } from "components/helpers"
import {
  CrossReferenceExchangeObjectResponse,
  CrossReferenceGridData,
  CrossReferenceGridRequest,
  CrossReferencedValueRequest,
  CrossReferencedValuesParam,
} from "components/pages/maintanance/crossReference/crossReferenceList/crossReference.models"
import {
  ebateQueryKeys,
  useEbateInfinityQuery,
  useEbateQuery,
} from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getExchangeFormatByMappingId } from "queries/implementation/exchangeFormat.query"
import { getAllData } from "queries/implementation/exchangeMapping.query"
import { useEffect } from "react"
import {
  getCrossReferenceGrid,
  getCrossReferencedValues,
} from "../crossReference.query"

export const useImportNames = () => {
  const fetchImportNames = async (): Promise<OptionModel[]> => {
    const response = await getAllData()

    if (response?.status === 200) {
      const importNamesFromApi = dropdownUtils.mapToDropDownOptionFromApi(
        response.data
      )
      return [...importNamesFromApi]
    }
    return []
  }

  const importNames = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_IMPORT_NAMES,
    fetchImportNames,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { importNames }
}

export const useDataTypes = (id: number) => {
  const fetchImportNames = async (): Promise<OptionModel[]> => {
    const response = await getExchangeFormatByMappingId(id)

    if (response?.status === 200) {
      const dataTypesFromApi = response.data?.map((option) => ({
        value: option.id,
        title: option.description,
      }))
      return [...dataTypesFromApi]
    }

    return []
  }

  const dataTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DATA_TYPES, id.toString()],
    fetchImportNames,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
      enabled: id !== null && id !== undefined,
    }
  )

  return { dataTypes }
}

export const useCrossReferenceValues = (data: CrossReferencedValuesParam) => {
  const onCompletedEbateValues = (
    data: CrossReferenceExchangeObjectResponse
  ) => {
    const dataEbateValueFromApi = data.exchangeObjectItems.map((option) => ({
      optionValue: option.value,
      value: option.id,
      title: `(${option.value}) ${option.description}`,
    }))
    return [...dataEbateValueFromApi]
  }
  const fetchCrossReferenceValues = async () => {
    const request: CrossReferencedValueRequest = {
      exchangeFormatId: data.exchangeFormatId,
      searchQuery: data.query,
    }
    const response = await getCrossReferencedValues(request)

    if (response?.status === 200) {
      return onCompletedEbateValues(response.data)
    }
    return []
  }
  const crossReferenceValues = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CROSS_REFERENCE_VALUES,
      data.exchangeFormatId?.toString(),
      data.query,
    ],
    fetchCrossReferenceValues,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      enabled:
        data !== undefined &&
        data !== null &&
        data.exchangeFormatId !== null &&
        data.exchangeFormatId !== undefined,
    }
  )
  return crossReferenceValues
}

export const useCrossReferenceGridData = (
  crossReferenceFilter: CrossReferenceGridRequest
) => {
  const getUniqueValues = (data): CrossReferenceGridData[] => {
    const flattened = data.pages.flatMap((page) => page.crossReferenceGridItems)
    if (flattened && flattened[0] !== null) {
      const arrayUniqueByKey = [
        ...new Map(flattened.map((item) => [item?.id, item]))?.values(),
      ] as CrossReferenceGridData[]

      return arrayUniqueByKey
    }
    return []
  }

  const fetchData = async () => {
    const response = await getCrossReferenceGrid(crossReferenceFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CROSS_REFERENCE_GRID_DATA,
      crossReferenceFilter.orderBy,
      crossReferenceFilter.isAscending ? "true" : "false",
      crossReferenceFilter.search || "",
      crossReferenceFilter.importNameId?.toString() || "",
      crossReferenceFilter.dataTypeId?.toString() || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= crossReferenceFilter.currentPage
          ? crossReferenceFilter.currentPage + 1
          : undefined,
      enabled:
        crossReferenceFilter.dataTypeId !== undefined &&
        crossReferenceFilter.dataTypeId !== null &&
        crossReferenceFilter.importNameId !== undefined &&
        crossReferenceFilter.importNameId !== null,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, crossReferenceFilter])

  const crossReferences = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > crossReferenceFilter.currentPage

  return { crossReferences, hasMore, isLoading, isError }
}
