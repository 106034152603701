import React, { useContext } from "react"
import { ThemeContext } from "theme"

//  Import sub-components

import { HorizontalLine } from "components/library"
import { StyledCard, Header, Body, Footer } from "./styled"

interface Props {
  children: any
  color?: string
  bgcolor?: string
  className?: string
  padding?: string
  split?: string
}
const Card: React.FC<Props> & { Header?: any } & { Footer?: any } & {
  Body?: any
} = (props: Props) => {
  const headerChildren = (children) => (
    <div>
      {props.children}
      {props.split ? <HorizontalLine margin="var(--spacing-x2)" /> : null}
    </div>
  )

  const { theme } = useContext(ThemeContext)

  return (
    <StyledCard
      theme={theme}
      bgcolor={props.bgcolor}
      color={props.color}
      className={props.className}
      padding={props.padding}
    >
      {/* Compound component logic. Maps over all children and sends props to those that may require them
      In this case, only the header uses props from Card */}
      {React.Children.map(props.children, (child) => {
        // If a child needs a prop, clone it and return it with the correct props

        switch (child.type.displayName) {
          case "Header":
            return React.cloneElement(
              child,
              {
                theme,
              },
              headerChildren(child.props.children)
            )

          case "Footer":
            return React.cloneElement(child, {
              theme,
            })
          default:
            // If no props are needed, just return the original child.
            return child
        }
      })}
    </StyledCard>
  )
}

// Define a list of subcomponents

Card.Header = Header
Card.Footer = Footer
Card.Body = Body

export default Card
