import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
/*
  The RouteManager takes an array of route objects
  passed via the 'views' prop

  Route objects are structured as follows:
  {
    route: "<Path>",
    Component: <The Component to be rendered on this route>,
    exact: <True or false>
  }

  e.g:
  { route: "/", Component: Home, exact: true }

  To Lazy load a route, simply use a React.lazy import in place of the reference to your component

  e.g:
  { route: "/", Component: React.lazy(() => import("./Home")), exact: true }
*/

const RouteManager = ({ views, children }) => (
  <>
    {children || null}
    <Routes>
      {views.map(
        ({ location, dropdownRoutes, Component, fallbackLocation }) => {
          if (dropdownRoutes) {
            return dropdownRoutes.map(
              ({ location, Component, redirectToExternalUrl }) => {
                if (!redirectToExternalUrl) {
                  return (
                    <Route
                      path={location}
                      key={location}
                      element={<Component />}
                    />
                  )
                }
                return null
              }
            )
          }

          if (!fallbackLocation)
            return (
              <Route path={location} key={location} element={<Component />} />
            )

          return (
            <Route
              index
              path="*"
              key={`fb_${fallbackLocation}`}
              element={<Navigate to={fallbackLocation} replace />}
            />
          )
        }
      )}
    </Routes>
  </>
)

export default RouteManager
