import { GenericApiResponseModel } from "components/models/common.model"
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginModel,
  LoginResponse,
  SetPasswordRequest,
} from "components/pages/login/eBateLogin/login.models"
import EbateAxios from "queries/ebateAxios"

const authUrl = "/api/Auth"

export const login = async (request: LoginModel) => {
  const url = `${authUrl}/Login`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<LoginModel, GenericApiResponseModel<LoginResponse>>(
    url,
    request
  )
}

export const refreshToken = async (abortSignal?: AbortSignal) => {
  const url = `${authUrl}/RefreshToken`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, GenericApiResponseModel<LoginResponse>>(
    url,
    undefined,
    abortSignal
  )
}

export const signOutApi = async () => {
  const url = `${authUrl}/SignOut`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, any>(url, undefined)
}

export const forgotPassword = async (request: ForgotPasswordRequest) => {
  const url = `${authUrl}/ForgotPassword`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, GenericApiResponseModel<ForgotPasswordResponse>>(
    url,
    request
  )
}

export const setPassword = async (request: SetPasswordRequest) => {
  const url = `${authUrl}/SetPassword`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<SetPasswordRequest, GenericApiResponseModel<LoginResponse>>(
    url,
    request
  )
}

export const confirmSignup = async (request: SetPasswordRequest) => {
  const url = `${authUrl}/ConfirmSignup`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<SetPasswordRequest, GenericApiResponseModel<LoginResponse>>(
    url,
    request
  )
}
