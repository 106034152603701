import React from "react"
import ReactSpeedometer from "react-d3-speedometer"

interface Props {
  minValue: number
  maxValue: number
  value: any
  segments?: number
  segmentColors: any
  customSegmentStops?: any
  ringWidth?: number
  currentValueText: string
  height?: number
  width?: number
}
const RadialWidget: React.FC<Props> = ({
  minValue,
  maxValue,
  value,
  segments,
  segmentColors,
  customSegmentStops,
  ringWidth,
  currentValueText,
  height,
  width,
}) => (
  <ReactSpeedometer
    minValue={minValue}
    maxValue={maxValue}
    value={value}
    segments={segments}
    segmentColors={segmentColors}
    customSegmentStops={customSegmentStops}
    needleColor="var(--color-ebatePrimary)"
    needleHeightRatio={0.9}
    ringWidth={ringWidth}
    currentValueText={currentValueText}
    height={height}
    width={width}
  />
)

export default RadialWidget
