import React, { useState } from "react"
import { TabView, TabPanel } from "primereact/tabview"
import { StyledTabsView } from "./styled"
import { PanelConfiguration } from "./models"

interface Props {
  panelsConfiguration: PanelConfiguration[]
  initialSelection?: number
}
const TabsView: React.FC<Props> = ({
  panelsConfiguration,
  initialSelection,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialSelection || 0)

  return (
    <StyledTabsView>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {panelsConfiguration &&
          panelsConfiguration.map((pc) => (
            <TabPanel
              key={pc.header}
              header={pc.header}
              headerClassName={pc.tabHeaderClassName}
            >
              {pc.content}
            </TabPanel>
          ))}
      </TabView>
    </StyledTabsView>
  )
}

export default TabsView
