import styled from "styled-components"

const StyledSignOut = styled.div`
  width: 50px;
  position: relative;

  #userMenu {
    background-color: var(--color-menuUser);
    width: 360px;

    position: absolute;
    right: 0px;
    text-align: left;
    box-shadow: var(--shadow-sm);
    z-index: var(--zindex-modal) !important;

    padding-left: var(--spacing-x3);
    padding-right: var(--spacing-base);

    border-top: 4px solid var(--color-ebatePrimary);
  }

  .account-menu {
    margin-top: 32px;
    justify-content: flex-start;
  }

  h3 {
    font-weight: normal;
  }

  h4 {
    font-weight: initial;
    font-family: var(--font-light);
  }

  .signout-link.user-initials {
    height: 46px !important;
    width: 46px !important;
    margin: 0 !important;
  }

  .user-info {
    width: auto;
    margin-left: var(--spacing-base);

    .user-info-name {
      top: 0;
      font-family: var(--font-header);
      font-size: var(--font-size-xl);
      line-height: var(--lineHeight-lg);
      font-weight: 700;
      margin-bottom: 0;
      max-width: 275px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .user-info-email {
      line-height: var(--lineHeight-sm);
      font-size: var(--font-size-sm);
      margin-top: 0;
      font-weight: 300;
      max-width: 275px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .user-info-menu-others {
    margin-top: var(--spacing-x2);
    .user-info-menu-company {
      justify-content: flex-start;
      font-family: var(--font-header);
      font-size: var(--font-size-sm);
      line-height: var(--lineHeight-sm);
      font-weight: 700;
      .user-info-company {
        font-weight: normal;
      }
    }

    .user-info-menu-role {
      justify-content: flex-start;
      margin-top: var(--spacing-base);
      font-family: var(--font-header);
      font-size: var(--font-size-sm);
      line-height: var(--lineHeight-sm);
      font-weight: 700;

      .user-info-role {
        font-weight: normal;
      }
    }
  }

  .user-info-lastActivity {
    line-height: var(--lineHeight-xs);
    font-size: var(--font-size-xs);
    margin-top: 0;
    font-weight: 300;
  }

  .user-info-teantIdentifier {
    line-height: var(--lineHeight-md);
    font-size: var(--font-size-md);
    margin-top: 0;
    font-weight: 700;
  }

  .support-tenant {
    margin-top: var(--spacing-base);
    margin-bottom: var(--spacing-base);
  }

  .btn-support {
    width: 102px;
  }

  .btnSignOut {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: var(--spacing-base);
    margin-right: var(--spacing-base);
  }

  .signout-link.user-initials {
    line-height: var(--lineHeight-lg);
    font-size: var(--font-size-lg);
    font-weight: 400;
  }
`

export default StyledSignOut
