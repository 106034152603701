import { OptionModel } from "components/helpers"
import { MultiSelect, ValidationMessage } from "components/library"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetAgreementsByCalculationType } from "./hook/useGetAgreeementsByCalculationType"

interface Props {
  calculationTypeId: number
  errorMessage?: string
  displayValidations?: boolean
  fieldName: string
  heading: string
  required?: boolean
  onChange: (e: any) => void
  selectedItems: any[]
}

export const AgreementByCalculationTypeMultiselect: React.FC<Props> = ({
  calculationTypeId,
  errorMessage,
  displayValidations,
  fieldName,
  heading,
  required,
  onChange,
  selectedItems,
}) => {
  const { t } = useTranslation()
  const [searchTimeout, setSearchTimeout] = useState<any>()
  const [query, setQuery] = useState<string>("")
  const { agreementsByCalculationType } = useGetAgreementsByCalculationType(
    calculationTypeId,
    query
  )
  const [agreementList, setAgreementList] = useState<OptionModel[]>([])

  const [agreementsSelected, setAgreementsSelected] = useState<OptionModel[]>(
    selectedItems
      ? selectedItems.map((item) => ({
          value: item,
          title: item,
          hidden: true,
        }))
      : []
  )
  const textChanged = (event: any) => {
    clearTimeout(searchTimeout)
    setSearchTimeout(
      setTimeout(() => {
        setQuery(event.filter)
      }, 500)
    )
  }

  const getSelectedAgreementValue = (ids: string[]) => {
    const agreementSelectedButNotInList = ids
      .filter((p) => !agreementList.map((c) => c.value).includes(p))
      ?.map((c) => ({
        value: c,
        title: agreementsSelected.find((psi) => psi.value === c)?.title ?? c,
        hidden: true,
      }))
    const options = agreementList.concat(agreementSelectedButNotInList)
    setAgreementList(options)

    const selectedItems = ids.map((c) => ({
      value: c,
      title:
        agreementList?.find((aps) => aps.value === c)?.title ??
        agreementsSelected.find((psi) => psi.value === c)?.title ??
        c,
      hidden: true,
    }))
    return selectedItems
  }

  React.useEffect(() => {
    if (
      agreementsByCalculationType.data &&
      agreementsByCalculationType.data.length > 0
    ) {
      const agreementSelectedButNotInList =
        selectedItems.length > 0
          ? agreementsSelected
              .filter(
                (us) =>
                  !agreementsByCalculationType.data
                    .map((u) => u.value)
                    .includes(us.value)
              )
              ?.map((us) => ({
                value: us.value,
                title: us.title,
                hidden: true,
              }))
          : []

      const agreementsTemp = agreementsByCalculationType.data.concat(
        agreementSelectedButNotInList
      )
      setAgreementList(agreementsTemp)
    } else {
      setAgreementList([])
    }
  }, [agreementsByCalculationType.data])
  return (
    <>
      <MultiSelect
        options={agreementList}
        required={required}
        optionLabel="title"
        optionValue="value"
        name={fieldName}
        heading={heading}
        value={agreementList?.length > 0 ? selectedItems : []}
        onChange={(e) => {
          onChange(e)
          setAgreementsSelected(getSelectedAgreementValue(e.value))
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
        placeholder={
          agreementsByCalculationType.data &&
          agreementsByCalculationType.data?.length < 1
            ? t("no-records-found")
            : t("select-options")
        }
      />
      {errorMessage && displayValidations && (
        <ValidationMessage
          messageError={errorMessage}
          showMessage={
            displayValidations &&
            errorMessage !== undefined &&
            errorMessage !== null &&
            errorMessage.length > 0
          }
        />
      )}
    </>
  )
}
