const DarkTheme = {
  body: "#090909",
  text: "#fcfcfc",
  background: "#323232",
  foreground: "#404040",
  lineColor: "#919191",
  transparentButtonColor: "#A02563",
}

export default DarkTheme
