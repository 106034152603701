import styled from "styled-components"

const StyledSearchTextbox = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .filterText {
    width: "auto";
    border-bottom: 1px solid lightgrey;
  }

  svg {
    position: absolute;
    top: 10px;
  }

  input {
    left: 20px;
    position: relative;
  }

  .input-icon {
    position: relative;
  }
  svg {
    font-size: 15px;
  }

  .input-icon-left > div {
    padding-top: 10px;
  }

  .input-icon-left > input {
    left: 0px;
  }

  .caption {
    margin-left: var(--spacing-base);
    font-family: var(--font-light);
    font-size: var(--font-size-md);
    white-space: nowrap;
  }

  #command-filter.p-inputtext:enabled:hover,
  #searchTextbox.p-inputtext:enabled:hover {
    border: none;
  }
`

export default StyledSearchTextbox
