import styled from "styled-components"
import { InputTextarea } from "primereact/inputtextarea"

interface Props {
  width: string
  $transparent?: boolean
  height: string
}
const StyledInputTextArea = styled(InputTextarea)<Props>`
  width: ${({ width }) => width || null};
  min-width: ${({ width }) => width || null};
  max-width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  max-height: ${({ height }) => height || null};
  min-height: ${({ height }) => height || null};
  &:enabled:focus {
    border: ${({ $transparent }) =>
      $transparent ? "none" : "1px solid var(--color-ebateControlBackground)"};
    box-shadow: ${({ $transparent }) =>
      $transparent
        ? "none"
        : "inset 0 0 0 1px var(--color-ebateControlBackground), " +
          "inset 0 0 0 1px var(--color-ebateControlBackground)," +
          " inset 0 0 0 1px var(--color-ebateControlBackground), " +
          "inset 0 0 0 1px var(--color-ebateControlBackground)"};
  }

  padding-left: var(--spacing-base) !important;

  border: ${({ $transparent }) =>
    $transparent ? "none" : "2px solid var(--color-ebateControlBackground)"};
  background: ${({ $transparent }) =>
    $transparent ? "transparent" : "var(--color-ebateControlBackground)"};
`

export default StyledInputTextArea
