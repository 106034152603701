import React from "react"
import { StyledErrorMessage } from "./styled"

interface Props {
  messages: string[]
}
const ErrorMessage: React.FC<Props> = ({ messages }) => (
  <StyledErrorMessage role="alert">
    {messages.length > 1 ? (
      <ul>
        {messages.map((el, index) => {
          const key = index
          return <li key={key}> {el}</li>
        })}
      </ul>
    ) : (
      <span>{messages[0]}</span>
    )}
  </StyledErrorMessage>
)

export default ErrorMessage
