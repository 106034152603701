import { Calendar } from "primereact/calendar"
import styled from "styled-components"

interface Props {
  width?: string
}

const StyledDatePicker = styled(Calendar)<Props>`
  width: ${({ width }) => width || "100%"};
  border-radius: 4px;
  .date-picker-secondary-button {
    color: var(--color-ebatePrimary);
  }
`
export default StyledDatePicker
