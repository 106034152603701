import styled from "styled-components"
import { device } from "theme/helpers"

interface Props {
  height?: string
  full?: boolean
  slice?: string
  numberOfCards?: number
  name?: string
  heightBody?: string
}
export const StyledSimpleCard = styled.div<Props>`
  min-width: 0;
  .card {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: var(--shadow-sm);
    display: flex;
    flex-wrap: nowrap;
    height: ${({ height }) => height};

    background-color: var(--color-white);
    border: 0.5px solid rgba(35, 31, 32, 0.2);
  }

  @media ${device.mobileS} {
    width: 100%;
    margin: ${({ full }) =>
      full ? " var(--spacing-x2) 0" : "var(--spacing-half) 1% !important"};
  }

  @media ${device.tablet} {
    width: 100%;
    margin: ${({ full }) =>
      full ? "var(--spacing-x2) 0" : "var(--spacing-half) 1% !important"};
  }

  @media ${device.laptop} {
    width: ${({ full, numberOfCards }) => {
      if (full) return "100%"
      if (numberOfCards) {
        return `calc(94% / ${numberOfCards})`
      }
      return "calc(94%/3)"
    }};
    margin: ${({ full }) =>
      full ? "var(--spacing-x2) 0" : "var(--spacing-x2) 1% !important"};
  }

  @media ${device.laptopL} {
    width: ${({ full, numberOfCards }) => {
      if (full) return "100%"
      if (numberOfCards) {
        return `calc(94% / ${numberOfCards})`
      }
      return "calc(94%/3)"
    }};
    margin: ${({ full }) =>
      full ? "var(--spacing-x2) 0" : "var(--spacing-half) 1% !important"};
  }

  .card-slice {
    width: 8px;
    background-color: ${({ slice }) => `var(${slice})`};
    opacity: 0.5;
  }

  .card-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    min-width: 0;
  }

  .card-header-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: var(--spacing-half) var(--spacing-half) 0 var(--spacing-x3);
  }

  .card-header {
    width: 100%;
    color: var(--color-text);
    font-size: var(--font-size-lg);
    line-height: var(--lineHeight-lg);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-bold);
  }

  .card-icons {
    position: relative;
  }

  .card-icon {
    cursor: pointer;
    margin-top: var(--spacing-half);
  }
  .subHeader {
    width: 100%;
    margin-bottom: var(--spacing-half);
    line-height: var(--lineHeight-lg);
    font-size: var(--font-size-md);
  }

  .card-body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 var(--spacing-x2) var(--spacing-half) var(--spacing-x3);
    height: ${({ heightBody }) => heightBody};
  }

  .footer {
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: var(--spacing-base);
  }

  .slide-down {
    width: 100%;
  }

  .expand-section {
    z-index: var(--zindex-page);
  }

  .body-hidden {
    opacity: 0;
    height: 0;
  }

  .body-visible {
    opacity: 1;
    height: auto;
    transition: visibility 1s, opacity 1s linear;
  }
`
