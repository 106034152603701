const sizes = {
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "24px",
  header: "28px",
  subHeader: "24px",
  modalClose: "42px",
}

export default sizes
