import {
  NewsFeed,
  NewsFeedByCompanyRequest,
  NewsFeedRequest,
} from "components/pages/collaboration/newsFeed/newsFeedList/NewsFeedList.models"
import { ebateQueryKeys, useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getNewsFeedGridData, getNewsFeedsByCompanyId } from "queries"
import { useEffect } from "react"

export const useNewsFeedGridData = (newsFeedFilter: NewsFeedRequest) => {
  const getUniqueValues = (data): NewsFeed[] => {
    const flattened = data.pages.flatMap((page) => page.newsFeeds)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as NewsFeed[]
    return arrayUniqueByKey
  }

  const fetchDocumentGridData = async () => {
    const response = await getNewsFeedGridData(newsFeedFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_NEWS_FEED_GRID_DATA,
      newsFeedFilter.orderBy,
      newsFeedFilter.isAscending ? "true" : "false",
      newsFeedFilter.quickSearchFilter ?? "",
      newsFeedFilter.visibleByFilter ?? "",
      newsFeedFilter.visibleFromFilter?.toString() ?? "",
      newsFeedFilter.visibleToFilter?.toString() ?? "",
    ],
    fetchDocumentGridData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= newsFeedFilter.currentPage
          ? newsFeedFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, newsFeedFilter])

  const newsFeeds = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > newsFeedFilter.currentPage

  return { newsFeeds, hasMore, isLoading, isError }
}

export const useNewsFeedByCompanyList = (
  newsFeedFilter: NewsFeedByCompanyRequest
) => {
  const getUniqueValues = (data): NewsFeed[] => {
    const flattened = data.pages.flatMap((page) => page.newsFeeds)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as NewsFeed[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getNewsFeedsByCompanyId(newsFeedFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_NEWS_FEED_BY_COMPANY_LIST,
      newsFeedFilter.companyId?.toString() ?? "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= newsFeedFilter.currentPage
          ? newsFeedFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, newsFeedFilter])

  const newsFeeds = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > newsFeedFilter.currentPage

  return { newsFeeds, hasMore, isLoading, isError }
}
