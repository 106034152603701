import EbateAxios from "queries/ebateAxios"
import {
  RebateAllocationModel,
  RebateAllocationRequest,
  UpdateRebateAllocationRequest,
} from "components/pages/pricemanagement/rebates/rebateAllocation/rebateAllocationModels"
import { EbateApiResponseModel } from "components/models/common.model"
import { RebateIncentiveAllocationModel } from "components/pages/pricemanagement/rebateIncentives/rebateIncentiveAllocation/rebateIncentiveAllocationModels"

const rebateAllocationApiUrl = "/api/RebateAllocation"

export const getGlobalRebateAllocationList = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateAllocationModel[] | RebateIncentiveAllocationModel[]>(
    `${rebateAllocationApiUrl}/GetGlobalRebateAllocationList/${id}`
  )
}

export const getRebateAllocations = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateAllocationModel[] | RebateIncentiveAllocationModel[]>(
    `${rebateAllocationApiUrl}/GetRebateAllocations/${id}`
  )
}

export const getSumPercentage = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<Number>(`${rebateAllocationApiUrl}/GetSumPercentage/${id}`)
}

export const addRebateAllocation = async (data: RebateAllocationRequest) => {
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RebateAllocationRequest, EbateApiResponseModel>(
    `${rebateAllocationApiUrl}/Add`,
    data
  )
}

export const updateRebateAllocation = async (
  data: UpdateRebateAllocationRequest
) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<UpdateRebateAllocationRequest, EbateApiResponseModel>(
    `${rebateAllocationApiUrl}/Update`,
    data
  )
}

export const deleteRebateAllocation = async (data: number[]) => {
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, EbateApiResponseModel>(
    `${rebateAllocationApiUrl}/Delete`,
    data
  )
}
