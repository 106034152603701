import React from "react"
import Permissions from "../components/helpers/permissions"
import permissionRoute from "./permissionRoute"

const CollaborationRoutes = [
  {
    location: "/collaboration/NewsFeed",
    name: "collaboration-news-feed-maintenance",
    requiredPermissions: [Permissions.CollaborationNewsFeed],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/NewsFeed")),
      Permissions.CollaborationNewsFeed
    ),
  },
  {
    location: "/collaboration/documents",
    name: "collaboration-documents",
    requiredPermissions: [Permissions.CollaborationDocumentManagement],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/Document")),
      Permissions.CollaborationDocumentManagement
    ),
  },
  {
    location: "/collaboration/importTransactions",
    name: "collaboration-import-transactions",
    requiredPermissions: [Permissions.CollaborationImportTransactions],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/ImportTransactions")),
      Permissions.CollaborationImportTransactions
    ),
  },
  {
    location: "/collaboration/portalUserManagement",
    name: "collaboration-portal-user-management",
    requiredPermissions: [Permissions.CollaboratationPortalUserManagment],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/PortalUserManagement")),
      Permissions.CollaboratationPortalUserManagment
    ),
  },
  {
    location: "/collaboration/tradeAgreements",
    name: "collaboration-trade-agreements",
    requiredPermissions: [Permissions.CollaboratationTradeAgreements],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/TradeAgreements")),
      Permissions.CollaboratationTradeAgreements
    ),
  },
]

export default CollaborationRoutes
