import React, { createContext, useReducer, useEffect } from "react"
// Import theme properties
import {
  border,
  colors,
  font,
  gradients,
  lineHeight,
  shadows,
  spacing,
  zindex,
} from "./settings"

const getClientName = () => {
  if (
    window.location.href.includes(".viz.") ||
    window.location.href.includes(".viz-")
  ) {
    return "vizient"
  }
  return ""
}

// start with light theme
// TODO: Update to use localstorage and remember user choices
const initialState = {
  themeMode: "light",
  themeSpacing: "normal",
  theme: {
    color: colors.light,
    shadow: shadows.light,
    gradient: gradients.light,
    spacing: spacing.normal,
    lineHeight,
    font,
    border,
    zindex,
  },
}

// Returns an updated theme based on a colorscheme and spacing mode
const generateThemeState = (colorMode, spacingMode) => ({
  themeMode: colorMode,
  themeSpacing: spacingMode,
  theme: {
    color: colors[colorMode],
    shadow: shadows[colorMode] || shadows.light,
    gradient: gradients[colorMode] || gradients.light,
    spacing: spacing[spacingMode] || spacing.normal,
    lineHeight,
    font,
    border,
    zindex,
  },
})

// Returns new theme based on action type and Payload
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_MODE":
      // Use payload to update colors
      return generateThemeState(action.payload, state.themeSpacing)
    case "UPDATE_SPACING":
      // Use payload to update spacing
      return generateThemeState(state.themeMode, action.payload)
    default:
      return state
  }
}

// Export the theme context
export const ThemeContext = createContext()

// build themeProvider component
const ThemeProvider = ({ children }) => {
  const [themeState, themeDispatch] = useReducer(reducer, initialState)
  const { theme } = themeState

  useEffect(() => {
    const clientName = getClientName()
    if (clientName === "vizient") {
      themeDispatch({ type: "UPDATE_MODE", payload: "viz" })
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ themeState, themeDispatch, theme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
