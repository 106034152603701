import { AxiosResponse } from "axios"
import { UploadFileToSFTPRequest } from "components/models"
import EbateAxios from "queries/ebateAxios"

const importTransactionUrl = "/api/ImportTransaction"

export const getImportTransactionFileTemplate = async (
  exchangeObjectId: number,
  exchangeMappingId: number
) => {
  const url = `${importTransactionUrl}/GetImportTransactionFileTemplate/${exchangeObjectId}/${exchangeMappingId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<Blob>(url)
}

export const uploadTransactionFileToSFTP = async (
  request: UploadFileToSFTPRequest
): Promise<AxiosResponse<any>> => {
  const url = `${importTransactionUrl}/uploadFileToSFTP`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<UploadFileToSFTPRequest, any>(url, request)
}
