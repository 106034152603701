import styled from "styled-components"

interface Props {
  backgroundColor: string
}
export const StyledIconRounded = styled.div<Props>`
  border-radius: 50%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-ebateIconButton)"};
  color: white;
  min-height: 30px;
  min-width: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
