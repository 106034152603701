import styled from "styled-components"

const StyledDetailsForm = styled.div`
  .editor-detail {
    margin: 0;
    padding: 0 var(--spacing-half);
    width: 50%;
  }

  .editor-detail-label {
    padding-top: var(--spacing-base);
    padding-left: var(--spacing-x3);
    padding-bottom: var(--spacing-base);
    background-color: var(--color-lineColor);
    font-family: var(--font-light);
    font-size: var(--font-size-sm);
    text-align: left;
    border-right: 1px solid var(--color-lineColor);
    margin: 0;
    align-self: stretch;
  }
  .editor-detail-label > span {
    font-family: var(--font-light);
  }
  .editor-detail-label-edit {
    padding-top: var(--spacing-x2);
    padding-bottom: var(--spacing-x2);

    transition: unset;
  }

  .p-inputtext {
    font-family: var(--font-primary);
    font-size: var(--font-size-sm);
    width: 100%;
  }

  .editor-detail-item {
    padding: 0 var(--spacing-x3);
    font-size: var(--font-size-sm);
    color: var(--color-text);
    font-family: var(--font-bold);
  }

  input[type="number"] {
    margin-left: 0 !important;
    font-family: var(--font-primary);
    font-size: var(--font-size-sm);
    font-family: var(--font-light);
    height: var(--spacing-x2);
  }

  .p-input-icon-left {
    display: block;
    width: 100%;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    max-width: 150px;
  }

  .field-not-editable {
    height: auto;
    padding-top: 12px;
  }
  .multiselect-not-editable {
    height: 43px;
    overflow-y: auto;
  }
`

export default StyledDetailsForm
