import styled from "styled-components"

interface Props {
  fontSize?: string
  backgroundColorHover?: string
  width?: string
  disabled?: boolean
}
export const StyledSecondaryButton = styled.div<Props>`
  align-self: center;
  width: ${({ width }) => width || null};
  ${({ backgroundColorHover, disabled }) => {
    if (backgroundColorHover && !disabled) {
      return `  
            &:hover {
              background-color: ${backgroundColorHover};
              border-radius: var(--border-radius-md);
              transition: background-color 1s border-radius 0.1s;
            }`
    }
    if (!disabled) {
      return `  
          &:hover {
            background-color: var(--color-ebateSecondaryButtonHover);
            border-radius: var(--border-radius-md);
            transition: background-color 1s border-radius 0.1s;
          }`
    }
    return ``
  }}

  .icon-button {
    padding: 0 var(--spacing-half);
  }
  ${({ fontSize }) => {
    if (fontSize) {
      return `  .text-button {
    font-size: ${fontSize};
  }`
    }
    return `.text-button {
    font-size: var(--font-size-md);
  }`
  }}
  .text-button {
    font-size: ${({ fontSize }) => fontSize || "var(--font-size-md)"};
  }
`
