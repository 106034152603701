import { AxiosResponse } from "axios"
import { ValidationResultModel } from "components/models"
import {
  ClaimResponseApiModel,
  RoleDetailsApiModel,
  RoleDto,
  UpdateRoleAndClaimsRequest,
} from "components/pages/maintanance/roles/rolesDetails/roleDetailsModels"
import {
  AddRoleRequest,
  CopyRoleRequest,
} from "components/pages/maintanance/roles"
import { RolesGridData } from "components/pages/maintanance/roles/rolesList/rolesList.models"
import EbateAxios from "queries/ebateAxios"
import { GenericApiResponseModel } from "components/models/common.model"
import { RolesUserData } from "components/pages/maintanance/workflow/workflowLevel/workflowLevelForm/WorkflowLevelForm.model"

const portalRolesUrl = "/api/PortalRoles"

export const getPortalRoleClaims = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ClaimResponseApiModel[]>(
    `${portalRolesUrl}/GetPortalRoleClaims/`
  )
}

export const getPortalRoleDetails = async (id: string) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RoleDetailsApiModel>(
    `${portalRolesUrl}/GetPortalRoleDetails/${id}`
  )
}

export const getPortalRolesGridData = async () => {
  const url = `${portalRolesUrl}/GetPortalRolesGridData`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RolesGridData>(url)
}

export const updatePortalRoleAndClaims = async (
  request: UpdateRoleAndClaimsRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${portalRolesUrl}/UpdatePortalRoleAndClaims`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<UpdateRoleAndClaimsRequest, ValidationResultModel>(
    url,
    request
  )
}

export const addPortalRole = async (data: AddRoleRequest) => {
  const url = `${portalRolesUrl}/AddPortalRole`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<AddRoleRequest, GenericApiResponseModel<RoleModel>>(
    url,
    data
  )
}
export const copyPortalRole = async (data: CopyRoleRequest) => {
  const url = `${portalRolesUrl}/CopyPortalRole`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CopyRoleRequest, GenericApiResponseModel<RoleModel>>(
    url,
    data
  )
}

export const getNormalRoles = async (isPortal: boolean) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RoleDto[]>(`${portalRolesUrl}/GetNormalRoles/${isPortal}`)
}

export const getRolesAndUsers = async (isPortal) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RolesUserData[]>(
    `${portalRolesUrl}/GetRolesAndUsers/${isPortal}`
  )
}

export interface RoleModel {
  id: string
}
