import styled from "styled-components"
import { device } from "theme/helpers"

interface Props {
  topPosition?: string
  marginTop?: boolean
}
export const StyledDataTableCrud = styled.div<Props>`
  ${({ marginTop }) => {
    if (marginTop) {
      return `
        margin-top: 142px;
      `
    }
    return ``
  }}
  .p-datatable .p-datatable-tbody > tr > td > button {
    display: none;
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover
    > td
    > button {
    display: inline-flex;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight > td > button {
    display: inline-flex;
  }

  ${({ topPosition }) => {
    if (topPosition) {
      return `
      .iconHeader {
        position: sticky;
        top: calc(${topPosition} - 56px);
        z-index: var(--zindex-page);
      }
      .p-datatable-thead {
        position: sticky;
        top: ${topPosition};
        z-index: var(--zindex-page);
      }
    `
    }
    return ``
  }}

  @media ${device.mobileS} {
    .p-datatable .p-datatable-tbody > tr > td,
    .p-datatable .p-datatable-thead > tr > th {
      /* overflow: hidden; */
      text-overflow: ellipsis;
    }

    .p-datatable .p-datatable-tbody > tr > td:hover,
    .p-datatable .p-datatable-thead > tr > th:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }
  }

  .p-row-toggler {
    display: inline-flex !important;
  }
`
