const extractBaseUrl = (url) => {
  const splitUrl = url.split("/")
  const protocol = splitUrl[0]
  const host = splitUrl[2]
  return `${protocol}//${host}`
}

const extractTenantName = (hostName) => {
  const tenantName = hostName.split(".")[0]
  return tenantName
}

export default { extractBaseUrl, extractTenantName }
