import React, { useContext } from "react"
import "./App.css"
import "./fonts/fonts.css"
import "react-slidedown/lib/slidedown.css"
import { ThemeContext, GlobalStyle } from "theme"
import { AuthBlock } from "components/library"
import EBApp from "EBApp"
import { SSOADInterceptor } from "components/library/auth"
import { BrowserRouter as Router } from "react-router-dom"
import { EbateReactQuery } from "queries/ebateReactQuery"

const App = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <React.StrictMode>
      <EbateReactQuery>
        <Router>
          <GlobalStyle theme={theme} />
          <SSOADInterceptor>
            <AuthBlock />
            <EBApp />
          </SSOADInterceptor>
        </Router>
      </EbateReactQuery>
    </React.StrictMode>
  )
}

export default App
