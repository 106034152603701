const dark = {
  light:
    "linear-gradient(120deg, rgba(160,37,99,1) 0%, rgba(216,116,81,1) 100%);",
  medium:
    "linear-gradient(120deg, rgba(101,36,128,1) 0%, rgba(216,116,81,1) 100%);",
  dark: "linear-gradient(120deg, rgba(160,37,99,1) 0%, rgba(101,36,128,1) 100%)",
  ebateYellow:
    "linear-gradient(45deg, rgb(167,113,2) 0%,  rgb(252,174,15) 100%);",
  ebatePrimary:
    "linear-gradient(45deg, rgb(101,36,128) 0%,  rgb(160,61,201) 100%);",
  ebateBurgundy:
    "linear-gradient(45deg, rgb(128,30,79) 0%,  rgb(207,49,129) 100%);",
  ebateSecondary:
    "linear-gradient(45deg, rgb(130,55,29) 0%,  rgb(209,90,49) 100%);",
  ebateSpectrum:
    "linear-gradient(45deg, rgb(167,113,2) 0%,  rgb(101,36,128) 100%);",
}

export default dark
