import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { dropdownUtils } from "components/helpers"
import {
  getPaymentsSummaryByRebate,
  ebateQueryKeys,
  useEbateQuery,
  getRebateGlobalIncentiveGridData,
  getRebateIncentiveGridData,
  getAllConditionsMustBeMetValue,
  getCalculatedInfo,
  getGlobalAllConditionsMustBeMetValue,
  getGlobalCalculatedInfo,
  getRebateDetails,
  getRebateGlobalDetails,
  getRebateIncentiveDetails,
  getRebateIncentiveGlobalDetails,
  getGlobalRebateAllocationList,
  getRebateAllocations,
  getSumPercentage,
  getAllRebateConditions,
  getGlobalAllRebateConditions,
  getRebateConditionTypes,
  getGlobalRebateFileGridData,
  getRebateFileGridData,
  useEbateInfinityQuery,
  getGlobalRebatesGridData,
  getRebatesGridData,
  getGlobalRatesGridData,
  getRatesGridData,
} from "queries"

import {
  IncentiveGridData,
  IncentiveGridFilterRequest,
} from "components/pages/pricemanagement/rebateIncentives/incentives/incentiveList/incentiveList.models"
import { DocumentTabGridFilterRequest } from "components/library/documentTab"
import { DocumentTabGridData } from "components/library/documentTab/documentTab.models"
import { useContext, useEffect } from "react"
import {
  RebateIncentivesGridRequest,
  RebateIncentivesModel,
} from "components/pages/pricemanagement/rebateIncentives/rebateIncentiveList/rebateIncentiveList.models"
import {
  RebateGridModel,
  RebateGridRequest,
} from "components/pages/pricemanagement/rebates/rebateList/rebateList.models"
import {
  RateGridFilterRequest,
  RebateRatesGridModel,
} from "components/pages/pricemanagement/rebates/rebateRates/rebateRates.model"
import { BEConfigurationContext } from "stores"
import { mapRateGridDataFromApiToVm } from "components/pages/pricemanagement/rebates/rebateRates/rebateRates.mapper"
import { getColumns } from "components/pages/pricemanagement/rebates/rebateRates/rebateRates.common"
import { useTranslation } from "react-i18next"

export const useRebateDetails = (id: number, isGlobal?: boolean) => {
  const fetchRebate = async () => {
    try {
      const response = isGlobal
        ? await getRebateGlobalDetails(id)
        : await getRebateDetails(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateDetails = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_DETAILS, id.toString()],
    fetchRebate,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return rebateDetails
}

export const useRebateCalculationInfo = (id: number, isGlobal?: boolean) => {
  const fetch = async () => {
    try {
      const response = isGlobal
        ? await getGlobalCalculatedInfo(id)
        : await getCalculatedInfo(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateCalculationInfo = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_CALCULATION_INFO, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return rebateCalculationInfo
}

export const useRebateAllocation = (id: number, isGlobal?: boolean) => {
  const fetch = async () => {
    try {
      const response = isGlobal
        ? await getGlobalRebateAllocationList(id)
        : await getRebateAllocations(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateAllocations = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_ALLOCATION, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return rebateAllocations
}

export const useRebateAllocationSumPercentage = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getSumPercentage(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateAllocationSumPercentage = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATE_ALLOCATION_SUM_PERCENTAGE,
      id.toString(),
    ],
    fetch,
    {
      cacheTime: CacheTime.zero,
      staleTime: StaleTime.zero,
      enabled: id !== undefined && id !== null,
    }
  )

  return rebateAllocationSumPercentage
}

export const useRebateConditions = (id: number, isGlobal?: boolean) => {
  const fetch = async () => {
    try {
      const response = isGlobal
        ? await getGlobalAllRebateConditions(id)
        : await getAllRebateConditions(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateConditions = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_CONDITIONS, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      enabled: id !== null && id !== undefined,
      refetchOnMount: "always",
    }
  )

  return rebateConditions
}

export const useRebateConditionsMustMet = (id: number, isGlobal?: boolean) => {
  const fetch = async () => {
    try {
      const response = isGlobal
        ? await getGlobalAllConditionsMustBeMetValue(id)
        : await getAllConditionsMustBeMetValue(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateConditionsMustMet = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_CONDITIONS_MUST_BE_MET, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      enabled: id !== null && id !== undefined,
      refetchOnMount: "always",
    }
  )

  return rebateConditionsMustMet
}

export const useRebateConditionTypes = () => {
  const fetch = async () => {
    try {
      const response = await getRebateConditionTypes()
      if (response?.status === 200) {
        return dropdownUtils.mapToDropDownOptionFromApi(response.data)
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateConditionsMustMet = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_CONDITION_TYPES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return rebateConditionsMustMet
}

export const useRebatePaymentsSummary = (rebateId: number) => {
  const fetch = async () => {
    try {
      const response = await getPaymentsSummaryByRebate(rebateId)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebatePaymentSummary = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_PAYMENTS_SUMMARY, rebateId.toString()],
    fetch,
    {
      cacheTime: CacheTime.short,
      staleTime: StaleTime.short,
    }
  )

  return rebatePaymentSummary
}

export const useRebateIncentiveDetails = (id: number, isGlobal?: boolean) => {
  const fetchRebate = async () => {
    try {
      const response = isGlobal
        ? await getRebateIncentiveGlobalDetails(id)
        : await getRebateIncentiveDetails(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateDetails = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_INCENTIVE_DETAILS, id.toString()],
    fetchRebate,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.zero,
    }
  )

  return rebateDetails
}

export const useRebateIncentiveGridData = (
  request: IncentiveGridFilterRequest,
  isGlobal?: boolean
) => {
  const fetchRebate = async () => {
    try {
      const response = isGlobal
        ? await getRebateGlobalIncentiveGridData(request)
        : await getRebateIncentiveGridData(request)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const rebateIncentiveGridData = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATE_INCENTIVE_GRID_DATA,
      request.parentId.toString(),
    ],
    fetchRebate,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.zero,
    }
  )

  return rebateIncentiveGridData
}

export const useRebateDocumentGridData = (
  documentFilter: DocumentTabGridFilterRequest,
  isIncentive: boolean,
  isGlobal?: boolean
) => {
  const getUniqueValues = (data): DocumentTabGridData[] => {
    const flattened = data.pages.flatMap((page) => page.files)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as DocumentTabGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = isGlobal
      ? await getGlobalRebateFileGridData(documentFilter)
      : await getRebateFileGridData(documentFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATE_DOCUMENT_GRID_DATA,
      isIncentive ? "true" : "false",
      documentFilter.id.toString(),
      documentFilter.orderBy,
      documentFilter.isAscending ? "true" : "false",
      documentFilter.fileLinkTypeId?.toString() || "",
      isGlobal ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= documentFilter.currentPage
          ? documentFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, documentFilter])

  const documents = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > documentFilter.currentPage

  return { documents, hasMore, isLoading, isError }
}

export const useIncentiveGridData = (
  incentiveFilter: IncentiveGridFilterRequest,
  isGlobal?: boolean
) => {
  const getUniqueValues = (data): IncentiveGridData[] => {
    const flattened = data.pages.flatMap((page) => page.rebateIncentives)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as IncentiveGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = isGlobal
      ? await getRebateGlobalIncentiveGridData(incentiveFilter)
      : await getRebateIncentiveGridData(incentiveFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_INCENTIVE_GRID_DATA,
      incentiveFilter.parentId.toString(),
      incentiveFilter.orderBy,
      incentiveFilter.isAscending ? "true" : "false",
      isGlobal ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= incentiveFilter.currentPage
          ? incentiveFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, incentiveFilter])

  const incentives = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > incentiveFilter.currentPage

  return { incentives, hasMore, isLoading, isError }
}

export const useRebateGridData = (
  filter: RebateIncentivesGridRequest | RebateGridRequest,
  isIncentive: boolean,
  isGlobal?: boolean
) => {
  const getUniqueValues = (
    data
  ): RebateIncentivesModel[] | RebateGridModel[] => {
    const flattened = data.pages.flatMap((page) => page.rebateGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as RebateIncentivesModel[] | RebateGridModel[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = isGlobal
      ? await getGlobalRebatesGridData(filter)
      : await getRebatesGridData(filter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATE_GRID_DATA,
      isIncentive ? "true" : "false",
      filter.agreementId.toString(),
      filter.orderBy,
      filter.isAscending ? "true" : "false",
      isGlobal ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= filter.currentPage
          ? filter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, filter])

  const rebates = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > filter.currentPage

  return { rebates, hasMore, isLoading, isError }
}

export const useRebateRateGridData = (
  filter: RateGridFilterRequest,
  calculationTypeId,
  actionBodyTemplate,
  isGlobal?: boolean
) => {
  const { tenantConfiguration } = useContext(BEConfigurationContext)
  const { t } = useTranslation(["translation", "rebates"])

  const getUniqueValues = (data): RebateRatesGridModel[] => {
    const flattened = data.pages.flatMap((page) =>
      page.rateGridItems.map((r) =>
        mapRateGridDataFromApiToVm(
          r,
          !tenantConfiguration.agreementApprovalEnabled &&
            tenantConfiguration.rebateApprovalWorkflowEnabled
        )
      )
    )
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as RebateRatesGridModel[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = isGlobal
      ? await getGlobalRatesGridData(filter)
      : await getRatesGridData(filter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_REBATE_RATE_GRID_DATA,
      filter.parentId.toString(),
      filter.showExpired ? "true" : "false",
      filter.orderBy,
      filter.isAscending ? "true" : "false",
      filter.search,
      isGlobal ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= filter.currentPage
          ? filter.currentPage + 1
          : undefined,
    }
  )

  const getColumnData = () => {
    const cols = getColumns(
      calculationTypeId,
      tenantConfiguration.agreementApprovalEnabled ||
        tenantConfiguration.rebateApprovalWorkflowEnabled,
      t
    )
    return [
      ...cols,
      {
        field: "",
        header: "",
        sortable: false,
        body: actionBodyTemplate,
        style: { textAlign: "left", width: "10%" },
      },
    ]
  }
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, filter])

  const rates = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > filter.currentPage

  const columnData = getColumnData()

  return { rates, columnData, hasMore, isLoading, isError }
}
