import React, { useState, useEffect } from "react"

import { useSort } from "components/hooks"

import { StyledTable } from "./styled"
import PaginatedTable from "./Paginated"

import Header from "./Header"
import Rows from "./Rows"

const Table = (props) => {
  // Extract data
  const { data } = props
  const { nested } = props
  const { selectable } = props
  const { setSelection } = props
  const { rowParent } = props
  const { resetSelection } = props
  const { setAllSelection } = props

  // setup table state with sorted data
  const [currentData, setCurrentData] = useState()

  const { head, rows } = data
  /* 
    If no sort object is sent, initial sort will be 
    DESC order on the first sortable item in the head array
  */
  const defaultSort = {
    key: Object.keys(head).filter((item) => (head[item].sort ? item : null))[0],
    order: "desc",
  }

  // Sort data prior to render, based on sort property
  // If we're not sent a default sort, use the defaultSort variable
  const { sort = defaultSort } = data
  const [currentSort, setCurrentSort] = useState(sort)
  const sorter = useSort(rows)
  const sortedRows = sorter.sort(currentSort.key, currentSort.order)

  useEffect(() => {
    setCurrentData({ rows: sortedRows })
  }, [sortedRows])

  if (data === undefined) {
    return null
  }

  // Sort function. Passed down to Header
  const sortData = (key) => {
    if (key === currentSort.key) {
      if (currentSort.order === "desc") {
        setCurrentSort({ ...currentSort, order: "asc" })
      } else {
        setCurrentSort({ ...currentSort, order: "desc" })
      }
    } else {
      setCurrentSort({ key, order: "desc" })
    }
  }

  // Defer to paginated table if needed
  const { paginated } = props
  if (paginated) {
    return (
      <>
        <PaginatedTable
          data={currentData}
          {...props}
          sortFunc={sortData}
          currentSort={currentSort}
          nested={nested}
          selectable={selectable}
          setSelection={setSelection}
          resetSelection={resetSelection}
          setAllSelection={setAllSelection}
        />
      </>
    )
  }

  // Otherwise just return the table with all data
  return (
    <StyledTable {...props}>
      <Header
        data={currentData.head}
        sortFunc={sortData}
        currentSort={sort}
        nested={nested}
        selectable={selectable}
        checked={
          data.rows.filter((key) => key.selected).length === data.rows.length &&
          data.rows.length > 0
        }
        indeterminate={
          data.rows.filter((key) => key.indeterminate === "indeterminate")
            .length > 0 ||
          data.rows.filter((key) => key.selected).length !== data.rows.length
        }
        resetSelection={resetSelection}
        setAllSelection={setAllSelection}
      />
      <Rows
        data={currentData}
        nested={nested}
        selectable={selectable}
        rowParent={rowParent}
        setSelection={setSelection}
      />
    </StyledTable>
  )
}

export default Table
