import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { CriteriaLevels } from "components/library/criteria/criteria.models"
import { getCriteria, getGlobalCriteria } from "../criteria.query"

export const useCriteria = (id: number, type: CriteriaLevels) => {
  const fetchCriteria = async () => {
    try {
      const response = await getCriteria(Number(id), type)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const criteriaValues = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CRITERIA_GET, id.toString(), type.toString()],
    fetchCriteria,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return criteriaValues
}

export const useGlobalCriteria = (id: number, type: CriteriaLevels) => {
  const fetchCriteria = async () => {
    try {
      const response = await getGlobalCriteria(Number(id), type)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const criteriaValues = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CRITERIA_GLOBAL_GET,
      id.toString(),
      type.toString(),
    ],
    fetchCriteria,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return criteriaValues
}
