import { Grid, PrimaryButton } from "components/library"
import React from "react"
import { useTranslation } from "react-i18next"
import { StyledModalMessage } from "./styled"

interface Props {
  messages: string[]
  onConfirmationMessage: () => void
}
export const ModalMessage: React.FC<Props> = ({
  messages,
  onConfirmationMessage,
}) => {
  const { t } = useTranslation()

  return (
    <StyledModalMessage>
      <Grid>
        <div coordinates={[1, 2, 12, 11]}>
          {messages.map((el, index) => {
            const key = index
            return (
              <div key={key} className="message">
                {el}
              </div>
            )
          })}
        </div>
        <div coordinates={[1, 8, 12, 1]}>
          <Grid>
            <div coordinates={[1, 1, 2, 1]} className="footer">
              <div className="button-container">
                <PrimaryButton
                  text={t("popup-save")}
                  onClick={() => onConfirmationMessage()}
                  width="120px"
                />
              </div>
            </div>
          </Grid>
        </div>
      </Grid>
    </StyledModalMessage>
  )
}
