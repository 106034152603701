import {
  ClientDetailsApiModel,
  UpdateTenantRequest,
} from "components/pages/maintanance/clientMaintenance/clientDetails/clientDetails.models"
import EbateAxios from "queries/ebateAxios"
import { OptionApi } from "components/helpers"
import { ValidationResultModel } from "components/models"

const tenantUrl = "/api/Tenant"

export const getTenant = async () => {
  const url = `${tenantUrl}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ClientDetailsApiModel>(url)
}

export const getAllUserStructureValues = async () => {
  const url = `${tenantUrl}/GetAllUserStructureValues`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const getCompanyUserStructureValues = async () => {
  const url = `${tenantUrl}/GetCompanyUserStructureValues`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const updateTenant = async (request) => {
  const url = `${tenantUrl}/UpdateTenant`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<UpdateTenantRequest, ValidationResultModel>(url, request)
}

export const getRollOverStatus = async () => {
  const url = `${tenantUrl}/GetRolloverStatuses`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}
export const getAgreementAggregation = async () => {
  const url = `${tenantUrl}/GetAgreementAggregation`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const cleanTenantCache = async () => {
  const url = `${tenantUrl}/CleanTenantCache`
  const { AxiosPost } = EbateAxios()

  return AxiosPost(url, {})
}
