import React, { useContext } from "react"
import {
  ErrorMessage,
  FlexContainer,
  PrimaryButton,
  SecondaryButton,
  TextEditor,
} from "components/library"
import { useTranslation } from "react-i18next"
import { useForm } from "components/hooks"
import { ModalContext } from "stores"
import { NoteForm } from "components/library/note"
import { HeaderTemplate } from "components/library/textEditor/textEditor"
import { stringUtils } from "components/helpers"
import { StyledNoteFormComponent } from "./styled"

interface Props {
  initialValues: any
  save: (rebateNote: any) => void
  errorMessages: string[]
}
export const NoteFormComponent: React.FC<Props> = ({
  initialValues,
  save,
  errorMessages,
}) => {
  const { t } = useTranslation()
  const { handleModal } = useContext(ModalContext)

  const [displayValidations, setDisplayValidations] =
    React.useState<boolean>(false)

  const formValidator = (values: NoteForm) => {
    const validateValues = { ...values }
    if (!validateValues.text.value?.trim())
      validateValues.text.error = t("v-field-is-required")
    else if (
      stringUtils.plainHtmlText(validateValues.text.value).length > 1000
    ) {
      validateValues.text.error = `${t("v-the-maximum-length-is")} ${1000}`
    }
    return validateValues
  }
  const formState = useForm(initialValues, null, formValidator)

  const saveNote = () => {
    if (formState.isValid()) {
      save(formState.formValues)
    } else {
      setDisplayValidations(true)
    }
  }

  return (
    <StyledNoteFormComponent>
      {errorMessages && errorMessages.length > 0 ? (
        <ErrorMessage messages={errorMessages} />
      ) : (
        <span />
      )}
      <TextEditor
        style={{ height: "320px" }}
        value={formState.formValues.text.value}
        onTextChange={(e) => {
          formState.updateHtmlValue("text", e.htmlValue)
        }}
        header={HeaderTemplate.Basic}
      />
      {displayValidations &&
      formState.formValues.text.error &&
      formState.formValues.text.error.length > 0 ? (
        <span role="alert" className="error-msg">
          {formState.formValues.text.error}
        </span>
      ) : (
        <span />
      )}
      <FlexContainer className="footer" justify="flex-end">
        <SecondaryButton
          onClick={() => handleModal()}
          text={t("popup-cancel")}
          fontSize="var(--font-size-sm)"
          width="120px"
          id="cancel-button"
        />
        <PrimaryButton
          text={t("popup-save")}
          onClick={() => saveNote()}
          width="120px"
          id="save-button"
        />
      </FlexContainer>
    </StyledNoteFormComponent>
  )
}
