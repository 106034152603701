import styled from "styled-components"

const StyledModalCommonHeader = styled.div`
  color: var(--color-ebatePrimary);
  width: 100%;
  text-align: center;
  margin-top: -8px;
`

export default StyledModalCommonHeader
