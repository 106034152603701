import { useState } from "react"

const useModal = () => {
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [modalTitle, setModalTitle] = useState()

  const handleModal = (title, content = false, showModal) => {
    if (showModal !== undefined && showModal !== null) {
      setModal(showModal)
    } else {
      setModal(!modal)
    }
    if (content) {
      setModalContent(content)
    }
    if (title !== undefined && title != null) {
      setModalTitle(title)
    } else {
      setModalTitle("")
    }
  }
  const closeModal = () => {
    setModal(false)
  }

  return {
    modal,
    handleModal,
    modalContent,
    modalTitle,
    setModalContent,
    setModalTitle,
    closeModal,
  }
}

export default useModal
