import React from "react"
import Permissions from "components/helpers/permissions"
import permissionRoute from "./permissionRoute"

const MaintenanceRoutes = [
  {
    location: "/maintenance/accountingPeriod",
    name: "maintenance-accounting-period",
    requiredPermissions: [Permissions.MaintenanceAccountingPeriod],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/AccountingPeriod")),
      Permissions.MaintenanceAccountingPeriod
    ),
  },
  {
    location: "/maintenance/clientConfiguration",
    name: "maintenance-client-configuration",
    requiredPermissions: [Permissions.MaintenanceClientConfiguration],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/ClientMaintenance")),
      Permissions.MaintenanceClientConfiguration
    ),
  },
  {
    location: "/maintenance/companyMaintenance",
    name: "maintenance-company-maintenance",
    requiredPermissions: [Permissions.MaintenanceCustomerMaintenance],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/CompanyMaintenance")),
      Permissions.MaintenanceCustomerMaintenance
    ),
  },

  {
    location: "/maintenance/crossReferences",
    name: "maintenance-cross-references",
    requiredPermissions: [Permissions.MaintenanceCrossReference],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/CrossReferences")),
      Permissions.MaintenanceCrossReference
    ),
  },
  {
    location: "/maintenance/currency",
    name: "maintenance-currency",
    requiredPermissions: [Permissions.MaintenanceCurrency],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/Currency")),
      Permissions.MaintenanceCurrency
    ),
  },
  // {
  //   location: "/maintenance/dimensionManager",
  //   name: "maintenance-dimension-manager",
  //   requiredPermissions: [Permissions.MaintenanceDimensionManager],
  //   Component: permissionRoute(
  //     React.lazy(() => import("views/maintenance/DimensionManager")),
  //     Permissions.MaintenanceDimensionManager
  //   ),
  // },
  {
    location: "/maintenance/documentCategory",
    name: "maintenance-document-categories",
    requiredPermissions: [Permissions.MaintenanceDocumentCategory],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/DocumentCategory")),
      Permissions.MaintenanceDocumentCategory
    ),
  },
  {
    location: "/maintenance/documentTag",
    requiredPermissions: [Permissions.MaintenanceDocumentTag],
    name: "maintenance-document-tags",
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/DocumentTag")),
      Permissions.MaintenanceDocumentTag
    ),
  },
  // {
  //   location: "/maintenance/hierarchies",
  //   name: "maintenance-hierarchies",
  //   requiredPermissions: [Permissions.MaintenanceHierarchies],
  //   Component: permissionRoute(
  //     React.lazy(() => import("views/maintenance/Hierarchies")),
  //     Permissions.MaintenanceHierarchies
  //   ),
  // },
  // {
  //   location: "/maintenance/hierarchyMaintenance",
  //   name: "maintenance-hierarchy-maintenance",
  //   requiredPermissions: [Permissions.MaintenanceHierarchyManualMaintenance],
  //   Component: permissionRoute(
  //     React.lazy(() => import("views/maintenance/HierarchyMaintenance")),
  //     Permissions.MaintenanceHierarchyManualMaintenance
  //   ),
  // },
  {
    location: "/maintenance/PortalRoleDetails/:id",
    name: "maintenance-portal-roles-details",
    routeOnly: true,
    requiredPermissions: [Permissions.MaintenancePortalRoles],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/PortalRolesDetail")),
      Permissions.MaintenancePortalRoles
    ),
  },
  {
    location: "/maintenance/portalRoles",
    name: "maintenance-portal-roles-maintenance",
    requiredPermissions: [Permissions.MaintenancePortalRoles],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/PortalRoles")),
      Permissions.MaintenancePortalRoles
    ),
  },
  {
    location: "/maintenance/productMaintenance",
    name: "maintenance-product-maintenance",
    requiredPermissions: [Permissions.MaintenanceProductMaintenance],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/ProductMaintenance")),
      Permissions.MaintenanceProductMaintenance
    ),
  },
  {
    location: "/maintenance/ProjectMaintenance",
    name: "maintenance-project-maintenance",
    requiredPermissions: [Permissions.MaintenanceProjectMaintenance],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/ProjectMaintenance")),
      Permissions.MaintenanceProjectMaintenance
    ),
  },
  {
    location: "/maintenance/TradingGroup",
    name: "maintenance-trading-group",
    requiredPermissions: [Permissions.MaintenanceTradingGroup],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/TradingGroup")),
      Permissions.MaintenanceTradingGroup
    ),
  },
  {
    location: "/maintenance/TradingGroupDetails/:id",
    routeOnly: true,
    requiredPermissions: [Permissions.MaintenanceTradingGroup],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/TradingGroupDetails")),
      Permissions.MaintenanceTradingGroup
    ),
  },
  //   location: "/maintenance/uom",
  //   name: "maintenance-units-of-measurement",
  //   requiredPermissions: [Permissions.MaintenanceUnitsOfMeasure],
  //   Component: permissionRoute(
  //     React.lazy(() => import("views/maintenance/Uom")),
  //     Permissions.MaintenanceUnitsOfMeasure
  //   ),
  // },
  // {
  //   location: "/maintenance/uomConversion",
  //   name: "maintenance-unit-of-measurement-conversion-rates",
  //   requiredPermissions: [Permissions.MaintenanceUnitsOfMeasure],
  //   Component: permissionRoute(
  //     React.lazy(() => import("views/maintenance/UomConversion")),
  //     Permissions.MaintenanceUnitsOfMeasure
  //   ),
  // },
  {
    location: "/maintenance/users",
    name: "maintenance-user-maintenance",
    requiredPermissions: [Permissions.MaintenanceUsers],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/Users")),
      Permissions.MaintenanceUsers
    ),
  },
  {
    location: "/maintenance/roles",
    name: "maintenance-roles",
    requiredPermissions: [Permissions.MaintenanceRoles],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/Roles")),
      Permissions.MaintenanceRoles
    ),
  },
  {
    location: "/maintenance/RoleDetails/:id",
    name: "maintenance-roles-details",
    routeOnly: true,
    requiredPermissions: [Permissions.MaintenanceRoles],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/RolesDetail")),
      Permissions.MaintenanceRoles
    ),
  },
  {
    location: "/maintenance/wholesalerDiscount",
    name: "maintenance-wholesaler-discount",
    requiredPermissions: [Permissions.MaintenanceWholeSalerDiscount],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/WholesalerDiscount")),
      Permissions.MaintenanceWholeSalerDiscount
    ),
  },

  {
    location: "/maintenance/workflowAuditTrail",
    name: "maintenance-workflow-audit",
    requiredPermissions: [Permissions.MaintenanceWorkflowAudit],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/WorkflowAuditTrail")),
      Permissions.MaintenanceWorkflowAudit
    ),
  },
  {
    location: "/maintenance/workflow",
    name: "maintenance-workflow",
    requiredPermissions: [Permissions.MaintenanceWorkflow],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/Workflow")),
      Permissions.MaintenanceWorkflow
    ),
  },

  {
    location: "/maintenance/workflowDetail/:id",
    name: "maintenance-workflow-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.MaintenanceWorkflow],
    Component: permissionRoute(
      React.lazy(() => import("views/maintenance/WorkflowDetail")),
      Permissions.MaintenanceWorkflow
    ),
  },
]

export default MaintenanceRoutes
