import React from "react"
import { StyledMultiSelect } from "./styled"
import { FormInput } from "../formInput"

const MultiSelect = (props) => {
  const {
    heading,
    width,
    border,
    value,
    options,
    onChange,
    placeholder,
    name,
    filterBy,
    optionLabel,
    optionValue,
    required,
    onFilter,
  } = props

  return (
    <FormInput
      heading={heading}
      width={width}
      border={border}
      required={required}
    >
      <StyledMultiSelect
        value={value}
        options={options}
        onChange={onChange}
        display="chip"
        filter
        placeholder={placeholder}
        name={name}
        filterBy={filterBy}
        width={width}
        optionValue={optionValue}
        optionLabel={optionLabel}
        onFilter={onFilter}
        resetFilterOnHide
        data-testid={name}
        {...props}
      />
    </FormInput>
  )
}

export default MultiSelect
