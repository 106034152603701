import {
  ebateQueryKeys,
  useEbateQuery,
  getEmptyProject,
  getProjectGridData,
  getProjectWithDynamicFields,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  ProjectGridData,
  ProjectGridRequest,
} from "components/pages/maintanance/project/projectList/project.models"
import { useEbateInfinityQuery } from "queries/ebateReactQuery"
import { useEffect } from "react"

export const useProjectWithExtensionField = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getProjectWithDynamicFields(id.toString())
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const project = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PROJECT_WITH_DYNAMIC_FIELDS, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return project
}

export const useProjectEmpty = () => {
  const fetch = async () => {
    try {
      const response = await getEmptyProject()
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const emptyCustomer = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PROJECT_EMPTY],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return emptyCustomer
}

export const useProjectGridData = (projectFilter: ProjectGridRequest) => {
  const getUniqueValues = (data): ProjectGridData[] => {
    const flattened = data.pages.flatMap((page) => page.projectGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as ProjectGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getProjectGridData(projectFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PROJECT_GRID_DATA,
      projectFilter.orderBy,
      projectFilter.isAscending ? "true" : "false",
      projectFilter.search,
      projectFilter.statusId?.toString() || "",
      projectFilter.projectRef?.toString() || "",
      projectFilter.Name || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= projectFilter.currentPage
          ? projectFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, projectFilter])

  const projects = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > projectFilter.currentPage

  return { projects, hasMore, isLoading, isError }
}
