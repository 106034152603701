import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  getAccountTypes,
  getAccrualTypes,
  getCompanies,
  getCompanyTypesComboValuesByUser,
  getCurrencies,
  getDashboards,
  getDocumentSizeLimit,
  getDocumentTypes,
  getPayToTypes,
  getPriceTypes,
} from "queries/masterData/masterData.query"
import { OptionApi, OptionModel, dropdownUtils } from "components/helpers"
import { QueryFilterModel } from "components/library/dropdownEbate/query.model"
import { CurrencyDropDownModel } from "components/pages/maintanance/currency/currencyForm/currencyForm.models"

export const useDocumentSizeLimit = () => {
  const fetchDocumentSizeLimit = async () => {
    const response = await getDocumentSizeLimit()
    if (response?.status === 200) {
      return response.data.maxSize
    }
    throw new Error(response.statusText)
  }

  const documentSizeLimit = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DOCUMENT_SIZE_LIMIT],
    fetchDocumentSizeLimit,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return documentSizeLimit
}

export const useDocumentTypes = () => {
  const onCompletedDocumentTypes = (data: any) => {
    const documentTypeMapped = data.map((type) => `${type.name}`)
    return [...documentTypeMapped]
  }
  const fetchDocumentTypes = async () => {
    const response = await getDocumentTypes()
    if (response?.status === 200) {
      return onCompletedDocumentTypes(response.data)
    }
    throw new Error(response.statusText)
  }

  const documentTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DOCUMENT_TYPES],
    fetchDocumentTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return documentTypes
}

export const useAccountTypes = () => {
  const onCompletedAccountTypes = (data: any) => {
    const accountTypeMapped = dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...accountTypeMapped]
  }
  const fetchAccountTypes = async () => {
    const response = await getAccountTypes()
    if (response?.status === 200) {
      return onCompletedAccountTypes(response.data)
    }
    throw new Error(response.statusText)
  }

  const accountTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ACCOUNT_TYPES],
    fetchAccountTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return accountTypes
}

export const useCompanyTypesComboValuesByUser = () => {
  const onCompletedCompanyTypesComboValuesByUser = (data: any) => {
    const companyTypesComboValuesByUserMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...companyTypesComboValuesByUserMapped]
  }
  const fetchCompanyTypesComboValuesByUser = async () => {
    const response = await getCompanyTypesComboValuesByUser()
    if (response?.status === 200) {
      return onCompletedCompanyTypesComboValuesByUser(response.data)
    }
    throw new Error(response.statusText)
  }

  const companyTypesComboValuesByUser = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANY_TYPE_COMBO_VALUES_BY_USER],
    fetchCompanyTypesComboValuesByUser,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return companyTypesComboValuesByUser
}

export const usePriceTypes = () => {
  const onCompletedPriceTypes = (data: any) => {
    const priceTypesMapped = dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...priceTypesMapped]
  }
  const fetchPriceTypes = async () => {
    const response = await getPriceTypes()
    if (response?.status === 200) {
      return onCompletedPriceTypes(response.data)
    }
    throw new Error(response.statusText)
  }

  const priceTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PRICE_TYPES],
    fetchPriceTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return priceTypes
}

export const usePayToTypes = () => {
  const onCompletedPayToTypes = (data: any) => {
    const payToTypesMapped = dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...payToTypesMapped]
  }
  const fetchPayToTypes = async () => {
    const response = await getPayToTypes()
    if (response?.status === 200) {
      return onCompletedPayToTypes(response.data)
    }
    throw new Error(response.statusText)
  }

  const payToTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PAY_TO_TYPES],
    fetchPayToTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return payToTypes
}

export const useAccrualTypes = () => {
  const onCompletedAccrualTypes = (data: any) => {
    const accrualMapped = dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...accrualMapped]
  }
  const fetchAccrualTypes = async () => {
    const response = await getAccrualTypes()
    if (response?.status === 200) {
      return onCompletedAccrualTypes(response.data)
    }
    throw new Error(response.statusText)
  }

  const accrualTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_ACCRUAL_TYPES],
    fetchAccrualTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return accrualTypes
}

export const useCompanies = (
  data: QueryFilterModel,
  isAllCompaniesActive?: boolean
) => {
  const onCompletedCompanies = (data: OptionApi[]) => {
    let companiesFromApi = dropdownUtils.mapToDropDownOptionFromApi(data)
    if (isAllCompaniesActive) {
      companiesFromApi = [
        {
          value: 0,
          title: "All Companies",
        },
        ...companiesFromApi,
      ]
    }
    return [...companiesFromApi]
  }

  const fetchCompanies = async (): Promise<OptionModel[]> => {
    const response = await getCompanies(data.query)

    if (response?.status === 200) {
      return onCompletedCompanies(response.data)
    }
    return []
  }
  const companiesQuery = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANIES_DROPDOWN, data.query],
    fetchCompanies,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
    }
  )

  return companiesQuery
}

export const useCurrencies = () => {
  const onCompleted = (data: any) => {
    const currenciesMapped: CurrencyDropDownModel[] = data.map((c) => ({
      value: c.id,
      title: c.name,
      code: c.iso,
    }))
    return [...currenciesMapped]
  }
  const fetch = async () => {
    const response = await getCurrencies()
    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const currencies = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CURRENCIES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return currencies
}

export const useDashboard = () => {
  const onCompleted = (data: OptionApi[]): OptionModel[] => {
    const mappedDashboards = dropdownUtils.mapToDropDownOptionFromApi(data)

    return [...mappedDashboards]
  }

  const fetch = async (): Promise<OptionModel[]> => {
    const response = await getDashboards()

    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    return []
  }
  const dashboards = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DASHBOARDS],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return dashboards
}
