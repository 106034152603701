import styled from "styled-components"

const StyledFilterInput = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  .filterText {
    width: ${({ full }) => (full ? "100%" : "auto")};
    border-bottom: 1px solid lightgrey;
  }

  svg {
    position: absolute;
    top: 10px;
  }

  input {
    left: 20px;
    position: relative;
  }

  .input-icon {
    position: relative;
  }
  svg {
    font-size: 15px;
  }

  .caption {
    line-height: 70px;
    margin-left: var(--spacing-base);
  }
`

export default StyledFilterInput
