import { OptionApi } from "components/helpers"
import { ValidationResultModel } from "components/models"
import {
  ClaimPaymentStatusUpdateRequestType,
  ClaimPaymentStatusBulkUpdateRequestType,
} from "components/pages/finance/claimsPaymentsStatus/claimsPaymentsStatusForm/claimsPaymentsStatusForm.models"
import { PaymentStatusHistoryResponse } from "components/pages/finance/claimsPaymentsStatus/claimsPaymentsStatusHistory/claimsPaymentsStatusHistory.models"
import {
  ClaimsPaymentsStatusGridRequest,
  ClaimsPaymentsStatusGridResponse,
} from "components/pages/finance/claimsPaymentsStatus/claimsPaymentsStatusList/claimsPaymentsStatus.models"
import EbateAxios from "queries/ebateAxios"

const paymentStatusUrl = "/api/PaymentStatus"

export const getClaimsPaymentsStatusGridData = async (
  data: ClaimsPaymentsStatusGridRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${paymentStatusUrl}/GetClaimsPaymentsStatusGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    ClaimsPaymentsStatusGridRequest,
    ClaimsPaymentsStatusGridResponse
  >(url, data, abortSignal)
}

export const getPaymentStatus = async () => {
  const url = `${paymentStatusUrl}/GetPaymentStatus`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<OptionApi[]>(url)
}

export const getPaymentStatusHistory = async (id: number) => {
  const url = `${paymentStatusUrl}/GetPaymentStatusHistory/${id}`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<PaymentStatusHistoryResponse[]>(url)
}

export const updatePaymentStatus = async (
  data: ClaimPaymentStatusUpdateRequestType
) => {
  const url = `${paymentStatusUrl}/UpdatePaymentStatus`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<ClaimPaymentStatusUpdateRequestType, ValidationResultModel>(
    url,
    data
  )
}

export const bulkUpdatePaymentStatus = async (
  data: ClaimPaymentStatusBulkUpdateRequestType
) => {
  const url = `${paymentStatusUrl}/BulkUpdatePaymentStatus`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<
    ClaimPaymentStatusBulkUpdateRequestType,
    ValidationResultModel
  >(url, data)
}
