import styled from "styled-components"

const StyledWizardSummary = styled.div`
  .summary-box {
    padding: var(--spacing-base);
    margin: var(--spacing-base) 0;
    box-shadow: var(--shadow-sm);
    display: flex;
    flex-wrap: wrap;
  }

  .summary-name {
    font-family: var(--font-bold);
    width: 100%;
    margin-bottom: var(--spacing-base);
  }

  .summary-item {
    width: 100%;
    margin: var(--spacing-base) 0;
  }
`
export default StyledWizardSummary
