import { ValidationResultModel } from "components/models"
import { GenericApiResponseModel } from "components/models/common.model"
import EbateAxios from "queries/ebateAxios"

const fileUrl = "/api/file"

export const uploadFile = async (data: any) => {
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, ValidationResultModel>(`${fileUrl}/UploadFile`, data)
}

export const uploadFileWithContent = async (data: any) => {
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, GenericApiResponseModel<FileModel>>(
    `${fileUrl}/UploadFileWithContent`,
    data
  )
}

export const downloadFile = async (fileId: string) => {
  const url = `${fileUrl}/DownloadFile/${fileId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any>(url)
}

export const downloadDocument = async (fileId: string) => {
  const url = `${fileUrl}/DownloadDocument/${fileId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any>(url)
}

export const deleteFile = async (fileId: string) => {
  const url = `${fileUrl}/DeleteFile/${fileId}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}

export interface FileModel {
  id: string
}
