import styled from "styled-components"
import { Checkbox } from "primereact/checkbox"

const StyledCheckbox = styled(Checkbox)`
  .p-checkbox-box.p-highlight {
    border-color: var(--color-ebateCheckboxActive);
    background: var(--color-ebateCheckboxActive);
  }
  .p-checkbox-box.p-highlight {
    border-color: var(--color-ebateCheckboxActive);
    background: var(--color-ebateCheckboxActive);
  }
  .p-checkbox-box.p-highlight:not(.p-disabled).p-focus {
    border-color: var(--color-ebateCheckboxActive);
    background: var(--color-ebateCheckboxActive);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--color-ebateCheckboxActive);
    background: var(--color-ebateCheckboxActive);
  }
  .p-hidden-accessible {
    position: relative;
    padding-left: 2px;
  }
`

export default StyledCheckbox
