import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getAgreementDetails,
  getAgreementFileGridData,
  getAgreementsForCopyRebateByPackage,
  getGlobalAgreement,
  getGlobalAgreementFileGridData,
  ebateQueryKeys,
  useEbateInfinityQuery,
  useEbateQuery,
  getGlobalTradeAgreementFileGridData,
  getTradeAgreementFileGridData,
} from "queries"
import { DocumentTabGridFilterRequest } from "components/library/documentTab"
import { DocumentTabGridData } from "components/library/documentTab/documentTab.models"
import { useEffect } from "react"
import { AgreementGridModel } from "components/pages/pricemanagement/agreements"
import { AgreementGridRequest } from "components/pages/pricemanagement/agreements/agreementList/agreements.model"
import {
  TradeAgreementsTabGridData,
  TradeAgreementsTabGridFilterRequest,
} from "components/library/tradeAgreementsTab"

export const useAgreementDetails = (id: number, isGlobal?: boolean) => {
  const fetchAgreement = async () => {
    try {
      const response = isGlobal
        ? await getGlobalAgreement(id)
        : await getAgreementDetails(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const agreementDetails = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGREEMENT_DETAIL_GET, id.toString()],
    fetchAgreement,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return agreementDetails
}

export const useAgreementsForCopyRebateByPackage = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getAgreementsForCopyRebateByPackage(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const agreements = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_AGREEMENTS_FOR_COPY_REBATE_BY_PACKAGE,
      id.toString(),
    ],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.zero,
    }
  )

  return agreements
}

export const useAgreementDocumentGridData = (
  documentFilter: DocumentTabGridFilterRequest,
  isGlobal?: boolean
) => {
  const getUniqueValues = (data): DocumentTabGridData[] => {
    const flattened = data.pages.flatMap((page) => page.files)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as DocumentTabGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = isGlobal
      ? await getGlobalAgreementFileGridData(documentFilter)
      : await getAgreementFileGridData(documentFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_AGREEMENT_DOCUMENT_GRID_DATA,
      documentFilter.id.toString(),
      documentFilter.orderBy,
      documentFilter.isAscending ? "true" : "false",
      documentFilter.fileLinkTypeId?.toString() || "",
      isGlobal ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= documentFilter.currentPage
          ? documentFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, documentFilter])

  const documents = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > documentFilter.currentPage

  return { documents, hasMore, isLoading, isError }
}

export const useAgreementGridData = (
  agreementFilter: AgreementGridRequest,
  backendCall: any,
  backendCallName: string
) => {
  const getUniqueValues = (data): AgreementGridModel[] => {
    const flattened = data.pages.flatMap((page) => page.agreementGridItems)

    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as AgreementGridModel[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await backendCall(agreementFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      backendCallName,
      agreementFilter.orderBy,
      agreementFilter.isAscending ? "true" : "false",
      agreementFilter.periodStart?.toString() || "",
      agreementFilter.periodEnd?.toString() || "",
      agreementFilter.statusId?.toString() || "",
      agreementFilter.isForecast ? "true" : "false",
      agreementFilter.packageId?.toString() || "",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= agreementFilter.currentPage
          ? agreementFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, agreementFilter])
  const agreements = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > agreementFilter.currentPage
  return { agreements, hasMore, isLoading, isError }
}

export const useAgreementTradeAgreementGridData = (
  documentFilter: TradeAgreementsTabGridFilterRequest,
  isGlobal?: boolean
) => {
  const getUniqueValues = (data): TradeAgreementsTabGridData[] => {
    const flattened = data.pages.flatMap((page) => page.agreementFiles)

    if (flattened.length === 1 && flattened[0] === null) return []

    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item?.fileId, item]))?.values(),
    ] as TradeAgreementsTabGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = isGlobal
      ? await getGlobalTradeAgreementFileGridData(documentFilter)
      : await getTradeAgreementFileGridData(documentFilter)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_AGREEMENT_TRADE_AGREEMENT_GRID_DATA,
      documentFilter.agreementId.toString(),
      documentFilter.orderBy,
      documentFilter.isAscending ? "true" : "false",
      isGlobal ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= documentFilter.currentPage
          ? documentFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, documentFilter])

  const tradeDocuments = data ? getUniqueValues(data) : []
  const hasMore = data?.pages[0]?.numberOfPages > documentFilter.currentPage

  return { tradeDocuments, hasMore, isLoading, isError }
}
