import styled from "styled-components"
import { Chip } from "primereact/chip"

interface Props {}
const StyledChip = styled(Chip)<Props>`
  height: 35px;
  width: auto;
  max-width: 100%;
`
export default StyledChip
