import styled from "styled-components"

interface Props {
  margin?: string
}

const StyledHorizontalLine = styled.div<Props>`
  display: flex;
  height: 1px;
  margin-top: ${({ margin }) => margin || null};
  margin-bottom: ${({ margin }) => margin || null};
  background-color: var(--color-lineColor);
  width: 100%;
`
export default StyledHorizontalLine
