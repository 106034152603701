import React from "react"
import Permissions from "../components/helpers/permissions"
import permissionRoute from "./permissionRoute"

const FinanceRoutes = [
  {
    location: "/finance/accruals",
    name: "finance-accruals",
    requiredPermissions: [Permissions.FinanceAccruals],
    Component: permissionRoute(
      React.lazy(() => import("views/finance/Accruals")),
      Permissions.FinanceAccruals
    ),
  },
  {
    location: "/finance/approvefocclaim",
    name: "finance-approve-FOC-claim-files",
    requiredPermissions: [Permissions.FinanceApproveFOCClaims],
    Component: permissionRoute(
      React.lazy(() => import("views/finance/ApproveFocClaims")),
      Permissions.FinanceApproveFOCClaims
    ),
  },
  {
    location: "/finance/adjustment",
    name: "finance-adjustment",
    requiredPermissions: [Permissions.FinancePaymentAdjustments],
    Component: permissionRoute(
      React.lazy(() => import("views/finance/Adjustment")),
      Permissions.FinancePaymentAdjustments
    ),
  },
  {
    location: "/finance/claimsandpayments",
    name: "finance-claims-payments",
    requiredPermissions: [Permissions.FinancePayments],
    Component: permissionRoute(
      React.lazy(() => import("views/finance/ClaimsNPayments")),
      Permissions.FinancePayments
    ),
  },
  {
    location: "/finance/claimspaymentsstatus",
    name: "finance-claims-payments-status",
    requiredPermissions: [Permissions.FinanceClaimPaymentStatus],
    Component: permissionRoute(
      React.lazy(() => import("views/finance/ClaimsPaymentsStatus")),
      Permissions.FinanceClaimPaymentStatus
    ),
  },
  {
    location: "/finance/importdata",
    name: "finance-import-data",
    requiredPermissions: [Permissions.FinanceImportData],
    Component: permissionRoute(
      React.lazy(() => import("views/finance/ImportData")),
      Permissions.FinanceImportData
    ),
  },
]

export default FinanceRoutes
