export enum CacheTime {
  zero = 0, // 0min
  xShort = 1 * 30 * 1000, // 30s
  short = 1 * 60 * 1000, // 1min
  medium = 5 * 60 * 1000, // 5min
  long = 15 * 60 * 1000, // 15min
  infinity = Infinity, // infinity
}

export enum StaleTime {
  zero = 0, // 0min
  xShort = 1 * 30 * 1000, // 30s
  short = 1 * 60 * 1000, // 1min
  medium = 5 * 60 * 1000, // 5min
  long = 15 * 60 * 1000, // 15min
  infinity = Infinity, // infinity
}

export enum RefetchTime {
  zero = 0, // 0min
  xShort = 1 * 30 * 1000, // 30s
  short = 1 * 60 * 1000, // 1min
  medium = 5 * 60 * 1000, // 5min
  long = 15 * 60 * 1000, // 15min
  infinity = Infinity, // infinity
}
export interface OptionQuery {
  staleTime: StaleTime
  cacheTime: CacheTime
  enabled?: boolean
  select?: (data: any) => any
  getNextPageParam?: (lastPage: any, allPages: any) => number | undefined
  refetchOnMount?: any
  refetchInterval?: RefetchTime
}
