import styled from "styled-components"

const StyledAlertWidget = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:hover {
    cursor: pointer;
  }

  .alert-block {
    width: 100%;
    display: flex;
    padding: var(--spacing-base);
    background: var(--color-background);
    color: rgba(0, 0, 0, 0.87);
    box-shadow: var(--shadow-sm);
    margin: var(--spacing-base);
  }

  .alert-detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .alert-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alert-text {
    width: 100%;
    font-size: var(--font-size-md);
  }
  .alert-time {
    width: 100%;
    font-size: var(--font-size-xs);
    opacity: 0.65;
  }
`
export default StyledAlertWidget
