import { useState } from "react"

const useForm = (values, onSubmit, validator) => {
  // Create a state for the form to use (blank if not provided)
  const [formValues, setFormValues] = useState(values || {})

  const add = (name, newValue, validate = true) => {
    const state = { ...formValues }
    state[name] = { value: newValue, error: "" }

    if (validator && validate) {
      // Validate the form as a whole
      // We pass the form values, and the name of the most recently changed item
      setFormValues(validator(state, name))
    } else {
      // Set form state
      setFormValues(state)
    }
  }

  // Update form values
  const update = (name, value, validate = true) => {
    // Create new state from updated value
    const newState = { ...formValues, [name]: value }

    // if we have a validator function and haven't been specifically told not to validate...
    if (validator && validate) {
      // Validate the form as a whole
      // We pass the form values, and the name of the most recently changed item
      setFormValues(validator(newState, name))
    } else {
      // Set form state
      setFormValues(newState)
    }

    return newState
  }

  const updateInt = (e) => {
    update(e.target.name, {
      value:
        e.target.value !== undefined && e.target.value !== null
          ? parseInt(e.target.value, 10)
          : undefined,
    })
  }

  const updateCheck = (e) => {
    update(e.target.name, { value: e.target.checked })
  }

  const updateDate = (e) => {
    update(e.target.name, { value: e.target.value })
  }

  const updateValue = (e) => {
    update(e.target.name, { value: e.target.value })
  }

  const updateHtmlValue = (fieldName, value) => {
    // Remove all span tags and style attributes
    const htmlValue = value
      .replace(/<span[^>]*>/g, "")
      .replace(/<\/span>/g, "")
      .replace(/style="[^"]*"/g, "")

    update(fieldName, { value: htmlValue })
  }

  const updateValueInEbateDropdown = (fieldname, e) => {
    update(fieldname, {
      value: e.value,
      error: "",
    })
  }
  const updateValueInArray = (fieldName, index, value) => {
    const newState = { ...formValues }
    const array = newState[fieldName]
    array[index].value = { ...array[index].value, value }
    array[index].error = ""
    if (validator) {
      // Validate the form as a whole
      // We pass the form values, and the name of the most recently changed item
      setFormValues(validator(newState, fieldName))
    } else {
      // Set form state
      setFormValues(newState)
    }

    return newState
  }

  const updateObjectInArray = (fieldName, objectProperty, index, value) => {
    const newState = { ...formValues }
    const array = newState[fieldName]
    array[index][objectProperty].value = value
    array[index][objectProperty].error = ""
    if (validator) {
      // Validate the form as a whole
      // We pass the form values, and the name of the most recently changed item
      setFormValues(validator(newState, fieldName))
    } else {
      // Set form state
      setFormValues(newState)
    }

    return newState
  }
  // Run submit function with current form state
  const submit = () => {
    onSubmit(formValues)
  }

  // Reset form to its initial state values (if any)
  const reset = (overriddenValues) => {
    setFormValues(overriddenValues || values || {})
  }

  // If a validator has been set, returns the current form fields validity
  const isValid = () => {
    if (validator) {
      const fvKeys = Object.keys(formValues)
      for (let i = 0; i < fvKeys.length; i += 1) {
        if (Array.isArray(formValues[fvKeys[i]])) {
          return !formValues[fvKeys[i]].some((x) => x.error?.length > 0)
        }
        if (
          Object.values(
            update(fvKeys[i], { value: formValues[fvKeys[i]].value })
          ).some((k) => k.error?.length > 0)
        ) {
          return false
        }
      }
    }

    return true
  }

  return {
    formValues,
    submit,
    update,
    add,
    reset,
    isValid,
    updateInt,
    updateCheck,
    updateDate,
    updateValue,
    updateHtmlValue,
    updateValueInArray,
    updateObjectInArray,
    updateValueInEbateDropdown,
  }
}

export default useForm
