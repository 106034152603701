import styled from "styled-components"
import { InputNumber } from "primereact/inputnumber"

interface Props {
  width?: string
}
const StyledInputNumber = styled(InputNumber)<Props>`
  width: ${({ width }) => width || "100%"};
  align-items: center;
  border-radius: 4px;

  .p-button {
    color: var(--color-ebatePrimary);
    background-color: var(--color-ebateInputNumberButton);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    padding: 5%;
  }
  .p-button:enabled:hover,
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background-color: var(--color-ebatePrimary);
    color: var(--color-ebateInputNumberButton);
    border: 1px solid var(--color-ebateInputNumberButtonHover);
  }
  .p-inputtext:enabled:focus {
    box-shadow: none;
  }

  span.p-inputnumber-button-group {
    height: 100%;
  }
`
export default StyledInputNumber
