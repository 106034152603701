import { SimpleCard } from "components/library/simpleCard"
import styled from "styled-components"

export const StyledNewsFeedCardWidget = styled(SimpleCard)`
  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
