import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getCalculationType,
  getCalculationTypeComboValues,
  getCalculationTypeGridData,
} from "../calculationType.query"

export const useCalculationTypesComboValues = () => {
  const onCompleted = (data: any) => {
    const calculationTypesMapped = data.map((c) => ({
      value: c.id,
      title: c.description,
      isTier: c.isTier,
    }))
    return [...calculationTypesMapped]
  }
  const fetch = async () => {
    const response = await getCalculationTypeComboValues()
    if (response?.status === 200) {
      return onCompleted(response.data)
    }
    throw new Error(response.statusText)
  }

  const calculationTypesComboValues = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CALCULATION_TYPES_COMBO_VALUES],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return calculationTypesComboValues
}

export const useCalculationTypeGridData = () => {
  const fetch = async () => {
    const response = await getCalculationTypeGridData()
    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const calculationTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CALCULATION_TYPE_GRID_DATA],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return calculationTypes
}

export const useCalculationType = (id: number) => {
  const fetch = async () => {
    const response = await getCalculationType(id)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error(response.statusText)
  }

  const calculationType = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CALCULATION_TYPE, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      refetchOnMount: "always",
    }
  )
  return calculationType
}
