import { useAzureAuthentication } from "components/hooks/auth/useAzureAuthentication"
import React, { createContext } from "react"

export const AzureProviderContext = createContext()

export const AzureProvider = ({ authProvider, token, children }) => {
  const { hasPerformedSilentLogin } = useAzureAuthentication(token)
  return (
    <AzureProviderContext.Provider value={{ authProvider }}>
      {hasPerformedSilentLogin && children}
    </AzureProviderContext.Provider>
  )
}
