import {
  QueryFunction,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query"
import { useContext } from "react"
import { AuthenticationContext } from "stores"
import { OptionQuery } from "./ebateReactQuery.models"

export const useEbateQuery = (
  key: string[],
  queryFn: any | QueryFunction<any>,
  options: OptionQuery
) => {
  const { getTokenValue } = useContext(AuthenticationContext)
  const userId = getTokenValue("cognito:username")
  const uniqueKey = [...key, userId]
  return useQuery(uniqueKey, queryFn, options)
}

export const useEbateInfinityQuery = (
  key: string[],
  queryFn: any | QueryFunction<any>,
  options: OptionQuery
) => {
  const { getTokenValue } = useContext(AuthenticationContext)
  const userId = getTokenValue("cognito:username")
  const uniqueKey = [...key, userId]

  return useInfiniteQuery(uniqueKey, queryFn, options)
}
