import { ValidationResultModel } from "components/models"
import { IncentiveConditionApiModel } from "components/pages/pricemanagement/rebateIncentives/rebateIncentiveCondition/rebateIncentiveCondition.models"

import EbateAxios from "queries/ebateAxios"

const rebateIncentiveApiUrl = "/api/IncentiveCondition"

export const getIncentiveGlobalConditionByRebateIncentive = async (
  id: number
) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<IncentiveConditionApiModel[]>(
    `${rebateIncentiveApiUrl}/IncentiveGlobalConditionByRebateIncentive/${id}`
  )
}

export const getIncentiveConditionByRebateIncentive = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<IncentiveConditionApiModel[]>(
    `${rebateIncentiveApiUrl}/GetIncentiveConditionByRebateIncentive/${id}`
  )
}

export const addIncentiveConditions = async (data: any) => {
  const { AxiosPost } = EbateAxios()
  return AxiosPost<any, ValidationResultModel>(
    `${rebateIncentiveApiUrl}/AddIncentiveConditions`,
    data
  )
}
