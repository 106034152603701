import { InputSwitch } from "primereact/inputswitch"
import styled from "styled-components"

const StyledSwitch = styled(InputSwitch)`
  margin-left: 1rem;
  margin-right: 1rem;
  vertical-align: middle;
`

export default StyledSwitch
