// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal"
import { Configuration } from "msal"
import { useState } from "react"

export const useAzureAuth = () => {
  const [authProvider, setAuthProvider] = useState<MsalAuthProvider>()

  // Authentication Parameters
  const authenticationParameters = {
    scopes: ["openid"],
  }

  // Options
  const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}`,
  }

  const setConfiguration = (configuration: Configuration) => {
    setAuthProvider(
      new MsalAuthProvider(configuration, authenticationParameters, options)
    )
  }

  return { setConfiguration, authProvider }
}
