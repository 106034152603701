import React from "react"
import Permissions from "components/helpers/permissions"
import PortalCommercialRoutes from "./PortalCommercialRoutes"
import PortalDocumentRoutes from "./PortalDocumentRoutes"
import PortalMaintenanceRoutes from "./PortalMaintenanceRoutes"

// Portal Top level routing of the application
const PortalTopLevelRoutes = [
  {
    location: "/maintenance",
    name: "top-portal-maintenance",
    color: "ebateSecondary",
    dropdownRoutes: PortalMaintenanceRoutes,
    requiredPermissions: [
      Permissions.CollaborationNewsFeed,
      Permissions.CollaboratationPortalUserManagment,
      Permissions.CollaborationImportTransactions,
    ],
  },
  {
    location: "/documents",
    name: "top-portal-documents",
    color: "ebateSecondary",
    dropdownRoutes: PortalDocumentRoutes,
    requiredPermissions: [
      Permissions.CollaborationDocumentLibrary,
      Permissions.CollaborationDocumentManagement,
    ],
  },
  {
    location: "/commercial",
    name: "top-portal-commercial",
    color: "ebateSecondary",
    dropdownRoutes: PortalCommercialRoutes,
    requiredPermissions: [Permissions.CollaboratationTradeAgreements],
  },
  {
    location: "/Dashboard",
    name: "top-portal-home",
    color: "ebateSecondary",
    Component: React.lazy(() => import("views/Dashboard")),
  },
  {
    location: "/login",
    fallbackLocation: "/dashboard",
    Component: React.lazy(() => import("views/Dashboard")),
  },
]
export default PortalTopLevelRoutes
