import React from "react"
import { StyledLegendItem } from "./styled"

const LegendItem = (props) => {
  const { color, text, value } = props

  return (
    <StyledLegendItem color={color}>
      <div className="legend-block" />
      <span className="legend-text">{text}</span>
      <span className="legend-value">{value}</span>
    </StyledLegendItem>
  )
}

export default LegendItem
