import React from "react"
import { StyledNumberWidget } from "./styled"
import { LoadingComponent } from "../loading"

interface Props {
  bgcolor: string
  icon: string
  number: string | number
  text: string
  isLoading: boolean
}
const NumberWidget: React.FC<Props> = ({
  bgcolor,
  icon,
  number,
  text,
  isLoading,
}) => (
  <StyledNumberWidget bgcolor={bgcolor}>
    <div className="nw-body">
      {isLoading ? (
        <div style={{ width: "100%", verticalAlign: "middle" }}>
          <LoadingComponent height="100px" />
        </div>
      ) : (
        <>
          <div className="nw-left">
            <span className="nw-number">{number}</span>
            <span className="nw-text">{text}</span>
          </div>
          <i className={`${icon} nw-icon`} />
        </>
      )}
    </div>
  </StyledNumberWidget>
)

export default NumberWidget
