import EbateAxios from "queries/ebateAxios"
import {
  CriteriaModel,
  CriteriaLevels,
  CriteriaListModel,
} from "components/library/criteria/criteria.models"
import { AddCriteriaRequest } from "components/library/criteria/criteriaAdd/criteriaAdd.models"
import { OptionApi } from "components/helpers"
import { ValidationResultModel } from "components/models"
import { AddCriteriaCompanyRequest } from "components/library/criteria/criteriaCompanyAdd/criteriaCompanyAdd.models"

const criteriaUrl = "/api/Criteria"

export const getGlobalCriteria = async (id: number, type: CriteriaLevels) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<CriteriaModel[]>(
    `${criteriaUrl}/GetGlobalCriteria/${id}/${type}`
  )
}

export const getCriteria = async (id: number, type: CriteriaLevels) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<CriteriaModel[]>(`${criteriaUrl}/GetCriteria/${id}/${type}`)
}

export const getCriteriaDimensionStructured = async (
  fileTypeId: number,
  id: number,
  type: CriteriaLevels,
  childFile?: boolean
) => {
  let url = `${criteriaUrl}/GetCriteriaDimensionStructured/${fileTypeId}/${id}/${type}`

  if (childFile !== undefined && childFile !== null) {
    url += `/${childFile}`
  }

  const { AxiosGet } = EbateAxios()
  return AxiosGet<CriteriaListModel[]>(url)
}

export const getCriteriaValues = async (
  id: number,
  type: CriteriaLevels,
  basicDimensionId: number,
  criteriaId: number,
  filter?: string
) => {
  let url = `${criteriaUrl}/GetCriteriaValues/${id}/${type}/${basicDimensionId}/${criteriaId}`

  if (filter !== undefined && filter !== null && filter !== "") {
    url += `/${filter}`
  }

  const { AxiosGet } = EbateAxios()
  return AxiosGet<CriteriaListModel[]>(url)
}

export const addMultipleCriterias = async (request: AddCriteriaRequest) => {
  const url = `${criteriaUrl}/AddMultipleCriterias`

  const { AxiosPost } = EbateAxios()
  return AxiosPost<AddCriteriaRequest, ValidationResultModel>(url, request)
}

export const deleteCriteria = async (
  id: number,
  type: CriteriaLevels,
  criteriaId: number
) => {
  const url = `${criteriaUrl}/DeleteCriteria/${id}/${type}/${criteriaId}`

  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(url, {})
}

export const deleteCriteriaCompany = async (
  id: number,
  type: CriteriaLevels,
  criteriaId: number
) => {
  const url = `${criteriaUrl}/DeleteCriteriaCompany/${id}/${type}/${criteriaId}`

  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(url, {})
}

export const getCompanyLevelsList = async () => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<OptionApi[]>(`${criteriaUrl}/GetCompanyLevelsList`)
}

export const addMultipleCriteriasCompany = async (
  request: AddCriteriaCompanyRequest
) => {
  const url = `${criteriaUrl}/AddMultipleCriteriasCompany`

  const { AxiosPost } = EbateAxios()
  return AxiosPost<AddCriteriaCompanyRequest, ValidationResultModel>(
    url,
    request
  )
}

export const getCriteriaCompanyTypes = async () => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<OptionApi[]>(`${criteriaUrl}/GetCriteriaCompanyTypes`)
}
