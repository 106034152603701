import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

const userLanguage = window.navigator.userLanguage || window.navigator.language
let clientName = ""

if (
  window.location.href.includes(".viz.") ||
  window.location.href.includes(".viz-")
) {
  clientName = "en-US-Vizient"
}

const language = clientName ? `${clientName}` : `${userLanguage}`
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    allowMultiLoading: true,
    lng: language,
    backend: {
      // eslint-disable-next-line no-template-curly-in-string
      // loadPath: `/locales/en-GB${clientName}/{{ns}}.json`, // Use this loadPath when we retrive the transaction from backend
    },
    detection: {
      order: [
        "querystring",
        "cookie",
        "navigator",
        "localStorage",
        "sessionStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],
    },
    fallbackLng: "en-gb",
    debug: true,
    load: "currentOnly",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
