import light from "./light"
import dark from "./dark"
import global from "./global"
import vizientColors from "./vizient"

const colors = {
  light: {
    ...light,
    ...global,
  },
  dark: {
    ...dark,
    ...global,
  },
  viz: {
    ...global,
    ...vizientColors,
    ...light,
  },
}

export default colors
