import React from "react"
import Permissions from "../components/helpers/permissions"
import permissionRoute from "./permissionRoute"

const PriceManagementRoutes = [
  {
    location: "/rebateManagement/packages",
    name: "rebate-management-packages",
    requiredPermissions: [Permissions.PricingManagementPackage],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/Packages")),
      Permissions.PricingManagementPackage
    ),
  },
  {
    location: "/rebateManagement/agreements",
    name: "rebate-management-agreements",
    requiredPermissions: [Permissions.PricingManagementAgreement],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/Agreements")),
      Permissions.PricingManagementAgreement
    ),
  },
  {
    location: "/rebateManagement/approvals",
    name: "rebate-management-approvals",
    requiredPermissions: [Permissions.PricingManagementApprovals],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/Approvals")),
      Permissions.PricingManagementApprovals
    ),
  },
  {
    location: "/rebateManagement/calcEngineStatus",
    name: "rebate-management-calc-engine-status",
    requiredPermissions: [Permissions.PricingManagementCalcEngineStatus],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/CalcEngineStatus")),
      Permissions.PricingManagementCalcEngineStatus
    ),
  },
  {
    location: "/rebateManagement/rebateDetail/:id",
    name: "rebate-management-rebate-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.PricingManagementRebate],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/RebateDetail")),
      Permissions.PricingManagementRebate
    ),
  },
  {
    location: "/rebateManagement/globalRebateDetail/:id",
    name: "rebate-management-rebate-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.PricingManagementRebate],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/RebateDetail")),
      Permissions.PricingManagementRebate
    ),
  },
  {
    location: "/rebateManagement/rebateIncentivesDetail/:id",
    name: "rebate-management-rebate-incentives-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.PricingManagementEventBaseIncentive],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/RebateIncentivesDetail")),
      Permissions.PricingManagementEventBaseIncentive
    ),
  },
  {
    location: "/rebateManagement/globalRebateIncentivesDetail/:id",
    name: "rebate-management-rebate-incentives-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.PricingManagementEventBaseIncentive],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/RebateIncentivesDetail")),
      Permissions.PricingManagementEventBaseIncentive
    ),
  },
  {
    location: "/rebateManagement/agreementDetail/:id",
    name: "rebate-management-agreement-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.PricingManagementAgreement],
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/AgreementDetail")),
      Permissions.PricingManagementAgreement
    ),
  },
  {
    location: "/rebateManagement/packageDetail/:id",
    name: "rebate-management-package-detail",
    routeOnly: true,
    requiredPermissions: [Permissions.PricingManagementPackage],

    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/PackageDetail")),
      Permissions.PricingManagementPackage
    ),
  },
  {
    location: "/rebateManagement/exportimportrates",
    name: "rebate-management-export-import-rates",
    requiredPermissions: [Permissions.PricingManagementExportAndImportRates],
    hideIfWorkflowEnable: true,
    Component: permissionRoute(
      React.lazy(() => import("views/pricemanagement/ExportImportRates")),
      Permissions.PricingManagementExportAndImportRates
    ),
  },
]

export default PriceManagementRoutes
