import React from "react"
import { FormInput } from "../formInput"
import { StyledInputTextArea } from "./styled"

const InputText = (props) => {
  const {
    transparent,
    heading,
    value,
    onChange,
    placeholder,
    name,
    flat,
    onKeyUp,
    width,
    onBlur,
    maxLength,
    required,
    disabled,
    rows,
    height,
    readOnly,
    style,
  } = props

  return (
    <FormInput heading={heading} flat={flat} width={width} required={required}>
      <span className="input-icon-left" style={{ width }}>
        <StyledInputTextArea
          $transparent={transparent}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          width={width}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          rows={rows}
          height={height}
          readOnly={readOnly}
          style={style}
          data-testid={props.name}
          id={props.id ?? props.name}
        />
      </span>
    </FormInput>
  )
}

export default InputText
