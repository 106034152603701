import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { dateUtils } from "components/helpers"
import { ModalContext } from "stores"
import {
  ColumnModel,
  DataTableCrud,
  IconButton,
  IconModel,
  ModalYesNoContent,
} from "components/library"
import { LoadingComponent } from "components/library/loading"
import { StyledNotesComponent } from "./styled"
import { NoteModel } from "../note.models"

interface Props {
  notes: NoteModel[]
  showSpinner: boolean
  isReadOnly?: boolean
  deleteNote: (id: number) => void
  addNote: () => void
  editNote: (note: NoteModel) => void
}

const NoteListComponent: React.FC<Props> = ({
  notes,
  deleteNote,
  showSpinner,
  addNote,
  editNote,
  isReadOnly,
}) => {
  const { t } = useTranslation()
  const { handleModal } = useContext(ModalContext)

  const icons: IconModel[] = [
    {
      icon: "pi pi-plus",
      action: () => addNote(),
      isReadOnly,
      name: "add-button",
    },
  ]

  const actionBodyTemplate = (rowData: NoteModel): JSX.Element => (
    <>
      {!isReadOnly && (
        <>
          <IconButton
            icon="pi pi-pencil"
            onClick={() => editNote(rowData)}
            size="m"
            name={`edit-button-${rowData.id}`}
          />

          <IconButton
            icon="pi pi-trash"
            onClick={() =>
              handleModal(
                t("delete-note-modal-title"),
                <ModalYesNoContent
                  message={t("confirmation-delete-note")}
                  onConfirm={() => deleteNote(rowData.id)}
                />
              )
            }
            size="m"
            name={`delete-button-${rowData.id}`}
          />
        </>
      )}
    </>
  )
  const dateTemplateBody = (rowData: NoteModel): string => {
    if (!rowData.dateUpdated) {
      return dateUtils.formatDateTimeToLocale(rowData.dateCreated)
    }
    return dateUtils.formatDateTimeToLocale(rowData.dateUpdated)
  }

  const htmlTemplateBody = (rowData: NoteModel): any => (
    <div
      className="row-note"
      dangerouslySetInnerHTML={{ __html: rowData.text }}
    />
  )

  const columns: ColumnModel[] = [
    {
      field: "id",
      header: "ID",
      sortable: true,
      style: { textAlign: "right", width: "8%" },
    },
    {
      field: "userName",
      header: t("user"),
      sortable: true,
      style: { textAlign: "left", width: "20%" },
    },
    {
      field: "date",
      header: t("date"),
      sortable: true,
      body: dateTemplateBody,
      style: { textAlign: "left", width: "10%" },
    },
    {
      field: "textPlain",
      header: t("comment"),
      sortable: true,
      body: htmlTemplateBody,
      style: {
        textAlign: "left",
        width: "auto",
        WebkitLineClamp: "5",
      },
    },
    {
      field: "",
      header: "",
      sortable: false,
      body: actionBodyTemplate,
      style: { textAlign: "left", width: "7rem" },
    },
  ]

  return (
    <StyledNotesComponent>
      {!showSpinner && (
        <DataTableCrud
          columns={columns}
          value={notes}
          icons={icons}
          dataKey="id"
          topPosition="62px"
          rowHover
        />
      )}
      {showSpinner && <LoadingComponent height="70px" />}
    </StyledNotesComponent>
  )
}

export default NoteListComponent
