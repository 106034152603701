import React, { useState } from "react"
import {
  Dropdown,
  FlexContainer,
  IconButton,
  IconContainer,
  SearchTextBox,
  SecondaryButton,
  Fade,
} from "components/library"
import { useTranslation } from "react-i18next"
import { StyledCommandBar } from "./styled"

interface Props {
  onFilterClick?: () => void
  onViewSwitch?: () => void
  cardView?: boolean
  onSort?: any
  onAdd?: () => void
  sortItems?: any
  initialSort?: any
  onUpload?: () => void
  search?: boolean
  searchName?: string
  searchValue?: string
  onChange?: (e) => void
  onKeyUp?: () => void
  searchPlaceholder?: string
  searchCaption?: string
  onCancel?: () => void
  onAdjustment?: () => void
  isReadOnly: boolean
}
export const CommandBarClaims: React.FunctionComponent<Props> = ({
  onFilterClick,
  onViewSwitch,
  cardView,
  onSort,
  onAdd,
  sortItems,
  initialSort,
  onUpload,
  search,
  searchName,
  searchValue,
  onChange,
  onKeyUp,
  searchPlaceholder,
  searchCaption,
  onCancel,
  onAdjustment,
  isReadOnly,
}) => {
  const [sortBy, setSortBy] = useState(initialSort)
  const { t } = useTranslation()

  const icons = () => (
    <IconContainer>
      {onViewSwitch ? (
        <IconButton icon="pi pi-table" onClick={onViewSwitch} />
      ) : null}
      {onAdd && !isReadOnly ? (
        <IconButton id="command-add" icon="pi pi-plus" onClick={onAdd} />
      ) : null}
      {onUpload && !isReadOnly ? (
        <IconButton icon="pi pi-cloud-upload" onClick={onUpload} />
      ) : null}
      {onCancel ? <IconButton icon="pi pi-times" onClick={onCancel} /> : null}

      {onAdjustment && !isReadOnly ? (
        <IconButton icon="pi pi-sliders-h" onClick={onAdjustment} />
      ) : null}
    </IconContainer>
  )

  const sort = () => (
    <>
      {sortItems && (
        <Fade collapse={cardView}>
          {cardView && (
            <Dropdown
              transparent
              options={sortItems}
              canSearch={false}
              width="250px"
              name="sort"
              value={sortBy}
              border="1px solid var(--color-ebatePrimary)"
              onChange={(e) => {
                setSortBy(e.target.value)
                onSort(e.target.value)
              }}
            />
          )}
        </Fade>
      )}
    </>
  )

  const searchTextBox = () => (
    <>
      {search && (
        <SearchTextBox
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={searchPlaceholder}
          name={searchName}
          value={searchValue}
          caption={searchCaption}
          id="command-filter"
        />
      )}
    </>
  )

  const filterIcon = () => (
    <>
      {onFilterClick && (
        <SecondaryButton
          onClick={() => onFilterClick()}
          text={t("filters")}
          icon="pi pi-filter"
          iconToRightText
          fontSize="1rem"
        />
      )}
    </>
  )

  return (
    <StyledCommandBar>
      <FlexContainer className="wrapper">
        <FlexContainer direction="row" justify="flex-start">
          {searchTextBox()}
          {filterIcon()}
        </FlexContainer>
        <FlexContainer justify="flex-end" className="margins">
          {sort()}
          {icons()}
        </FlexContainer>
      </FlexContainer>
    </StyledCommandBar>
  )
}
