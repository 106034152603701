import Axios, { AxiosResponse } from "axios"

const EbateAxios = () => {
  const AxiosPost = <T extends object, R extends object>(
    url: string,
    data: T,
    signal?: AbortSignal
  ): Promise<AxiosResponse<R>> =>
    Axios.post<T, AxiosResponse<R>>(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      signal,
    })

  const AxiosPut = <T extends object, R extends object>(
    url: string,
    data: T
  ): Promise<AxiosResponse<R>> =>
    Axios.put<T, AxiosResponse<R>>(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })

  const AxiosGet = <T extends object>(
    url: string,
    params: any | undefined = undefined,
    responseType = "",
    signal?: AbortSignal
  ): Promise<AxiosResponse<T>> => {
    if (responseType) {
      return Axios.get<T>(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          responseType,
        },
        params,
        signal,
      })
    }
    return Axios.get<T>(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      params,
      signal,
    })
  }

  const AxiosPostSilentLogin = <T extends object, R extends object>(
    url: string,
    token: string,
    signal?: AbortSignal
  ): Promise<AxiosResponse<R>> =>
    Axios.post<T, AxiosResponse<R>>(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
        },
        signal,
      }
    )

  const AxiosDelete = <R extends object>(
    url: string
  ): Promise<AxiosResponse<R>> =>
    Axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })

  return { AxiosPostSilentLogin, AxiosPost, AxiosPut, AxiosGet, AxiosDelete }
}

export default EbateAxios
