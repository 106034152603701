import { AxiosResponse } from "axios"
import { OptionApi } from "components/helpers"
import { TurnoverAdjustmentAddRequest } from "components/pages/finance/TurnoverAdjustment/turnoverAdjustment.models"
import EbateAxios from "queries/ebateAxios"
import { ValidationResultModel } from "components/models/validationResultModel"

const turnoverAdjustmentUrl = "/api/TurnoverAdjustment"

export const getAdjustmentTypes = async () => {
  const url = `${turnoverAdjustmentUrl}/GetAdjustmentTypes`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const getTransactionTypes = async () => {
  const url = `${turnoverAdjustmentUrl}/GetTransactionTypes`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const addTurnoverAdjustment = async (
  request: TurnoverAdjustmentAddRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${turnoverAdjustmentUrl}/AddTurnoverAdjustment`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<TurnoverAdjustmentAddRequest, ValidationResultModel>(
    url,
    request
  )
}
