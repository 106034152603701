import React from "react"
import logo from "assets/ebate-loader.gif"
import { StyledCardGridLayoutContainer } from "./styled"

const CardGridLayoutContainer = (props) => {
  const { id, cardView, grid, cards, isLoading, className } = props

  if (isLoading) {
    return (
      <StyledCardGridLayoutContainer className={className}>
        <div className="spinnerContainer">
          <img
            src={logo}
            alt="Loading..."
            id="imgLoadMore"
            height="100"
            width="100"
          />
        </div>
      </StyledCardGridLayoutContainer>
    )
  }

  return (
    <StyledCardGridLayoutContainer id={id} className={className}>
      <div
        className={cardView ? "transition-visible" : "transition-hidden"}
        style={{ display: cardView ? "block" : "none" }}
      >
        {cards}
      </div>
      <div
        className={!cardView ? "transition-visible" : "transition-hidden"}
        style={{ display: !cardView ? "block" : "none" }}
      >
        {grid}
      </div>
    </StyledCardGridLayoutContainer>
  )
}

export default CardGridLayoutContainer
