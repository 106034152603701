import React from "react"
import permissionRoute from "routes/permissionRoute"
import Permissions from "components/helpers/permissions"

const PortalCommercialRoutes = [
  {
    location: "/commercial/tradeAgreements",
    name: "portal-commercial-trade-agreements",
    requiredPermissions: [Permissions.CollaboratationTradeAgreements],
    Component: permissionRoute(
      React.lazy(() => import("views/collaboration/TradeAgreements")),
      Permissions.CollaboratationTradeAgreements
    ),
  },
]

export default PortalCommercialRoutes
