import EbateAxios from "queries/ebateAxios"
import {
  AgreementGridRequest,
  PublishAgreementRequest,
  SubscriptionAgreementGridResponse,
  UnPublishDeleteAgreementRequest,
} from "components/pages/pricemanagement/agreements/agreementList/agreements.model"
import { GenericApiResponseModel } from "components/models/common.model"
import { ValidationResultModel } from "components/models"
import { AgreementSubscriptionType } from "components/pages/pricemanagement/deal/dealSubscriptionModels"

interface PublishAgreementResponse {
  IsSubscribable: boolean
}

const subscriptionURL = "/api/Subscription"

export const publishAgreementApi = async (request: PublishAgreementRequest) => {
  const url = `${subscriptionURL}/PublishAgreement`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    PublishAgreementRequest,
    GenericApiResponseModel<PublishAgreementResponse>
  >(url, request)
}

export const unSubscribeAndDeleteApi = async (
  request: UnPublishDeleteAgreementRequest
) => {
  const url = `${subscriptionURL}/UnsubscribeAndDeleteAgreement`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<UnPublishDeleteAgreementRequest, ValidationResultModel>(
    url,
    request
  )
}

export const getSubscriptionAgreementsGridData = async (
  request: AgreementGridRequest
) => {
  const url = `${subscriptionURL}/GetSubscriptionAgreementsGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<AgreementGridRequest, SubscriptionAgreementGridResponse>(
    url,
    request
  )
}

export const getAgreementSubscription = async (id: string) => {
  const url = `${subscriptionURL}/GetAgreementSubscription/${id}`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<AgreementSubscriptionType>(url)
}

export const subscribeToAgreement = async (request: any) => {
  const url = `${subscriptionURL}/SubscribeToAgreement`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<any, any>(url, request)
}
