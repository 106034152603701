import { dateUtils } from "components/helpers"
import { ColumnModel } from "components/library"

const dateStartTemplateBody = (rowData): string =>
  dateUtils.formatDateToLocale(rowData.fromDate)

const TieredValuePerColumns: ColumnModel[] = [
  {
    field: "id",
    header: "ID",
    sortable: true,
  },
  {
    field: "fromDate",
    header: "Start Date",
    sortable: true,
    body: dateStartTemplateBody,
  },
  {
    field: "unitsOfMeasurementDescription",
    header: "UOM",
    sortable: true,
  },
  {
    field: "statusDescription",
    header: "Status",
    sortable: true,
  },
  // Will uncomment this column after the rate value issur solved
  // {
  //   field: "currentRate",
  //   header: "Current Rate",
  //   sortable: true,
  // },
]

export default TieredValuePerColumns
