import styled from "styled-components"

const StyledIcon = styled.div`
  color: ${({ color }) => color || "var(--color-ebatePrimary)"};

  ${({ size }) => {
    if (size === "sm") {
      return `
        height: 20px !important;
        width: 20px !important;
        font-size: var(--font-size-xs);

      .p-button-icon {
          font-size: var(--font-size-xs) !important;
        }
        `
    }

    return `
        height: 40px !important;
        width: 40px !important;
        font-size: var(--font-size-xl);
        margin-left: var(--spacing-base);

        .p-button-icon {
            font-size: var(--font-size-xl) !important;
        }`
  }}
`

export default StyledIcon
