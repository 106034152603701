import React, { useState } from "react"
import { NavLink, useMatch } from "react-router-dom"
import { Slidedown } from "components/library"
import { FaCaretDown } from "react-icons/fa"

import { LinkCollectionContainer, StyledLinkedCollection } from "./styled"

const LinkCollection = ({ links, className }) => {
  const [slidedown, setSlidedown] = useState("")

  const linkItemSelected = () => setSlidedown("")
  const route = useMatch("/")
  return (
    <LinkCollectionContainer className={className || ""}>
      <StyledLinkedCollection>
        {links
          .filter((x) => !x.routeOnly && x.name)
          .map((link) => (
            <li
              key={link.location}
              onMouseLeave={() => link.dropdownRoutes && setSlidedown("")}
              style={{ paddingBottom: "0px", textDecoration: "none" }}
              className={
                link.hideOnTablet ? "nav-link hide-on-tablet" : "nav-link"
              }
            >
              <NavLink
                to={`${route?.pathnameBase === "/" ? "" : route?.pathnameBase}${
                  link.location
                }`}
                key={link.location}
                onMouseEnter={() =>
                  link.dropdownRoutes && setSlidedown(link.location)
                }
                onClick={(e) => link.dropdownRoutes && e.preventDefault()}
                className={({ isActive }) =>
                  isActive ? "active-nav-link" : "main-nav-link"
                }
              >
                {link.name}
                {link.dropdownRoutes && (
                  <FaCaretDown style={{ width: "8px", marginLeft: "8px" }} />
                )}
              </NavLink>
              {link.dropdownRoutes && slidedown === link.location && (
                <Slidedown
                  key={`sd_${link.location}`}
                  menuItems={link.dropdownRoutes}
                  linksColor={link.color}
                  linkItemSelected={linkItemSelected}
                />
              )}
            </li>
          ))}
      </StyledLinkedCollection>
    </LinkCollectionContainer>
  )
}

export default LinkCollection
