import styled from "styled-components"

const Item = styled.div`
  ${({ coordinates, breakpoint }) => {
    // Check for breakpoint specific sizing
    let gridcoordinates = coordinates
    if (coordinates[breakpoint]) {
      gridcoordinates = coordinates[breakpoint]
    }
    // Calculatie the dimensions of the grid item
    const dimensions = `
      grid-column: ${
        gridcoordinates
          ? `${gridcoordinates[0]} / span ${gridcoordinates[2]};`
          : ""
      };
      grid-row: ${
        gridcoordinates
          ? `${gridcoordinates[1]} / span ${gridcoordinates[3]};`
          : ""
      }
    `
    // Return the finished styles
    return `
      display: block;
      opacity:1;
      width: 100%;
      height: 100%;
      ${dimensions}
      transition: all 0.5s;
    `
  }}
  ${({ draggable }) => (draggable ? "cursor: move;" : null)}
`

export default Item
