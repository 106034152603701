import styled from "styled-components"
import { DataTable } from "primereact/datatable"

interface Props {
  headings?: string
}

const StyledFullGrid = styled(DataTable)<Props>`
  .p-sortable-column-icon {
    color: var(--color-white) !important;
    margin-left: 0.5rem;
  }

  th {
    display: ${({ headings }) => `${headings}`};
    background-color: var(--color-ebatePrimary) !important;
    color: var(--color-white) !important;
  }
`

export default StyledFullGrid
