import React from "react"
import { StyledMultiStateCheckbox } from "./styled"

const MultiStateCheckbox = (props) => {
  const { value, onChange } = { ...props }
  const options = [
    { value: "checked", icon: "pi pi-check" },
    { value: "indeterminate", icon: "pi pi-minus" },
  ]
  return (
    <StyledMultiStateCheckbox
      options={options}
      value={value}
      onChange={onChange}
      optionValue="value"
    />
  )
}

export default MultiStateCheckbox
