function isStringAsNumber(input: string): boolean {
  if (input === undefined || input === null || input === "") return false
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(Number(input))
}

function isStringAsDate(input: string): boolean {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(Date.parse(input))
}

export default { isStringAsNumber, isStringAsDate }
