import { OptionModel } from "components/helpers"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getProductsOfAgreementsByCalculationType } from "queries"
import { ProductsOfAgreementsRequest } from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"

export const useGetProductCodesByCalculationType = (
  calculationTypeId: number,
  agreements: number[],
  rebates: number[],
  query: string
) => {
  const fetchProductCodesByCalculationType = async (): Promise<
    OptionModel[]
  > => {
    const request: ProductsOfAgreementsRequest = {
      calculationTypeId,
      agreementIds: agreements,
      rebateIds: rebates,
      filter: query,
    }
    const response = await getProductsOfAgreementsByCalculationType(request)
    if (response?.status === 200) {
      return response.data?.map((d) => ({
        value: d.id,
        title: `${d.id} - ${d.name}`,
      }))
    }
    return []
  }
  const productCodesByCalculationType = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_PRODUCT_CODES_BY_CALCULATION_TYPE,
      calculationTypeId.toString(),
      agreements.join(","),
      rebates.join(","),
      query,
    ],
    fetchProductCodesByCalculationType,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      enabled: agreements && agreements.length > 0,
    }
  )

  return { productCodesByCalculationType }
}
