const normal = {
  base: "8px",
  x2: "16px",
  x3: "24px",
  x4: "32px",
  x5: "40px",
  half: "4px",
  quarter: "2px",
}
export default normal
