import { useOktaAuth } from "@okta/okta-react"
import { useEffect, useState, useContext } from "react"
import Axios from "axios"
import { AuthenticationContext } from "stores"

export const useOktaAuthentication = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const [hasPerformedSilentLogin, setHasPerformedSilentLogin] = useState(false)
  const { storeAuthToken } = useContext(AuthenticationContext)
  const [oktaEmail, setOktaEmail] = useState()
  const [oktaToken, setOktaToken] = useState()

  const performSilentLogin = async (email, token) => {
    const url = `api/SSOADSilentLogin`
    setOktaEmail(email)
    setOktaToken(token)
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
    await Axios.post(
      url,
      {
        email,
      },
      config
    )
      .then((response) => {
        if (response && response.status === 200) {
          setHasPerformedSilentLogin(true)
          storeAuthToken(
            response.data.data.idToken,
            response.data.data.expiresIn
          )
        } else {
          setHasPerformedSilentLogin(false)
        }
      })
      .catch(() => {
        setHasPerformedSilentLogin(false)
        storeAuthToken({})
      })
  }

  useEffect(() => {
    const performOktaAuth = async () => {
      if (authState && authState.isAuthenticated) {
        const accessToken = oktaAuth.getAccessToken()
        let email = ""
        await oktaAuth.getUser().then((info) => {
          email = info.email ?? ""
        })
        if (!hasPerformedSilentLogin) {
          performSilentLogin(email, accessToken)
        }
      }
    }
    performOktaAuth()
  }, [authState, oktaAuth])

  return [oktaEmail, oktaToken]
}
