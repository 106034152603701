import { useEffect, useRef } from "react"

export const useLoadingWithScroll = (loadingRef) => {
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const options = {
      threshold: 0.1,
    }
    if (observerRef.current === null) {
      observerRef.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          const entry = entries[0]
          if (entry.isIntersecting) {
            const element = entry.target as HTMLElement
            element.click()
          }
        },
        options
      )
    }
  }, [])

  useEffect(() => {
    if (loadingRef) {
      observerRef.current?.observe(loadingRef)
    }
  }, [loadingRef])
}
