import React from "react"

interface Props {
  showMessage: boolean
  messageError: string
}
export const ValidationMessage: React.FC<Props> = ({
  showMessage,
  messageError,
}) => {
  if (showMessage && messageError && messageError.length > 0) {
    return (
      <span role="alert" className="p-error" style={{ margin: "0px" }}>
        {messageError}
      </span>
    )
  }
  return <span style={{ display: "none" }} />
}
