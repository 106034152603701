import {
  ebateQueryKeys,
  useEbateQuery,
  getLinkedAccountsGridData,
  getTradingGroupById,
  useEbateInfinityQuery,
  getTradingGroupGridData,
} from "queries"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  LinkedAccountGridFilterRequest,
  LinkedAccountItemGrid,
} from "components/pages/maintanance/tradingGroup/linkedAccountList/linkedAccountList.models"
import { useEffect } from "react"
import {
  TradingGroupGridFilterRequest,
  TradingGroupGridItem,
} from "components/pages/maintanance/tradingGroup/tradingGroupList/tradingGroupList.models"

export const useTradingGroupById = (id: number) => {
  const fetch = async () => {
    try {
      const response = await getTradingGroupById(id)
      if (response?.status === 200) {
        return response.data
      }
      return null
    } catch (e) {
      return null
    }
  }

  const tradingGroup = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_TRADING_GROUP_BY_ID, id.toString()],
    fetch,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      refetchOnMount: "always",
    }
  )

  return tradingGroup
}

export const useLinkedAccountGridData = (
  linkedAccountRequest: LinkedAccountGridFilterRequest
) => {
  const getUniqueValues = (data): LinkedAccountItemGrid[] => {
    const flattened = data.pages.flatMap((page) => page.linkedAccountsGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as LinkedAccountItemGrid[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getLinkedAccountsGridData(linkedAccountRequest)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_LINKED_ACCOUNT_GRID_DATA,
      linkedAccountRequest.orderBy,
      linkedAccountRequest.isAscending ? "true" : "false",
      linkedAccountRequest.search,
      linkedAccountRequest.tradingGroupId.toString(),
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= linkedAccountRequest.currentPage
          ? linkedAccountRequest.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, linkedAccountRequest])

  const linkedAccounts = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > linkedAccountRequest.currentPage

  return { linkedAccounts, hasMore, isLoading, isError }
}

export const useTradingGroupGridData = (
  tradingGroupGridRequest: TradingGroupGridFilterRequest
) => {
  const getUniqueValues = (data): TradingGroupGridItem[] => {
    const flattened = data.pages.flatMap((page) => page.tradingGroupGridItems)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.id, item])).values(),
    ] as TradingGroupGridItem[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getTradingGroupGridData(tradingGroupGridRequest)
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_TRADING_GROUP_GRID_DATA,
      tradingGroupGridRequest.orderBy,
      tradingGroupGridRequest.isAscending ? "true" : "false",
      tradingGroupGridRequest.search,
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= tradingGroupGridRequest.currentPage
          ? tradingGroupGridRequest.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, tradingGroupGridRequest])

  const tradingGroups = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > tradingGroupGridRequest.currentPage

  return { tradingGroups, hasMore, isLoading, isError }
}
