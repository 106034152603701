function useSort(data = []) {
  const sort = (key, order) => {
    function compare(a, b) {
      if (a[key] < b[key]) {
        return order === "desc" ? -1 : 1
      }
      if (a[key] > b[key]) {
        return order === "desc" ? 1 : -1
      }
      return 0
    }
    return data.sort(compare)
  }
  return { sort }
}
export default useSort
