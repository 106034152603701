import { useState, useEffect } from "react"

import { debounce } from "components/helpers"

// Returns the current window size after 100ms of no resize events being triggered

interface WindowsSize {
  width?: number
  height?: number
}
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowsSize>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize, with debounce of 100ms for performance
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, 100)
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export default useWindowSize
