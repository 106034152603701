import {
  TradeAgreementGridData,
  TradeAgreementGridRequest,
} from "components/pages/collaboration/tradeAgreements/tradeAgreementList/tradeAgreement.models"
import { getTradeAgreementByCompanyGridData } from "queries"
import { ebateQueryKeys, useEbateInfinityQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { useEffect } from "react"

export const useTradeAgreementGridData = (
  tradeAgreementFilter: TradeAgreementGridRequest
) => {
  const getUniqueValues = (data): TradeAgreementGridData[] => {
    const flattened = data.pages.flatMap((page) => page.tradeAgreementFiles)
    const arrayUniqueByKey = [
      ...new Map(flattened.map((item) => [item.fileId, item])).values(),
    ] as TradeAgreementGridData[]
    return arrayUniqueByKey
  }

  const fetchData = async () => {
    const response = await getTradeAgreementByCompanyGridData(
      tradeAgreementFilter
    )
    if (response?.status === 200) {
      return response.data
    }
    throw new Error("Failed to fetch data")
  }

  const { data, fetchNextPage, isLoading, isError } = useEbateInfinityQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_TRADE_AGREEMENT_GRID_DATA,
      tradeAgreementFilter.orderBy,
      tradeAgreementFilter.isAscending ? "true" : "false",
    ],
    fetchData,
    {
      staleTime: StaleTime.infinity,
      cacheTime: CacheTime.zero,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.numberOfPages >= tradeAgreementFilter.currentPage
          ? tradeAgreementFilter.currentPage + 1
          : undefined,
    }
  )
  useEffect(() => {
    fetchNextPage()
  }, [fetchNextPage, tradeAgreementFilter])

  const tradeAgreements = data ? getUniqueValues(data) : []
  const hasMore =
    data?.pages[0]?.numberOfPages > tradeAgreementFilter.currentPage

  return { tradeAgreements, hasMore, isLoading, isError }
}
