import { ValidationResultModel } from "components/models"
import {
  PackageSeasonalityGridResponse,
  PackageSeasonalityRequest,
} from "components/pages/pricemanagement/packages/packageSeasonality/packageSeasonality.models"
import EbateAxios from "queries/ebateAxios"

const seasonalityApiUrl = "/api/seasonality"

export const getSeasonalitiesByPackage = async (id: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<PackageSeasonalityGridResponse>(
    `${seasonalityApiUrl}/GetSeasonalitiesByPackage/${id}`
  )
}

export const updateSeasonalitiesByPackage = async (
  request: PackageSeasonalityRequest
) => {
  const url = `${seasonalityApiUrl}/UpdateSeasonalitiesByPackage`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<PackageSeasonalityRequest, ValidationResultModel>(
    url,
    request
  )
}
