import {
  LoadingSpinner,
  MultiSelect,
  ValidationMessage,
} from "components/library"
import {
  createProductListRequest,
  ProductGridRequest,
} from "components/pages/maintanance/product/productList/product.models"
import { useProductDropdown } from "components/pages/maintanance/product/productDropdown/useProductDropdown"
import React, { useState } from "react"
import { OptionModel } from "components/helpers"

interface Props {
  productsSelected: any[]
  productsOnChange: (e: any) => void
  errorMessage: string
  displayValidations: boolean
  fieldName: string
  heading: string
  required?: boolean
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  initialProductList?: any[]
}

export const ProductMultiSelect: React.FC<Props> = ({
  productsSelected,
  productsOnChange,
  errorMessage,
  displayValidations,
  fieldName,
  heading,
  required,
  setLoading,
  initialProductList,
}) => {
  const [productRequest, setProductsRequest] = useState<ProductGridRequest>(
    createProductListRequest()
  )

  const { products, setIsReload, showSpinner } =
    useProductDropdown(productRequest)

  const [productList, setProductList] = useState<OptionModel[]>([])
  const [productSelectedInfo, setProductSelectedInfo] = useState<OptionModel[]>(
    []
  )

  const [searchTimeout, setSearchTimeout] = useState<any>()

  const filterByText = (textToFilter: string) => {
    setIsReload(true)
    setProductsRequest((previousProducts: ProductGridRequest) => ({
      ...previousProducts,
      currentPage: 1,
      filter: textToFilter,
    }))
  }

  const textChanged = (event: any) => {
    clearTimeout(searchTimeout)
    setSearchTimeout(
      setTimeout(() => {
        filterByText(event.filter)
      }, 500)
    )
  }

  React.useEffect(() => {
    if (initialProductList && initialProductList?.length > 0) {
      setProductSelectedInfo(
        initialProductList.map((p) => ({
          value: p.productExtensionId ?? "",
          title: `(${p.productCode}) ${p.productDescription}` ?? "",
        }))
      )
    }
  }, [initialProductList])

  React.useEffect(() => {
    if (products && products.length > 0) {
      const productSelectedButNotInList = productSelectedInfo
        .filter((ps) => !products.map((c) => c.value).includes(ps.value))
        ?.map((ps) => ({
          value: ps.value,
          title: ps.title,
          hidden: true,
        }))

      const productNew = products.concat(productSelectedButNotInList)

      setProductList(productNew)
    }
  }, [products])

  React.useEffect(() => {
    if (!showSpinner && setLoading) {
      setLoading(false)
    }
  }, [showSpinner])

  const getSelectedProductValue = (ids: string[]) => {
    const productSelectedButNotInList = ids
      .filter((p) => !productList.map((c) => c.value).includes(p))
      ?.map((c) => ({
        value: c,
        title: productSelectedInfo.find((psi) => psi.value === c)?.title ?? c,
        hidden: true,
      }))

    const options = productList.concat(productSelectedButNotInList)
    setProductList(options)

    return ids.map((c) => ({
      value: c,
      title:
        productList?.find((aps) => aps.value === c)?.title ??
        productSelectedInfo.find((psi) => psi.value === c)?.title ??
        c,
      hidden: true,
    }))
  }
  return (
    <>
      <MultiSelect
        options={productList}
        placeholder="Select options..."
        required={required}
        optionLabel="title"
        optionValue="value"
        name={fieldName}
        heading={heading}
        value={productList?.length > 0 ? productsSelected : []}
        onChange={(e) => {
          productsOnChange(e)
          setProductSelectedInfo(getSelectedProductValue(e.value))
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
      />
      <ValidationMessage
        messageError={errorMessage}
        showMessage={
          displayValidations &&
          errorMessage !== undefined &&
          errorMessage !== null &&
          errorMessage.length > 0
        }
      />
      {showSpinner && <LoadingSpinner />}
    </>
  )
}
